import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux';

import RedirectCompleteness from '@/profile/components/ProfileCompleteness/RedirectCompleteness';
import { black, borderColor, darkGray, sand } from '@/theme/colors';

import Network from './Network';

const getStyles = makeStyles(() => ({
  title: {
    marginTop: 0,
    marginBottom: 40,
  },
  activeNetwork: {
    marginTop: 30,
  },
  invitations: {
    backgroundColor: sand,
    padding: 20,
    border: `1px solid ${borderColor}`,
  },
  invitationsTitle: {
    color: black,
    fontSize: 20,
    fontWeight: 500,
    marginBottom: 5,
  },
  invitationsText: {
    color: darkGray,
  },
  awaitingAcceptanceNetwork: {
    borderTop: 0,
  },
}));

function ExpertNetwork({ profile }: any) {
  const s = getStyles();

  const awaitingAcceptanceNetworks = profile.expert_internal_networks.filter(
    (n: any) => n.connection_state === 'awaiting_acceptance'
  );

  const activeNetworks = profile.expert_internal_networks.filter(
    (n: any) => n.connection_state === 'active'
  );

  return (
    <div>
      <RedirectCompleteness profile={profile} />

      <h3 className={s.title}>My Clients</h3>

      {awaitingAcceptanceNetworks.length > 0 && (
        <div>
          <div className={s.invitations}>
            <div className={s.invitationsTitle}>
              Invitations ({awaitingAcceptanceNetworks.length})
            </div>
            <div className={s.invitationsText}>
              By accepting, you are granting access to your profile information so they can connect
              with you more easily.
            </div>
          </div>
          {awaitingAcceptanceNetworks.map((n: any) => (
            <Network key={n.id} className={s.awaitingAcceptanceNetwork} networkExpert={n} />
          ))}
        </div>
      )}

      {activeNetworks.map((n: any) => (
        <Network key={n.id} active className={s.activeNetwork} networkExpert={n} />
      ))}
    </div>
  );
}

// @ts-expect-error TS(2630) FIXME: Cannot assign to 'ExpertNetwork' because it is a f... Remove this comment to see the full error message
ExpertNetwork = connect((state, ownProps) => ({
  // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
  viewer: state.viewer,
  // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
  profile: state.profiles.fullProfiles[ownProps.profileId],
}))(ExpertNetwork);

export default ExpertNetwork;
