import TextField from '@mui/material/TextField';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { fetchGroups } from '@/actions/group';
import LongList from '@/components/LongList';
import TeamPreview from '@/components/TeamPreview';
import { debounce, normalizeSpace } from '@/utils';

// @ts-expect-error TS(2339) FIXME: Property 'fetchGroups' does not exist on type '{}'... Remove this comment to see the full error message
const TeamList = memo(({ fetchGroups, viewer, pageInfo, edges, loading }) => {
  const [name, setName] = useState('');
  const [enableSearch, setEnableSearch] = useState(false);

  useEffect(() => {
    if (!enableSearch) return;
    handleSearch(name);
  }, [name]);

  useEffect(() => {
    if (enableSearch) return;

    setEnableSearch(!!edges.length && edges.length > 5);
  }, [edges.length]);

  const filterGroups = useCallback(
    ({ force, cursor, name }: any) => {
      fetchGroups({
        force,
        collection: 'default',
        cursor,
        memberOnly: !viewer.admin,
        memberLimit: 5,
        billingAccount: true,
        stats: true,
        name,
      });
    },
    [viewer]
  );

  const handleSearch = useCallback(
    debounce((name: any) => {
      filterGroups({
        force: true,
        name,
      });
    }, 500),
    [filterGroups]
  );

  const handleMore = (e: any) => {
    e.preventDefault();
    if (!pageInfo.hasNextPage) return;

    if (!edges.length) throw new Error('last group edge not found');

    filterGroups({
      cursor: edges[edges.length - 1].cursor,
      name,
    });
  };

  return (
    <div>
      <LongList
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        loading={loading}
        title="Teams"
        hasNextPage={pageInfo.hasNextPage}
        onMore={handleMore}
        action={
          enableSearch && (
            <TextField
              value={name}
              fullWidth={false}
              onChange={({ target: { value } }) => setName(normalizeSpace(value))}
              placeholder="Filter by name"
            />
          )
        }
      >
        {edges.map((g: any) => (
          <TeamPreview key={g.node.id} team={g.node} />
        ))}
      </LongList>
    </div>
  );
});

export default connect(
  (state) => {
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    const page = state.groups.default;
    return {
      ...page,
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      viewer: state.viewer,
    };
  },
  {
    fetchGroups,
  }
)(TeamList);
