import { Button as MaterialButton } from '@mui/material';
import Alert from '@mui/material/Alert';
import cx from 'classnames';
import React, { FC, useEffect, useMemo, useState } from 'react';

import Divider from '@/components/Divider';
import EditIcon from '@/components/EditIcon/EditIcon';
import FAIcon from '@/components/Icon/FAIcon';
import MaterialIcon from '@/components/Icon/MaterialIcon';
import Link from '@/components/Link';
import Markdown from '@/components/Markdown';
import SendMessageButton from '@/components/SendMessageButton';
import config from '@/config';
import { chargeLabels, fileTypes, formatDuration, parseDuration } from '@/core/consultation';
import { money } from '@/core/money';
import { getTimezoneDescription } from '@/core/time';
import { usePermissions } from '@/hooks/useAppContext';
import { darkGreen } from '@/theme/colors';
import { capitalize, formatDate, formatDateTime } from '@/utils';

import ChangeCarrierDialog from '../ChangeCarrierDialog';
import EditTrackingCodeDialog from '../EditTrackingCodeDialog';
import RecordingPlayer from '../RecordingPlayer';
import SelectPhoneNumber from '../SelectPhoneNumber';
import TranscriptPromo from '../TranscriptPromo';
import TranscriptStepper from '../TranscriptStepper';
import s from './CallDetails.module.scss';
import Notes from './Notes';
import Actions from './call/Actions';
import ProposedTimes from './call/ProposedTimes';
import RejectedTimes from './call/RejectedTimes';

const Detail = ({ title, children, style = {}, inline = true }: any) => (
  <div className={s.callDetail} style={style}>
    <div className={s.detailTitle} style={style}>
      {title}
    </div>
    <div className={cx(s.detailContent, { [s.detailContentInline]: inline })} style={style}>
      {children}
    </div>
  </div>
);

interface CostProps {
  cost: {
    credits: number;
    charge_type: string;
  };
}

const Cost: React.FC<CostProps> = ({ cost }) => {
  if (!cost || !cost.credits) {
    return <div className={s.detailCostItem}>{money(0, 'OFC').formatted()} credits</div>;
  }
  const formatted = money(cost.credits).negate().formatted();
  const withUnit = `${formatted} ${formatted === '1' ? 'credit' : 'credits'}`;
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const label = chargeLabels[cost.charge_type];
  return (
    <div className={s.detailCostItem}>
      {label || cost.charge_type}: {withUnit}
    </div>
  );
};

function CostsSum({ costs }: any) {
  const sum = costs.reduce((acc: any, tx: any) => acc.add(money(tx.credits)), money(0, 'OFC'));
  const formatted = sum.negate().formatted();
  return `${formatted} ${formatted === '1' ? 'credit' : 'credits'}`;
}

const CostDetail = ({ costs }: any) => {
  const estimated = costs
    // estimated if any cost not completed
    .map((c: any) => c.state !== 'completed')
    .reduce((acc: any, incomplete: any) => acc || incomplete, false);

  return (
    <Detail title={`Cost${estimated ? ' (estimated)' : ''}`}>
      <CostsSum costs={costs} />
      {costs.map((cost: any) => (
        <Cost key={cost.id} cost={cost} />
      ))}
    </Detail>
  );
};

interface CallDetailsProps {
  consultation?: Record<string, any>;
  expertRequest?: Record<string, any>;
  requesterName: string;
  expertName: string;
  isViewerExpert: boolean;
  isExpertActive: boolean;
  isViewerRequester: boolean;
  isViewerAdmin: boolean;
  user: any;
  isExpired: boolean;
  isCompleted: boolean;
  isConfirmed: boolean;
  isDenied: boolean;
  isIncomplete: boolean;
  isWaitingConfirmation: boolean;
  isWaitingExpertConfirmation: boolean;
  isWaitingRequesterConfirmation: boolean;
  isWaitingViewerConfirmation: boolean;
  isWrittenConsultation: boolean;
  canEdit: boolean;
  canConfirm: boolean;
  canCancel: boolean;
  canDeny: boolean;
  canReschedule: boolean;
  shouldReview: boolean;
  hasReview: boolean;
  viewer: any;
  userContext: any;
  joinInfo: any;
  showDetails: boolean;
  isMobileVersion: boolean;
  ongoingCall: boolean;
  selectedDate: Date;
  transcription: any;
  isCanceled?: boolean;
  openReviewCall: boolean;
  onRequestTranscript: () => void;
  onOpenSuggestTime: () => void;
  onDateSelect: (date: Date) => void;
  onConfirm: () => void;
  onCancel: () => void;
  onDeny: () => void;
  onReschedule: () => void;
  onInviteParticipant: () => void;
}

const CallDetails: FC<CallDetailsProps> = ({
  consultation = {},
  joinInfo,
  viewer,
  userContext,
  isViewerExpert,
  isViewerRequester,
  requesterName,
  expertName,
  showDetails,
  isMobileVersion,
  isCanceled = false,
  isConfirmed,
  isCompleted,
  isExpired,
  isDenied,
  canConfirm,
  selectedDate,
  isWaitingViewerConfirmation,
  ongoingCall,
  openReviewCall,
  onRequestTranscript,
  onOpenSuggestTime,
  onDateSelect,
  onConfirm,
  onCancel,
  onDeny,
  onReschedule,
  onInviteParticipant,
  transcription,
  canReschedule,
  canCancel,
  canDeny,
}) => {
  const [chatUrl, setChatUrl] = useState(undefined);
  const [chatFilename, setChatFilename] = useState(undefined);
  const [transcriptUrl, setTranscriptUrl] = useState(undefined);
  const [transcriptFilename, setTranscriptFilename] = useState(undefined);
  const [openTrackingCodeDialog, setOpenTrackingCodeDialog] = useState(false);
  const [openCarrierDialog, setOpenCarrierDialog] = useState(false);

  const {
    requester = {},
    expert = {},
    starts_at: startsAt,
    started_at: startedAt,
    external,
    conference,
    html_url: htmlUrl,
    cancel_reason: cancelReason,
    requester_identifier: requesterIdentifier,
    expert_payout: expertPayout,
    estimated_expert_payout: estimatedExpertPayout,
    expert_identifier: expertIdentifier,
    expert_request: expertRequest,
    costs,
    transcription_order: transcriptionOrder,
    expected_duration: expectedDuration,
    billing_duration: billingDuration,
    recording_duration: recordingDuration,
    unpaid,
    tracking_code: trackingCode,
    state,
    proposed_times: proposedTimes = [],
    rejected_times: rejectedTimes = [],
    description: notes,
    disclosure,
    recording_url: recordingURL,
  } = consultation || {};

  const accountId = expertRequest?.project?.group?.billing_account?.id;

  const [canOrderTranscript] = usePermissions(
    [{ service: 'billing', action: 'create_transaction', resource: accountId }],
    [accountId]
  );

  const isViewerAdmin = !!viewer.admin;
  const canEdit = isViewerRequester || isViewerExpert || isViewerAdmin;
  const user = isViewerExpert ? requester : expert;
  const userFirstName =
    disclosure === 'private'
      ? 'client'
      : user
        ? user.first_name
        : isViewerExpert
          ? requesterName
          : expertName;

  const isExpertActive = expert && expert.expert_state === 'active';
  const isWaitingExpertConfirmation = state === 'negotiating_expert_time';
  const isWaitingRequesterConfirmation = state === 'negotiating_client_time';
  const isWaitingConfirmation = isWaitingExpertConfirmation || isWaitingRequesterConfirmation;
  const isWaitingOtherPartConfirmation =
    (isViewerExpert && isWaitingRequesterConfirmation) ||
    (!isViewerExpert && isWaitingExpertConfirmation);
  const canSuggestTime = isExpertActive && isWaitingConfirmation && canEdit;
  const showProposedTimes =
    isWaitingConfirmation || isDenied || isExpired || (isCanceled && !startsAt);
  const isExpertFirstAttempt =
    isViewerExpert && isWaitingExpertConfirmation && rejectedTimes.length === 0;
  const showRejectedTimes =
    (isWaitingConfirmation || userContext === 'admin') && rejectedTimes.length > 0;

  const canChangeCarrier = viewer.admin && isWaitingConfirmation;
  // TODO: Use API permissons service
  const canViewTranscript =
    isViewerAdmin || (!isViewerExpert && consultation.group?.account_type === 'enterprise');

  const { phone_numbers: phoneNumbers, registrants = [] } = conference || {};

  useEffect(() => {
    if (viewer.admin && conference !== undefined) {
      (async function loadChatUrl() {
        if (conference?.id) {
          const chat = conference?.attachments?.find(
            (attachment: any) => attachment.file_type === fileTypes.chat
          );
          setChatUrl(
            // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
            chat === undefined ? undefined : `${chat.location}&download=true`
          );
          // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          setChatFilename(`chat_consultation_${consultation.id}.txt`);
        }
      })();
    }
    if (canViewTranscript && conference !== undefined) {
      (async function loadTranscriptUrl() {
        if (conference?.id) {
          const transcript = conference?.attachments?.find(
            (attachment: any) => attachment.file_type === fileTypes.transcript
          );
          setTranscriptUrl(
            // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
            transcript === undefined ? undefined : `${transcript.location}&download=true`
          );
          setTranscriptFilename(
            // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
            `audio_transcript_consultation_${consultation.id}.txt`
          );
        }
      })();
    }
  }, [canViewTranscript, conference, consultation.id, viewer.admin]);

  const renderDuration = () => {
    const parsedBillingDuration = billingDuration && parseDuration(billingDuration);
    if (parsedBillingDuration && parsedBillingDuration.seconds() > 0 && !isCanceled) {
      return <Detail title="Duration">{formatDuration(parsedBillingDuration)}</Detail>;
    }

    const parsedExpectedDuration = expectedDuration && parseDuration(expectedDuration);
    if (parsedExpectedDuration && parsedExpectedDuration.seconds() > 0) {
      return <Detail title="Duration">{formatDuration(parsedExpectedDuration)}</Detail>;
    }

    const parsedRecordingDuration = recordingDuration && parseDuration(recordingDuration);
    if (parsedRecordingDuration && parsedRecordingDuration.seconds() > 0) {
      return <Detail title="Duration">{formatDuration(parsedRecordingDuration)}</Detail>;
    }
  };

  const renderPayout = () => {
    if (!isViewerExpert && !isViewerAdmin) return;

    const payoutLabel = isViewerExpert ? 'Earnings' : 'Expert Payout';

    if (unpaid) {
      return <Detail title={`${payoutLabel}`}>$0</Detail>;
    }

    if (expertPayout > 0) {
      return <Detail title={`${payoutLabel}`}>${(expertPayout / 100).toFixed(2)}</Detail>;
    }

    if (estimatedExpertPayout > 0) {
      return (
        <Detail title={`${payoutLabel} (estimated)`}>
          ${(estimatedExpertPayout / 100).toFixed(2)}
        </Detail>
      );
    }
  };

  const renderTrackingCode = () => {
    if (!isViewerAdmin && isViewerExpert) return;

    return (
      <Detail title="Tracking Code">
        {trackingCode && <span className={s.spanTracking}>{trackingCode}</span>}
        <EditIcon onClick={() => setOpenTrackingCodeDialog(true)} />
      </Detail>
    );
  };

  let userIdentifier;
  if (consultation.conference?.carrier !== 'zoom') {
    if (viewer.admin) {
      userIdentifier = `${requesterIdentifier} (requester) ${expertIdentifier} (expert)`;
    } else {
      userIdentifier = isViewerExpert ? expertIdentifier : requesterIdentifier;
    }
  }

  const renderCostAndTracking = () => (
    <div className={s.flex}>
      {!isViewerExpert && costs && !!costs.length && <CostDetail costs={costs} />}
      {renderTrackingCode()}
    </div>
  );

  const renderCarrier = () => {
    if (!canChangeCarrier) return;

    return (
      <Detail title="Carrier">
        {conference.carrier && (
          <span className={s.spanTracking}>{capitalize(conference.carrier)}</span>
        )}
        <EditIcon onClick={() => setOpenCarrierDialog(true)} />
      </Detail>
    );
  };

  const registrantsByInviter: { [key: string]: any[] } = {};
  registrants?.forEach((registrant: any) => {
    if (
      registrant.invited_by !== '' &&
      ![expertIdentifier, requesterIdentifier].includes(registrant.identifier)
    ) {
      if (!registrantsByInviter[registrant.invited_by]) {
        registrantsByInviter[registrant.invited_by] = [];
      }
      registrantsByInviter[registrant.invited_by].push(registrant);
    }
  });
  const inviters = Object.keys(registrantsByInviter);

  const renderRegistrant = (
    registrant: any // TODO: When registrant is associated with a profile use the profile info
  ) => (
    // to render the registrant in the same way as expert/requester
    <li>
      {registrant.name}: ({registrant.email})
    </li>
  );

  const showParticipants = useMemo(
    () => ['confirmed', 'in-progress', 'completed'].includes(state),
    [state]
  );

  return (
    <div>
      {consultation.conference?.recording_state === 'waiting' && (
        <Alert severity="warning" style={{ marginBottom: 20 }}>
          Waiting for pending recording
        </Alert>
      )}
      {transcription && transcription.summary && (
        <Detail title="AI Summary" inline={false}>
          <Markdown body={transcription.summary} />
        </Detail>
      )}
      {showParticipants && (
        <div>
          {requester && requester.username && (
            <Detail inline={false} title="Requester">
              <Link to={requester.html_url}>{requesterName}</Link>
              {isViewerAdmin && (
                <SendMessageButton userId={requester.id} style={{ marginLeft: -10 }} />
              )}
            </Detail>
          )}
          {expert && expert.username && (
            <Detail inline={false} title="Expert">
              <Link to={expert.html_url}>{expertName}</Link>
              {isViewerAdmin && (
                <SendMessageButton userId={expert.id} style={{ marginLeft: -10 }} />
              )}
            </Detail>
          )}
          {inviters.length > 0 && (
            <Detail inline={false} title="Other Participants">
              {inviters.map((inviter, i) => (
                <Detail
                  key={i}
                  title={`Invited by ${inviter}`}
                  inline={false}
                  style={{ marginLeft: 10 }}
                >
                  {registrantsByInviter[inviter]?.map(renderRegistrant)}
                </Detail>
              ))}
            </Detail>
          )}
        </div>
      )}

      {/* We should display even if other details are hidden */}
      {!showDetails && renderCostAndTracking()}
      {!showDetails && renderPayout()}
      {!showDetails && renderCarrier()}

      {showDetails && (
        <div>
          {recordingURL && (
            <div>
              <RecordingPlayer
                conference={conference}
                enabled={!ongoingCall && !openReviewCall}
                fallbackURL={recordingURL}
              />

              {canOrderTranscript &&
                isCompleted &&
                !isViewerExpert &&
                (!transcriptionOrder || transcriptionOrder.state === 'canceled') && (
                  <div className={s.transcript}>
                    <TranscriptPromo onRequestTranscript={onRequestTranscript} />
                  </div>
                )}

              {!isViewerExpert &&
                transcriptionOrder &&
                ['transcribing', 'received'].includes(transcriptionOrder.state) && (
                  <div className={s.transcript}>
                    <TranscriptStepper order={transcriptionOrder} />
                  </div>
                )}
              {chatUrl && (
                <div className={s.chat}>
                  <a href={chatUrl} download={chatFilename}>
                    <MaterialButton
                      startIcon={<MaterialIcon icon="speaker_notes" style={{ fontSize: 22 }} />}
                      style={{ fontSize: 16 }}
                    >
                      Download Chat
                    </MaterialButton>
                  </a>
                </div>
              )}
              {transcriptUrl && (
                <div className={s.chat}>
                  <a href={transcriptUrl} download={transcriptFilename}>
                    <MaterialButton
                      startIcon={<MaterialIcon icon="speaker_notes" style={{ fontSize: 22 }} />}
                      style={{ fontSize: 16 }}
                    >
                      Download Automatically Produced Transcript
                    </MaterialButton>
                  </a>
                </div>
              )}
            </div>
          )}

          {startsAt && (
            <Detail title="Date & Time">
              {formatDateTime(startsAt, viewer.timezone)}&nbsp;
              <span className={s.timezone}>{getTimezoneDescription(viewer.timezone)}</span>
            </Detail>
          )}

          {external && (
            <Detail title="Date">
              {formatDate(startedAt, viewer.timezone)}&nbsp;
              <span className={s.timezone}>{getTimezoneDescription(viewer.timezone)}</span>
            </Detail>
          )}

          {renderDuration()}

          {renderCostAndTracking()}

          {renderPayout()}

          {renderCarrier()}

          {conference ? (
            <div>
              {isConfirmed && (
                <Detail title="Call in Details">
                  {conference?.carrier !== 'zoom' && (
                    <>
                      {phoneNumbers && phoneNumbers.length > 0 && (
                        <SelectPhoneNumber
                          viewer={viewer}
                          className={s.phoneDetail}
                          numbers={phoneNumbers}
                        />
                      )}
                      {userIdentifier && (
                        <div className={s.phoneDetail}>
                          <FAIcon
                            icon="hashtag"
                            size={20}
                            color={darkGreen}
                            style={{ marginRight: 5 }}
                          />
                          {userIdentifier}
                        </div>
                      )}
                    </>
                  )}
                  {conference?.carrier === 'zoom' && (
                    <>
                      {joinInfo?.dial_in_number && joinInfo?.dial_in_region && (
                        <div className={s.phoneDetail}>
                          <FAIcon
                            icon="phone"
                            size={20}
                            color={darkGreen}
                            style={{ marginLeft: 5, marginRight: 5 }}
                          />
                          {joinInfo.dial_in_region}: {joinInfo.dial_in_number}
                        </div>
                      )}
                      {joinInfo?.meeting_id && (
                        <div className={s.phoneDetail}>
                          <FAIcon
                            icon="hashtag"
                            size={20}
                            color={darkGreen}
                            style={{ marginRight: 5 }}
                          />
                          Meeting ID: {joinInfo.meeting_id}
                        </div>
                      )}
                      {joinInfo?.passcode && (
                        <div className={s.phoneDetail}>
                          <FAIcon
                            icon="hashtag"
                            size={20}
                            color={darkGreen}
                            style={{ marginRight: 5 }}
                          />
                          Passcode: {joinInfo.passcode}
                        </div>
                      )}
                      {joinInfo?.pin && (
                        <div className={s.phoneDetail}>
                          <FAIcon
                            icon="hashtag"
                            size={20}
                            color={darkGreen}
                            style={{ marginRight: 5 }}
                          />
                          {joinInfo.pin}
                        </div>
                      )}
                      {joinInfo?.dial_in_numbers_url && (
                        <div className={s.phoneDetail}>
                          <FAIcon
                            icon="globe"
                            size={20}
                            color={darkGreen}
                            style={{ marginLeft: 5, marginRight: 5 }}
                          />
                          <a href={joinInfo?.dial_in_numbers_url} target="_blank" rel="noreferrer">
                            Find your local dial-in number
                          </a>
                        </div>
                      )}
                    </>
                  )}
                  {htmlUrl && (
                    <div className={s.consultationUrl}>
                      <FAIcon
                        icon="laptop"
                        size={20}
                        color={darkGreen}
                        style={{ marginRight: 5 }}
                      />
                      {htmlUrl}
                    </div>
                  )}
                </Detail>
              )}
            </div>
          ) : (
            isConfirmed && <div>A research manager will provide call in details.</div>
          )}

          {isCanceled && cancelReason && <Detail title="Cancel Reason">{cancelReason}</Detail>}
        </div>
      )}

      {showProposedTimes && (
        <ProposedTimes
          selectedDate={selectedDate}
          isWaitingViewerConfirmation={isWaitingViewerConfirmation}
          isCanceled={isCanceled}
          isDenied={isDenied}
          isExpired={isExpired}
          canConfirm={canConfirm}
          onOpenSuggestTime={onOpenSuggestTime}
          onDateSelect={onDateSelect}
          viewer={viewer}
          userFirstName={userFirstName}
          isWaitingOtherPartConfirmation={isWaitingOtherPartConfirmation}
          canSuggestTime={canSuggestTime}
          proposedTimes={proposedTimes}
          rejectedTimes={rejectedTimes}
          expectedDuration={expectedDuration}
        />
      )}

      <Actions
        canConfirm={canConfirm}
        canCancel={canCancel}
        canReschedule={canReschedule}
        canDeny={canDeny}
        onConfirm={onConfirm}
        onCancel={onCancel}
        onDeny={onDeny}
        onReschedule={onReschedule}
        onInviteParticipant={onInviteParticipant}
        consultation={consultation}
        isConfirmed={isConfirmed}
        isViewerExpert={isViewerExpert}
        isExpertFirstAttempt={isExpertFirstAttempt}
        canInviteParticipant={config.enableInviteParticipant && isConfirmed}
      />

      <EditTrackingCodeDialog
        open={openTrackingCodeDialog}
        onClose={() => setOpenTrackingCodeDialog(false)}
        consultation={consultation}
      />

      <ChangeCarrierDialog
        initialValues={{ carrier: consultation.conference?.carrier }}
        open={openCarrierDialog}
        onClose={() => setOpenCarrierDialog(false)}
        consultation={consultation}
      />

      {showRejectedTimes && (
        <RejectedTimes
          viewer={viewer}
          rejectedTimes={rejectedTimes}
          expectedDuration={expectedDuration}
        />
      )}

      {notes && <Notes notes={notes} />}

      {isMobileVersion && <Divider spacing={30} />}
    </div>
  );
};

export default CallDetails;
