import { PureComponent } from 'react';

import FAIcon from '@/components/Icon/FAIcon';
import { formatExperiencePeriod } from '@/core/profile';
import { darkGray } from '@/theme/colors';
import { highlight } from '@/utils';

import s from './Education.module.scss';

interface EducationProps {
  education: Array<{
    start_date: string;
    end_date: string;
    description_snippets?: string[];
    degree?: string;
    field_of_study?: string;
    school?: string;
  }>;
}

class Education extends PureComponent<EducationProps> {
  render() {
    const { education } = this.props;
    if (!education || education.length === 0) return null;
    return (
      <div className={s.educations}>
        {education.map((e: any) => {
          const period = formatExperiencePeriod(e.start_date, e.end_date, false, {
            duration: false,
          });
          const description = e.description_snippets && e.description_snippets.join(' (…) ');

          const titleSeparator = e.degree && e.field_of_study ? ' · ' : '';
          const title = `${e.degree || ''}${titleSeparator}${e.field_of_study || ''}`;

          const schoolSeparator = e.school && (period || description) ? ' · ' : '';
          const periodSeparator = period && description ? ' · ' : '';

          return (
            <div key={JSON.stringify(e)} className={s.education}>
              <div className={s.educationIcon}>
                <FAIcon
                  iconSet="fal"
                  icon="graduation-cap"
                  style={{ marginRight: 5 }}
                  color={darkGray}
                  size={14}
                />
              </div>
              <div>
                <div
                  // @ts-expect-error TS(2322) FIXME: Type 'string | HTMLElement | DocumentFragment' is ... Remove this comment to see the full error message
                  dangerouslySetInnerHTML={{ __html: highlight(title) }}
                />
                <div>
                  {e.school && (
                    <span
                      // @ts-expect-error TS(2322) FIXME: Type 'string | HTMLElement | DocumentFragment' is ... Remove this comment to see the full error message
                      dangerouslySetInnerHTML={{ __html: highlight(e.school) }}
                    />
                  )}
                  {schoolSeparator}
                  {period}
                  {periodSeparator}
                  {description && (
                    <span
                      className={s.educationSummary}
                      dangerouslySetInnerHTML={{
                        // @ts-expect-error TS(2322) FIXME: Type 'string | HTMLElement | DocumentFragment' is ... Remove this comment to see the full error message
                        __html: highlight(`(…) ${description}…`),
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default Education;
