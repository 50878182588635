import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { fetchSignupDomain } from '@/actions/domain';
import Alert from '@/components/Alert/OptionalAlert';
import Button from '@/components/Button/Button';
import Form from '@/components/Form/Form';
import Link from '@/components/Link';
import { mediumBlue } from '@/theme/colors';

import s from './DomainSelector.module.scss';

const useInputStyles = makeStyles(() => ({
  input: {
    textAlign: 'right',
    '&::placeholder': {
      color: mediumBlue,
      opacity: 1,
    },
  },
  underline: {
    '&:before, &:after': {
      borderColor: mediumBlue,
    },
    '&:hover:not(.Mui-disabled):before, &:focus:not(.Mui-disabled):before': {
      borderColor: mediumBlue,
    },
  },
}));

function DomainSelect({ fetchSignupDomain }: any) {
  const navigate = useNavigate();
  const inputClasses = useInputStyles();

  const [domainName, setDomainName] = useState('');
  const [error, setError] = useState('');

  const goToDomain = async (event: any) => {
    event.preventDefault();
    if (!domainName) {
      setError("Enter your workspace's domain");
      return;
    }

    const domain = await fetchSignupDomain(domainName);
    if (!domain) {
      setError('Invalid team domain');
      return;
    }

    setError('');

    navigate(`/${domainName}/signup/member`);
  };

  const handleContactUs = (e: any) => {
    // @ts-expect-error TS(2339): Property 'HubSpotConversations' does not exist on ... Remove this comment to see the full error message
    const status = window.HubSpotConversations?.widget?.status();
    if (status?.loaded) {
      e.preventDefault();
      // @ts-expect-error TS(2339): Property 'HubSpotConversations' does not exist on ... Remove this comment to see the full error message
      window.HubSpotConversations?.widget?.open();
    }
  };

  return (
    <div className={s.root}>
      <Alert type="error" center message={error} />
      <h2 className={s.title}>Sign up to your team</h2>
      <h4 className={s.subTitle}>Enter your workspace&rsquo;s OnFrontiers URL</h4>
      <Form onSubmit={goToDomain}>
        <div style={{ display: 'inline-block' }}>
          <div className={s.domainField}>
            <TextField
              style={{ width: 232 }}
              placeholder="domain"
              margin="none"
              InputProps={{ classes: inputClasses }}
              onChange={({ target: { value } }: any) => setDomainName(value)}
            />
            <span style={{ paddingLeft: 5 }}>.onfrontiers.com</span>
          </div>
          <Button fullWidth type="submit" size="normal">
            Continue
          </Button>
        </div>
      </Form>
      <div className={s.instructions}>
        Don&rsquo;t know your team URL?{' '}
        <Link absolutePath="https://support.onfrontiers.com/en/" onClick={handleContactUs}>
          Contact us
        </Link>
      </div>
    </div>
  );
}

export default connect(undefined, {
  fetchSignupDomain,
})(DomainSelect);
