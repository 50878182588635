import React from 'react';

import { canRequestConsultation } from '@/core/consultation';

import ExpertCard from './ExpertCard';

export default function ExpertWidgetHorizontal({
  dark,
  mobile,
  pictureSize = 80,
  profile,
  badgeText,
  highlighted,
  ...rest
}: any) {
  return (
    <ExpertCard
      {...rest}
      dark={dark}
      mobile={mobile}
      pictureSize={pictureSize}
      expert={{
        ...profile,
        url: profile.html_url,
        picture: profile.picture_url,
        badge_text: badgeText,
        highlight: highlighted,
        experience: profile.experiences && profile.experiences[0],
        can_request_consultation: canRequestConsultation(profile),
      }}
    />
  );
}
