import { add, removeAt } from '@/utils/reducer';

import { SEARCH, SEARCH_CLEAR_PROFILE_SELECTION, SEARCH_SELECT_PROFILE } from '.';

const initialState = {
  queries: {},
  query: {},
  selectedProfiles: [],
  error: undefined,
};

const initialSearchState = {
  fetching: false,
  edges: [],
};

function reduceSearch(state = initialSearchState, action: any) {
  switch (action.type) {
    case SEARCH:
      return {
        ...state,
        edges: [...state.edges, ...(action.edges || [])],
        // @ts-expect-error TS(2339): Property 'pageInfo' does not exist on type '{ fetc... Remove this comment to see the full error message
        pageInfo: { ...state.pageInfo, ...action.pageInfo },
        fetching: action.fetching,
      };
    default:
      return state;
  }
}

export default function searches(state = initialState, action: any) {
  switch (action.type) {
    case SEARCH:
      const { queries } = state;
      return {
        ...state,
        error: action.error,
        query: {
          ...state.query,
          ...action.query,
        },
        queries: {
          ...queries,
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          [action.hash]: reduceSearch(queries[action.hash], action),
        },
      };
    case SEARCH_SELECT_PROFILE:
      if (action.selected) {
        return {
          ...state,
          selectedProfiles: add(
            state.selectedProfiles,
            action.profile,
            (p: any) => p.id === action.profile.id
          ),
        };
      }

      return {
        ...state,
        selectedProfiles: removeAt(
          state.selectedProfiles,
          // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
          state.selectedProfiles.findIndex((p) => p.id === action.profile.id)
        ),
      };
    case SEARCH_CLEAR_PROFILE_SELECTION:
      return {
        ...state,
        selectedProfiles: [],
      };
    default:
      return state;
  }
}
