import { Box, Button, Popover } from '@mui/material';

import { teal500 } from '@/theme/colors';

import PlivoPopoverContent from './PlivoPopoverContent';
import TwilioPopoverContent from './TwilioPopoverContent';

const CarriersPopoverContent = {
  twilio: TwilioPopoverContent,
  plivo: PlivoPopoverContent,
};

function TimelinePopover({ carrier, participant, range, ...other }: any) {
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const Content = CarriersPopoverContent[carrier];

  return (
    <Popover
      {...other}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box p={2}>
        {Content && <Content participant={participant} range={range} />}
        <div style={{ textAlign: 'center' }}>
          {!participant.hasMetrics() && <p>Metrics are pending</p>}

          <Button href={participant.url()} target="__blank" style={{ width: 120, color: teal500 }}>
            View Details
          </Button>
        </div>
      </Box>
    </Popover>
  );
}

export default TimelinePopover;
