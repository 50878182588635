import { redirect } from 'react-router-dom';

import { CandidateState } from '@/__generated__/graphql';
import { awaitingProjectMembershipApproval } from '@/actions/project';
import { track } from '@/actions/tracking';
import InaccessibleArchived from '@/components/InaccessibleArchived';
import LayoutPage from '@/components/Layout/LayoutPage';
import RequestProjectAccess from '@/components/RequestProjectAccess';
import { fetchStarting } from '@/consultation/store';
import { Viewer } from '@/core/viewer';
import { ExpertRequest } from '@/expertrequest';
import {
  fetchExpertRequest,
  fetchExpertRequestCandidates,
  updateExpertRequestCandidate,
} from '@/expertrequest/store';
import {
  ActionContext,
  firstQueryValue,
  redirectIfAgreementsNotAccepted,
  redirectIfEmailNotVerified,
} from '@/routes/routesMiddleware';

import ExpertRequestComponent, { SectionType } from './ExpertRequest';

function requestAdd(query: any, viewer: Viewer, id: string, accessRequested: boolean) {
  document.title = 'Expert Request';
  return (
    <LayoutPage showNav selected="expert_requests">
      <RequestProjectAccess
        viewer={viewer}
        path="expert_request"
        query={query}
        expertRequestId={id}
        accessRequested={accessRequested}
      />
    </LayoutPage>
  );
}

export const action = async (id: string, { store, location, query }: ActionContext) => {
  const shouldRedirect =
    redirectIfEmailNotVerified(store) || redirectIfAgreementsNotAccepted(store, location);

  if (shouldRedirect) return shouldRedirect;

  const { viewer } = store.getState();
  if (!viewer.id) return requestAdd(query, viewer, id, false);

  let expertRequest: ExpertRequest;
  try {
    expertRequest = await store.dispatch(fetchExpertRequest(id));
    await Promise.all(
      [
        fetchExpertRequestCandidates(id, 'suggested', true),
        fetchExpertRequestCandidates(id, 'matched', true),
        fetchStarting(),
      ].map(store.dispatch)
    );
  } catch (e) {
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    if (!e.isPermissionError) throw e;
    const accessRequested = await store.dispatch(
      awaitingProjectMembershipApproval({ expertRequestId: id })
    );
    return requestAdd(query, viewer, id, accessRequested);
  }

  if (
    !expertRequest ||
    !expertRequest.permissions ||
    !expertRequest.permissions.includes('view_private')
  )
    return redirect('/404');
  if (expertRequest.archived && !expertRequest.permissions.includes('view_archived')) {
    // @ts-expect-error TS(2554) FIXME: Expected 3-5 arguments, but got 1.
    store.dispatch(track('promo.show.request.archived'));
    document.title = expertRequest.name;
    return (
      <InaccessibleArchived
        selectedTab="expert_requests"
        entity="request"
        entities="requests"
        trackingCode="promo.chat.request.archived"
      />
    );
  }

  const selectedSection = firstQueryValue(query, 'section');
  const isGoodMatch = firstQueryValue(query, 'is_good_match') || '';
  const candidateId = firstQueryValue(query, 'candidate_id');

  if (candidateId && ['no', 'maybe', 'yes'].includes(isGoodMatch)) {
    try {
      const candidateData: {
        id: string;
        client_note: { is_good_match: string };
        state?: CandidateState;
      } = {
        id: candidateId,
        client_note: { is_good_match: isGoodMatch },
      };
      if (isGoodMatch === 'yes') {
        candidateData.state = CandidateState.Matched;
      }
      await store.dispatch(updateExpertRequestCandidate(id, candidateData));
    } catch (err) {
      Promise.reject(err);
    }
  }

  const targetSection = selectedSection || 'experts';

  const fetchMoreCandidates = async (
    type: 'matched' | 'suggested',
    pageInfo: Record<string, any>
  ) => {
    if (pageInfo?.hasNextPage) {
      await store.dispatch(
        fetchExpertRequestCandidates(id, type, false, pageInfo.cursor as string)
      );
    }
  };

  document.title = expertRequest.name;
  return (
    <ExpertRequestComponent
      section={targetSection as SectionType}
      expertRequestId={expertRequest.id}
      expertRequest={expertRequest}
      candidateId={candidateId as string}
      isGoodMatch={isGoodMatch === 'yes'}
      fetchMoreCandidates={fetchMoreCandidates}
    />
  );
};
