import { fetchProjects } from '@/actions/project';
import LayoutPage from '@/components/Layout/LayoutPage';
import WrapperComponent from '@/components/WrapperComponent';
import { fetchStarting } from '@/consultation/store';
import { isGroupContext } from '@/core/user';
import { LegacyRoute } from '@/routes/routesMiddleware';

import ExpertRequests from './ExpertRequests';

const route: LegacyRoute = {
  path: '/expert_requests',
  element: <WrapperComponent />,
  async action({ store }) {
    const { ui } = store.getState();

    const groupId = isGroupContext(ui.userContext) ? ui.userContext : undefined;

    await Promise.all([
      store.dispatch(
        fetchProjects({
          groupId,
          openOnly: false,
          memberOnly: false,
        })
      ),
      store.dispatch(fetchStarting()),
    ]).catch((e) => {
      if (!e.isPermissionError) throw e;
    });

    document.title = 'Expert Requests';
    return (
      <LayoutPage showNav selected="expert_requests" bodyClassName="meu-teste">
        <ExpertRequests />
      </LayoutPage>
    );
  },
};

export default route;
