import { AppThunk } from '@/store';

export function validateToken(token: any, singleUse = true): AppThunk<Promise<any>> {
  return async (_dispatch, _getState, { graphql }) => {
    const { validateToken } = await graphql.query(
      `query ($token: String!, $singleUse: Boolean) {
        validateToken(token: $token, single_use: $singleUse)
      }`,
      { token, singleUse }
    );
    return validateToken;
  };
}
