import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Waypoint } from 'react-waypoint';

import Button from '../Button/Button';
import CircularProgress from '../CircularProgress';
import s from './LongList.module.scss';

class LongList extends PureComponent {
  static propTypes = {
    entityName: PropTypes.string,
    entityNamePlural: PropTypes.string,
    title: PropTypes.string,
    containerClassName: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.array.isRequired,
    onMore: PropTypes.func,
    total: PropTypes.number,
    hasNextPage: PropTypes.bool,
    infiniteScroll: PropTypes.bool,
  };

  renderTitle = () => {
    // @ts-expect-error TS(2339): Property 'entityName' does not exist on type 'Read... Remove this comment to see the full error message
    const { entityName, entityNamePlural, total, title, children, loading } = this.props;
    if (title) return title;
    if (loading || loading === undefined) return '';
    if (!children || !children.length) return `No ${entityNamePlural} Found`;
    if (total === 1) return `1 ${entityName} Found`;
    if (!total) return `${entityNamePlural} Found`;
    return `${total.toLocaleString('en-US')} ${entityNamePlural} Found`;
  };

  render() {
    const {
      // @ts-expect-error TS(2339): Property 'children' does not exist on type 'Readon... Remove this comment to see the full error message
      children,
      // @ts-expect-error TS(2339): Property 'onMore' does not exist on type 'Readonly... Remove this comment to see the full error message
      onMore,
      // @ts-expect-error TS(2339): Property 'hasNextPage' does not exist on type 'Rea... Remove this comment to see the full error message
      hasNextPage,
      // @ts-expect-error TS(2339): Property 'containerClassName' does not exist on ty... Remove this comment to see the full error message
      containerClassName,
      // @ts-expect-error TS(2339): Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
      className,
      // @ts-expect-error TS(2339): Property 'loading' does not exist on type 'Readonl... Remove this comment to see the full error message
      loading,
      // @ts-expect-error TS(2339): Property 'action' does not exist on type 'Readonly... Remove this comment to see the full error message
      action,
      // @ts-expect-error TS(2339): Property 'infiniteScroll' does not exist on type '... Remove this comment to see the full error message
      infiniteScroll,
    } = this.props;

    return (
      <div className={cx({ [className]: !!className })}>
        <div className={s.header}>
          <h3 className={s.title}>{this.renderTitle()}</h3>
          {action}
        </div>

        <div className={cx({ [containerClassName]: !!containerClassName })}>{children}</div>

        {hasNextPage &&
          !loading &&
          (infiniteScroll ? (
            <Waypoint onEnter={onMore} />
          ) : (
            <div className={s.loadMore}>
              <Button onClick={onMore}>Load More</Button>
            </div>
          ))}

        {loading && (
          <div className={s.loading}>
            <CircularProgress />
          </div>
        )}
      </div>
    );
  }
}

export default LongList;
