import DialogMaterial, { DialogProps as DialogMaterialProps } from '@mui/material/Dialog';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';
import { FC, ReactNode, useCallback } from 'react';

import { black, primary, red500, white } from '@/theme/colors';

import Button from '../Button';
import ExpertPicture from '../ExpertPicture';

const useStyles = makeStyles({
  header: {
    display: 'flex',
    flex: '0 0 auto',
    alignItems: 'center',
    marginBottom: 15,
  },

  photoContainer: {
    flex: '0 0 auto',
    paddingRight: 15,
  },

  titleContainer: {
    flex: 1,
  },

  subTitle: {
    marginTop: 5,
  },

  title: {
    fontSize: 20,
    color: black,
    fontWeight: 'bold',
  },

  titleWarning: {
    color: red500,
  },

  actions: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  paper: (props) => ({
    padding: 24,
    // @ts-expect-error TS(2339): Property 'width' does not exist on type '{}'.
    maxWidth: props.width,
  }),

  paperFullScreen: {
    boxSizing: 'border-box',
  },

  promo: {
    backgroundColor: primary,
    color: white,
  },
});

export interface DialogProps extends Omit<DialogMaterialProps, 'open' | 'title'> {
  open?: boolean;
  warning?: boolean;
  title?: ReactNode;
  titleClassname?: string;
  subTitle?: React.ReactNode;
  children?: React.ReactNode;
  contentStyle?: React.CSSProperties;
  user?: any;
  titleStyle?: React.CSSProperties;
  subTitleStyle?: React.CSSProperties;
  cancelButtonProps?: any;
  confirmButtonProps?: any;
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm?: (event: React.SyntheticEvent<HTMLButtonElement | HTMLFormElement>) => void;
  onCancel?: (event: React.SyntheticEvent<HTMLButtonElement | HTMLFormElement>) => void;
  disableSubmit?: boolean;
  classes?: any;
  promo?: any;
  width?: number;
  useForm?: boolean;
  size?: string | number;
  submitting?: boolean;
}

const Dialog: FC<DialogProps> = ({
  warning = false,
  title,
  titleClassname,
  children,
  contentStyle,
  subTitle,
  user,
  titleStyle,
  subTitleStyle,
  cancelButtonProps,
  confirmButtonProps,
  confirmLabel = 'Confirm',
  cancelLabel = 'Dismiss',
  onConfirm,
  onCancel,
  disableSubmit,
  classes,
  promo,
  width = 640,
  maxWidth,
  useForm = false,
  open = false,
  size,
  submitting,
  ...other
}) => {
  const s = useStyles({ width: maxWidth ? undefined : width });

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (onConfirm) onConfirm(e);
    },
    [onConfirm]
  );

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      if (onConfirm) onConfirm(e);
    },
    [onConfirm]
  );

  const actions = (onConfirm || onCancel || useForm) && (
    <div className={s.actions} id="dialogActionButtonsContainer">
      {onCancel && (
        <Button
          id="cancelDialogButton"
          {...cancelButtonProps}
          variant="text"
          onClick={onCancel}
          size="medium"
        >
          {cancelLabel}
        </Button>
      )}

      {(onConfirm || useForm) && (
        <Button
          {...confirmButtonProps}
          disabled={disableSubmit}
          onClick={handleClick}
          type="submit"
          size="medium"
          submitting={submitting}
        >
          {confirmLabel}
        </Button>
      )}
    </div>
  );

  return (
    <DialogMaterial
      {...other}
      open={open}
      fullScreen={fullScreen}
      classes={{
        ...classes,
        paper: cx(s.paper, {
          [classes && classes.paper]: !!classes && !!classes.paper,
          [s.promo]: promo,
        }),
        paperFullScreen: s.paperFullScreen,
      }}
      maxWidth={maxWidth}
    >
      {title && (
        <header className={s.header}>
          {user && (
            <div className={s.photoContainer}>
              <ExpertPicture user={user} size={50} />
            </div>
          )}

          <div className={s.titleContainer}>
            <div
              className={cx(s.title, titleClassname, {
                [s.titleWarning]: warning,
              })}
              style={titleStyle}
            >
              {title}
            </div>

            {subTitle && (
              <div style={subTitleStyle} className={s.subTitle}>
                {subTitle}
              </div>
            )}
          </div>
        </header>
      )}

      {useForm ? (
        <form onSubmit={handleSubmit}>
          {children}
          {actions}
        </form>
      ) : (
        <>
          {children}
          {actions}
        </>
      )}
    </DialogMaterial>
  );
};

export default Dialog;
