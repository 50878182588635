import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { InjectedFormProps } from 'redux-form';

import Article from '@/components/Article';
import Button from '@/components/Button/Button';
import { Checkbox } from '@/components/FormAdapters';
import MediaQuery from '@/components/MediaQuery';
import agreements, { matchAgreement, policies } from '@/core/agreements';
import { updateUser } from '@/store/user';
import { SCREEN_SM } from '@/theme/screens';

import s from './LegalAcknowledgement.module.scss';

interface LegalAcknowledgementProps {
  user: any;
  pages: any[];
  updateUser: (arg0: { id: string; agreements: any }) => void;
  userId: string;
  policyKeys: string[];
  aggregateAgreementName: string;
  note?: string;
}

function LegalAcknowledgement(
  props: InjectedFormProps<{ user?: string }, LegalAcknowledgementProps> & LegalAcknowledgementProps
) {
  const [accepted, setAccepted] = useState(() => {
    const { user, policyKeys } = props;
    return agreements(user.agreements).hasAccepted(...policyKeys);
  });
  const selPolicies = useMemo(() => {
    return (props.policyKeys || []).map((key: string | number) => policies[key]);
  }, [props.policyKeys]);

  const { updateUser, user, pages, aggregateAgreementName, note } = props;

  const handleAgree = (_: any, accepted: boolean) => {
    setAccepted(accepted);
  };

  const handleSubmit = async () => {
    if (!accepted()) throw new Error('legal should have been accepted');

    const agreements = user.agreements || [];
    const now = new Date();
    selPolicies.forEach((policy: { apiKey: any }) => {
      let agreement = agreements.find((a: { policy: any }) => matchAgreement(a.policy, policy));
      if (!agreement) {
        agreement = {};
        agreements.push(agreement);
      }
      agreement.policy = policy.apiKey;
      agreement.accepted = true;
      agreement.updated_at = now;
    });

    await updateUser({ id: user.id, agreements });
    setAccepted(null);
  };

  if (!selPolicies) {
    throw new Error('no policy to require acceptance');
  }

  const policyPages = selPolicies.map((p: { id: any; slug: any }) => {
    const page = pages.find((x: { id: any }) => x.id === p.id);
    if (!page) throw new Error(`unable to find /${p.slug} blog entry`);
    return page;
  });

  return (
    <div>
      <MediaQuery maxWidth={SCREEN_SM}>
        {(smallScreen: any) => (
          <div>
            <div className={s.title}>
              Please take a moment to review and agree to our updated {aggregateAgreementName}.
            </div>
            <div className={s.legalContents}>
              <div key={policyPages[0].id}>
                <h1>{policyPages[0].title_text}</h1>
                <Article html={policyPages[0].content.rendered} />
              </div>
              {policyPages
                .slice(1)
                .map(
                  (page: {
                    id: React.Key | null | undefined;
                    title_text:
                      | string
                      | number
                      | boolean
                      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                      | Iterable<React.ReactNode>
                      | React.ReactPortal
                      | null
                      | undefined;
                    content: { rendered: any };
                  }) => (
                    <div key={page.id}>
                      <hr />
                      <h1>{page.title_text}</h1>,
                      <Article html={page.content.rendered} />
                    </div>
                  )
                )}
            </div>
            {note && <div className={s.note}>{note}</div>}
            <Field
              type="checkbox"
              name="user"
              component={Checkbox}
              checked={accepted}
              label={`I have read and agreed to the above ${aggregateAgreementName}.`}
              labelStyles={{ fontWeight: 'bold' }}
              onChange={(e: any) => handleAgree(e, !accepted)}
            />
            <Button
              onClick={handleSubmit}
              size={smallScreen ? 'normal' : 'large'}
              type="submit"
              disabled={!accepted}
              style={{ marginTop: 10 }}
            >
              Accept
            </Button>
          </div>
        )}
      </MediaQuery>
    </div>
  );
}

const WrappedLegalAcknowledgement = reduxForm<{}, LegalAcknowledgementProps>({
  enableReinitialize: true,
  form: 'acceptedAgreements',
  validate: (values: { user?: string }) => {
    const errors: { user?: string } = {};
    if (!values.user) {
      errors.user = 'You must accept the privacy policy to proceed';
    }
    return errors;
  },
})(LegalAcknowledgement);

const mapStateToProps = (state: { users: any[]; legal: { pages: [] } }, ownProps: any) => ({
  user: state.users[ownProps.userId],
  pages: state.legal.pages,
});

export default connect(mapStateToProps, {
  updateUser,
})(WrappedLegalAcknowledgement);
