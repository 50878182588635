import React from 'react';

import Divider from '@/components/Divider/Divider';

import TimeButton from '../../TimeButton';

interface Viewer {
  timezone: string;
}

interface RejectedTimesProps {
  viewer: Viewer;
  rejectedTimes: string[];
  expectedDuration: number;
}

function RejectedTimes({ viewer, rejectedTimes, expectedDuration }: RejectedTimesProps) {
  return (
    <div>
      <Divider />

      <div style={{ marginTop: 30, marginBottom: 30 }}>These times did not work:</div>

      {rejectedTimes
        .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
        .map((date) => (
          <TimeButton
            disabled
            key={date}
            date={date}
            timezone={viewer.timezone}
            expectedDuration={expectedDuration}
            style={{ opacity: 0.5 }}
          />
        ))}
    </div>
  );
}

export default RejectedTimes;
