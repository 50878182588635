import React from 'react';
import { redirect } from 'react-router-dom';

import { approveGroupMember, denyGroupMember, fetchGroup } from '@/actions/group';
import { notify } from '@/actions/ui';
import ConsultationsStarting from '@/components/ConsultationsStarting';
import LayoutPage from '@/components/Layout/LayoutPage';
import WrapperComponent from '@/components/WrapperComponent';
import { fetchStarting } from '@/consultation/store';
import { isGroupContext } from '@/core/user';

import Team from './Team';

async function action({ store, params }: any) {
  let { slug } = params;
  const { userContext, userContextOptions } = store.getState().ui;
  const groupContexts = userContextOptions.filter((o: any) => isGroupContext(o.value));

  let redirectSlug;
  if (!slug || slug === 'default') {
    if (isGroupContext(userContext)) {
      redirectSlug = userContext;
    } else {
      if (!groupContexts.length) return redirect('/404');
      redirectSlug = groupContexts[0].value;
    }
    slug = redirectSlug;
  }

  const [group] = await Promise.all([
    store.dispatch(
      fetchGroup(slug, {
        awaitingMembers: true,
        internalNetwork: true,
      })
    ),
    store.dispatch(fetchStarting()),
  ]);

  if (!group) return redirect('/404');

  if (redirectSlug) {
    return redirect(`/team/${group.slug}`);
  }

  document.title = `${group.name} Team`;
  return (
    <LayoutPage showNav selected="teams">
      <ConsultationsStarting />
      <Team groupId={group.id} />
    </LayoutPage>
  );
}

export default [
  {
    path: '/team/:slug?',
    element: <WrapperComponent />,
    action,
  },
  {
    path: '/team/:slug/member/:memberId/:op',
    element: <WrapperComponent />,
    async action({ store, params }: any) {
      const { slug, memberId, op } = params;

      const group = await store.dispatch(
        fetchGroup(slug, {
          awaitingMembers: true,
        })
      );
      if (!group.members_awaiting || !group.members_awaiting.edges) return redirect('/404');

      const member = group.members_awaiting.edges
        .map((e: any) => e.node)
        .find((m: any) => m.id === memberId);

      if (!member) {
        return action({ store, params });
      }

      switch (op) {
        case 'approve':
          await store.dispatch(approveGroupMember(member));
          store.dispatch(notify(`${member.user.name} approved`));
          break;
        case 'deny':
          await store.dispatch(denyGroupMember(member));
          store.dispatch(notify(`${member.user.name} denied`));
          break;
        default:
          return redirect('/404');
      }

      return action({ store, params });
    },
  },
];
