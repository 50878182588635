import React from 'react';

import { fetchAllGroups } from '@/actions/group';
import { fetchExpertRequests } from '@/expertrequest/store';

import ProfileUploader from './ProfileUploader';

export default {
  path: '/profile-uploader',
  element: <ProfileUploader />,
  async action({ store }: any) {
    await Promise.all(
      [
        fetchExpertRequests({ state: 'open' }),
        fetchAllGroups({
          collection: 'internal',
          internal: true,
          memberOnly: false,
          memberLimit: 100,
        }),
        fetchAllGroups({ collection: 'networks', internalNetwork: true }),
      ].map(store.dispatch)
    );

    document.title = 'Profile Uploader';
    return null;
  },
};
