import React, { useCallback } from 'react';
import { redirect, useNavigate } from 'react-router-dom';

import { fetchCountries } from '@/actions/country';
import { fetchSignupDomain } from '@/actions/domain';
import { fetchSectors } from '@/actions/sector';
import BodyContainer from '@/components/BodyContainer';
import Layout from '@/components/Layout';
import MediaQuery from '@/components/MediaQuery';
import Steps from '@/components/Steps/Steps';
import WrapperComponent from '@/components/WrapperComponent';
import { fetchUser, updateExpertState } from '@/store/user';
import { SCREEN_SM } from '@/theme/screens';

import BasicInformation from './BasicInformation';
import CompleteProfile from './CompleteProfile';
import RateAndPreference from './RateAndPreference';

const baseUrl = '/signup/expert';

export default {
  subdomain: '*',
  path: '/signup/expert/:stepPath',
  // @ts-ignore
  element: <WrapperComponent />,
  async action({ store, params }: any) {
    const { stepPath = '' } = params;
    const { viewer } = store.getState();

    const domain = await store.dispatch(fetchSignupDomain(viewer.signup_subdomain));

    // Already applied, redirect to dashboard
    if (viewer.expert_state && viewer.expert_state !== 'applying') {
      return redirect('/dashboard');
    }

    // If no state, set applying
    if (!viewer.expert_state && stepPath !== 'complete-profile') {
      store.dispatch(updateExpertState({ id: viewer.id, expert_state: 'applying' }));
    }

    return action({
      stepPath,
      domain,
      viewer,
      store,
    });
  },
};

const steps = Object.freeze([
  {
    path: 'basic-information',
    title: 'Basic Information',
    Component: BasicInformation,
  },
  {
    path: 'rate-and-preferences',
    title: 'Rate & Preferences',
    Component: RateAndPreference,
  },
  {
    path: 'complete-profile',
    title: 'Complete Profile',
    Component: CompleteProfile,
  },
]);

function ExpertApplication({ nextUrl, step, stepNumber, viewer, domain, isLastStep }: any) {
  const navigate = useNavigate();
  const onNext = useCallback(() => navigate(nextUrl), [navigate, nextUrl]);

  return (
    <Layout hideSearch showNewRequest={false} headerBorder>
      <MediaQuery maxWidth={SCREEN_SM}>
        {(isMobileVersion: any) => (
          <BodyContainer noMobilePadding={isLastStep}>
            <Steps
              current={stepNumber}
              // @ts-expect-error
              steps={steps}
              onStepClick={(step: any) => navigate(step.path)}
            />
            {React.createElement(step.Component, {
              viewer,
              domain,
              profileId: viewer.profile && viewer.profile.id,
              userId: viewer && viewer.id,
              nextUrl,
              onNext,
              isMobileVersion,
              ...step.props,
            })}
          </BodyContainer>
        )}
      </MediaQuery>
    </Layout>
  );
}

async function action({ stepPath, domain, viewer, store }: any) {
  const step = steps.find((s) => s.path === stepPath);

  if (!step) {
    return redirect(`${baseUrl}/basic-information`);
  }

  const stepNumber = steps.indexOf(step);
  const nextStep = steps[stepNumber + 1];
  // @ts-expect-error TS(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
  const isLastStep = step === steps.length - 1;
  const nextUrl = nextStep && `${baseUrl}/${nextStep.path}`;

  const forceFetch =
    stepPath === 'complete-profile' ||
    (viewer.can_autofill_profile && stepPath === 'rate-and-preferences');

  if (viewer.id) {
    viewer = await store.dispatch(
      fetchUser(viewer.username, {
        force: forceFetch,
        signupSubdomain: true,
        canAutofillProfile: true,
        experiences: true,
        education: true,
        addresses: true,
        expertise: true,
        internalNetworks: true,
      })
    );
  }

  await Promise.all([
    store.dispatch(fetchCountries()),
    store.dispatch(fetchSectors()),
    // @ts-expect-error TS(2339) FIXME: Property 'prepare' does not exist on type '{ path:... Remove this comment to see the full error message
    step.prepare && step.prepare(store),
  ]);

  document.title = step.title;
  return <ExpertApplication {...{ nextUrl, stepNumber, step, viewer, domain, isLastStep }} />;
}
