import Grid from '@mui/material/Grid';
import React, { useCallback, useMemo } from 'react';
import { Field } from 'react-final-form';

import { erTypes, isCallType } from '@/expertrequest/store';
import { normalizeNewLines, normalizeSpace, sortBy } from '@/utils';

import ConsultationDates from '../ConsultationDates';
import DurationPicker from '../DurationPicker';
import { Select, TextField } from '../FormAdapters';

function Fields({
  viewer,
  profile,
  expertRequests,
  groups,
  groupId,
  expertRequest,
  creditRate,
  isFixedRate,
  form,
  enableOpportunityCall,
}: any) {
  const project = expertRequest && expertRequest.project;
  const members = project && project.members;
  // In the case of a direct consultation request, groupId will be
  // null until a group has been selected by the user in the "Add to team"
  // dropdown.
  const group =
    viewer.groups.filter((grp: any) => grp.id === groupId)[0] || (project && project.group);

  const memberOptions = useMemo(
    () =>
      (members || [])
        .filter(
          (m: any) => m.user && m.state === 'active' && (m.role === 'owner' || m.role === 'member')
        )
        .map((m: any) => ({
          value: m.user.id,
          label: m.user.name,
        })),
    [project]
  );

  const engagementTypeOptions = useMemo(
    () => [
      { value: 'consultation', label: 'Paid Consultation' },
      { value: 'opportunity', label: 'Opportunity Call' },
    ],
    []
  );

  const disclosureOptions = useMemo(
    () => [
      { value: 'full', label: 'Share my company information with Experts' },
      { value: 'private', label: 'Do not share my name with Experts' },
    ],
    []
  );

  const sortedGroups = useMemo(
    () =>
      groups.edges
        .map((e: any) => e.node)
        .map((e: any) => ({
          value: e.id,
          label: e.name,
        }))
        .sort(sortBy('label')),
    [groups.edges.length, group]
  );

  const sortedRequests = useMemo(
    () =>
      expertRequests.edges
        .filter((e: any) => isCallType(e.node.er_type))
        .map((e: any) => ({
          value: e.node.id,
          label: e.node.name,
        }))
        .sort(sortBy('label')),
    [expertRequests.edges.length]
  );

  const validateGroup = useCallback(
    (groupId: any, values: any) => (groupId ? values.marketplace_error : 'Required'),
    []
  );

  // Heuristics to avoid visible layout changes when showing/hiding components
  // on dialog open
  const groupsVisible = viewer.admin || viewer.groups.length > 1;
  const expertRequestsVisible =
    (!expertRequest && !expertRequests.loaded) || expertRequests.edges.length > 0;

  const confidentiality = (
    <Field
      component={Select}
      name="disclosure"
      label="Confidentiality"
      options={disclosureOptions}
    />
  );

  return (
    <Grid container justifyContent="space-between">
      {enableOpportunityCall && (
        <Field
          component={Select}
          name="engagement_type"
          style={{
            flexDirection: 'row',
            marginTop: 5,
            justifyContent: 'center',
          }}
          options={engagementTypeOptions}
          disabled={
            !!expertRequest &&
            (expertRequest.er_type === erTypes.consultation ||
              (!viewer.admin &&
                (expertRequest.er_type === erTypes.newHire ||
                  expertRequest.er_type === erTypes.consultingProject)))
          }
        />
      )}

      <Grid item xs={12}>
        <Field
          component={TextField}
          name="description"
          multiline
          label={`Include a note to ${profile.first_name}`}
          onKeyDown={(e: any) => e.keyCode === 13 && e.preventDefault()}
          onKeyUp={(e: any) => form.change('description', normalizeNewLines(e.target.value))}
        />
      </Grid>

      <Grid item xs={12}>
        <Field
          component={DurationPicker}
          name="duration"
          creditRate={creditRate}
          showCredits={!isFixedRate}
        />
      </Grid>

      <Grid item xs={12}>
        <ConsultationDates
          name="dates"
          viewer={viewer}
          userName={profile.first_name}
          userTimezone={profile.timezone}
          label="Suggest times that work for you"
          style={{ marginTop: 20 }}
        />
      </Grid>

      {expertRequestsVisible && (
        <Grid item xs={12}>
          <Field
            component={Select}
            name="expert_request_id"
            label="Add to Expert Request"
            options={sortedRequests}
            autocomplete={sortedRequests.length > 10}
            includeEmpty
            placeholder="Start typing the name of an expert request"
          />
        </Grid>
      )}

      {groupsVisible && (
        <Grid item sm={7} xs={12} style={{ paddingRight: 20 }}>
          <Field
            component={Select}
            name="group_id"
            label="Add to team"
            options={sortedGroups}
            autocomplete={sortedGroups.length > 10}
            // this option is only enabled for a direct consultation
            disabled={!!group && !!expertRequest}
            includeEmpty
            validate={validateGroup}
            placeholder="Start typing the name of a team"
          />
        </Grid>
      )}

      <Grid item sm={groupsVisible ? 5 : 12} xs={12}>
        <Field
          component={TextField}
          name="tracking_code"
          label="Tracking Code"
          inputProps={{ maxLength: 60 }}
          parse={(v) => normalizeSpace(v)}
        />
      </Grid>

      {viewer.admin && memberOptions.length > 0 && (
        <Grid item xs={12}>
          <Field
            component={Select}
            name="requester_id"
            label="Request call on behalf of"
            options={memberOptions}
            includeEmpty
          />
        </Grid>
      )}

      <Grid item xs={12}>
        {confidentiality}
      </Grid>
    </Grid>
  );
}

export default Fields;
