import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';

import { notify } from '@/actions/ui';
import { isValidPassword } from '@/auth';
import { PasswordText } from '@/auth/components/PasswordTextField';
import { resetPassword, setPassword } from '@/auth/store';
import Button from '@/components/Button/Button';

const ERROR_INCORRECT_PASSWORD = 'GraphQL Error: current_password:current password is incorrect';

const ERROR_USED_PASSWORD = 'GraphQL Error: new_password:cannot use the same password';

const useStyles = makeStyles({
  form: {
    maxWidth: 350,
  },
  actions: {
    marginTop: 25,
  },
});

function ChangePassword({
  token,
  hasPassword,
  userId,
  setPassword,
  resetPassword,
  onSubmit,
  successNotification = true,
  notify,
}: any) {
  const s = useStyles();

  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        if (token) {
          await resetPassword(token, values.newPassword);
        } else {
          await setPassword(userId, values.currentPassword, values.newPassword);
        }

        if (successNotification) notify('Password updated.');

        if (onSubmit) onSubmit(values);
      } catch (e) {
        if ((e as Error)?.message === ERROR_INCORRECT_PASSWORD) {
          return { currentPassword: 'Current password is incorrect.' };
        }

        if ((e as Error)?.message === ERROR_USED_PASSWORD) {
          return { newPassword: 'Cannot reuse the last 5 passwords.' };
        }

        if ((e as any).isPermissionError) {
          notify('Reset password link has expired.', 'error');
        } else {
          notify('An error occurred when trying to set the password.', 'error');
        }
      }
    },
    [notify, onSubmit, resetPassword, setPassword, successNotification, token, userId]
  );

  const validate = useCallback(
    (values: any) => {
      const errors = {};

      if (hasPassword && !values.currentPassword) {
        // @ts-expect-error TS(2339): Property 'currentPassword' does not exist on type ... Remove this comment to see the full error message
        errors.currentPassword = 'Required';
      }

      if (!isValidPassword(values.newPassword)) {
        // @ts-expect-error TS(2339): Property 'newPassword' does not exist on type '{}'... Remove this comment to see the full error message
        errors.newPassword = 'Password does not meet the requirements';
      }

      if (values.newPassword && values.newPassword === values.currentPassword) {
        // @ts-expect-error TS(2339): Property 'newPassword' does not exist on type '{}'... Remove this comment to see the full error message
        errors.newPassword = 'New password must be different from the current';
      }

      return errors;
    },
    [hasPassword]
  );

  return (
    <Form onSubmit={handleSubmit} validate={validate} subscription={{ submitting: true }}>
      {({ handleSubmit, form }: any) => {
        const onSubmit = async (e: any) => {
          e.preventDefault();
          const error = await handleSubmit(e);
          if (error || form.getState().hasValidationErrors) return error;
          form.restart();
        };

        return (
          <form className={s.form} onSubmit={onSubmit}>
            {hasPassword && (
              <Field
                id="currentPassword"
                component={PasswordText}
                name="currentPassword"
                label="Current Password"
                variant="outlined"
              />
            )}
            <Field
              id="newPassword"
              component={PasswordText}
              name="newPassword"
              label="New Password"
              showHelp
              variant="outlined"
              inputProps={{ autoComplete: 'new-password' }}
              current={false}
            />
            <div className={s.actions}>
              <Button
                type="submit"
                // @ts-expect-error
                classes={{ root: s.button }}
                size="medium"
                id="updatePasswordButton"
              >
                Update Password
              </Button>
            </div>
          </form>
        );
      }}
    </Form>
  );
}

export default connect(
  (state, ownProps) => {
    // @ts-expect-error TS(2339): Property 'users' does not exist on type 'DefaultRo... Remove this comment to see the full error message
    const user = state.users[ownProps.userId] || {};
    return {
      user,
      hasPassword: user.has_password,
    };
  },
  {
    setPassword,
    resetPassword,
    notify,
  }
)(ChangePassword);
