import React from 'react';
import { redirect } from 'react-router-dom';

import { fetchConflicts } from '@/profile/store';

import ProfileConflicts from './ProfileConflicts';

export default {
  path: '/profile-conflicts',
  element: <ProfileConflicts />,
  async action({ store }: any) {
    const { viewer } = store.getState();
    if (!viewer.admin) return redirect('/404');

    // @ts-expect-error TS(2554) FIXME: Expected 1-2 arguments, but got 0.
    store.dispatch(fetchConflicts());

    document.title = 'Profile Merge Conflicts';
    return null;
  },
};
