import { redirect, useLoaderData } from 'react-router-dom';

import WrapperComponent from '@/components/WrapperComponent';
import { fetchPublicExpertRequest, requestAddExpertRequestCandidate } from '@/expertrequest/store';
import { ActionContext, LegacyRoute } from '@/routes/routesMiddleware';

import { action as privateExpertsRequestAction } from '../expertRequestDetails';
import ExpertAuthPage from './ExpertAuthPage';
import ExpertRequestAddRequest from './ExpertRequestAddRequest';
import ExpertRequestAddRequestSubmitted from './ExpertRequestAddRequestSubmitted';
import ExpertRequestPublic from './ExpertRequestPublic';
import ReferralAuthPage from './ReferralAuthPage';
import promoImageURL from './promo.jpg';

const path = '/expert_request/:id';
const ALREADY_ADDED = 'GraphQL Error: expert request candidate already added';

function findExpertRequest(store: any, id: any) {
  return store.dispatch(fetchPublicExpertRequest(id));
}

function RequestWrapper() {
  // @ts-expect-error TS(2339) FIXME: Property 'viewer' does not exist on type 'unknown'... Remove this comment to see the full error message
  const { viewer, expertRequest, signup, tags } = useLoaderData();

  if (!viewer.id) {
    return <ExpertAuthPage expertRequest={expertRequest} signup={signup} tags={tags} />;
  }

  if (expertRequest) {
    return (
      // @ts-ignore
      <ExpertRequestAddRequest viewer={viewer} expertRequest={expertRequest} />
    );
  }

  return <ExpertRequestAddRequestSubmitted viewer={viewer} />;
}

function ExpertsRequestComponent() {
  const data = useLoaderData();
  // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
  if (data.public) {
    // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'Intrinsi... Remove this comment to see the full error message
    return <ExpertRequestPublic {...data} />;
  }

  return <WrapperComponent />;
}

async function publicExpertsRequestAction(id: string, { store, query }: ActionContext) {
  const expertRequest = await findExpertRequest(store, id);
  if (!expertRequest) return redirect('/404');

  document.title = 'Expert Request';
  return {
    public: true,
    expertRequest,
    action: query.action,
    tags: query.t,
    meta: {
      description: expertRequest.description,
      imageUrl: promoImageURL,
      url: expertRequest.public_html_url,
    },
  };
}

const route: LegacyRoute = {
  path,
  title: 'Expert Request',
  children: [
    {
      path,
      element: <ExpertsRequestComponent />,
      async action(context) {
        const { id = '' } = context.params;

        if (/^\d+$/.test(id)) {
          return await privateExpertsRequestAction(id, context);
        }

        return await publicExpertsRequestAction(id, context);
      },
    },
    {
      path: `${path}/request_add/:auth?/:signupType?`,
      element: <RequestWrapper />,
      async action({ store, params, query }) {
        const { id, auth } = params;
        document.title = 'Expert Request';

        const expertRequest = await findExpertRequest(store, id);
        if (!expertRequest) return redirect('/404');

        const { viewer } = store.getState();
        if (!viewer.id && !auth) {
          return redirect(`/expert_request/${expertRequest.slug}/request_add/signup`);
        }

        if (viewer.id && auth) {
          return redirect(`/expert_request/${expertRequest.slug}/request_add`);
        }

        if (!viewer.id && auth) {
          return {
            viewer,
            expertRequest,
            signup: auth === 'signup',
            tags: query.t,
          };
        }

        try {
          await store.dispatch(
            requestAddExpertRequestCandidate({
              expert_request_id: id,
            })
          );
        } catch (err) {
          // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
          if (err.message === ALREADY_ADDED) {
            return { viewer };
          }
          throw err;
        }

        return {
          viewer,
          expertRequest,
        };
      },
    },
    {
      path: `${path}/refer/:auth?/:signupType?`,
      element: <ReferralAuthPage />,
      async action({ store, params, query }) {
        const { id, auth } = params;
        document.title = 'Expert Request';

        const expertRequest = await findExpertRequest(store, id);
        if (!expertRequest) return redirect('/404');

        const { viewer } = store.getState();
        if (!viewer.id && !auth) {
          return redirect(`/expert_request/${expertRequest.slug}/refer/signup`);
        }

        if (viewer.id) {
          return redirect(`/expert_request/${expertRequest.slug}`);
        }

        return {
          expertRequest,
          signup: auth === 'signup',
          tags: query.t,
        };
      },
    },
  ],
};

export default route;
