import { useCallback, useEffect, useState } from 'react';

import MediaPlayer from '@/components/MediaPlayer';
import { presignAttachmentURL } from '@/core/attachment';
import { fileTypes, isAudioType, isVideoType, recordingTypes } from '@/core/consultation';
import { useApp } from '@/hooks/useAppContext';

// TODO: Make fallbackURL a recording type {location, file type, recording type, etc }
interface Recording {
  location: string;
  file_type: string;
  recording_type?: string;
}

function RecordingPlayer({ conference, enabled, fallbackURL }: any) {
  const { graphql } = useApp();

  const [recording, setRecording] = useState<Recording | undefined>(undefined);

  const presignedRecording = useCallback(
    async (recording: any) => {
      if (recording) {
        recording.location = await presignAttachmentURL(graphql.client, recording.location);
        setRecording(recording);
      } else setRecording(undefined);
    },
    [graphql.client]
  );

  useEffect(() => {
    let internalRecording = conference?.attachments?.find(
      (a: any) => a.recording_type === recordingTypes.sharedScreenWithSpeakerView
    );
    if (!internalRecording) {
      internalRecording = conference?.attachments?.find((a: any) => isVideoType(a.file_type));
    }
    if (!internalRecording) {
      internalRecording = conference?.attachments?.find((a: any) => isAudioType(a.file_type));
    }
    if (!internalRecording && fallbackURL) {
      internalRecording = {
        location: fallbackURL,
        file_type: fileTypes.mp3,
      };
    }
    presignedRecording(internalRecording);
  }, [conference, fallbackURL, presignedRecording]);

  return (
    <div>
      {recording && recording.location && (
        <MediaPlayer
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          src={recording.location}
          isAudioOnly={isAudioType(recording.file_type)}
          enabled={enabled}
        />
      )}
    </div>
  );
}

export default RecordingPlayer;
