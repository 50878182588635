import { Fab as FloatingActionButton } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { fetchProjects } from '@/actions/project';
import ConsultationsStarting from '@/components/ConsultationsStarting';
import MaterialIcon from '@/components/Icon/MaterialIcon';
import LongList from '@/components/LongList';
import ProjectPreview from '@/components/ProjectPreview';
import TeamAccountPromoButton from '@/components/TeamAccountPromo/TeamAccountPromoButton';
import { isGroupContext } from '@/core/user';

function ExpertRequests(props: any) {
  const {
    userContext,
    fetchProjects,
    edges = [],
    pageInfo = {
      hasNextPage: false,
    },
    loading,
  } = props;

  const handleMore = (e: any) => {
    e.preventDefault();
    if (!pageInfo.hasNextPage) return;

    const groupId = isGroupContext(userContext) ? userContext : undefined;

    if (!edges.length) throw new Error('last project edge not found');
    fetchProjects({
      cursor: edges[edges.length - 1].cursor,
      groupId,
      openOnly: false,
      memberOnly: false,
    });
  };

  const projects = edges.map((e: any) => e.node);

  return (
    <div>
      <ConsultationsStarting />

      <LongList
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        loading={loading}
        title={projects.length > 0 || loading ? 'Expert Requests' : 'No Expert Requests Found'}
        hasNextPage={pageInfo.hasNextPage && edges.length > 0}
        onMore={handleMore}
        action={
          <TeamAccountPromoButton component={FloatingActionButton}>
            <MaterialIcon icon="add" />
          </TeamAccountPromoButton>
        }
      >
        {projects.map((p: any) => (
          <ProjectPreview key={p.id} project={p} />
        ))}
      </LongList>
    </div>
  );
}

ExpertRequests.propTypes = {
  edges: PropTypes.array,
  pageInfo: PropTypes.object,
  fetchProjects: PropTypes.func,
};

// @ts-expect-error TS(2631) FIXME: Cannot assign to 'ExpertRequests' because it is a ... Remove this comment to see the full error message
ExpertRequests = connect(
  (state) => {
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    const page = state.projects.default;
    return {
      ...page,
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      userContext: state.ui.userContext,
    };
  },
  {
    fetchProjects,
  }
)(ExpertRequests);
export default ExpertRequests;
