import { ApolloClient, ApolloProvider } from '@apollo/client';
import { CacheProvider } from '@emotion/react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { excludeGraphQLFetch } from 'apollo-link-sentry';
import moment from 'moment-timezone';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import App from '@/App';
import { gql } from '@/__generated__/gql';
import ActionTypes from '@/actions/ActionTypes';
import { createToken, updateCall } from '@/actions/call';
import { track } from '@/actions/tracking';
import { notify } from '@/actions/ui';
import { TokenMeta, logout } from '@/auth';
import { setViewer } from '@/auth/store/viewerSlice';
// import PlivoCarrier from '@/conference/plivoCarrier';
// import TwilioCarrier from '@/conference/twilioCarrier';
import voipCarrier from '@/conference/voipCarrier';
import ZoomCarrier from '@/conference/zoomCarrier';
import config from '@/config';
import { initSegmentIdentifier } from '@/core/analytics';
import { GraphQLClient, bearerAuth } from '@/core/api';
import ApiWebSocket from '@/core/apiWebSocket';
import '@/core/compat';
import { initHubSpot, initIntercom } from '@/core/crm';
import { saveInvitationToken } from '@/core/invite';
import { PermissionService } from '@/core/permissions';
import { saveTracking } from '@/core/tracking';
import { getDefaultUserContext, getUserContextOptions, isGroupContext } from '@/core/user';
import { Viewer } from '@/core/viewer';
import { AppProvider } from '@/hooks/useAppContext';
import SendbirdHelper from '@/messaging/sendbird';
import { updateProfile } from '@/profile/store';
import store, { setThunkExtraArg } from '@/store';
import { setRuntimeVariable } from '@/store/runtime';
import { updateUserInfo } from '@/store/user';
import theme, { createEmotionCache } from '@/theme';
import '@/theme/index.css';
import { getCache } from '@/utils';

import pkg from '../package.json';
import './overrides';
import reportWebVitals from './reportWebVitals';
import createRoutes from './routes';

declare global {
  interface Window {
    __VERSION__: string;
    __GIT_SHA__?: string;
    OF_SEARCH_V2_FEATURE?: string;
    OF_KNOWLEDGE_TABLE_FEATURE?: string;
    dataLayer: (string | object)[];
    _hsq?: (string | object)[];
    _analyticsTraits?: object;
  }
}

interface CustomError extends Error {
  extra?: Record<string, any>;
}

interface VoipError {
  message: string;
}

const GET_VIEWER = gql(/* GraphQL */ `
  query getViewer($userId: String) {
    user(id: $userId) {
      id
      billing_account_id
      admin
      expert_state
      compliance_completed_at
      signup_type
      has_password
      locked
      signup_subdomain
      password_expiry {
        expiry
        expired
        expiring
      }
      agreements {
        policy
        updated_at
        accepted
      }
      groups {
        id
        name
        slug
        html_url
        branding_logo_url
        branding_show_poweredbyof
        billing_account {
          id
          type
          state
          credit_balance {
            cents
            currency
          }
        }
        about
        account_type
        internal
        internal_network {
          id
          name
        }
        default_anonymous_messaging
      }
      profile {
        id
        first_name
        last_name
        name
        html_url
        url_endpoint
        linkedin_username
        linkedin_url
        title
        summary
        skype
        timezone
        city
        country
        picture_url
        questions
        cv_url
        available_long_term
        available_marketplace
        hide_profile
        bill_rate
        credit_rate
        languages {
          code
          fluency
        }
        keywords
        tier
      }
      # deprecated fields
      first_name
      last_name
      name
      picture_url
      country_code
      timezone
      username
      phone
      html_url
      email {
        address
        accepted
        confirmed
      }
    }
  }
`);

const gitSha = import.meta.env.VITE_GIT_SHA;
let buildVersion = '';
if (import.meta.env.VITE_BUILD_LABEL) {
  buildVersion += `-${import.meta.env.VITE_BUILD_LABEL}`;
}
if (import.meta.env.VITE_BUILD_NUMBER) {
  buildVersion += `+${import.meta.env.VITE_BUILD_NUMBER}`;
}

window.__VERSION__ = pkg.version + buildVersion;
window.__GIT_SHA__ = gitSha;

(async function main() {
  Sentry.init({
    release: window.__VERSION__,
    environment: import.meta.env.VITE_ENV || 'development',
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        unblock: ['.sentry-unblock, [data-sentry-unblock]'],
        unmask: ['.sentry-unmask, [data-sentry-unmask]'],
      }),
      Sentry.captureConsoleIntegration({ levels: ['error'] }),
    ],

    ignoreErrors: ['AbortError', 'CancelError'],

    beforeSend(event: Sentry.ErrorEvent, hint: Sentry.EventHint) {
      if (event.exception) {
        // KT: disable this for now.
        // Sentry.showReportDialog({ eventId: event.event_id });
      }
      const error = hint.originalException as CustomError;
      if (error?.extra) {
        event.extra = {
          ...(event.extra || {}),
          ...error.extra,
        };
      }
      return event;
    },

    beforeBreadcrumb: excludeGraphQLFetch,
    // beforeBreadcrumb(breadcrumb) {
    //   // too noisy
    //   if (breadcrumb.category === 'redux.action') {
    //     return null;
    //   }

    //   return breadcrumb;
    // },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
  Sentry.setTag('git_sha', gitSha);

  const accessToken = getCache('access_token') as TokenMeta | undefined;
  const token = accessToken?.token;
  let graphql = new GraphQLClient(config.apiUrl, token ? bearerAuth(token) : undefined);

  let viewer = structuredClone(store.getState().viewer);

  function isUnauthorizedError(err: any): boolean {
    return err?.networkError?.statusCode === 401;
  }

  if (token) {
    try {
      viewer = await fetchViewer(graphql.client);
    } catch (err) {
      if (!isUnauthorizedError(err)) {
        throw err;
      }
      logout();
      graphql = new GraphQLClient(config.apiUrl);
    }
  }

  // KT: setup sentry user as soon as possible to give more context
  if (viewer.id) {
    const segment = viewer.admin ? 'admin' : viewer.signup_type;
    const scope = Sentry.getCurrentScope();
    scope.setUser({
      id: viewer.id,
      username: viewer.username,
      email: viewer.email?.address || '',
      segment,
    });
  }

  viewer.intercomHash = getCache('intercom_hash');

  const apiWebSocket = new ApiWebSocket(config.webSocketUrl, token);
  const sendbird = new SendbirdHelper(viewer, graphql);

  setThunkExtraArg({
    graphql,
    history,
    sendbird,
    voipCarrier,
    apiWebSocket,
  });
  store.dispatch(setViewer(viewer));
  store.dispatch(updateUserInfo(viewer));
  store.dispatch(setRuntimeVariable('referrer', document.referrer));
  store.dispatch(setRuntimeVariable('token', token));
  store.dispatch(setRuntimeVariable('login', accessToken?.login_meta || undefined));

  const cache = createEmotionCache();

  await store.dispatch({
    type: ActionTypes.UI__SET_USER_CONTEXT,
    userContext: 'logged_out',
    userContextOptions: [],
  });

  // Infer user context
  let userContext = 'client';
  let userContextOptions = [];
  if (viewer.id) {
    userContext = getCache('user_context');
    userContextOptions = getUserContextOptions(viewer);
    const userContextOptionExists =
      userContext && userContextOptions.some((o) => o.value === userContext);

    if (!userContextOptionExists) {
      if (isGroupContext(userContext) && viewer.admin) {
        const data = await graphql.query(
          'query ($id: String) { group(id: $id) { id, name, billing_account { id } } }',
          {
            id: userContext,
          }
        );
        userContextOptions = getUserContextOptions(viewer, userContext, [data.group]);
      } else {
        userContext = getDefaultUserContext(viewer);
      }
    }

    store.dispatch({
      type: ActionTypes.UI__SET_USER_CONTEXT,
      userContext,
      userContextOptions,
    });
  }

  store.dispatch({ type: ActionTypes.LOAD_FROM_LOCAL_STORAGE });

  const permission = new PermissionService(graphql.client, viewer.id);

  // Global (context) variables that can be easily accessed from any React
  // component https://facebook.github.io/react/docs/context.html
  const context = {
    viewer: viewer,

    // Integrate Redux
    // http://redux.js.org/docs/basics/UsageWithReact.html
    store,

    // GraphQL client (Lokka)
    // https://github.com/kadirahq/lokka
    graphql,

    location: window.location,
    sendbird,
    voipCarrier,
    permission,
    apiWebSocket,
    capabilities: {
      screen: {
        width: window.screen.width,
      },
    },
  };

  // Telephony
  // voipCarrier.registerCarrier('twilio', TwilioCarrier);
  // voipCarrier.registerCarrier('plivo', PlivoCarrier);
  voipCarrier.registerCarrier('zoom', ZoomCarrier);
  voipCarrier.setTokenFactory((identifier: string) => store.dispatch(createToken(identifier)));
  voipCarrier.on('callStateChange', (state: object) => store.dispatch(updateCall(state)));
  voipCarrier.on('error', (err: VoipError) => {
    store.dispatch(notify(err.message, 'error'));
    const { viewer, call } = store.getState();
    trackConsultationJoin('error');
    Promise.reject({
      ...err,
      userId: (viewer as Viewer).id,
      consultationId: call.consultationId,
      sid: call.sid,
    });
  });
  voipCarrier.on('connect', () => {
    trackConsultationJoin('success');
  });

  async function fetchViewer(client: ApolloClient<any>) {
    const { data } = await client.query({ query: GET_VIEWER });
    const viewer = structuredClone(data.user) as Viewer;
    viewer.full_name = data.user?.profile?.name || undefined;
    return viewer;
  }

  function trackConsultationJoin(action: string) {
    const { call } = store.getState();
    store.dispatch(
      track(
        `consultation.join.web.${action}`,
        call.consultationId,
        {
          ua: navigator && navigator.userAgent,
        },
        false,
        true
      )
    );
  }

  function saveTimezone(viewer: Viewer) {
    if (!viewer.id || !viewer.profile || viewer.timezone) return;
    store.dispatch(
      updateProfile({
        id: viewer.profile.id,
        timezone: moment.tz.guess(),
      })
    );
  }

  // Init

  initSegmentIdentifier(viewer);

  saveTracking();

  saveInvitationToken();

  saveTimezone(viewer);

  if (config.intercomAppId) {
    initIntercom(config.intercomAppId, viewer);
  }

  initHubSpot(viewer);

  // KT: some possibly relevant server code
  // const icons = {
  //   shortcut: `${cdnUrl}/images/icons/favicon.png`,
  //   appleTouch: `${cdnUrl}/images/icons/apple-touch-icon.png`,
  //   manifest: `${cdnUrl}/images/icons/site.webmanifest`,
  //   msAppConfig: `${cdnUrl}/images/icons/browserconfig.xml`,
  // };

  // const data = {
  //   title: 'OnFrontiers - Learn faster',
  //   meta: {
  //     description:
  //       'Automation for the knowledge economy | Access specialists on any topic within hours. Share your experience to help others on their path forward. Invest in your expert network.',
  //     url: location.toString(),
  //     imageUrl: '/images/meta.png',
  //     type: 'website',
  //   },

  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
  const router = sentryCreateBrowserRouter(
    createRoutes({ store, graphqlClient: graphql, sendbird, permission })
  );

  // Note that React strict mode doesn't work with @mui/styles
  // See https://mui.com/system/styles/basics/
  createRoot(document.getElementById('root') as Element).render(
    <ReduxProvider store={store}>
      <CacheProvider value={cache}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            {/* <SnackbarProvider
              hideIconVariant
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              autoHideDuration={6000}
              maxSnack={3}
              Components={{
                undo: UndoSnackbar,
                knowledgeSummary: KnowledgeSummarySnackbar,
              }}
            > */}
            <ApolloProvider client={graphql.client}>
              <AppProvider context={context}>
                <App>
                  <RouterProvider router={router} />
                </App>
              </AppProvider>
            </ApolloProvider>
            {/* </SnackbarProvider> */}
          </ThemeProvider>
        </StyledEngineProvider>
      </CacheProvider>
    </ReduxProvider>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
})();
