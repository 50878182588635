import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootState } from '@/store';
import { black } from '@/theme/colors';

import DeleteProjectDialog from '../DeleteDialog/DeleteProjectDialog';
import FAIcon from '../Icon/FAIcon';
import IconMenu from '../IconMenu';
import EditMembers from './Dialog';

const SettingsMenu = (props: any) => {
  const navigate = useNavigate();
  const [dialogs, setDialogs] = React.useState({});
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);

  const openDialog = (dialog: any) => setDialogs({ [dialog]: true });

  const closeDialog = (dialog: any) => setDialogs({ [dialog]: false });

  const confirmDelete = () => setDeleteConfirmationOpen(true);

  const closeConfirmDelete = () => setDeleteConfirmationOpen(false);

  const { viewer, project, showDelete } = props;
  const members = project.members
    .filter((m: any) => ['active', 'denied'].includes(m.state))
    .sort((a: any, b: any) => {
      const roles = ['manager', 'associate', 'owner', 'member', 'viewer'];
      return roles.indexOf(a.role) - roles.indexOf(b.role);
    });

  return (
    <div>
      <IconMenu
        iconElement={
          <span style={{ padding: 12 }}>
            <FAIcon icon="cog" color={black} />
          </span>
        }
      >
        <MenuItem key="managerMembers" onClick={() => openDialog('members')}>
          Manage Members
        </MenuItem>
        <MenuItem
          key="addExpertRequest"
          onClick={() => navigate(`/request_expert?project_id=${project.id}`)}
        >
          Add Expert Request
        </MenuItem>
        {showDelete && (
          <MenuItem
            key="deleteProject"
            onClick={confirmDelete}
            disabled={project.expert_requests.some((e: any) => e.stats && e.stats.calls > 0)}
          >
            Delete Project
          </MenuItem>
        )}
      </IconMenu>
      <EditMembers
        viewer={viewer}
        // @ts-expect-error TS(2339): Property 'members' does not exist on type '{}'.
        open={dialogs.members}
        onClose={() => closeDialog('members')}
        project={project}
        initialValues={{ members }}
      />
      <DeleteProjectDialog
        projectId={project.id}
        onConfirm={closeConfirmDelete}
        onCancel={closeConfirmDelete}
        open={deleteConfirmationOpen}
        returnTo="/expert_requests"
      />
    </div>
  );
};

export default connect((state: RootState) => ({
  viewer: state.viewer,
}))(SettingsMenu);
