import MenuItem from '@mui/material/MenuItem';
import moment from 'moment-timezone';

import Button from '@/components/Button/Button';
import MaterialIcon from '@/components/Icon/MaterialIcon';
import IconMenu from '@/components/IconMenu/IconMenu';
import Link from '@/components/Link';
import config from '@/config';
import Duration from '@/core/duration';
import { darkGreen } from '@/theme/colors';
import { formatDuration } from '@/utils';

import googleImageURL from './calendar/google.png';
import googleImage2xURL from './calendar/google@2x.png';
import iCalImageURL from './calendar/ical.png';
import iCalImage2xURL from './calendar/ical@2x.png';
import outlookImageURL from './calendar/outlook.png';
import outlookImage2xURL from './calendar/outlook@2x.png';
import yahooImageURL from './calendar/yahoo.png';
import yahooImage2xURL from './calendar/yahoo@2x.png';

const imgStyle = {
  verticalAlign: 'middle',
  marginRight: 10,
};

const separator = '\n\n--\n\n';

export default function CalendarMenu({ event, expectedDuration, consultationId }: any) {
  const duration = Duration.parse(expectedDuration);
  const eventStart = event && `${moment(event.start).utc().format('YYYYMMDDTHHmmss')}Z`;
  const eventEnd = event && `${moment(event.end).utc().format('YYYYMMDDTHHmmss')}Z`;

  const eventDuration = event && `${formatDuration(duration, '')}`;

  const description = event && encodeURIComponent(event.description);
  const summary = event && encodeURIComponent(event.summary);
  const location = event && encodeURIComponent(event.location);

  // Shorter description for Outlook as it crashes if the URL size is greater than
  // 1369 when the user is not logged in at Microsoft at the time.
  // Also, parenthesis get replace with $.
  let outlookDescription = event && event.description.split(separator)[0];
  if (outlookDescription) {
    outlookDescription = encodeURIComponent(
      `${outlookDescription}${separator}https://try.onfrontiers.com/knowledge/cancellations-no-shows`
    );
  }

  return (
    <IconMenu
      iconElement={
        <Button fontColor={darkGreen} variant="text" startIcon={<MaterialIcon icon="event" />}>
          Add to Calendar
        </Button>
      }
    >
      <MenuItem>
        <Link target="_blank" href={`${config.webUrl}/consultation/${consultationId}/calendar`}>
          <img style={imgStyle} alt="" src={iCalImageURL} srcSet={`${iCalImage2xURL} 2x`} />
          iCal
        </Link>
      </MenuItem>

      {event && (
        <MenuItem>
          <Link
            target="_blank"
            href={`http://www.google.com/calendar/event?action=TEMPLATE&dates=${eventStart}%2F${eventEnd}&text=${summary}&location=${location}&details=${description}`}
          >
            <img style={imgStyle} alt="" src={googleImageURL} srcSet={`${googleImage2xURL} 2x`} />
            Google
          </Link>
        </MenuItem>
      )}

      {event && (
        <MenuItem>
          <Link
            target="_blank"
            href={`https://outlook.live.com/owa/?path=/calendar/action/compose&startdt=${eventStart}&enddt=${eventEnd}&subject=${summary}&location=${location}&body=${outlookDescription}`}
          >
            <img style={imgStyle} alt="" src={outlookImageURL} srcSet={`${outlookImage2xURL} 2x`} />
            Outlook
          </Link>
        </MenuItem>
      )}

      {event && (
        <MenuItem>
          <Link
            target="_blank"
            href={`https://calendar.yahoo.com/?v=60&VIEW=d&in_loc=${location}&TITLE=${summary}&ST=${eventStart}&DUR=${eventDuration}&DESC=${description}`}
          >
            <img style={imgStyle} alt="" src={yahooImageURL} srcSet={`${yahooImage2xURL} 2x`} />
            Yahoo!
          </Link>
        </MenuItem>
      )}
    </IconMenu>
  );
}
