import arrayMutators from 'final-form-arrays';
import React from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';

import { notify } from '@/actions/ui';
import Button from '@/components/Button';
import { addAttachments } from '@/consultation/store';
import Attachments from '@/expertrequest/components/ExpertRequestForm/Attachments';

import s from './SubmitAttachments.module.scss';

const maxAttachmentFiles = 5;
const maxAttachmentSize = 10;
const attachmentsAddStyle = { textAlign: 'right', marginRight: 40 };

interface SubmitAttachmentsState {
  allowSubmit: boolean;
}

class SubmitAttachments extends React.PureComponent<{}, SubmitAttachmentsState> {
  constructor(props: any) {
    super(props);

    this.state = {
      allowSubmit: false,
    };
  }

  handleAddFiles = () => {
    this.setState({ allowSubmit: true });
  };

  handleSubmitFiles = async (values: any) => {
    const { attachments } = values;
    // @ts-expect-error TS(2339) FIXME: Property 'addAttachments' does not exist on type '... Remove this comment to see the full error message
    const { addAttachments, consultation, notify } = this.props;

    try {
      await addAttachments({
        consultation,
        attachments: attachments.filter((a: any) => !a.id),
      });
      notify('Successfully submitted written response files.', 'success');
    } catch (ex) {
      notify('Error submitting written response files.', 'error');
      throw ex;
    }

    this.setState({ allowSubmit: false });
  };

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'isViewerExpert' does not exist on type '... Remove this comment to see the full error message
    const { isViewerExpert, consultation, isMobileVersion } = this.props;

    const canUpload = isViewerExpert;

    const title = isViewerExpert
      ? 'File(s) uploaded for written response to client'
      : consultation.attachments.length
        ? 'File(s) uploaded by expert for written response'
        : 'No files have been uploaded by expert';

    return (
      <Form
        onSubmit={this.handleSubmitFiles}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={consultation}
      >
        {({ handleSubmit }) => (
          <div className={s.attachmentSection}>
            <div>
              <p className={s.attachmentTitle}> {title}</p>
              {canUpload && (
                <p className={s.attachmentSubtitle}>
                  {`Upload files below (File limit: ${maxAttachmentFiles}, File size limit: ${maxAttachmentSize}MB)`}
                </p>
              )}
            </div>
            <Attachments
              // @ts-expect-error TS(2769) FIXME: No overload matches this call.
              name="attachments"
              disabled={!canUpload}
              label="Upload Files"
              addButtonLabel="Files"
              isMobileVersion={isMobileVersion}
              maxAttachmentFiles={maxAttachmentFiles}
              maxAttachmentSize={maxAttachmentSize}
              onAddedFiles={this.handleAddFiles}
              addActionStyle={attachmentsAddStyle}
            />
            {this.state.allowSubmit && (
              <div className={s.submitButton}>
                <Button onClick={handleSubmit} color="teal" size="large">
                  Submit Files
                </Button>
              </div>
            )}
          </div>
        )}
      </Form>
    );
  }
}

// @ts-expect-error TS(2629) FIXME: Cannot assign to 'SubmitAttachments' because it is... Remove this comment to see the full error message
SubmitAttachments = connect(undefined, {
  notify,
  addAttachments,
})(SubmitAttachments);

export default SubmitAttachments;
