import makeStyles from '@mui/styles/makeStyles';

import { SignupType, formatAuthPath } from '@/auth';
import Button from '@/components/Button';
import Link from '@/components/Link';

const useStyles = makeStyles({
  root: {
    maxWidth: 350,
    margin: '0 auto',
  },
  linkedin: {
    cursor: 'pointer',
  },
  actions: {
    margin: '30px 0 40px',
  },
  login: {
    marginRight: 40,
    minWidth: 100,
  },
});

interface AuthProps {
  domain?: any;
  invite?: string;
  nextUrl?: string;
  signupType?: SignupType;
}

function AuthChoice({ domain, invite, nextUrl, signupType }: AuthProps) {
  const signupTo = formatAuthPath({
    to: '/signup',
    domain,
    signupType,
    invite,
    next: nextUrl,
  });

  const signinTo = formatAuthPath({
    to: '/login',
    domain,
    signupType,
    invite,
    next: nextUrl,
  });

  const s = useStyles();
  return (
    <div className={s.root}>
      <div className={s.actions}>
        <Link to={signupTo} classes={{ root: s.login }}>
          <Button>Sign up</Button>
        </Link>
        <Link to={signinTo}>
          <Button variant="text">Log in →</Button>
        </Link>
      </div>
    </div>
  );
}

export default AuthChoice;
