import Collapse from '@mui/material/Collapse';
import Drawer from '@mui/material/Drawer';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import stringify from 'json-stringify-deterministic';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from '@/components/Button';
import FAIcon from '@/components/Icon/FAIcon';
import IconButton from '@/components/IconButton/IconButton';
import Module from '@/components/Module';
import { toQueryString } from '@/core/search';
import { getCache, setCache } from '@/utils';

import AdminFields from './fields/AdminFields';
import AdvancedFields from './fields/AdvancedFields';
import RegularFields from './fields/RegularFields';

const useMobileStyles = makeStyles(() => ({
  root: {
    marginBottom: 20,
  },
  module: {
    display: 'flex',
    alignItems: 'center',
  },
  side: {
    flex: 1,
  },
  text: {
    textAlign: 'center',
    flex: 3,
    fontSize: 18,
  },
  total: {
    fontSize: 20,
    marginBottom: 15,
    marginTop: 0,
  },
  paper: {
    width: '100%',
  },
}));

function MobileFilters({
  isAdmin,
  clearButton,
  adminFields,
  regularFields,
  advancedFields,
  totalFiltersApplied,
}: any) {
  const s = useMobileStyles();

  const [open, setOpen] = useState(false);

  const title = (
    <div className={s.module}>
      <FAIcon className={s.side} icon="close" onClick={() => setOpen(false)} />
      <div className={s.text}>Filters</div>
      <div className={s.side}>{clearButton}</div>
    </div>
  );

  return (
    <div className={s.root}>
      <Button startIcon={<FAIcon icon="filter" size={14} />} onClick={() => setOpen(true)}>
        Filter ({totalFiltersApplied})
      </Button>
      <Drawer open={open} PaperProps={{ classes: { root: s.paper } }}>
        <Module title={title}>
          {isAdmin && adminFields}
          {regularFields}
          {advancedFields}

          <Button color="teal" style={{ marginTop: 30 }} fullWidth onClick={() => setOpen(false)}>
            Update results
          </Button>
        </Module>
      </Drawer>
    </div>
  );
}

function DesktopFilters({ isAdmin, clearButton, adminFields, regularFields, advancedFields }: any) {
  const [showAdvanced, setShowAdvanced] = useState(false);

  useEffect(() => {
    const advanced = getCache('filterAdvanced');
    setShowAdvanced(advanced && advanced.value);
  }, []);

  const toggleAdvanced = () => {
    setCache('filterAdvanced', { value: !showAdvanced });
    setShowAdvanced(!showAdvanced);
  };

  return (
    <>
      {isAdmin && (
        <Module
          title="Admins Only"
          contextActionsElement={clearButton}
          bodyStyle={{ padding: 0 }}
          style={{ marginBottom: 20 }}
        >
          {adminFields}
        </Module>
      )}

      <Module
        title="Filters"
        contextActionsElement={isAdmin ? null : clearButton}
        bodyStyle={{ padding: 0 }}
        style={{ borderBottom: 0 }}
      >
        {regularFields}
      </Module>

      <Module
        title="Advanced"
        bodyStyle={{ padding: 0 }}
        secondary
        style={{ borderBottom: 0 }}
        contextActionsElement={
          <IconButton onClick={toggleAdvanced}>
            <FAIcon icon={`chevron-${showAdvanced ? 'up' : 'down'}`} size={16} />
          </IconButton>
        }
      >
        <Collapse in={showAdvanced}>{advancedFields}</Collapse>
      </Module>
    </>
  );
}

function Filters({ viewer, query, ...rest }: any) {
  const navigate = useNavigate();
  const [clearCounter, setClearCounter] = useState(0);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const search = (newQuery: any) => {
    navigate(
      `/search?${toQueryString({
        ...query,
        ...newQuery,
      })}`
    );
  };

  const handleFilterEvent = (field: any, e: any) => {
    search({
      [field]: e.target.value,
    });
  };

  const handleFilterAdd = (field: any, filter: any) => {
    search({
      [field]: [...query[field], filter],
    });
  };

  const handleFilterRemove = (field: any, filter: any) => {
    const list = query[field];
    const index = list.findIndex((f: any) => stringify(f) === stringify(filter));
    search({
      [field]: [...list.slice(0, index), ...list.slice(index + 1, list.length)],
    });
  };

  const clearFilters = () => {
    setClearCounter((prev) => prev + 1);
    navigate(`/search?q=${query.text}`);
  };

  const clearButton = (
    <Button size="medium" variant="text" onClick={clearFilters} style={{ padding: 0 }}>
      Clear
    </Button>
  );

  const adminFields = viewer.admin && (
    <AdminFields query={query} handleFilterEvent={handleFilterEvent} search={search} />
  );

  const regularFields = (
    <RegularFields
      query={query}
      handleFilterAdd={handleFilterAdd}
      handleFilterRemove={handleFilterRemove}
      handleFilterEvent={handleFilterEvent}
      search={search}
      viewer={viewer}
    />
  );

  const advancedFields = (
    <AdvancedFields
      query={query}
      handleFilterAdd={handleFilterAdd}
      handleFilterRemove={handleFilterRemove}
      handleFilterEvent={handleFilterEvent}
      // @ts-expect-error
      search={search}
      viewer={viewer}
    />
  );

  return (
    <div key={clearCounter}>
      {mobile ? (
        <MobileFilters
          isAdmin={viewer.admin}
          clearButton={clearButton}
          adminFields={adminFields}
          regularFields={regularFields}
          advancedFields={advancedFields}
          {...rest}
        />
      ) : (
        <DesktopFilters
          isAdmin={viewer.admin}
          clearButton={clearButton}
          adminFields={adminFields}
          regularFields={regularFields}
          advancedFields={advancedFields}
          {...rest}
        />
      )}
    </div>
  );
}

export default connect((state) => ({
  // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
  viewer: state.viewer,
  // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
  query: state.search.query,
}))(Filters);
