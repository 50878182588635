import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function (popupFn: any, purchaseLink: any, isTeam: any) {
  const navigate = useNavigate();
  return popupFn({
    title: 'Not enough credits',
    contents: (
      <>
        There are not enough credits to request the consultation.
        {isTeam && (
          <>
            <br />
            You can purchase more credits in your team settings.
          </>
        )}
      </>
    ),
    buttonAlignment: 'space-between',
    buttons: [
      {
        label: 'Cancel',
        callback: () => {},
        flat: true,
      },
      // {
      //   label: 'Contact',
      //   primary: true,
      //   callback: () => window.HubSpotConversations?.widget?.open(),
      // },
      {
        label: 'Purchase Credits',
        primary: true,
        callback: () => navigate(purchaseLink),
      },
    ],
  });
}
