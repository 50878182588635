import cx from 'classnames';
import langs from 'langs';
import React, { PureComponent } from 'react';

import { formatLocation, highlight } from '@/utils';

import FAIcon from '../Icon/FAIcon';
import Link from '../Link';
import s from './Tidbit.module.scss';

function Dot() {
  return <span className={s.dot}>•</span>;
}

interface TidbitProps {
  city?: string;
  country?: string;
  experience?: any;
  className?: string;
  onEditLocation?: any;
  onEditLanguages?: any;
  languages?: any[];
  editable?: boolean;
  inline?: boolean;
  showIcon?: boolean;
  [x: string]: any;
}

class Tidbit extends PureComponent<TidbitProps> {
  render() {
    const {
      city,
      country,
      experience,
      className,
      onEditLocation,
      onEditLanguages,
      languages = [],
      editable = false,
      inline = true,
      showIcon = true,
      ...other
    } = this.props;
    const location = formatLocation(city, country);

    const items = languages
      .map((l: any) => {
        const language = langs.where('3', l.code);
        return language ? language.name : l.code;
      })
      .filter((l: any) => l)
      .sort((a: any, b: any) => (a > b ? 1 : b > a ? -1 : 0))
      .join(', ');

    const rootClassName = cx(s.root, {
      [s.inline]: inline,
      className,
    });

    const addLocationLink = onEditLocation ? undefined : '/settings/location';
    const adLanguagesLink = onEditLanguages ? undefined : '/settings/communication';

    return (
      <div className={rootClassName} {...other}>
        {(location || editable) && (
          <div className={s.location}>
            {showIcon && (
              <FAIcon iconSet="fal" icon="map-marker" style={{ width: 14, marginRight: 5 }} />
            )}
            {location ? (
              // @ts-expect-error TS(2322): Type 'string | HTMLElement | DocumentFragment' is ... Remove this comment to see the full error message
              <span dangerouslySetInnerHTML={{ __html: highlight(location) }} />
            ) : (
              editable && (
                <Link className={s.link} onClick={onEditLocation} to={addLocationLink}>
                  Add location
                </Link>
              )
            )}
          </div>
        )}

        {((location && items) || editable) && inline && <Dot />}

        {items ? (
          <div>Speaks {items}</div>
        ) : (
          editable && (
            <Link className={s.link} onClick={onEditLanguages} to={adLanguagesLink}>
              Add language
            </Link>
          )
        )}

        {(items || location) && experience && inline && <Dot />}

        {experience && (
          <div>
            {!inline && (
              <FAIcon iconSet="fal" icon="briefcase" style={{ width: 14, marginRight: 5 }} />
            )}
            <span
              dangerouslySetInnerHTML={{
                // @ts-expect-error TS(2322): Type 'string | HTMLElement | DocumentFragment' is ... Remove this comment to see the full error message
                __html: highlight(
                  experience.title ? `${experience.title}, ${experience.organization}` : experience
                ),
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default Tidbit;
