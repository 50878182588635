import { FC } from 'react';

import Dialog, { DialogProps } from '../Dialog';
import Form from '../Form';

export interface EditDialogProps extends DialogProps {
  children?: React.ReactNode;
  disabled?: boolean;
  disableSubmitOnEnter?: boolean;
  onSubmit?: (values: any) => any;
  onReset?: () => void;
  onClose?: (arg: boolean) => void;
  fieldValue?: string;
}

const EditDialog: FC<EditDialogProps> = ({
  children,
  disabled,
  disableSubmitOnEnter,
  onSubmit,
  onReset,
  onClose,
  ...other
}) => {
  const handleClose = (c: any) => {
    if (onReset) {
      onReset();
    }

    if (onClose) {
      onClose(c);
    }
  };

  const handleSubmit = (values: any) => {
    if (onSubmit) {
      const errors = onSubmit(values);
      if (errors) return;
    }

    if (onClose) {
      onClose(true);
    }
  };

  return (
    <Dialog {...other} onClose={handleClose} onCancel={handleClose} onConfirm={handleSubmit}>
      <Form
        onSubmit={handleSubmit}
        onReset={handleClose}
        disableSubmitOnEnter={disableSubmitOnEnter}
      >
        {children}
      </Form>
    </Dialog>
  );
};

export default EditDialog;
