import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

// import PaymentUpdate from '../PaymentUpdate';
import { money } from '@/core/money';
import { RootState } from '@/store';

import Link from '../Link';
import TransactionList from '../TransactionList';
import s from './BillingSettings.module.scss';

class Billing extends PureComponent {
  render() {
    // @ts-expect-error TS(2339): Property 'userId' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { userId, groupId, account, email } = this.props;
    const { default_card: defaultCard } = account || {};
    const { last4digits: digits } = defaultCard || {};
    const transactions = (account && account.transactions) || [];

    return (
      <div>
        {/* <div className={s.paymentMethod}>
          <div className={s.paymentMethodCol1}>Payment</div>
          <div className={s.paymentMethodCol2}>
            {digits ? (
              <div>
                <FAIcon
                  iconSet="fal"
                  icon="credit-card"
                  color={darkGray}
                  size={18}
                  style={{ verticalAlign: 'middle', marginRight: 5 }}
                />{' '}
                Credit Card Ending {digits}
              </div>
            ) : (
              'No Credit Card Available'
            )}
          </div>
          <div className={s.paymentMethodCol3}>
            {(!account || account.permissions.some(x => x === 'write')) && (
              <PaymentUpdate userId={userId} groupId={groupId} email={email}>
                <Button
                  secondary
                  label={account ? 'Change Card' : 'Add Card'}
                />
              </PaymentUpdate>
            )}
          </div>
        </div> */}
        <div>
          <TransactionList
            transactions={transactions.filter((t: any) => money(t.money).cents !== 0)}
            amountColumn={{
              header: 'Amount',
              field: 'money',
            }}
          />
        </div>
        <div className={s.questions}>
          Questions about billing? Contact our{' '}
          <Link to="https://try.onfrontiers.com/knowledge">customer care team</Link>.
        </div>
      </div>
    );
  }
}

export default connect((state: RootState) => ({
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  email: state.viewer.email.address,
}))(Billing);
