import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';
import Tooltip from 'rc-tooltip';
import CopyToClipboard from 'react-copy-to-clipboard';
import { connect } from 'react-redux';

import { notify } from '@/actions/ui';
import EditIcon from '@/components/EditIcon';
import Link from '@/components/Link';
import { darkBrown, darkGreen } from '@/theme/colors';

const useStyles = makeStyles(() => ({
  field: {
    marginBottom: 8,
    display: 'flex',
    alignItems: 'center',
  },
  fieldContent: {
    overflow: 'hidden',
    flex: 1,
  },
  fieldRow: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    color: darkBrown,
  },
  fieldLabel: {
    fontWeight: 'bold',
    marginRight: 8,
  },
  fieldNote: {
    marginLeft: 29,
    fontSize: 14,
  },
  fieldIcon: {
    fontSize: 0,
    marginRight: 5,
    flex: '0 0 24px',
  },
  fieldEditIcon: {
    marginLeft: 5,
    flex: '0 0 auto',
  },
  clipboard: {
    cursor: 'pointer',
    color: darkGreen,
  },
}));

export default function Field({
  notify,
  className,
  icon,
  tooltip,
  label,
  note,
  link,
  clipboard,
  children,
  onEdit,
}: any) {
  const s = useStyles();

  let content = (
    <div>
      <div className={s.fieldRow}>
        {icon && <span className={s.fieldIcon}>{icon}</span>}
        {label && <span className={s.fieldLabel}>{label}:</span>}
        {children}
      </div>
      {note && <div className={s.fieldNote}>{note}</div>}
    </div>
  );

  if (tooltip) {
    content = (
      <Tooltip
        overlay={<span>{tooltip}</span>}
        placement="right"
        trigger={['hover']}
        mouseEnterDelay={2}
      >
        {content}
      </Tooltip>
    );
  }
  if (link) {
    content = <Link href={link}>{content}</Link>;
  } else if (clipboard) {
    content = (
      <CopyToClipboard text={clipboard} onCopy={() => notify('Copied to clipboard')}>
        <div className={s.clipboard}>{content}</div>
      </CopyToClipboard>
    );
  }

  return (
    <div className={cx(className, s.field)}>
      <div className={s.fieldContent}>{content}</div>
      {onEdit && (
        <div className={s.fieldEditIcon}>
          <EditIcon onClick={onEdit} />
        </div>
      )}
    </div>
  );
}
// @ts-expect-error TS(2630): Cannot assign to 'Field' because it is a function.
Field = connect(undefined, { notify })(Field);
