import React from 'react';

import Button from '@/components/Button/Button';
import WizardDone from '@/components/WizardDone';
import { isCallType } from '@/consultation/store';

import s from './Done.module.scss';

function Done({ consultation, confirmTime }: any) {
  const text = `
    Congratulations! You have successfully completed OnFrontiers' Compliance Training.

    You may now accept Consultation and Opportunity Call requests and make full use of the Platform.

    - OnFrontiers Compliance Team
  `;

  const { engagement_type: engagementType } = consultation || {};

  let acceptMessage = 'Go to Dashboard →';
  if (isCallType(engagementType)) {
    acceptMessage = 'Accept Call →';
  } else if (consultation) {
    acceptMessage = 'Accept Consultation →';
  }

  return (
    <WizardDone title="Compliance Training Complete" text={text}>
      <div className={s.actions}>
        <Button
          size="large"
          href={
            consultation
              ? `/consultation/${consultation.id}?confirm_time=${confirmTime}`
              : '/dashboard'
          }
        >
          {consultation ? acceptMessage : 'Go to Dashboard →'}
        </Button>
      </div>
    </WizardDone>
  );
}

export default Done;
