import { ApolloClient } from '@apollo/client';
import { PickerFileMetadata, PickerOptions, Security } from 'filestack-js';

import { gql } from '@/__generated__/gql';
import config from '@/config';

const CREATE_FILESTACK_SECURITY_UPLOAD = gql(/* GraphQL */ `
  mutation generateFilestackSecurityUpload {
    generateFilestackSecurityUpload {
      policy
      signature
    }
  }
`);

const CREATE_READ_POLICY = gql(/* GraphQL */ `
  mutation generateFilestackSecurityRead($handle: String!) {
    generateFilestackSecurityRead(handle: $handle) {
      policy
      signature
    }
  }
`);

export async function openFileDialog(graphql: ApolloClient<any>, options: PickerOptions = {}) {
  const { accept, transformations, maxSize, fromSources, ...rest } = options;

  if (!config.filestackApiKey) {
    throw new Error('Filestack API key is not defined');
  }

  const [filestack, { data }] = await Promise.all([
    import('filestack-js'),
    graphql.mutate({ mutation: CREATE_FILESTACK_SECURITY_UPLOAD }),
  ]);

  const security = data?.generateFilestackSecurityUpload;
  const client = filestack.init(config.filestackApiKey, {
    security: {
      policy: security?.policy as string,
      signature: security?.signature as string,
    },
  });

  return new Promise<PickerFileMetadata[]>((resolve, reject) => {
    client
      .picker({
        accept,
        transformations,
        fromSources: fromSources || [
          'local_file_system',
          'url',
          'googledrive',
          'dropbox',
          'box',
          'onedrive',
          'onedriveforbusiness',
          'audio',
        ],
        maxSize: maxSize || 10 * 1024 * 1024, // defaults to 10 MB
        onUploadDone: (result) => {
          const { filesUploaded: files } = result;
          if (files?.length > 0) {
            resolve(files);
          } else {
            reject(new Error('File has not been uploaded.'));
          }
        },
        // onFileUploadFailed: (file, error) => {
        //   console.error(file, error);
        // },
        ...rest,
      })
      .open();
  });
}

export async function presignAttachmentURL(graphql: ApolloClient<any>, filestackURL: string) {
  if (!filestackURL || !filestackURL.includes('filestackcontent.com')) {
    return filestackURL;
  }

  if (!config.filestackApiKey) {
    throw new Error('Filestack API key is not defined');
  }

  const url = new URL(filestackURL);
  const handle = url.pathname.split('/').pop();
  if (!handle) {
    throw new Error('Invalid filestack URL');
  }

  const { data } = await graphql.mutate({ mutation: CREATE_READ_POLICY, variables: { handle } });
  const { policy, signature } = data?.generateFilestackSecurityRead as Security;

  return `${url.origin}${url.pathname}?policy=${policy}&signature=${signature}`;
}

export function openImageDialog(graphql: ApolloClient<any>, { dimensions, accept }: any = {}) {
  const { width, height } = dimensions || {};

  return openFileDialog(graphql, {
    accept: accept || 'image/*',
    fromSources: [
      'local_file_system',
      'url',
      'imagesearch',
      'facebook',
      'instagram',
      'googledrive',
      'dropbox',
      'box',
      'googlephotos',
      'onedrive',
      'onedriveforbusiness',
      'webcam',
    ],
    transformations: dimensions
      ? {
          crop: {
            aspectRatio: width / height,
            force: true,
          },
        }
      : undefined,
  });
}

export function openPictureDialog(graphql: ApolloClient<any>) {
  return openImageDialog(graphql, { dimensions: { width: 220, height: 220 } });
}

export function openResumeDialog(graphql: ApolloClient<any>) {
  return openFileDialog(graphql, {
    accept: ['.doc', '.docx', '.pdf', '.rtf'],
    maxSize: 3 * 1024 * 1024, // 3 MB
    fromSources: [
      'local_file_system',
      'url',
      'googledrive',
      'dropbox',
      'box',
      'onedrive',
      'onedriveforbusiness',
    ],
  });
}

export function openSvgDialog(graphql: ApolloClient<any>) {
  return openFileDialog(graphql, {
    accept: ['image/svg+xml'],
    fromSources: [
      'local_file_system',
      'url',
      'googledrive',
      'dropbox',
      'box',
      'onedrive',
      'onedriveforbusiness',
    ],
    transformations: {
      crop: false,
      circle: false,
      rotate: false,
    },
  });
}
