import WrapperComponent from '@/components/WrapperComponent';
import { fetchUser } from '@/store/user';

import { LegacyRoute } from '../routesMiddleware';
import Browse from './Browse';

const expertsPerSection = 4;

// TODO: use iterators, variable length arrays
function zip(...arrays: any[]) {
  const results = [];
  for (let i = 0; i < arrays[0].length; i++) {
    results.push(arrays.map((a) => a[i]));
  }
  return results;
}

function flatten(list: any) {
  return list.reduce((a: any, b: any) => a.concat(Array.isArray(b) ? flatten(b) : b), []);
}

async function fetchCaseStudy(slug: string) {
  const module = await import(`./cases/${slug}.md`);
  const caseStudy = module.default;
  caseStudy.slug = slug;
  return caseStudy;
}

const route: LegacyRoute = {
  path: '/browse/:path',
  element: <WrapperComponent />,
  async action({ store, params }) {
    const { path } = params;
    const data = (await import(`./pages/${path}.yml`)).default;
    const bgImage = (await import(`./pages/${path}_bg.jpg`)).default;

    // KT: On second require webpack caches data in node. Smells like a bug
    if (!data || Object.keys(data).length === 0) {
      return undefined;
    }

    console.log(data);

    const expertIds = flatten(
      data.sections.map((s: any) => s.expertIds.slice(0, expertsPerSection))
    );
    const experts = await Promise.all(
      expertIds.map((id: any) => store.dispatch(fetchUser(id, { experiences: true })))
    );

    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    const expertMap = new Map(zip(expertIds, experts));
    const getExpert = expertMap.get.bind(expertMap);
    data.sections.forEach((section: any) => {
      section.experts = section.expertIds.map(getExpert).filter(Boolean);
    });

    // const blogPosts = await Promise.all(
    //   data.blogIds.slice(0, 2).map((slug: any) => store.dispatch(fetchBlogPostBySlug(slug)))
    // );
    const blogPosts = [] as Record<string, any>[];

    const caseStudies = await Promise.all(
      data.caseStudyIds.slice(0, 3).map((slug: any) => fetchCaseStudy(slug))
    );

    // title: `${data.title} Experts at OnFrontiers`,
    // meta: {
    //   imageUrl: bgImage,
    //   description: data.description,
    // }

    return (
      <Browse
        icon={data.icon}
        title={data.title}
        description={data.description}
        imgSrc={bgImage}
        industry={data.industry}
        sections={data.sections}
        blogPosts={blogPosts.filter(Boolean)}
        caseStudies={caseStudies.filter(Boolean)}
      />
    );
  },
};

export default route;
