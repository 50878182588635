import { Viewer } from './viewer';

export function getUserContextOptions(user: any, userContext?: any, groups?: any) {
  let options = [];

  options.push({
    value: 'client',
    name: user.first_name,
    billingAccountId: '',
  });

  if (user.signup_type === 'expert' || user.expert_state) {
    options.push({
      value: 'expert',
      name: user.first_name,
      description: 'Expert',
      billingAccountId: user.billing_account_id,
    });
  }

  if (user.admin) {
    options.push({
      value: 'admin',
      name: user.first_name,
      description: 'Admin',
      billingAccountId: user.billing_account_id,
    });
  }

  if (user.groups) {
    options = options.concat(
      user.groups.map((g: any) => ({
        value: g.id,
        name: g.name,
        billingAccountId: g.billing_account?.id || '',
      }))
    );
  }

  const group = userContext && groups && groups.find((g: any) => g.id === userContext);
  if (group && !options.some((o) => o.value === group.id)) {
    options.push({
      value: group.id,
      name: group.name,
      billingAccountId: group.billing_account?.id || '',
    });
  }

  return options;
}

export function isGroupContext(userContext: any) {
  return !!userContext && !['admin', 'client', 'expert'].includes(userContext);
}

export function getDefaultUserContext(viewer: any) {
  if (viewer.admin) return 'admin';

  const groupId = viewer.groups && viewer.groups[0] && viewer.groups[0].id;

  if (groupId) return groupId;

  if (viewer.signup_type === 'expert' || viewer.expert_state) return 'expert';

  return 'client';
}

export function getUserOrGroup(userContext: any, user: any) {
  if (isGroupContext(userContext)) {
    return user.groups.find((g: any) => g.id === userContext);
  }
  return user;
}

export function hasAcceptedEmail(viewer: Viewer): boolean {
  return !!(
    viewer.email?.accepted ||
    (viewer?.profile?.emails && viewer.profile?.emails.some((e: any) => e.accepted))
  );
}
