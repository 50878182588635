import moment from 'moment-timezone';

import ActionTypes from '@/actions/ActionTypes';
import { add, mergeAt, removeAt } from '@/utils/reducer';

const {
  GROUP__UPDATE_MEMBER,
  GROUP__REMOVE_MEMBER,
  GROUP__REMOVE_MEMBER_AWAITING,
  GROUP__ADD_MEMBER,
  GROUP__UPDATE,
  GROUP__BATCH_ADD,
  GROUP__LIST_LOADING,
  GROUP__LIST_LOADED,
} = ActionTypes;

interface GroupCollection {
  loading: boolean;
  loaded: boolean;
  edges: any[];
  pageInfo: { hasNextPage: boolean };
  resetAt?: Date;
}

const initialCollectionState = {
  loading: false,
  loaded: false,
  edges: [],
  pageInfo: { hasNextPage: true },
  resetAt: undefined,
};

interface GroupsState {
  default: GroupCollection;
  all: GroupCollection;
  networks: GroupCollection;
  sharing: GroupCollection;
}

const initialState: GroupsState = {
  default: initialCollectionState,
  all: initialCollectionState,
  networks: initialCollectionState,
  sharing: initialCollectionState,
};

function membersReducer(state = [], action: any) {
  let index;

  switch (action.type) {
    case GROUP__UPDATE_MEMBER:
      // @ts-expect-error TS(2339): Property 'node' does not exist on type 'never'.
      index = state.findIndex((m) => m.node.id === action.groupMemberId);
      if (index < 0) return state;
      return mergeAt(state, index, {
        // @ts-expect-error TS(2339): Property 'node' does not exist on type 'never'.
        node: { ...state[index].node, role: action.role },
      });
    case GROUP__REMOVE_MEMBER:
    case GROUP__REMOVE_MEMBER_AWAITING:
      // @ts-expect-error TS(2339): Property 'node' does not exist on type 'never'.
      index = state.findIndex((m) => m.node.id === action.groupMemberId);
      if (index < 0) return state;
      return removeAt(state, index);
    case GROUP__ADD_MEMBER:
      if (
        state.findIndex((m) => {
          // @ts-expect-error TS(2339): Property 'user' does not exist on type 'never'.
          if (m.user) {
            return (
              // @ts-expect-error TS(2339): Property 'node' does not exist on type 'never'.
              action.member.user && m.node.user.id === action.member.user.id
            );
          }
          // @ts-expect-error TS(2339): Property 'email' does not exist on type 'never'.
          if (m.email) {
            // @ts-expect-error TS(2339): Property 'node' does not exist on type 'never'.
            return m.node.email === action.member.email;
          }
          return false;
        }) >= 0
      )
        return state;

      return [...state, { node: action.member }];
    default:
      return state;
  }
}

function groupReducer(state = {}, action: any) {
  switch (action.type) {
    case GROUP__UPDATE:
      return { ...state, ...action.group };
    case GROUP__UPDATE_MEMBER:
    case GROUP__ADD_MEMBER:
    case GROUP__REMOVE_MEMBER:
      return {
        ...state,
        members: {
          // @ts-expect-error TS(2339): Property 'members' does not exist on type '{}'.
          ...state.members,
          // @ts-expect-error TS(2339): Property 'members' does not exist on type '{}'.
          edges: state.members
            ? // @ts-expect-error TS(2339): Property 'members' does not exist on type '{}'.
              membersReducer(state.members.edges, action)
            : [],
        },
      };
    case GROUP__REMOVE_MEMBER_AWAITING:
      return {
        ...state,
        // @ts-expect-error TS(2339): Property 'members_awaiting' does not exist on type... Remove this comment to see the full error message
        members_awaiting: state.members_awaiting && {
          // @ts-expect-error TS(2339): Property 'members_awaiting' does not exist on type... Remove this comment to see the full error message
          ...state.members_awaiting,
          // @ts-expect-error TS(2339): Property 'members_awaiting' does not exist on type... Remove this comment to see the full error message
          edges: membersReducer(state.members_awaiting.edges, action),
        },
      };
    default:
      return state;
  }
}

function updateNode(state: any, index: any, action: any) {
  const original = state.edges[index];
  const updated = {
    ...original,
    node: groupReducer(original.node, action),
    expiresAt: moment().add(1, 'minute').toISOString(),
    queryKey: action.queryKey,
  };
  return {
    ...state,
    edges: mergeAt(state.edges, index, updated),
  };
}

function collectionReducer(state = initialCollectionState, action: any) {
  switch (action.type) {
    case GROUP__UPDATE:
      return (() => {
        const item = action.group;
        // @ts-expect-error TS(2339): Property 'node' does not exist on type 'never'.
        const index = state.edges.findIndex((p) => p.node.id === item.id);
        if (index < 0) {
          return {
            ...state,
            // @ts-expect-error TS(2554): Expected 3 arguments, but got 2.
            edges: add(state.edges, {
              node: item,
              expiresAt: moment().add(1, 'minute').toISOString(),
              queryKey: action.queryKey,
            }),
          };
        }
        return updateNode(state, index, action);
      })();
    case GROUP__BATCH_ADD: {
      const { reset, edges, pageInfo } = action;
      if (reset) {
        return { edges, pageInfo, resetAt: new Date() };
      }
      return { edges: [...state.edges, ...edges], pageInfo };
    }
    case GROUP__LIST_LOADING:
      return { ...state, loading: true, loaded: false };
    case GROUP__LIST_LOADED:
      return { ...state, loading: false, loaded: true };
    case GROUP__UPDATE_MEMBER:
    case GROUP__ADD_MEMBER:
    case GROUP__REMOVE_MEMBER:
    case GROUP__REMOVE_MEMBER_AWAITING:
      return (() => {
        const { groupId } = action;
        // @ts-expect-error TS(2339): Property 'node' does not exist on type 'never'.
        const index = state.edges.findIndex((p) => p.node.id === groupId);
        if (index < 0) return state;
        return updateNode(state, index, action);
      })();
    default:
      return state;
  }
}

export default function groupsReducer(state = initialState, action: any): GroupsState {
  switch (action.type) {
    case GROUP__UPDATE:
    case GROUP__UPDATE_MEMBER:
    case GROUP__ADD_MEMBER:
    case GROUP__REMOVE_MEMBER:
    case GROUP__REMOVE_MEMBER_AWAITING: {
      const newState = { ...state };
      Object.keys(state).forEach((col) => {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        newState[col] = collectionReducer(state[col], action);
      });
      return newState;
    }
    case GROUP__BATCH_ADD:
    case GROUP__LIST_LOADING:
    case GROUP__LIST_LOADED: {
      const col = action.collection;
      return {
        ...state,
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        [col]: collectionReducer(state[col], action),
      };
    }
    default:
      return state;
  }
}
