import { Checkbox as MuiCheckbox } from '@mui/material';
import { FC } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { fetchGroupKeywordCounts, updateGroup } from '@/actions/group';
import { track } from '@/actions/tracking';
import { notify, popup } from '@/actions/ui';
import Button from '@/components/Button/Button';
import Form from '@/components/Form';
import { Checkbox, TextField } from '@/components/FormAdapters/FormAdapters';
import KeywordInput from '@/components/KeywordInput';
import { promoPopup } from '@/components/SendMessageButton/promo';
import SettingsSection from '@/components/SettingsSection';
import { isAdvancedUser } from '@/core/group';
import { usePermissions } from '@/hooks/useAppContext';
import { RootState } from '@/store';
import { fetchUser } from '@/store/user';

interface FormData {
  foo: any;
}

interface PreferencesProps {
  group: any;
  track: any;
}

const connector = connect(
  (state: RootState, ownProps: PreferencesProps) => ({
    viewer: state.viewer,
    initialValues: {
      id: ownProps.group.id,
      about: ownProps.group.about,
      default_anonymous_messaging: ownProps.group.default_anonymous_messaging,
      enforce_2fa: ownProps.group.enforce_2fa,
      profile_keywords_definition: (ownProps.group.profile_keywords_definition || []).map(
        (k: any) => k.name
      ),
    },
  }),
  {
    fetchGroupKeywordCounts,
    updateGroup,
    fetchUser,
    notify,
    popup,
    track,
  }
);

const Preferences: FC<
  PreferencesProps &
    ConnectedProps<typeof connector> &
    InjectedFormProps<FormData, PreferencesProps>
> = ({
  viewer,
  group,
  popup,
  track,
  fetchUser,
  updateGroup,
  notify,
  fetchGroupKeywordCounts,
  handleSubmit: onSubmit,
}) => {
  const [canUpdateAdmin, canUpdateHigherTier] = usePermissions(
    [
      { service: 'group', action: 'update_admin_settings', resource: group.id },
      { service: 'group', action: 'update_higher_tier_settings', resource: group.id },
    ],
    [group.id]
  );

  const handleUpdateGroup = async (values: FormData) => {
    try {
      await updateGroup(values);

      // update viewer groups
      fetchUser(viewer.username, { groups: true });
      notify('Team settings updated.', 'success');
    } catch (err) {
      console.error(err);
      notify('Error when updating team settings.', 'error');
    }
  };

  const handleConfirmation = (values: any, removes: any) => {
    const contents = (
      <div>
        Profiles marked with deleted team keyword(s) will have that annotation&nbsp;removed:
        {removes.groupKeywordCounts.map((c: any) => (
          <p key={c.id}>
            {c.name}: {c.count} profile(s) affected
          </p>
        ))}
      </div>
    );

    popup({
      title: 'Warning',
      contents,
      buttonAlignment: 'space-between',
      buttons: [
        { flat: true, label: 'Cancel' },
        {
          label: 'Confirm',
          callback: async () => {
            handleUpdateGroup(values);
          },
        },
      ],
    });
  };

  const handleSubmit = async (values: any) => {
    const removeIDs: any = [];
    group.profile_keywords_definition.forEach((keyword: any) => {
      if (values.profile_keywords_definition.includes(keyword.name) === false) {
        removeIDs.push(keyword.id.toString());
      }
    });

    const removes = await fetchGroupKeywordCounts(removeIDs);

    if (removes.groupKeywordCounts && removes.groupKeywordCounts.length > 0) {
      handleConfirmation(values, removes);
    } else handleUpdateGroup(values);
  };

  const showMessagingPromo = () => {
    promoPopup(popup, track);
  };

  /*
  For now, we allow super-admins to enable 2FA enforcement ONLY to the OnFrontiers group.
  To enable other teams and/or allow other team admins/owners to enable this feature we
  need to relax the following logic.  If team admins/owners wish to enable this feature
  on their team, `update_higher_tier_settings` permissions are appropriate. Note we can't
  use this for OnFrontiers group because members do not have this permission explicitly
  revoked and would be granted permission.

  TODO: When we choose to allow members outside of super-admins (OF owners/admins) to
  enforce 2FA or enable this feature for other teams, modify the below logic appropriately.
*/
  const displayEnforce2FA = group.name === 'OnFrontiers' && viewer.admin && canUpdateAdmin;

  return (
    <div>
      <Form onSubmit={onSubmit(handleSubmit)} disableSubmitOnEnter>
        <SettingsSection title="General">
          <Field
            component={TextField}
            name="about"
            multiline
            minRows={3}
            maxRows={10}
            label="About"
            placeholder="This will be displayed on your team sign up page"
            fullWidth
          />
        </SettingsSection>

        <SettingsSection title="Messaging" box>
          {isAdvancedUser(viewer) ? (
            <Field
              type="checkbox"
              component={Checkbox}
              name="default_anonymous_messaging"
              label="Keep team members anonymous by default when messaging experts"
            />
          ) : (
            <MuiCheckbox
              // @ts-expect-error TS(2322) FIXME: Type '{ label: string; checked: false; onClick: ()... Remove this comment to see the full error message
              label="Keep team members anonymous by default when messaging experts"
              checked={false}
              onClick={showMessagingPromo}
            />
          )}
        </SettingsSection>

        {displayEnforce2FA && (
          <SettingsSection title="Security" box>
            <Field
              type="checkbox"
              component={Checkbox}
              name="enforce_2fa"
              label="Require members to activate Two Factor Authentication"
            />
          </SettingsSection>
        )}

        {canUpdateHigherTier && (
          <SettingsSection
            title="Team Keywords"
            text="Define terms team members can use when listing expertise and organizing experts within your network"
            box
          >
            <Field
              component={KeywordInput}
              primary
              freeSolo
              getOptionLabel={(o: any) => o}
              name="profile_keywords_definition"
              placeholder="Click to add keywords"
            />
          </SettingsSection>
        )}
        <Button type="submit" style={{ marginTop: 20 }} size="medium">
          Save
        </Button>
      </Form>
    </div>
  );
};

export default reduxForm<FormData, PreferencesProps>({
  form: 'teamPreferences',
})(connector(Preferences));
