import { AppThunk } from '@/store';

import ActionTypes from './ActionTypes';

const { TRACKING_LOG__CREATE } = ActionTypes;

export function track(
  code: any,
  objectId: any,
  context: any,
  preventDuplicate = false,
  recordIpAddress = false
): AppThunk<Promise<any>> {
  return async (dispatch, getState, { graphql }) => {
    if (preventDuplicate) {
      const { tracking } = getState();
      // @ts-ignore
      const obj = tracking[`${code}:${objectId}`];
      if (obj) return obj;
    }

    const { track: tracking } = await graphql.mutate(
      `(
      $code: String!
      $objectId: String
      $context: Json
      $recordIpAddress: Boolean
    ) {
      track(
        code: $code
        object_id: $objectId
        context: $context
        record_ip_address: $recordIpAddress
      ) {
        id, code, object_id
      }
    }`,
      { code, context, objectId, recordIpAddress }
    );

    if (track) {
      dispatch({ type: TRACKING_LOG__CREATE, tracking });

      return track;
    }
  };
}
