import queryString from 'query-string';

import { CandidateState } from '@/__generated__/graphql';

export interface Candidate {
  id: string;
  state: CandidateState;
}

export interface ExpertRequest {
  id: string;
  slug: string;
  public_html_url: string;
  er_type: string;
  state: string;
  name: string;
  project: Record<string, any>;
  permissions: any[];
  stats: any;
  archived: boolean;
}

export function buildRefUrl(expertRequest: ExpertRequest, source: string, userId?: string) {
  const params = {
    utm_source: source,
    utm_medium: 'referral',
    utm_campaign: `expert_request${expertRequest.id}`,
  } as Record<string, any>;
  if (userId) {
    params.ref = userId;
  }

  return `${expertRequest.public_html_url}?${queryString.stringify(params)}`;
}
