import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { inviteUser } from '@/actions/invitation';
import { notify } from '@/actions/ui';
import { RootState } from '@/store';
import { red500 } from '@/theme/colors';

import FAIcon from '../Icon/FAIcon';
import Link from '../Link';
import Picture from '../Picture';
import { Select } from '../Select/Select';
import Tidbit from '../Tidbit';
import s from './GroupMember.module.scss';

const roleText = {
  owner: 'Owner',
  admin: 'Admin',
  member: 'Member',
};

const inviteButtonStyle = {
  style: {
    lineHeight: '16px',
    height: '16px',
    minWidth: 0,
    fontSize: 14,
    paddingLeft: 8,
    paddingRight: 8,
    textTransform: 'capitalize',
  },
};

class GroupMember extends PureComponent {
  static propTypes = {
    groupId: PropTypes.string.isRequired,
    member: PropTypes.object.isRequired,
    allowedRoles: PropTypes.array.isRequired,
    removeGroupMember: PropTypes.func,
    updateGroupMember: PropTypes.func,
  };

  state = {
    resendInviteAvailable: true,
  };

  handleRemoveClick = () => {
    // @ts-expect-error TS(2339): Property 'removeGroupMember' does not exist on typ... Remove this comment to see the full error message
    const { removeGroupMember, groupId, member } = this.props;
    removeGroupMember(groupId, member.id);
  };

  // @ts-expect-error TS(7031): Binding element 'role' implicitly has an 'any' typ... Remove this comment to see the full error message
  handleRoleChange = ({ target: { value: role } }) => {
    // @ts-expect-error TS(2339): Property 'updateGroupMember' does not exist on typ... Remove this comment to see the full error message
    const { updateGroupMember, groupId, member } = this.props;
    updateGroupMember(groupId, member.id, { role });
  };

  handleReinvite = () => {
    // @ts-expect-error TS(2339): Property 'member' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { member, inviteUser, groupId, notify } = this.props;
    this.setState({ sendingInvite: true });
    return inviteUser(
      {
        collectionType: 'group',
        collectionId: groupId,
        email: member.email,
        role: member.role,
      },
      { reinvite: true }
    ).then(
      () => {
        this.setState({ sendingInvite: false, resendInviteAvailable: false });
        notify('Invitation sent.');
      },
      () => {
        this.setState({ sendingInvite: false, resendInviteAvailable: false });
        notify('Unable to resend invite', 'error');
      }
    );
  };

  render() {
    // @ts-expect-error TS(2339): Property 'member' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { member, allowedRoles, removeGroupMember, updateGroupMember } = this.props;
    const { email, user, role } = member;
    const { html_url: htmlUrl, name, city, country } = user || {};

    // @ts-expect-error TS(2339): Property 'sendingInvite' does not exist on type '{... Remove this comment to see the full error message
    const { sendingInvite, resendInviteAvailable } = this.state;

    return (
      <div className={s.root}>
        <Picture user={user} size={60} />

        <div className={s.body}>
          <div className={s.header}>
            {user ? (
              <Link to={htmlUrl}>
                <h3 className={s.name}>{name}</h3>
              </Link>
            ) : (
              <div>
                <h3 className={s.name}>{email}</h3>
                <div className={s.memberInvited}>
                  Invite Sent.{' '}
                  {resendInviteAvailable && (
                    // @ts-expect-error TS(2769): No overload matches this call.
                    <Button
                      color="secondary"
                      disabled={sendingInvite}
                      {...inviteButtonStyle}
                      onClick={this.handleReinvite}
                    >
                      {sendingInvite ? 'Sending...' : 'Resend'}
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
          <Tidbit city={city} country={country} />
        </div>

        <div className={s.actions}>
          <Select
            // @ts-expect-error TS(2322): Type '{ id: string; value: any; disabled: boolean;... Remove this comment to see the full error message
            id={`memberRole${email}`}
            value={role}
            disabled={!updateGroupMember || allowedRoles.length <= 1}
            style={{ width: 110 }}
            onChange={this.handleRoleChange}
            sort={false}
            margin="dense"
            options={[
              // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              { label: roleText[role], value: role },
              ...allowedRoles
                .filter((r: any) => r !== role)
                .map((r: any) => ({
                  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  label: roleText[r],
                  value: r,
                })),
            ]}
          />
          <IconButton
            style={{ marginRight: -12, color: red500 }}
            disabled={!removeGroupMember}
            onClick={this.handleRemoveClick}
          >
            <FAIcon icon="trash-o" color={red500} size={20} />
          </IconButton>
        </div>
      </div>
    );
  }
}

export default connect(
  (state: RootState) => ({
    viewer: state.viewer,
  }),
  {
    inviteUser,
    notify,
  }
  // @ts-expect-error TS(2345): Argument of type 'typeof GroupMember' is not assig... Remove this comment to see the full error message
)(GroupMember);
