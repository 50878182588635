import gql from 'graphql-tag';

import { AppThunk } from '@/store';

/**
 * @param {number} userId
 * @returns {Promise<{ payouts_enabled: boolean }>} payoutAccount
 */
export function payoutAccountByUserID(userId: any): AppThunk<Promise<any>> {
  const getPayoutAccountQuery = gql`
    query getPayoutAccount($userId: String!) {
      payoutAccountByUserID(id: $userId) {
        payouts_enabled
      }
    }
  `;
  return async (_dispatch, _getState, { graphql }) => {
    const result = await graphql.send(getPayoutAccountQuery, { userId });
    return result.payoutAccountByUserID;
  };
}

/**
 * @param {number} userId
 * @returns {Promise<string>} payoutLoginUrl
 */
export function createPayoutLoginURLByUser(userId: any): AppThunk<Promise<any>> {
  const createPayoutLoginURLQuery = gql`
    mutation createPayoutLoginURL($userId: String!) {
      createPayoutLoginURL(user_id: $userId)
    }
  `;

  return async (_dispatch, _getState, { graphql }) => {
    const result = await graphql.send(createPayoutLoginURLQuery, { userId });
    return result.createPayoutLoginURL;
  };
}

/**
 * @param {number} userId
 * @param {string} type
 * @returns {Promise<string>} payoutLoginUrl
 */
export function createPayoutAccountURLByUserAndType(
  userId: any,
  type: any
): AppThunk<Promise<any>> {
  const createPayoutAccountURLQuery = gql`
    mutation createPayoutAccountURL($userId: String!, $type: PayoutAccountURLType!) {
      createPayoutAccountURL(user_id: $userId, type: $type)
    }
  `;

  return async (_dispatch, _getState, { graphql }) => {
    const result = await graphql.send(createPayoutAccountURLQuery, {
      userId,
      type,
    });
    return result.createPayoutAccountURL;
  };
}
