/**
 * Generates a GraphQL variable declaration string if the field exists in the object.
 *
 * @param {string} field - The name of the field to check in the object.
 * @param {string} type - The GraphQL type of the field.
 * @param {Object} obj - The object to check for the field.
 * @returns {string} The GraphQL variable declaration string or an empty string if the field does not exist in the object.
 */
export const gqlVar = (field: string, type: string, obj: object): string =>
  field in obj ? `$${field}: ${type}` : '';

/**
 * Generates a GraphQL parameter string if the field exists in the object.
 *
 * @param {string} field - The name of the field to check in the object.
 * @param {Object} obj - The object to check for the field.
 * @returns {string} The GraphQL parameter string or an empty string if the field does not exist in the object.
 */
export const gqlParam = (field: string, obj: object): string =>
  field in obj ? `${field}: $${field}` : '';
