import queryString from 'query-string';
import { redirect } from 'react-router-dom';

import {
  awaitingProjectMembershipApproval,
  fetchProject,
  updateProjectMember,
} from '@/actions/project';
import LayoutPage from '@/components/Layout/LayoutPage';
import RequestProjectAccess from '@/components/RequestProjectAccess';
import WrapperComponent from '@/components/WrapperComponent';
import { Viewer } from '@/core/viewer';

import ProjectDetails from './ProjectDetails';

function requestAdd(query: any, viewer: Viewer, id: any, accessRequested: any) {
  document.title = 'Expert Request';
  return (
    <LayoutPage showNav selected="expert_requests">
      <RequestProjectAccess
        viewer={viewer}
        path="project"
        query={query}
        projectId={id}
        accessRequested={accessRequested}
      />
    </LayoutPage>
  );
}

export default [
  {
    // private project page
    path: '/project/:id',
    element: <WrapperComponent />,
    async action({ store, params: { id } = {}, query }: any) {
      const { token, approve_member: approve, deny_member: deny } = query;
      if (/(.+-[0-9]+)$/.test(id)) {
        return redirect(`/expert_request/${id}?${queryString.stringify(query)}`);
      }

      const { viewer } = store.getState();
      if (!viewer.id) return requestAdd(query, viewer, id, false);

      let project;
      try {
        project = await store.dispatch(fetchProject(id));
      } catch (e) {
        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        if (!e.isPermissionError) throw e;
        const accessRequested = await store.dispatch(
          awaitingProjectMembershipApproval({ projectId: id })
        );
        return requestAdd(query, viewer, id, accessRequested);
      }

      if (token && (approve || deny)) {
        try {
          await store.dispatch(
            updateProjectMember(id, {
              id: approve || deny,
              state: approve ? 'active' : 'denied',
            })
          );
        } catch (err) {
          Promise.reject(err);
        }
        // we don't want to keep the token in the URL since user can share link
        return redirect(`/project/${id}`);
      }

      document.title = project.name;
      return <ProjectDetails projectId={id} />;
    },
  },
  {
    // legacy new project wizard flow
    path: '/projects/new',
    async action() {
      return redirect('/request_expert');
    },
  },
];
