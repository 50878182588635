import makeStyles from '@mui/styles/makeStyles';
import qs from 'query-string';
import { FC, useCallback, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { ConnectedProps, connect } from 'react-redux';

import { fetchAppNotifications } from '@/actions/appNotification';
import { notify } from '@/actions/ui';
import {
  authenticateOtp,
  createOtpAuthConfiguration,
  otpQrCodeBase64,
  otpTypes,
} from '@/auth/store';
import Button from '@/components/Button';
import Dialog, { DialogProps } from '@/components/Dialog';
import FaqLink from '@/components/FaqLink';
import { PhoneInput, RadioGroup, TextField } from '@/components/FormAdapters';
import Link from '@/components/Link';
import { RootState } from '@/store';
import { fetchUser } from '@/store/user';
import { darkGreen } from '@/theme/colors';
import { capitalize, errorMessage } from '@/utils';

export const FAQ_URL =
  'https://support.onfrontiers.com/en/articles/4662547-two-factor-authentication';

const useStyles = makeStyles({
  qrCode: {
    width: 200,
    height: 200,
    margin: '0 auto',
  },
  title: {
    margin: '0 0 15px',
  },
  titleSuccess: {
    margin: 0,
    color: darkGreen,
  },
  paper: {
    fontSize: 14,
    textAlign: 'center',
    maxWidth: 410,
    boxSizing: 'border-box',
  },
  verificationCode: {
    maxWidth: 200,
    '& input': {
      fontSize: 20,
      textAlign: 'center',

      MozAppearance: 'textfield',
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
      },
    },
  },
  resend: {
    fontSize: 14,
    color: darkGreen,
    textTransform: 'none',
    fontWeight: 400,
    margin: '30px 0 0 5px',
    padding: '8px 11px',
  },
  text: {
    lineHeight: 1.5,
  },
});

interface SuccessStepProps extends DialogProps {
  user?: any;
}

const SuccessStep: FC<SuccessStepProps> = ({ onClose, user, ...other }) => {
  const s = useStyles();
  return (
    <Dialog fullWidth classes={{ paper: s.paper }} onClose={onClose} {...other}>
      <h4 className={s.titleSuccess}>All set!</h4>
      <p className={s.text}>
        Successfully set up two-factor authentication.
        {`${user ? '' : ' A new verification code may be required for login.'}`}
      </p>
      {/* @ts-ignore */}
      <Button onClick={onClose} fullWidth={false}>
        Done
      </Button>
    </Dialog>
  );
};

const SecurityDialog = (props: DialogProps) => {
  const s = useStyles();
  return (
    <Dialog
      fullWidth
      cancelLabel="Cancel"
      confirmLabel="Continue"
      cancelButtonProps={{ size: 'sm' }}
      confirmButtonProps={{ size: 'sm' }}
      classes={{ paper: s.paper }}
      {...props}
    />
  );
};

interface AuthenticatorStepProps {
  otpAuthUri: string;
  qrCodeBase64: string;
  showFAQ?: boolean;
}

const AuthenticatorStep: FC<AuthenticatorStepProps> = ({
  otpAuthUri,
  qrCodeBase64,
  showFAQ = false,
}) => {
  const s = useStyles();

  const secret = useMemo(() => qs.parse(otpAuthUri).secret, [otpAuthUri]);

  return (
    <>
      <h4 className={s.title}>Set up two-factor authentication</h4>
      <p className={s.text}>
        Scan the QR code below with an authentication app, such as Google Authenticator, on your
        phone.
      </p>
      <Link to={otpAuthUri}>
        <img className={s.qrCode} src={qrCodeBase64} alt="Two factor authentication key" />
      </Link>
      <p className={s.text}>
        If you are unable to see the code, type this key on your authentication app (time based
        password):&nbsp;
        <Link to={otpAuthUri}>
          <b>{secret}</b>
        </Link>
      </p>
      <Field
        component={TextField}
        changeOnBlur={false}
        autoFocus
        name="otp"
        label="Verification Code"
        variant="outlined"
        classes={{ root: s.verificationCode }}
        inputType="number"
      />
      {showFAQ && <FaqLink url={FAQ_URL} className={s.text} />}
    </>
  );
};

interface VerificationStepProps {
  address: string;
  method: string;
  user?: any;
  onSuccess: () => void;
  setOtp: (otp: string) => void;
  refetch?: () => Promise<void>;
  open: boolean;
  onCancel: () => void;
}

const verificationStepConnector = connect(undefined, {
  createOtpAuthConfiguration,
  authenticateOtp,
  notify,
  fetchUser,
  fetchAppNotifications,
});

const VerificationStepBare: FC<
  VerificationStepProps & ConnectedProps<typeof verificationStepConnector>
> = ({
  address,
  method,
  createOtpAuthConfiguration,
  authenticateOtp,
  notify,
  onSuccess,
  fetchAppNotifications,
  user,
  setOtp,
  refetch,
  open,
  onCancel,
}) => {
  const s = useStyles();

  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        const success = await authenticateOtp(values.otp);

        if (!success) {
          return { otp: 'Invalid verification code' };
        }

        if (user) {
          fetchAppNotifications({ forceReload: true });
        } else {
          await setOtp(values.otp);
        }

        if (refetch) refetch();
        onSuccess();
      } catch (err) {
        notify('An error occurred when trying to setup two-factor authentication.', 'error');
        throw err;
      }
    },
    [refetch, authenticateOtp, fetchAppNotifications, notify, onSuccess, setOtp, user]
  );

  const handleResend = useCallback(async () => {
    try {
      await createOtpAuthConfiguration(method, address);
      notify(`Verification code sent to ${address}`);
    } catch (err) {
      notify('An error occurred when trying to setup otp.', 'error');
      throw err;
    }
  }, [address, createOtpAuthConfiguration, method, notify]);

  const validate = useCallback((values: any) => {
    const errors = {};
    if (!(values.otp || '').trim()) {
      // @ts-expect-error TS(2339): Property 'otp' does not exist on type '{}'.
      errors.otp = 'Required';
    }
    return errors;
  }, []);

  return (
    <Form onSubmit={handleSubmit} validate={validate} subscription={{ submitting: true }}>
      {({ handleSubmit, submitting }: any) => {
        return (
          <SecurityDialog
            open={open}
            onCancel={onCancel}
            disableSubmit={submitting}
            onConfirm={handleSubmit}
          >
            <form onSubmit={(e: any) => handleSubmit(e)}>
              <h4 className={s.title}>Enter the code we sent you</h4>
              <div>
                <Field
                  component={TextField}
                  changeOnBlur={false}
                  autoFocus
                  name="otp"
                  label="Verification Code"
                  variant="outlined"
                  classes={{ root: s.verificationCode }}
                  inputType="number"
                />
                <Button
                  variant="text"
                  size="medium"
                  classes={{ root: s.resend }}
                  onClick={handleResend}
                >
                  Resend
                </Button>
              </div>
            </form>
          </SecurityDialog>
        );
      }}
    </Form>
  );
};

const VerificationStep = verificationStepConnector(VerificationStepBare);

const methodSelectionConnector = connect(
  (state: RootState) => ({
    viewer: state.viewer,
    // @ts-ignore
    otpAuthUri: state.auth.otpAuthUri,
  }),
  {
    notify,
    fetchUser,
    fetchAppNotifications,
    authenticateOtp,
    otpQrCodeBase64,
    createOtpAuthConfiguration,
  }
);

interface MethodSelectionProps {
  user: any;
  email: string;
  method: string;
  setMethod: (method: string) => any;
  onSuccess: (address: string) => void;
  handleVerificationSuccess: () => void;
  setOtp: (otp: string) => void;
  open: boolean;
  onCancel: () => void;
}

interface MethodFormData {
  email: string;
  otp: string;
  phone: string;
  method_state: 'email' | 'sms' | 'app';
}

const MethodSelectionBare: FC<
  MethodSelectionProps & ConnectedProps<typeof methodSelectionConnector>
> = ({
  user,
  email,
  viewer,
  method,
  setMethod,
  createOtpAuthConfiguration,
  otpAuthUri,
  authenticateOtp,
  otpQrCodeBase64,
  fetchUser,
  fetchAppNotifications,
  onSuccess,
  handleVerificationSuccess,
  notify,
  setOtp,
  open,
  onCancel,
}) => {
  const s = useStyles();

  const [showFAQ, setShowFAQ] = useState(true);
  const [qrCodeBase64, setQRCodeBase64] = useState<string | undefined>(undefined);

  const handleSubmit = useCallback(
    async (values: MethodFormData) => {
      try {
        switch (values.method_state) {
          case 'email':
            await createOtpAuthConfiguration(values.method_state, values.email);
            onSuccess(values.email);
            break;
          case 'app': {
            setShowFAQ(false);
            const success = await authenticateOtp(values.otp);
            if (!success) {
              setShowFAQ(true);
              return { otp: 'Invalid verification code' };
            }
            if (user) {
              await fetchUser(user.username, {
                force: true,
                otpAuthEnabled: true,
              });
              fetchAppNotifications({ forceReload: true });
            } else {
              setOtp(values.otp);
            }
            handleVerificationSuccess();
            break;
          }
          default:
            await createOtpAuthConfiguration(values.method_state, values.phone);
            onSuccess(values.phone);
        }
      } catch (err) {
        if (err instanceof Error && err.message.startsWith('GraphQL Error')) {
          notify(errorMessage(err.message), 'error');
        } else {
          notify('An error occurred when trying to setup.', 'error');
          throw err;
        }
      }
    },
    [
      authenticateOtp,
      createOtpAuthConfiguration,
      fetchAppNotifications,
      fetchUser,
      handleVerificationSuccess,
      notify,
      onSuccess,
      setOtp,
      user,
    ]
  );

  const validate = useCallback((values: any) => {
    const errors = {};
    if (values.method_state === otpTypes.email && !(values.email || '').trim()) {
      // @ts-expect-error TS(2339): Property 'email' does not exist on type '{}'.
      errors.email = 'Required';
    }
    if (values.method_state === otpTypes.sms && !(values.phone || '').trim()) {
      // @ts-expect-error TS(2339): Property 'phone' does not exist on type '{}'.
      errors.phone = 'Required';
    }
    if (values.method_state === otpTypes.app && !(values.otp || '').trim()) {
      // @ts-expect-error TS(2339): Property 'otp' does not exist on type '{}'.
      errors.otp = 'Required';
    }

    return errors;
  }, []);

  const handleChange = useCallback(
    async (value: any) => {
      try {
        await setMethod(value);
        if (value === otpTypes.app) {
          await createOtpAuthConfiguration(otpTypes.app, '');
          const res = await otpQrCodeBase64();
          setQRCodeBase64(res);
        }
      } catch (err) {
        notify(
          'An error occurred when trying to select the two-factor authenticator method.',
          'error'
        );
        throw err;
      }
    },
    [createOtpAuthConfiguration, notify, otpQrCodeBase64, setMethod]
  );

  const initialValues = useMemo(() => ({ phone: viewer.phone }), [viewer.phone]);

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      subscription={{ submitting: true }}
      initialValues={initialValues}
    >
      {({ handleSubmit, submitting }: any) => {
        return (
          <SecurityDialog
            open={open}
            onCancel={onCancel}
            disableSubmit={submitting}
            onConfirm={handleSubmit}
          >
            <form onSubmit={(e: any) => handleSubmit(e)}>
              <h4 className={s.title}>Set up two-factor authentication</h4>
              <Field
                name="method_state"
                component={RadioGroup}
                onChange={handleChange}
                label="Select the authentication method"
                FormControlProps={{ style: { marginTop: 20 } }}
                style={{ flexDirection: 'row', justifyContent: 'center' }}
                defaultValue={method}
                options={[
                  {
                    label: capitalize(otpTypes.email),
                    value: otpTypes.email,
                  },
                  // {
                  //   label: capitalize(otpTypes.sms),
                  //   value: otpTypes.sms,
                  // },
                ]}
              />
              {method === otpTypes.sms && (
                <Field
                  component={PhoneInput}
                  name="phone"
                  label="Phone"
                  variant="outlined"
                  showExampleOnError
                />
              )}
              {method === otpTypes.email && (
                <Field
                  name="email"
                  component={TextField}
                  defaultValue={email}
                  autoFocus
                  label="Email"
                  variant="outlined"
                  disabled={!user}
                />
              )}
              {method === otpTypes.app && qrCodeBase64 && (
                <AuthenticatorStep
                  otpAuthUri={otpAuthUri}
                  qrCodeBase64={qrCodeBase64}
                  showFAQ={showFAQ}
                />
              )}
            </form>
          </SecurityDialog>
        );
      }}
    </Form>
  );
};

const MethodSelection = methodSelectionConnector(MethodSelectionBare);

interface EnableOtpAuthProps {
  open: boolean;
  onCancel: () => void;
  user?: any;
  email: string;
  setOtp: (otp: string) => void;
  refetch?: () => Promise<any>;
}

export const EnableOtpAuth: FC<EnableOtpAuthProps> = ({
  user,
  email,
  setOtp,
  refetch,
  open,
  onCancel,
}) => {
  const initialStep = 'select';
  const initialMethod = otpTypes.email;

  const [step, setStep] = useState(initialStep);
  const [method, setMethod] = useState<string>(initialMethod);
  const [address, setAddress] = useState('');

  // const handleExit = useCallback(() => {
  //   setStep(initialStep);
  //   setMethod(initialMethod);
  // }, [initialMethod]);

  const handleSelectionSuccess = useCallback((address: string) => {
    setStep('verification');
    setAddress(address);
  }, []);

  const handleVerificationSuccess = useCallback(() => setStep('success'), []);

  return (
    <>
      {step === 'select' && (
        <MethodSelection
          open={open}
          onCancel={onCancel}
          //onExited={handleExit}
          onSuccess={handleSelectionSuccess}
          handleVerificationSuccess={handleVerificationSuccess}
          method={method}
          email={email}
          user={user}
          setMethod={setMethod}
          setOtp={setOtp}
        />
      )}
      {step === 'verification' && (
        <VerificationStep
          open={open}
          onCancel={onCancel}
          address={address}
          method={method}
          user={user}
          //onExited={handleExit}
          onSuccess={handleVerificationSuccess}
          setOtp={setOtp}
          refetch={refetch}
        />
      )}
      {step === 'success' && <SuccessStep user={user} />}
    </>
  );
};

export default EnableOtpAuth;
