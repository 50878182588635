import { Viewer } from './viewer';

export function hsq(...args: (string | object)[]) {
  window._hsq = window._hsq || [];
  window._hsq.push(args);
}

export function initHubSpot(viewer: Viewer) {
  if (!viewer.id) return;
  hsq('identify', {
    id: viewer.id,
    email: viewer.email?.address || '',
    username: viewer.username,
  });
}

export function initIntercom(appId: string, viewer: Viewer) {
  window.Intercom = window.Intercom || function () {};
  const settings: Intercom_.IntercomSettings = {
    app_id: appId,
  };

  if (viewer.id) {
    Object.assign(settings, {
      user_id: viewer.id,
      user_hash: viewer.intercomHash,
    });
  }

  const group = viewer.groups && viewer.groups[0];
  if (group) {
    settings.company = {
      id: group.id,
      name: group.name,
    };
  }

  window.Intercom('boot', settings);
}
