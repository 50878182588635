import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import { approveGroupMember, denyGroupMember } from '@/actions/group';

import Button from '../Button/Button';
import MemberRequest from './MemberRequest';

const pageSize = 3;

function MemberRequests({
  memberRequests,
  approveGroupMember,
  denyGroupMember,
  onApprove,
  onDeny,
}: any) {
  const [pageNum, setPageNum] = useState(0);

  const first = pageNum * pageSize;
  const last = first + pageSize;
  const hasPrevious = first > 0;
  const hasNext = last < memberRequests.length;
  const page = memberRequests.slice(first, last);

  const handleApprove = useCallback(
    async (m: any) => {
      await approveGroupMember(m);
      if (onApprove) onApprove(m);
    },
    [approveGroupMember, onApprove]
  );

  const handleDeny = useCallback(
    async (m: any) => {
      await denyGroupMember(m);
      if (onDeny) onDeny(m);
    },
    [denyGroupMember, onDeny]
  );

  return (
    <div>
      {(hasPrevious || hasNext) && (
        <div style={{ textAlign: 'right' }}>
          <Button onClick={() => setPageNum(pageNum - 1)} size="tiny" disabled={!hasPrevious}>
            Previous
          </Button>
          <Button onClick={() => setPageNum(pageNum + 1)} size="tiny" disabled={!hasNext}>
            Next
          </Button>
        </div>
      )}
      {page.map((m: any) => (
        <MemberRequest
          key={m.id}
          user={m.user}
          membersSet="this group"
          onApprove={() => handleApprove(m)}
          onDeny={() => handleDeny(m)}
        />
      ))}
    </div>
  );
}

// @ts-expect-error TS(2630): Cannot assign to 'MemberRequests' because it is a ... Remove this comment to see the full error message
MemberRequests = connect(undefined, {
  approveGroupMember,
  denyGroupMember,
})(MemberRequests);

export default MemberRequests;
