import { Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { darkGreen, white } from '@/theme/colors';
import { highlight } from '@/utils';

import ChipList from '../ChipList';
import Link from '../Link';

const useStyles = makeStyles((theme) => ({
  // @ts-expect-error TS(2339): Property 'navigable' does not exist on type '{}'.
  root: ({ navigable, darker }) => ({
    cursor: navigable ? 'pointer' : '',
    whiteSpace: 'normal',
    height: 'auto',
    ...(darker
      ? {
          backgroundColor: darkGreen,
          color: white,
        }
      : {}),
  }),
  // @ts-expect-error TS(2339): Property 'breakLine' does not exist on type '{}'.
  label: ({ breakLine }) => ({
    padding: '9px 12px',
    // @ts-expect-error TS(2339): Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('md')]: {
      ...(breakLine
        ? {
            whiteSpace: 'normal',
          }
        : {}),
    },
  }),
  // @ts-expect-error TS(2339): Property 'darker' does not exist on type '{}'.
  deleteIcon: ({ darker }) => ({
    ...(darker
      ? {
          fill: white,
        }
      : {}),
  }),
}));

function KeywordChip({ keywordHtml, navigable, darker, breakLine, ...other }: any) {
  const s = useStyles({ navigable, breakLine, darker });

  const safeKeywordHtml = highlight(keywordHtml);
  const isHighlighted = keywordHtml.indexOf('<em>') !== -1;
  const keywordText = keywordHtml.replace('<em>', '').replace('</em>', '');

  const label = isHighlighted ? (
    // @ts-expect-error TS(2322): Type 'string | HTMLElement | DocumentFragment' is ... Remove this comment to see the full error message
    <span dangerouslySetInnerHTML={{ __html: safeKeywordHtml }} />
  ) : keywordText.length > 64 ? (
    `${keywordText.slice(0, 64)}…`
  ) : (
    keywordText
  );

  const chip = <Chip title={keywordText} label={label} classes={s} {...other} />;

  return navigable ? (
    <Link href={`/search?q=${encodeURIComponent(keywordText)}`}>{chip}</Link>
  ) : (
    chip
  );
}

function KeywordList({
  keywords = [],
  highlightedKeywords,
  children,
  icon = 'tag',
  style,
  navigable = true,
  chipProps,
  breakLine,
  ...other
}: any) {
  return (
    <ChipList {...other} icon={icon} style={{ overflow: 'hidden', ...style }}>
      {(highlightedKeywords || [])
        .map((k: any) => (
          <KeywordChip key={k} keywordHtml={k} navigable={navigable} breakLine={breakLine} darker />
        ))
        .concat(
          // @ts-expect-error TS(7006): Parameter 'k' implicitly has an 'any' type.
          (keywords || []).map((k) => (
            <KeywordChip
              key={k}
              keywordHtml={k}
              breakLine={breakLine}
              navigable={navigable}
              {...chipProps}
            />
          ))
        )}
    </ChipList>
  );
}

export default KeywordList;
