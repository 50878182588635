import moment from 'moment-timezone';
import { UnknownAction } from 'redux';

import { PROFILE__UPDATE } from '@/profile/store';
import { removeAttribute } from '@/utils/reducer';

import { AppThunk } from '.';

const USER__UPDATE_INFORMATION = 'USER__UPDATE_INFORMATION';
const USER__DELETE = 'USER__DELETE';

export function updateUserInfo(user: object): UnknownAction {
  return {
    type: USER__UPDATE_INFORMATION,
    user,
  };
}

export function updateUser(values: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const data = await graphql.mutate(
      `
      (
        $id: String!
        ${'agreements' in values ? '$agreements: [AgreementInput]' : ''}
        ${'signup_type' in values ? '$signup_type: SignupType' : ''}
        ${'available_self_service' in values ? '$available_self_service: Boolean' : ''}
        ${'background_check' in values ? '$background_check: Boolean' : ''}
      ) {
        updateUser(
          id: $id
          ${'agreements' in values ? 'agreements: $agreements' : ''}
          ${'signup_type' in values ? 'signup_type: $signup_type' : ''}
          ${
            'available_self_service' in values
              ? 'available_self_service: $available_self_service'
              : ''
          }
          ${'background_check' in values ? 'background_check: $background_check' : ''}
        ) {
          id
          agreements {
            policy
            updated_at
            accepted
          }
          signup_type
          available_self_service
          background_check
        }
      }
    `,
      values
    );

    if (data) {
      dispatch(updateUserInfo(data.updateUser));
      return data.updateUser;
    }
  };
}

export function updateExpertState(values: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const data = await graphql.mutate(
      `
      (
        $id: String!
        $expert_state: ExpertState!
      ) {
        updateExpertState(
          id: $id
          expert_state: $expert_state
        )
      }
    `,
      values
    );

    if (data.updateExpertState) {
      dispatch({
        type: USER__UPDATE_INFORMATION,
        user: values,
      });
    }
  };
}

export function deleteUser(id: any): AppThunk<Promise<any>> {
  return (dispatch, _getState, { graphql }) =>
    graphql
      .mutate(
        `($id: String!) {
      deleteUser(id: $id) {
        id
      }
    }`,
        { id }
      )
      .then(() =>
        dispatch({
          type: USER__DELETE,
          id,
        })
      );
}

export function fetchUser(
  id: any,
  {
    force = false,

    // user
    stats = false,
    canAutofillProfile = false,
    signupSubdomain = false,
    groups = false,
    profileKeywordsDefinition = false,
    recruiter = false,
    otpAuthEnabled = false,

    // profile
    audit = false,
    experiences = false,
    addresses = false,
    expertise = false,
    groupKeywords = false,
    education = false,
    sources = false,
    sharedInternalNetworks = false,
    internalNetworks = false,
    expertRequestCandidates = false,
    availableConsultation = false,
    connectionStates = ['active'],
  } = {}
): AppThunk<Promise<any>> {
  return async (dispatch, getState, { graphql }) => {
    const queryKey = JSON.stringify({
      id,
      stats,
      canAutofillProfile,
      groups,
      recruiter,
      audit,
      experiences,
      addresses,
      education,
      expertise,
      internalNetworks,
      sources,
      sharedInternalNetworks,
      expertRequestCandidates,
    });
    // use cache when possible - avoid unnecessary round trips
    const queryStatus = (getState().users.$$query_meta$$ || {})[queryKey];
    if (!force && queryStatus && queryStatus.result && moment().isBefore(queryStatus.expiresAt)) {
      const user = queryStatus.result;
      const creditRate = await dispatch(fetchUserCreditRate(user.id));
      if (creditRate) {
        user.profile.credit_rate = creditRate.cents;
      }

      dispatch({
        type: USER__UPDATE_INFORMATION,
        queryKey,
        user,
      });

      dispatch({
        type: PROFILE__UPDATE,
        queryKey,
        profile: user.profile,
      });

      return user;
    }

    if (!id) id = getState().viewer.username;

    const deprecatedFields = `
      first_name
      last_name
      name
      picture_url
      country_code
      country
      city
      timezone
      username
      phone
      html_url
      email {
        address
        accepted
        confirmed
      }
      headline
      summary
      languages {
        code
        fluency
      }
      bill_rate
    `;

    const params = { id };

    if (internalNetworks) {
      // @ts-expect-error TS(2339): Property 'connectionStates' does not exist on type... Remove this comment to see the full error message
      params.connectionStates = connectionStates;
    }

    const result = await graphql.query(
      `query getUser(
      $id: String
      ${internalNetworks ? '$connectionStates: [NetworkExpertConnectionState]' : ''}
    ) {
      user(id: $id) {
        id
        admin
        expert_state
        locked
        compliance_completed_at
        signup_type
        agreements {
          policy
          updated_at
          accepted
        }
        available_self_service
        background_check

        ${otpAuthEnabled ? 'otp_auth_enabled' : ''}

        ${deprecatedFields}

        profile {
          id
          first_name
          last_name
          name
          html_url
          url_endpoint
          linkedin_username
          linkedin_url
          title
          summary
          skype
          timezone
          city
          country
          picture_url
          cover_url
          questions
          cv_url
          available_long_term
          available_marketplace
          hide_profile
          bill_rate
          credit_rate
          languages { code, fluency }
          keywords
          tier
          additional_information

          ${
            expertise
              ? `
          sectors { id, name }
          regions { id, name, country_iso2_code }
          `
              : ''
          }

          ${
            groupKeywords
              ? `
          group_keywords {
            group { id, name }
            keywords { id, name }
          }`
              : ''
          }

          ${
            addresses
              ? `
          emails {
            address
            display
            primary
            verified
            accepted
            confirmed
          }
          phones {
            address
            display
            primary
            verified
            accepted
          }
          `
              : ''
          }

          ${availableConsultation ? 'can_request_consultation' : ''}

          ${
            experiences
              ? `
          experiences {
            id
            title
            start_date
            end_date
            description
            organization
            linkedin_url
            location
            current
            role
          }
          `
              : ''
          }

          ${
            education
              ? `
          education {
            id
            degree
            field_of_study
            school
            start_date
            end_date
            description
          }
          `
              : ''
          }

          ${
            internalNetworks
              ? `
          expert_internal_networks(
            connection_states: $connectionStates
          ) {
            id
            network {
              id
              name
              group {
                id
                city
                country
              }
            }
            add_method
            participation_level
            connection_state
            from_group { id name }
            unpaid
          }
          `
              : ''
          }

          ${
            expertRequestCandidates
              ? `
          expert_request_id
          expert_request_candidates {
            id
            request_id
            state
          }
          `
              : ''
          }

          ${
            sources
              ? `
          sources {
            agent_id
            agent_profile_id
            source_id
            source_profile_id
            created_by { name, html_url }
            created_at
          }
          `
              : ''
          }

          ${
            audit
              ? `
          created_at
          created_by { id, name, html_url }
          updated_at
          updated_by { id, name, html_url }
          `
              : ''
          }
        }

        ${canAutofillProfile ? 'can_autofill_profile' : ''}

        ${signupSubdomain ? 'signup_subdomain' : ''}

        ${recruiter ? 'recruiter { id, name, html_url }' : ''}

        ${
          stats
            ? `
        rating
        expert_stats {
          consultation_count
          candidate_summary {
            vetting_count
            verified_count
            matched_count
          }
        }
        client_stats {
          expert_request_count
          consultation_count
          avg_expert_rating
        }
        `
            : ''
        }

        ${
          groups
            ? `
        groups {
          id
          name
          html_url
          branding_logo_url
          branding_show_poweredbyof
          account_type
          default_anonymous_messaging
          ${sharedInternalNetworks ? 'shared_internal_networks { id, name }' : ''}
          ${profileKeywordsDefinition ? 'profile_keywords_definition { id, name }' : ''}
        }`
            : ''
        }
      }
    }`,
      params
    );

    const { user } = result;

    if (user) {
      const creditRate = await dispatch(fetchUserCreditRate(user.id));
      if (creditRate) {
        user.profile.credit_rate = creditRate.cents;
      }

      dispatch({
        type: USER__UPDATE_INFORMATION,
        queryKey,
        user,
      });

      dispatch({
        type: PROFILE__UPDATE,
        queryKey,
        profile: user.profile,
      });
    }

    return user;
  };
}

export function submitClientInterest(data: any): AppThunk<Promise<any>> {
  return (_dispatch, _getState, { graphql }) =>
    graphql.mutate(
      `(
    $name: String
    $title: String
    $organization: String
    $email: String
    $phone: String
    $use_case: String
    $questions: String
    $source: ClientSource!
  ) {
    submitClientInterest(
      name: $name
      title: $title
      organization: $organization
      email: $email
      phone: $phone
      use_case: $use_case
      questions: $questions
      source: $source
    )
  }`,
      data
    );
}

/**
 * Fetched the credit rate for a given user taking in context the current user's billing account
 * @param {number} userId
 * @returns {async function(dispatch, getState, {graphql: *}): Promise<{cents: number, currency: string}>}
 */
export function fetchUserCreditRate(userId: any): AppThunk<Promise<any>> {
  return async (_: any, getState: any, { graphql }: any) => {
    const { userContext, userContextOptions } = getState().ui;

    const billingAccountId =
      userContextOptions?.find((o: any) => o.value === userContext)?.billingAccountId || '';

    const data = await graphql.query(
      `
      query ($id: String!, $billingAccountId: String) {
        expert(id: $id) {
          creditRate(billing_account_id: $billingAccountId) {
            cents
            currency
          }
        }
      }
      `,
      { id: userId, billingAccountId }
    );

    const { expert } = data;
    if (!expert) return;

    return expert.creditRate;
  };
}

const INITIAL_STATE = {
  $$query_meta$$: {},
};

function reduceUser(state = {}, action: any) {
  switch (action.type) {
    case USER__UPDATE_INFORMATION:
      return {
        ...state,
        ...action.user,
      };
    default:
      return state;
  }
}

export default function (state = INITIAL_STATE, action: any) {
  const userId =
    action.type === USER__UPDATE_INFORMATION
      ? // @ts-expect-error TS(2339): Property 'currentId' does not exist on type '{ $$q... Remove this comment to see the full error message
        action.user.id || state.currentId
      : // @ts-expect-error TS(2339): Property 'currentId' does not exist on type '{ $$q... Remove this comment to see the full error message
        action.userId || state.currentId;

  if (!userId) return state;

  if (action.type === USER__DELETE) {
    return removeAttribute(state, action.id);
  }

  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const newUserState = reduceUser(state[userId], action);
  const newState = {
    ...state,
    [userId]: newUserState,
  };

  if (action.user && action.user.username) {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    newState[action.user.username] = newUserState;
  }

  if (action.type === USER__UPDATE_INFORMATION && action.queryKey) {
    newState.$$query_meta$$ = {
      ...newState.$$query_meta$$,
      [action.queryKey]: {
        expiresAt: moment().add(1, 'minute').toISOString(),
        result: newUserState,
      },
    };
  }

  return newState;
}
