import { FC } from 'react';

import Button from '@/components/Button/Button';
import LayoutPage from '@/components/Layout/LayoutPage';
import Link from '@/components/Link';
import WizardDone from '@/components/WizardDone';
import { Viewer } from '@/core/viewer';

import s from './ExpertRequestAddRequestSubmitted.module.scss';

interface ExpertRequestAddRequestSubmittedProps {
  viewer: Viewer;
}

const ExpertRequestAddRequestSubmitted: FC<ExpertRequestAddRequestSubmittedProps> = ({
  viewer,
}) => (
  <LayoutPage hideSearch verticalCenter contentClassName={s.submittedLayoutContent}>
    <WizardDone title="Thank you!" text="Our research team will be notified about your interest.">
      <div className={s.actions}>
        {viewer.expert_state === 'active' || viewer.expert_state === 'applied' ? (
          <Link to="/dashboard">
            <Button size="large">Go to Dashboard →</Button>
          </Link>
        ) : (
          <Link to="/signup/expert">
            <Button size="large">Continue to Application →</Button>
          </Link>
        )}
      </div>
    </WizardDone>
  </LayoutPage>
);

export default ExpertRequestAddRequestSubmitted;
