import React, { Component } from 'react';

import Link from '../Link/Link';
import Picture from '../Picture/Picture';
import PictureList from '../PictureList/PictureList';

interface Member {
  id: string;
  user: string;
}

interface MemberListProps {
  members: Member[];
  membersDisplayLimit?: number;
  total?: number;
  htmlUrl?: string;
}

class MemberList extends Component<MemberListProps> {
  state = {};

  render() {
    const { members, membersDisplayLimit = 0, total = 0, htmlUrl, ...other } = this.props;

    let moreComponent;
    let membersLimit = members;
    const more = total - membersDisplayLimit;

    if (more > 1) {
      membersLimit = membersLimit.slice(0, membersDisplayLimit);
      moreComponent = (
        <Link key="more" id="more" to={htmlUrl}>
          <Picture style={{ fontSize: 16 }} size={40}>
            +{more}
          </Picture>
        </Link>
      );
    }

    return (
      <PictureList {...other}>
        {membersLimit
          .map((m: any) => (
            <Picture
              user={m.user}
              key={m.id}
              id={m.id}
              size={40}
              ring={m.role === 'manager' || m.role === 'associate'}
            />
          ))
          .concat(moreComponent ? [moreComponent] : [])}
      </PictureList>
    );
  }
}

export default MemberList;
