import stringify from 'json-stringify-deterministic';
import { UnknownAction } from 'redux';

import { AppThunk } from '@/store';

export const SEARCH = 'SEARCH';
export const SEARCH_SELECT_PROFILE = 'SEARCH_SELECT_PROFILE';
export const SEARCH_CLEAR_PROFILE_SELECTION = 'SEARCH_CLEAR_PROFILE_SELECTION';

export class Query {
  text?: string;
  sort?: string;
  ascending?: boolean;
  highlight?: boolean;
  mustHaveEmail?: boolean;
  profileType?: string;
  expertStates?: string[];
  expertHistory?: string[];
  languages?: string[];
  countries?: string[];
  industries?: string[];
  agentIds?: string[];
  networkIds?: string[];
  sharingGroupIds?: string[];
  organizationsCurrentRestriction?: string;
  organizations?: string[];
  locationsCurrentRestriction?: string;
  locations?: string[];
  jobTitlesCurrentRestriction?: string;
  jobTitles?: string[];
  consultationsWith?: string[];
  educationDegrees?: string[];
  educationFos?: string[];
  groupKeywordIds?: string[];
  keywords?: string[];
  marketplacePreference?: string;

  constructor(options: Partial<Query> = {}) {
    Object.assign(this, {
      text: (options.text || '').trim(),
      sort: options.sort || '',
      ascending: options.sort ? options.ascending || false : undefined,
      highlight: options.highlight === undefined ? true : options.highlight,
      mustHaveEmail: options.mustHaveEmail === undefined ? true : options.mustHaveEmail,
      profileType: options.profileType || '',
      expertStates: options.expertStates || [],
      expertHistory: options.expertHistory || [],
      languages: options.languages || [],
      countries: options.countries || [],
      industries: options.industries || [],
      agentIds: options.agentIds || [],
      networkIds: options.networkIds || [],
      sharingGroupIds: options.sharingGroupIds || [],
      organizationsCurrentRestriction: options.organizationsCurrentRestriction || 'none',
      organizations: options.organizations || [],
      locationsCurrentRestriction: options.locationsCurrentRestriction || 'none',
      locations: options.locations || [],
      jobTitlesCurrentRestriction: options.jobTitlesCurrentRestriction || 'none',
      jobTitles: options.jobTitles || [],
      consultationsWith: options.consultationsWith || [],
      educationDegrees: options.educationDegrees || [],
      educationFos: options.educationFos || [],
      groupKeywordIds: options.groupKeywordIds || [],
      keywords: options.keywords || [],
      marketplacePreference: options.marketplacePreference || 'none',
    });
  }

  serialize(): Partial<Query> {
    return {
      text: this.text,
      sort: this.sort,
      ascending: this.ascending,
      highlight: this.highlight,
      mustHaveEmail: this.mustHaveEmail,
      profileType: this.profileType,
      expertStates: this.expertStates,
      expertHistory: this.expertHistory,
      languages: this.languages,
      countries: this.countries,
      industries: this.industries,
      agentIds: this.agentIds,
      networkIds: this.networkIds,
      sharingGroupIds: this.sharingGroupIds,
      organizationsCurrentRestriction: this.organizationsCurrentRestriction,
      organizations: this.organizations,
      locationsCurrentRestriction: this.locationsCurrentRestriction,
      locations: this.locations,
      jobTitlesCurrentRestriction: this.jobTitlesCurrentRestriction,
      jobTitles: this.jobTitles,
      consultationsWith: this.consultationsWith,
      educationDegrees: this.educationDegrees,
      educationFos: this.educationFos,
      groupKeywordIds: this.groupKeywordIds,
      keywords: this.keywords,
      marketplacePreference: this.marketplacePreference,
    };
  }

  hash(): string {
    return stringify(this);
  }
}

export function search(query: Query, cursor: any): AppThunk<Promise<any>> {
  return async (dispatch, getState, { graphql }) => {
    // Prevent refetching results
    // @ts-ignore
    const lastPage = getState().search.queries[query.hash()];
    if (!cursor && lastPage.pageInfo.total !== undefined) {
      dispatch(
        setSearchResults({
          query,
          fetching: false,
        })
      );
      return lastPage;
    }

    dispatch(
      setSearchResults({
        query,
        fetching: true,
      })
    );

    try {
      const result = await graphql.query(
        `query search(
        $cursor: String
        $text: String
        $sort: String
        $ascending: Boolean
        $highlight: Boolean
        $countries: [String!]
        $languages: [String!]
        $industries: [String!]
        $mustHaveEmail: Boolean
        $profileTypes: [ProfileType]
        $expertStates: [ExpertState]
        $expertHistory: [ExpertHistory]
        $agentIds: [String!]
        $networkIds: [String!]
        $sharingGroupIds: [String!]
        $experienceOrganizations: ExperienceFieldInput
        $experienceLocations: ExperienceFieldInput
        $experienceJobTitles: ExperienceFieldInput
        $consultationsWith: ConsultationsWithInput
        $educationDegrees: [String!]
        $educationFos: [String!]
        $groupKeywordIds: [String!]
        $keywords: [String!]
        $marketplacePreference: MarketplacePreference
      ) {
        search(
          after: $cursor
          query: $text
          sort: $sort
          ascending: $ascending
          highlight: $highlight
          countries: $countries
          languages: $languages
          industries: $industries
          must_have_email: $mustHaveEmail
          profile_types: $profileTypes
          expert_states: $expertStates
          expert_history: $expertHistory
          agent_ids: $agentIds
          network_ids: $networkIds
          sharing_group_ids: $sharingGroupIds
          experience_organizations: $experienceOrganizations
          experience_locations: $experienceLocations
          experience_job_titles: $experienceJobTitles
          consultations_with: $consultationsWith
          education_degrees: $educationDegrees
          education_fos: $educationFos
          group_keyword_ids: $groupKeywordIds
          keywords: $keywords
          marketplace_preference: $marketplacePreference
        ) {
          edges {
            cursor
            node {
              first_name
              last_name
              name
              title
              city
              country
              languages
              keywords
              group_keywords
              sectors
              regions
              summary_snippets
              cv_snippets
              additional_information_snippets
              experiences {
                title
                organization
                location
                role
                start_date
                end_date
                description_snippets
                current
              }
              education {
                degree
                field_of_study
                school
                start_date
                end_date
                description_snippets
              }
              candidates {
                id
                state
                request {
                  name
                  html_url
                }
                project {
                  id
                  name
                  html_url
                  group { name }
                }
                request_name_highlight
                answerable_questions {
                  query
                  query_snippets
                  response
                  response_snippets
                  response_type
                }
                qualification_responses {
                  query
                  query_snippets
                  response
                  response_snippets
                  response_type
                }
                notes {
                  id
                  author {
                    id
                    name
                    first_name
                    last_name
                    picture_url
                    html_url
                  }
                  author_role
                  text_snippets
                  is_good_match
                }
              }
              network_experts {
                id
                network {
                  id
                  group { name }
                }
                created_by {
                  id
                  name
                  first_name
                  last_name
                  picture_url
                  html_url
                }
                add_note_snippets
              }
              profile {
                id
                name
                user {
                  id
                }
                sources(only_last: true, has_custom_candidate_invitation_message: true) {
                  agent {
                    name
                    custom_candidate_invitation_message
                  }
                }
                html_url
                picture_url
                first_name
                last_name
                name
                credit_rate
                languages {
                  code
                  fluency
                }
                expert_internal_networks(connection_states: [active]) {
                  id
                  add_method
                  participation_level
                  from_group { id name }
                  network { id name }
                }
                available_marketplace
                url_endpoint
              }
            }
          }
          pageInfo {
            total
            hasNextPage
          }
        }
      }`,
        {
          cursor,
          text: query.text,
          sort: query.sort,
          ascending: query.ascending,
          highlight: query.highlight,
          countries: query.countries,
          languages: query.languages,
          industries: query.industries,
          mustHaveEmail: query.mustHaveEmail,
          profileTypes: query.profileType ? [query.profileType] : [],
          expertStates: query.expertStates,
          expertHistory: query.expertHistory,
          agentIds: query.agentIds,
          networkIds: query.networkIds,
          sharingGroupIds: query.sharingGroupIds,
          experienceOrganizations: {
            current_restriction: query.organizationsCurrentRestriction,
            accepted_values: query.organizations,
          },
          experienceLocations: {
            current_restriction: query.locationsCurrentRestriction,
            accepted_values: query.locations,
          },
          experienceJobTitles: {
            current_restriction: query.jobTitlesCurrentRestriction,
            accepted_values: query.jobTitles,
          },
          consultationsWith: {
            me: (query.consultationsWith || []).includes('me'),
            group_ids: (query.consultationsWith || []).filter((x: any) => x !== 'me'),
          },
          educationDegrees: query.educationDegrees,
          educationFos: query.educationFos,
          groupKeywordIds: query.groupKeywordIds,
          keywords: query.keywords,
          marketplacePreference: query.marketplacePreference,
        }
      );

      const page = result.search;

      // temp hack
      if (typeof cursor === 'undefined') {
        const { pageInfo } = page;
        pageInfo.total = pageInfo.total || Number(page.edges.length > 0 || pageInfo.hasNextPage);
      }

      dispatch(
        setSearchResults({
          query,
          fetching: false,
          ...page,
        })
      );

      return page;
    } catch (err) {
      dispatch(
        setSearchResults({
          query,
          fetching: false,
          // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
          error: err.message,
        })
      );
    }
  };
}

export function setSearchResults({ query, fetching, error, ...page }: any): UnknownAction {
  return {
    type: SEARCH,
    query: query.serialize(),
    hash: query.hash(),
    fetching,
    error,
    ...page,
  };
}

export function selectProfile(profile: any, selected: any): UnknownAction {
  return {
    type: SEARCH_SELECT_PROFILE,
    profile,
    selected,
  };
}

export function clearProfileSelection(): UnknownAction {
  return {
    type: SEARCH_CLEAR_PROFILE_SELECTION,
  };
}
