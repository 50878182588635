const ERROR_CODES = {
  UNKNOWN: 'unknown.unknown',
  AUTH_INVALID_CREDENTIALS: 'auth.invalid_credentials',
  AUTH_MFA_SETUP_REQUIRED: 'auth.mfa_setup_required',
  AUTH_MFA_REQUIRED: 'auth.mfa_required',
  AUTH_LOCKED_FOR_INTERVAL: 'auth.locked_for_interval',
  AUTH_OTP_INVALID: 'auth.otp_invalid',
  CONFERENCE_HOST_CONFERENCE_NOT_FOUND: 'conference.host_conference_not_found',
  CONFERENCE_NOT_FOUND: 'conference.not_found',
} as const;

export type ErrorCode = (typeof ERROR_CODES)[keyof typeof ERROR_CODES];

export default ERROR_CODES;
