import { FC } from 'react';

import Button from '@/components/Button/Button';
import Link from '@/components/Link';
import { formatDuration } from '@/core/consultation';
import { getTimezoneDescription } from '@/core/time';
import { darkGreen } from '@/theme/colors';

import TimeButton from '../../TimeButton';
import s from './ProposedTimes.module.scss';

interface ProposedTimes {
  viewer: any;
  selectedDate: Date;
  userFirstName: string;
  isWaitingViewerConfirmation: boolean;
  isWaitingOtherPartConfirmation: boolean;
  isCanceled: boolean;
  isDenied: boolean;
  isExpired: boolean;
  canConfirm: boolean;
  canSuggestTime: boolean;
  proposedTimes: Date[];
  rejectedTimes: Date[];
  expectedDuration: number;
  onOpenSuggestTime: () => void;
  onDateSelect: (date: Date) => void;
}

const ProposedTimes: FC<ProposedTimes> = ({
  viewer,
  selectedDate,
  userFirstName,
  isWaitingViewerConfirmation,
  isWaitingOtherPartConfirmation,
  isCanceled,
  isDenied,
  isExpired,
  canConfirm,
  canSuggestTime,
  proposedTimes,
  rejectedTimes,
  expectedDuration,
  onOpenSuggestTime,
  onDateSelect,
}) => {
  const expectedDurationString = formatDuration(expectedDuration);

  return (
    <div className={s.suggestedTimes}>
      <div className={s.suggestedTimesTitle}>
        {(isWaitingViewerConfirmation &&
          rejectedTimes.length > 0 &&
          `${userFirstName} suggested an alternate time for a ${expectedDurationString} call:`) ||
          (isWaitingViewerConfirmation &&
            rejectedTimes.length === 0 &&
            `Let ${userFirstName} know if any of these times will work:`) ||
          (isWaitingOtherPartConfirmation &&
            `Waiting to hear back from ${userFirstName} if ${
              proposedTimes.length === 1 ? 'this time' : 'any of these times'
            } will work...`) ||
          ((isCanceled || isDenied || isExpired) && 'Suggested call times')}
      </div>

      {proposedTimes
        // @ts-expect-error TS(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
        .sort((a: any, b: any) => new Date(a) - new Date(b))
        .map((date: any) => (
          <TimeButton
            disabled={!canConfirm}
            key={date}
            date={date}
            timezone={viewer.timezone}
            expectedDuration={expectedDuration}
            selected={selectedDate === date}
            onClick={() => onDateSelect(date)}
          />
        ))}

      {canSuggestTime && (
        <Button
          square
          color="white"
          fontColor={darkGreen}
          style={{
            textTransform: 'initial',
            fontWeight: 500,
            padding: '0 10px',
            marginBottom: 20,
          }}
          onClick={onOpenSuggestTime}
        >
          {isWaitingViewerConfirmation ? 'Suggest Another' : 'Change Times'}
        </Button>
      )}

      <div className={s.changeTimezone}>
        <Link className={s.changeTimezoneLink} to="/settings/communication">
          Change My Timezone {getTimezoneDescription(viewer.timezone)}
        </Link>
      </div>
    </div>
  );
};

export default ProposedTimes;
