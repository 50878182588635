import moment from 'moment-timezone';
import { Component } from 'react';
import { connect } from 'react-redux';

import { getUser, getUserName } from '@/core/consultation';
import { isCallType } from '@/expertrequest/store';
import { RootState } from '@/store';
import { darkBlue, white } from '@/theme/colors';

import Alert from '../Alert';
import Button from '../Button';
import MaterialIcon from '../Icon/MaterialIcon';
import Link from '../Link';
import Picture from '../Picture';
import s from './ConsultationsStarting.module.scss';

class ConsultationsStarting extends Component {
  state = {
    consultationDialogClosed: {},
  };

  closeConsultationAlert = (id: any) => {
    this.setState((prevState) => ({
      consultationDialogClosed: {
        // @ts-expect-error TS(2339): Property 'consultationDialogClosed' does not exist... Remove this comment to see the full error message
        ...prevState.consultationDialogClosed,
        [id]: true,
      },
    }));
  };

  render() {
    // @ts-expect-error TS(2339): Property 'consultations' does not exist on type 'R... Remove this comment to see the full error message
    const { consultations, userContext, viewer, call } = this.props;
    const { consultationDialogClosed } = this.state;

    const filteredConsultations = consultations.filter(
      (c: any) =>
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        !consultationDialogClosed[c.id] &&
        c.id !== call.consultationId &&
        (!c.expert_request || isCallType(c.expert_request.er_type))
    );

    if (consultations.length === 0) return null;

    return (
      <div>
        {filteredConsultations.map((c: any) => {
          const diff = moment(c.starts_at).diff(moment());
          const minutes = moment.duration(diff).minutes();

          const user = getUser(viewer, userContext, c, false);
          const userName = getUserName(viewer, userContext, c, false);

          return (
            <Alert
              key={c.id}
              backgroundColor={darkBlue}
              onRequestClose={() => this.closeConsultationAlert(c.id)}
            >
              <div className={s.consultationStarting}>
                <div>
                  <Picture user={user} size={80} />
                </div>

                <div className={s.consultationStartingContent}>
                  <div className={s.consultationStartingText}>
                    {minutes <= 0
                      ? 'In progress'
                      : `Starting in ${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`}
                    : &nbsp;
                    <span className={s.consultationStartingUser}>Call with {userName}</span>
                  </div>

                  <Link to={`${c.html_url}?join_call=true`}>
                    <Button
                      startIcon={<MaterialIcon color={white} icon="phone" size={20} />}
                      size="medium"
                    >
                      Join Call
                    </Button>
                  </Link>
                </div>
              </div>
            </Alert>
          );
        })}
      </div>
    );
  }
}

export default connect((state: RootState) => ({
  viewer: state.viewer,
  call: state.call,
  userContext: state.ui.userContext,
  consultations: ((state.consultations.starting || {}).edges || []).map((c: any) => c.node),
}))(ConsultationsStarting);
