import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { ComponentType, memo } from 'react';
import { Navigate } from 'react-router-dom';

import { userSelector } from '@/auth/store/userSlice';
import { NavBarSkeleton } from '@/componentsv2/NavBar/Root';
import { useAppSelector } from '@/store';

interface ProtectedRouteProps {
  component: ComponentType;
  disabled?: boolean;
  redirectTo?: string;
}

export function Loading() {
  const theme = useTheme();

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      sx={{
        minHeight: '100vh',
        background: 'green',
      }}
    >
      <Grid item>
        <NavBarSkeleton />
      </Grid>
      <Grid
        container
        flexGrow={1}
        justifyContent="center"
        alignItems="center"
        sx={{
          background: theme.palette.grey[100],
          padding: theme.spacing(2),
        }}
      >
        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CircularProgress
            size={100}
            thickness={3}
            sx={{
              color: 'orange',
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

const ProtectedRoute = ({ component, disabled, redirectTo }: ProtectedRouteProps) => {
  //const { isLoading, isAuthenticated } = useAuth0();
  const isLoading = false;
  const isAuthenticated = false;
  const { data: userData } = useAppSelector(userSelector);

  if (disabled && redirectTo) {
    return <Navigate to={redirectTo} replace />;
  }

  if (isLoading || !isAuthenticated || !userData?.id) {
    return <Loading />;
  }

  // const Component = withAuthenticationRequired(
  //   isLoading || !isAuthenticated || !userData?.id ? Loading : component,
  //   {
  //     onRedirecting: () => <Loading />,
  //   }
  // );

  const Component = component;
  return <Component />;
};

export default memo(ProtectedRoute);
