import { gql } from '@apollo/client';

import { AUTH__UPDATE, BASIC_AUTH__UPDATE } from '@/auth/store/reducer';
import { basicAuth } from '@/core/api';
import { AppThunk } from '@/store';
import { updateUserInfo } from '@/store/user';

export const otpTypes = Object.freeze({
  sms: 'sms',
  app: 'app',
  email: 'email',
});

export function setBasicAuth(userId: any, password: any): AppThunk<Promise<any>> {
  return async (dispatch) => {
    dispatch({
      type: BASIC_AUTH__UPDATE,
      basicAuthentication: basicAuth(userId, password),
    });
  };
}

const CREATE_OTP_AUTH_CONFIG = gql(/* GraphQL */ `
  mutation authStoreCreateOtpAuthConfiguration($method: String!, $address: String) {
    createOtpAuthConfiguration(method: $method, address: $address)
  }
`);

export function createOtpAuthConfiguration(
  method: string,
  address: string
): AppThunk<Promise<string>> {
  return async (dispatch, getState, { graphql }) => {
    // @ts-ignore
    const { basicAuthentication } = getState().auth;
    const client = basicAuthentication
      ? graphql.fromAuth(basicAuthentication).client
      : graphql.client;
    const { data } = await client.mutate({
      mutation: CREATE_OTP_AUTH_CONFIG,
      variables: { method, address },
    });
    const { createOtpAuthConfiguration: otpAuthUri } = data;
    dispatch({ type: AUTH__UPDATE, otpAuthUri });
    return otpAuthUri;
  };
}

export function removeOtpAuth(userId: any, password: any): AppThunk<Promise<any>> {
  return async (_dispatch, _getState, { graphql }) => {
    const { removeOtpAuth } = await graphql.mutate(
      `(
      $userId: String, $password: String!
    ) {
      removeOtpAuth(user_id: $userId, password: $password)
    }`,
      { userId, password }
    );
    return removeOtpAuth;
  };
}

export function disableOtpAuth(userId: any): AppThunk<Promise<any>> {
  return async (_dispatch, _getState, { graphql }) => {
    const { disableOtpAuth } = await graphql.mutate(
      `(
      $userId: String
    ) {
      disableOtpAuth(user_id: $userId)
    }`,
      { userId }
    );
    return disableOtpAuth;
  };
}

export function authenticateOtp(otp: any): AppThunk<Promise<any>> {
  return async (_dispatch, getState, { graphql }) => {
    // @ts-ignore
    const { basicAuthentication } = getState().auth;
    const client = basicAuthentication ? graphql.fromAuth(basicAuthentication) : graphql;
    const { authenticateOtp } = await client.mutate(
      `($otp: String!) {
      authenticateOtp(otp: $otp)
    }`,
      { otp }
    );
    return authenticateOtp;
  };
}

export function otpQrCodeBase64(): AppThunk<Promise<string>> {
  return async (_dispatch, getState, { graphql }) => {
    // @ts-ignore
    const { basicAuthentication } = getState().auth;
    const client = basicAuthentication ? graphql.fromAuth(basicAuthentication) : graphql;
    const { otpQrCodeBase64: qrCodeBase64 } = await client.query(`query {
      otpQrCodeBase64
    }`);
    return qrCodeBase64;
  };
}

export function setPassword(userId: any, currentPass: any, newPass: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const { setPassword: user } = await graphql.mutate(
      `(
      $userId: String
      $currentPass: String
      $newPass: String!
    ) {
      setPassword(
        user_id: $userId
        current_password: $currentPass
        new_password: $newPass
      ) {
        id
        has_password
        password_expiry {
          expiry
          expired
          expiring
        }
      }
    }`,
      { userId, currentPass, newPass }
    );

    if (user) {
      dispatch(updateUserInfo(user));

      return setPassword;
    }
  };
}

export function resetPassword(token: any, newPass: any): AppThunk<Promise<any>> {
  return (_dispatch, _getState, { graphql }) => {
    return graphql.mutate(
      `(
      $token: String!
      $newPass: String!
    ) {
      resetPassword(
        token: $token
        new_password: $newPass
      )
    }`,
      { token, newPass }
    );
  };
}

export function unlockAccount(userId: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const { unlockAccount } = await graphql.mutate(
      `
    ($user_id: String!) {
      unlockAccount(user_id: $user_id)
    }
  `,
      { user_id: userId }
    );

    dispatch(
      updateUserInfo({
        id: userId,
        locked: !unlockAccount,
      })
    );

    return unlockAccount;
  };
}

export function requestPasswordReset(email: any): AppThunk<Promise<any>> {
  return (_dispatch, _getState, { graphql }) =>
    graphql.mutate(
      `(
      $email: String!
    ) {
      requestPasswordReset(email: $email)
    }`,
      { email }
    );
}

export function getOtpConfig(email: any, password: any): AppThunk<Promise<any>> {
  return async (_dispatch, _getState, { graphql }) => {
    const client = graphql.fromAuth(basicAuth(email, password));
    const {
      otpConfiguration: { method, address, maskedAddress },
    } = await client.query(`query {
      otpConfiguration {
        method
        address
        maskedAddress
      }
    }`);
    return { method, maskedAddress, address };
  };
}
