import PropTypes from 'prop-types';
import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';

import Button from '@/components/Button/Button';
import ConsultationList from '@/components/ConsultationList';
import EditExternalConsultation from '@/components/EditExternalConsultation';
import EmptyMessage from '@/components/EmptyMessage';
import FAIcon from '@/components/Icon/FAIcon';
import MaterialIcon from '@/components/Icon/MaterialIcon';
import { fetchAwaiting, fetchCanceled, fetchCompleted, fetchConfirmed } from '@/consultation/store';
import { fetchExpertRequests } from '@/expertrequest/store';
import { darkGreen } from '@/theme/colors';

import s from './Consultations.module.scss';

function ConsultationPromo() {
  return (
    <EmptyMessage
      style={{ marginTop: 10 }}
      icon={<FAIcon icon="frown-o" className={s.sadIcon} />}
      title="You don't have any upcoming calls."
      body=""
    />
  );
}

class Consultations extends Component {
  static propTypes = {
    viewer: PropTypes.object,
    consultations: PropTypes.array,
  };

  state = {
    externalConsultationOpen: false,
  };

  handleConsultations = (fn: any, collection: any) => {
    const { pageInfo, edges } = collection;
    if (!pageInfo.hasNextPage) return;
    if (!edges.length) throw new Error('last consultation edge not found');
    const { cursor } = edges[edges.length - 1];
    fn(cursor, 10, true);
  };

  handleAwaiting = (e: any) => {
    e.preventDefault();
    // @ts-expect-error TS(2339) FIXME: Property 'fetchAwaiting' does not exist on type 'R... Remove this comment to see the full error message
    const { fetchAwaiting, awaiting } = this.props;
    this.handleConsultations(fetchAwaiting, awaiting);
  };

  handleConfirmed = (e: any) => {
    e.preventDefault();
    // @ts-expect-error TS(2339) FIXME: Property 'fetchConfirmed' does not exist on type '... Remove this comment to see the full error message
    const { fetchConfirmed, confirmed } = this.props;
    this.handleConsultations(fetchConfirmed, confirmed);
  };

  handleCanceled = (e: any) => {
    e.preventDefault();
    // @ts-expect-error TS(2339) FIXME: Property 'fetchCanceled' does not exist on type 'R... Remove this comment to see the full error message
    const { fetchCanceled, canceled } = this.props;
    this.handleConsultations(fetchCanceled, canceled);
  };

  handleCompleted = (e: any) => {
    e.preventDefault();
    // @ts-expect-error TS(2339) FIXME: Property 'fetchCompleted' does not exist on type '... Remove this comment to see the full error message
    const { fetchCompleted, completed } = this.props;
    this.handleConsultations(fetchCompleted, completed);
  };

  handleExternalConsultationDialog = () => {
    this.props
      // @ts-expect-error TS(2339) FIXME: Property 'fetchExpertRequests' does not exist on t... Remove this comment to see the full error message
      .fetchExpertRequests({ state: 'open' })
      .then(() => this.setState({ externalConsultationOpen: true }));
  };

  closeExternalConsultation = () => {
    this.setState({ externalConsultationOpen: false });
  };

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'awaiting' does not exist on type 'Readon... Remove this comment to see the full error message
    const { awaiting, confirmed, canceled, completed, viewer } = this.props;

    const awaitingConsultations = awaiting.edges || [];
    const confirmedConsultations = confirmed.edges || [];
    const canceledConsultations = canceled.edges || [];
    const completedConsultations = completed.edges || [];

    const hasConsultations =
      awaitingConsultations.length > 0 ||
      confirmedConsultations.length > 0 ||
      canceledConsultations.length > 0 ||
      completedConsultations.length > 0;

    const loading = awaiting.loading || confirmed.loading || canceled.loading || completed.loading;

    const belongsToEnterpriseGroup =
      viewer.groups?.filter((g: any) => g.account_type === 'enterprise')?.length > 0;

    return (
      <div>
        {belongsToEnterpriseGroup && (
          <div className={s.externalConsultationButton}>
            <Button
              variant="text"
              fontColor={darkGreen}
              startIcon={<MaterialIcon icon="file_upload" />}
              onClick={this.handleExternalConsultationDialog}
            >
              Add External Consultation
            </Button>
          </div>
        )}

        {!hasConsultations && loading === false && <ConsultationPromo />}

        <ConsultationList
          title="Awaiting"
          consultations={awaitingConsultations.map((c: any) => c.node)}
          loading={awaiting.loading}
          pageInfo={awaiting.pageInfo}
          handleMore={this.handleAwaiting}
        />

        <ConsultationList
          title="Upcoming"
          consultations={confirmedConsultations.map((c: any) => c.node)}
          loading={confirmed.loading}
          pageInfo={confirmed.pageInfo}
          handleMore={this.handleConfirmed}
        />

        <ConsultationList
          grid
          title="Completed"
          consultations={completedConsultations.map((c: any) => c.node)}
          loading={completed.loading}
          pageInfo={completed.pageInfo}
          handleMore={this.handleCompleted}
        />

        <ConsultationList
          grid
          title="Expired/Canceled"
          consultations={canceledConsultations.map((c: any) => c.node)}
          loading={canceled.loading}
          pageInfo={canceled.pageInfo}
          handleMore={this.handleCanceled}
        />

        <EditExternalConsultation
          // @ts-expect-error
          open={this.state.externalConsultationOpen}
          onClose={this.closeExternalConsultation}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    viewer: state.viewer,
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    awaiting: state.consultations.awaiting || {},
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    confirmed: state.consultations.confirmed || {},
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    canceled: state.consultations.canceled || {},
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    completed: state.consultations.completed || {},
  }),
  {
    fetchAwaiting,
    fetchCanceled,
    fetchConfirmed,
    fetchCompleted,
    fetchExpertRequests,
  }
  // @ts-expect-error TS(2345) FIXME: Argument of type 'typeof Consultations' is not ass... Remove this comment to see the full error message
)(Consultations);
