import { Link } from 'react-router-dom';
import { useMediaQuery } from 'usehooks-ts';

import { ACCESS_ICON, LOGO, PRODUCT_NAME } from '@/config';
import APP_ROUTES from '@/routes/APP_ROUTES';
import { mq } from '@/theme/screens';

export const LogoImg = () => {
  const isMobile = useMediaQuery(mq.smd);

  return (
    <Link data-testid="of-logo-img" className="shrink-0" to={APP_ROUTES.dashboard}>
      <img
        src={isMobile ? ACCESS_ICON : LOGO}
        alt={PRODUCT_NAME}
        width={isMobile ? 34 : 142}
        height="auto"
      />
    </Link>
  );
};

export default LogoImg;
