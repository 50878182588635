import { darken } from '@mui/material/styles';
import moment from 'moment';
import Tooltip from 'rc-tooltip';

import { TimeRange, formatSeconds } from '@/core/time';
import { darkBlue, orange500, red500, teal500 } from '@/theme/colors';

import s from './TimelineEvent.module.scss';

function attemptRangeSeconds(start: any, end: any) {
  try {
    return new TimeRange(start, end).duration().seconds();
  } catch (e) {
    // ignore ranges where end is before start
  }
}

const levelColor = {
  ERROR: red500,
  WARNING: orange500,
  INFO: darkBlue,
};

// Event dots can overlap on the UI, messages that are important suc as error
// should appear on the top
const eventZIndex = {
  ERROR: 40,
  WARNING: 30,
  INFO: 20,
};

const groupLabel: { [key: string]: string } = {
  'network-quality-warning-raised': 'network',
  'audio-level-warning-raised': 'audio',
};

interface Event {
  level: 'ERROR' | 'WARNING' | 'INFO';
  timestamp: string;
  group: string;
  name: string;
  payload?: {
    message?: string;
  };
}

function TimelineEvent({
  event,
  callId,
  range,
  conferenceDuration,
}: {
  event: Event;
  callId: string;
  range: any;
  conferenceDuration: number;
}) {
  const percentPerSecond = 100 / conferenceDuration;

  const secondsUntilEvent = attemptRangeSeconds(moment(range.start), moment(event.timestamp));

  if (!secondsUntilEvent) return null;

  let zIndex = eventZIndex[event.level];
  // Events with message have priority
  if (event.payload && event.payload.message) zIndex += 1;

  const color = levelColor[event.level] || teal500;
  const style = {
    backgroundColor: color,
    border: `1px solid ${darken(color, 0.1)}`,
    left: `${secondsUntilEvent * percentPerSecond}%`,
    zIndex,
  };

  const tooltip = (
    <div>
      {formatSeconds(secondsUntilEvent)}&nbsp;
      <span style={{ color: levelColor[event.level] }}>{event.level}</span>
      &nbsp; [{groupLabel[event.group] || event.group}] {event.name}
      {event.payload && event.payload.message && <div>{event.payload.message}</div>}
    </div>
  );

  return (
    <Tooltip overlay={tooltip} trigger={['hover']} placement="top">
      <a
        href={`https://www.twilio.com/console/voice/calls/logs/${callId}/events`}
        target="__blank"
        className={s.event}
        style={style}
      />
    </Tooltip>
  );
}

export default TimelineEvent;
