import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';
import moment from 'moment-timezone';
import React, { memo, useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { connect } from 'react-redux';

import { notify } from '@/actions/ui';
import { Checkbox, TextField } from '@/components/FormAdapters';
import FAIcon from '@/components/Icon/FAIcon';
import LabelledOutline from '@/components/LabelledOutline';
import Link from '@/components/Link';
import config from '@/config';
import { openFileDialog, presignAttachmentURL } from '@/core/attachment';
import { useApp } from '@/hooks/useAppContext';
import AddButton from '@/profile/components/forms/buttons/AddButton';
import { black, darkGreen } from '@/theme/colors';
import { required } from '@/utils';

const useStyles = makeStyles(() => ({
  attachment: {
    width: '100%',
    flexDirection: 'column',
    margin: '0 10px',
  },
  author: {
    marginTop: 15,
    textAlign: 'right',
  },
  attachmentInput: {
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'row',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  removeAction: {
    flex: '0 0 auto',
  },
  button: {
    fontSize: '20px',
  },
}));

function Attachment({ viewer, name, index, placeholder, disabled, value, isViewerExpert }: any) {
  const s = useStyles();
  const { graphql } = useApp();

  const editDisabled = disabled || (value && value.id);
  const [externalURL, setExternalURL] = useState<string | undefined>(undefined);

  useEffect(() => {
    async function getpresignAttachmentURL() {
      const url = await presignAttachmentURL(graphql.client, value.file_url);
      setExternalURL(url);
    }
    if (value.file_url) getpresignAttachmentURL();
  }, [graphql.client, value.file_url]);

  return (
    <div className={s.attachment}>
      <a href={externalURL} target="_blank" rel="noreferrer">{`${index + 1}. ${value.name}`}</a>
      <Field
        name={`${name}.description`}
        component={TextField}
        validate={required}
        required
        disabled={editDisabled}
        label="Description"
        placeholder={placeholder}
        multiline
        minRows={1}
        maxRows={3}
        fullWidth
        style={{ marginTop: 15 }}
        // Disable native required validation
        inputProps={{
          required: true,
          maxLength: 2048,
        }}
      />
      {!isViewerExpert && (
        <Field
          name={`${name}.hide_from_experts`}
          component={Checkbox}
          type="checkbox"
          disabled={editDisabled}
          label="Hide from experts (only show to Team Members and Onfrontiers staff)"
        />
      )}
      {value.author && (
        <div className={s.author}>
          Uploaded by{' '}
          <Link href={`${value.author.html_url}`} target="_blank">{`${value.author.name}`}</Link>
          &nbsp;on {`${moment(value.created_at).tz(viewer.timezone).format('YYYY-MM-DD • h:mma')}`}.
        </div>
      )}
    </div>
  );
}

// @ts-expect-error TS(2630): Cannot assign to 'Attachment' because it is a func... Remove this comment to see the full error message
Attachment = connect((state) => ({
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  viewer: state.viewer,
}))(Attachment);

function AttachmentInput({
  showRemove,
  name,
  index,
  onRemove,
  inputProps,
  inputComponent,
  className,
  removeClassName,
  value,
}: any) {
  const s = useStyles();

  const InputComponent = inputComponent;

  return (
    <div className={cx(s.attachmentInput, className)}>
      <div className={cx(s.removeAction, removeClassName)}>
        {showRemove ? (
          <FAIcon
            icon="trash"
            className={s.button}
            onClick={onRemove}
            style={{
              color: black,
              cursor: 'pointer',
            }}
          />
        ) : (
          <FAIcon
            icon="check-circle"
            className={s.button}
            style={{
              color: darkGreen,
            }}
          />
        )}
      </div>
      <InputComponent name={name} index={index} value={value} {...inputProps} />
    </div>
  );
}

let AttachmentList = memo(
  ({
    // @ts-expect-error TS(2339): Property 'fields' does not exist on type '{}'.
    fields,
    // @ts-expect-error TS(2339): Property 'inputProps' does not exist on type '{}'.
    inputProps,
    // @ts-expect-error TS(2339): Property 'inputComponent' does not exist on type '... Remove this comment to see the full error message
    inputComponent,
    // @ts-expect-error TS(2339): Property 'minLength' does not exist on type '{}'.
    minLength = 0,
    // @ts-expect-error TS(2339): Property 'addButtonLabel' does not exist on type '... Remove this comment to see the full error message
    addButtonLabel,
    // @ts-expect-error TS(2339): Property 'style' does not exist on type '{}'.
    style,
    // @ts-expect-error TS(2339): Property 'className' does not exist on type '{}'.
    className,
    // @ts-expect-error TS(2339): Property 'itemClassName' does not exist on type '{... Remove this comment to see the full error message
    itemClassName,
    // @ts-expect-error TS(2339): Property 'removeClassName' does not exist on type ... Remove this comment to see the full error message
    removeClassName,
    // @ts-expect-error TS(2339): Property 'disabled' does not exist on type '{}'.
    disabled,
    // @ts-expect-error TS(2339): Property 'notify' does not exist on type '{}'.
    notify,
    // @ts-expect-error TS(2339): Property 'maxAttachmentFiles' does not exist on ty... Remove this comment to see the full error message
    maxAttachmentFiles,
    // @ts-expect-error TS(2339): Property 'maxAttachmentSize' does not exist on typ... Remove this comment to see the full error message
    maxAttachmentSize,
    // @ts-expect-error TS(2339): Property 'onAddedFiles' does not exist on type '{}... Remove this comment to see the full error message
    onAddedFiles,
    // @ts-expect-error TS(2339): Property 'addActionStyle' does not exist on type '... Remove this comment to see the full error message
    addActionStyle,
    // @ts-expect-error TS(2339): Property 'showRemoveForField' does not exist on ty... Remove this comment to see the full error message
    showRemoveForField = () => true,
  }) => {
    const { graphql } = useApp();
    const maxFiles = maxAttachmentFiles > fields.length ? maxAttachmentFiles - fields.length : 0;

    const handleAdd = async () => {
      openFileDialog(graphql.client, {
        accept: config.filestackEngagementAttachmentMimeTypes,
        fromSources: [
          'local_file_system',
          'url',
          'googledrive',
          'dropbox',
          'box',
          'onedrive',
          'onedriveforbusiness',
        ],
        // need to be explicitly false on transformation to remove transformations UI
        transformations: {
          crop: false,
          circle: false,
          rotate: false,
        },
        maxFiles,
        maxSize: maxAttachmentSize * 1024 * 1024,
        onFileSelected: (file) => {
          // If you throw any error in this function it will reject the file selection.
          // The error message will be displayed to the user as an alert.
          const hasDuplicate = fields
            .map(function (name: any, index: any) {
              const attachment = fields.value[index];
              return !attachment.id && attachment.name === file.filename;
            })
            .some((f: any) => f);
          if (hasDuplicate) {
            const msg = `File is already selected for submission: ${file.filename}`;
            notify(msg, 'error');
            throw new Error(msg);
          }
        },
      })
        .then(async (files) => {
          files.map(function (file) {
            fields.push({
              name: file.filename,
              file_url: file.url,
              description: undefined,
            });
          });
        })
        .then(onAddedFiles);
    };

    const showRemove = fields.length > minLength;

    return (
      <>
        <div style={style} className={className}>
          {fields.map((name: any, index: any) => (
            <LabelledOutline key={name}>
              <AttachmentInput
                inputComponent={inputComponent}
                name={name}
                index={index}
                inputProps={inputProps}
                onRemove={() => fields.remove(index)}
                showRemove={!disabled && showRemove && showRemoveForField(fields.value[index])}
                className={itemClassName}
                removeClassName={removeClassName}
                value={fields.value[index]}
              />
            </LabelledOutline>
          ))}
        </div>
        {!disabled && maxFiles > 0 && (
          <div style={addActionStyle}>
            <AddButton label={addButtonLabel} onClick={handleAdd} />
          </div>
        )}
      </>
    );
  }
);
AttachmentList.displayName = 'AttachmentList';

// @ts-expect-error TS(2741): Property '$$typeof' is missing in type 'FunctionCo... Remove this comment to see the full error message
AttachmentList = connect(undefined, {
  notify,
})(AttachmentList);

class Attachments extends React.PureComponent {
  render = () => {
    // @ts-expect-error TS(2339): Property 'name' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const { name, disabled, label, isMobileVersion, isViewerExpert, ...other } = this.props;
    return (
      <FieldArray
        component={AttachmentList}
        disabled={disabled}
        name={name}
        defaultValue={[]}
        inputComponent={Attachment}
        inputProps={{
          label,
          placeholder: isMobileVersion ? '' : 'Add a file description',
          disabled,
          isViewerExpert,
        }}
        showRemoveForField={(v: any) => !v?.id}
        {...other}
      />
    );
  };
}

export default Attachments;
