import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';

import { darkGreen } from '@/theme/colors';
import { highlight } from '@/utils';

import FAIcon from '../Icon/FAIcon';

const highlightSimpleOrArray = (text: any, snippets: any, truncateAt = 60) => {
  return Array.isArray(snippets) && snippets?.length
    ? highlight(`(…) ${snippets.join(' (…) ')}…`)
    : highlight(text.length > truncateAt ? `${text.substring(0, truncateAt)} (…)` : text);
};

function ResponseText({
  response: { query, query_snippets: querySnippets, response, response_snippets: responseSnippets },
}: any) {
  return (
    <>
      <span className="fa-li">
        <FAIcon icon="comment-dots" color={darkGreen} />
      </span>
      <span
        dangerouslySetInnerHTML={{
          // @ts-expect-error TS(2322): Type 'string | HTMLElement | DocumentFragment' is ... Remove this comment to see the full error message
          __html: highlightSimpleOrArray(query, querySnippets),
        }}
      />
      <br />
      <span className="fa-li">
        <FAIcon icon="reply" color={darkGreen} />
      </span>
      <span
        dangerouslySetInnerHTML={{
          // @ts-expect-error TS(2322): Type 'string | HTMLElement | DocumentFragment' is ... Remove this comment to see the full error message
          __html: highlightSimpleOrArray(response, responseSnippets),
        }}
      />
    </>
  );
}

function YesNo({ response: { query, query_snippets: querySnippets } }: any) {
  return (
    <>
      <span className="fa-li">
        <FAIcon icon="check" color={darkGreen} />
      </span>
      <span
        dangerouslySetInnerHTML={{
          // @ts-expect-error TS(2322): Type 'string | HTMLElement | DocumentFragment' is ... Remove this comment to see the full error message
          __html: highlightSimpleOrArray(query, querySnippets),
        }}
      />
    </>
  );
}

const useResponseStyles = makeStyles(() => ({
  noteItems: {
    marginTop: 0,
    marginBottom: 0,
  },
  item: {
    '& + &': {
      marginTop: 10,
    },
  },
}));

export default function QueryResponses({ values = [], queryType = 'questions' }) {
  const s = useResponseStyles();

  return values && values.length > 0 ? (
    <ul className={cx(s.noteItems, 'fa-ul')}>
      {values.map((response) => {
        // @ts-expect-error TS(2339): Property 'response_type' does not exist on type 'n... Remove this comment to see the full error message
        const isFreeFormType = response.response_type === 'free_form';
        return (
          <li className={s.item} key={response}>
            {isFreeFormType ? <ResponseText response={response} /> : <YesNo response={response} />}
          </li>
        );
      })}
    </ul>
  ) : (
    <i>Can’t answer any of the {queryType}.</i>
  );
}
