import { gql, useMutation } from '@apollo/client';
import { MetaData } from '@sendbird/chat';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { PickerFileMetadata } from 'filestack-js';
import { ChangeEventHandler, Suspense, useCallback, useRef } from 'react';
import { FC, memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { notify } from '@/actions/ui';
import { useAuth } from '@/auth';
import { isAdvancedUser } from '@/core/group';
import { channelUrlToId, isAnonymousUser } from '@/messaging/sendbird';

import { findOtherMember } from '../utils';
import s from './Chat.module.scss';
import ChatHeader from './ChatHeader';
import MessageInput from './MessageInput';
import MessageList from './MessageList';

const CREATE_MESSAGING_ATTACHMENT = gql(/* GraphQL */ `
  mutation chatSaveMessagingAttachment($channelUrl: String!, $fileUrl: String!) {
    saveMessagingAttachment(channel_url: $channelUrl, file_url: $fileUrl)
  }
`);

const CREATE_CHANNEL = gql(/* GraphQL */ `
  mutation chatCreateChannel($userIds: [String!], $anonymous: Boolean) {
    createMessagingChannel(user_ids: $userIds, anonymous: $anonymous) {
      url
    }
  }
`);

interface ChatProps {
  channel: GroupChannel;
  metadata: MetaData;
  text?: string;
  isMobileVersion?: boolean;
  onBackToChannels?: () => void;
}

const Chat: FC<ChatProps> = memo(
  ({ channel, metadata, isMobileVersion = false, onBackToChannels, text: initialText = '' }) => {
    const navigate = useNavigate();

    const { viewer } = useAuth();
    if (!viewer.id) {
      throw new Error('Viewer must be logged in');
    }

    const [text, setText] = useState(initialText);

    const endTypingTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
    useEffect(() => {
      return () => {
        if (endTypingTimeout.current) {
          clearTimeout(endTypingTimeout.current);
          channel.endTyping();
        }
      };
    }, [channel]);

    const [createMessagingAttachment] = useMutation(CREATE_MESSAGING_ATTACHMENT);
    const [createChannel] = useMutation(CREATE_CHANNEL);

    const handleMessageInput: ChangeEventHandler<HTMLInputElement> = useCallback(
      ({ target: { value } }) => {
        setText(value);

        if (!channel) return;

        if (endTypingTimeout.current) {
          clearTimeout(endTypingTimeout.current);
        }

        channel.startTyping();
        endTypingTimeout.current = setTimeout(() => {
          channel.endTyping();
        }, 3000);
      },
      [channel]
    );

    const handleMessageSend = useCallback(
      async (text: string, createAnonymousChannel: boolean) => {
        if (!channel) {
          throw new Error('Trying to send message with no channel');
        }

        await channel.endTyping();

        if (createAnonymousChannel) {
          const otherMember = findOtherMember(channel.members, viewer.id as string);
          const { data } = await createChannel({
            variables: { userIds: [viewer.id, otherMember.userId], anonymous: true },
          });
          const url = data.createMessagingChannel.url;
          navigate(`/messaging/${channelUrlToId(url)}`);
          return;
        }

        try {
          channel.sendUserMessage({ message: text });
          setText('');

          // scrollToMessage(message);
        } catch (err) {
          notify('An error occurred when sending message', 'error');
          throw err;
        }
      },
      [channel, createChannel, navigate, viewer.id]
    );

    const handleAttachFile = useCallback(
      async (file: PickerFileMetadata) => {
        if (!channel) {
          throw new Error('Trying to send message with no channel');
        }

        const { data } = await createMessagingAttachment({
          variables: { channelUrl: channel.url, fileUrl: file.url },
        });

        const url = data.saveMessagingAttachment;

        channel.sendFileMessage({
          fileUrl: url,
          fileName: file.filename,
          fileSize: file.size,
          mimeType: file.mimetype,
        });
      },
      [channel, createMessagingAttachment]
    );

    const initialSendAnonymously = viewer.groups.some((g) => g.default_anonymous_messaging);

    return (
      <div className={s.chat}>
        <Suspense>
          <ChatHeader
            channel={channel}
            metadata={metadata}
            isMobileVersion={isMobileVersion}
            onBackToChannels={onBackToChannels}
          />
          <MessageList channel={channel} metadata={metadata} />
        </Suspense>
        <MessageInput
          text={text}
          isFocused={true}
          isMobileVersion={isMobileVersion}
          onChange={handleMessageInput}
          onSend={handleMessageSend}
          isAnonymous={isAnonymousUser(metadata, viewer.id)}
          canSendAnonymously={isAdvancedUser(viewer)}
          onAttachFile={handleAttachFile}
          initialSendAnonymously={initialSendAnonymously}
        />
      </div>
    );
  }
);
Chat.displayName = 'Chat';

export default Chat;
