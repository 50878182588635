import cx from 'classnames';
import React, { PureComponent } from 'react';

import s from './PictureList.module.scss';

class PictureList extends PureComponent {
  render() {
    // @ts-expect-error TS(2339): Property 'children' does not exist on type 'Readon... Remove this comment to see the full error message
    const { children, className } = this.props;
    return (
      <div className={cx(s.root, { [className]: !!className })}>
        {children.map((p: any) => (
          <div key={p.props.id} className={s.item}>
            {p}
          </div>
        ))}
      </div>
    );
  }
}

export default PictureList;
