import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { hideMessage, notify } from '@/actions/ui';
import Dialog from '@/components/Dialog';
import Divider from '@/components/Divider';
import EditIcon from '@/components/EditIcon/EditIcon';
import Link from '@/components/Link';
import SendMessageButton from '@/components/SendMessageButton/SendMessageButton';
import {
  Trigger,
  fetchPredictTransactions,
  isWrittenConsultationConfirmed,
  updateConsultation,
} from '@/consultation/store';
import { parseDuration } from '@/core/consultation';
import Duration from '@/core/duration';
import { money } from '@/core/money';
import { isAttachmentDeliverableType } from '@/expertrequest/store';
import { formatDateTime } from '@/utils';

import EditTrackingCodeDialog from '../EditTrackingCodeDialog';
import CostsAndPayout from './CostsAndPayout';
import Notes from './Notes';
import s from './WrittenResponse.module.scss';
import Actions from './written/Actions';
import SubmitAttachments from './written/SubmitAttachments';

function Participants({ requester, expert, isViewerAdmin, requesterName, expertName }: any) {
  return (
    <>
      <Grid item md={6} sm={12}>
        <div className={s.headerText}>Requester</div>
        {requester ? (
          <div className={s.valueText}>
            <Link to={requester?.html_url}>{requesterName}</Link>
            {isViewerAdmin && <SendMessageButton userId={requester.id} />}
          </div>
        ) : (
          requesterName
        )}
      </Grid>

      <Grid item md={6} sm={12}>
        <div className={s.headerText}>Expert</div>
        {expert ? (
          <div className={s.valueText}>
            <Link to={expert.html_url}>{expertName}</Link>
            {isViewerAdmin && <SendMessageButton userId={expert.id} />}
          </div>
        ) : (
          expertName
        )}
      </Grid>
    </>
  );
}

function DurationPicker({
  durationHours,
  cost,
  minHours = 1,
  maxHours,
  handleDecrement,
  handleIncrement,
}: any) {
  const classes = makeStyles(() => ({
    root: {
      '&$disabled': { color: 'black', textTransform: 'none' },
      fontSize: 16,
    },
    disabled: {},
  }))();
  const displayDuration = `${durationHours} Hour${durationHours > 1 ? 's' : ''}`;
  return (
    <div className={s.valueText}>
      <p>
        If the terms suggested by the client are not agreeable, suggest another duration to be
        reviewed by the client.
      </p>
      <ButtonGroup variant="outlined" size="small" aria-label="small outlined button group">
        <Button onClick={handleDecrement} disabled={durationHours <= minHours}>
          -
        </Button>
        <Button disabled classes={{ root: classes.root, disabled: classes.disabled }}>
          <b>{displayDuration}</b>
        </Button>
        <Button onClick={handleIncrement} disabled={durationHours >= maxHours}>
          +
        </Button>
      </ButtonGroup>
      &nbsp; for <b>{cost}</b>
    </div>
  );
}

function WrittenResponse({
  consultation,
  expertRequest,
  isMobileVersion,
  isViewerExpert,
  isViewerRequester,
  isViewerAdmin,
  user,
  showCompleteTraining,
  expertName,
  requesterName,
  hideMessage,
  notify,
  updateConsultation,
  fetchPredictTransactions,
}: any) {
  const [openTrackingCodeDialog, setOpenTrackingCodeDialog] = useState(false);
  const [openFinalizeWithAttachment, setOpenFinalizeWithAttachment] = useState(false);

  const {
    requester,
    expert,
    proposed_times: proposedTimes,
    state,
    costs,
    estimated_expert_payout: estimatedExpertPayout,
    engagement_type: engagementType,
    expert_payout: expertPayout,
    expected_duration: expectedDuration,
    initial_expected_duration: initialExpectedDuration,
    group,
    ended_at: endsAt,
    tracking_code: trackingCode,
    requester_timezone: requesterTimezone,
    expert_timezone: expertTimezone,
    description: notes,
    bill_rate: billRate,
    credit_rate: creditRate,
  } = consultation || {};

  const hasCompletedComplianceTraining = expert && !!expert.compliance_completed_at;

  const expectedDurationHours = parseDuration(expectedDuration).hours();

  const [newDurationHours, setNewDurationHours] = useState(expectedDurationHours);
  const [newExpertEarnings, setNewExpertEarnings] = useState(estimatedExpertPayout);
  const [newClientExpertFee, setNewClientExpertFee] = useState(null);

  const [initialExpertEarnings, setInitialExpertEarnings] = useState(null);
  const [initialClientExpertFee, setInitialClientExpertFee] = useState(null);

  const handleIncrement = () => {
    if (!hasCompletedComplianceTraining) {
      showCompleteTraining(true);
      return;
    }
    setNewDurationHours(newDurationHours + 1);
  };

  const handleDecrement = () => {
    if (!hasCompletedComplianceTraining) {
      showCompleteTraining(true);
      return;
    }
    setNewDurationHours(newDurationHours - 1);
  };
  const newDuration = useMemo(() => {
    return Duration.parse(`${newDurationHours}h`).toString();
  }, [newDurationHours]);

  useEffect(() => {
    // Fetch new fees on expert negotiation
    if (expertRequest?.id || group) {
      (async function () {
        const predictTransactions = await fetchPredictTransactions(
          consultation.expert.id,
          expertRequest?.id,
          group?.id,
          engagementType,
          newDuration,
          billRate,
          creditRate
        );
        setNewExpertEarnings(money(predictTransactions.expert_earnings).cents || 0);
        setNewClientExpertFee(money(predictTransactions.client_expert_fee).cents || 0);
      })();
    }
  }, [consultation, newDuration]);

  useEffect(() => {
    // Fetch client's original proposal
    if (expertRequest?.id || group) {
      (async function () {
        const predictTransactions = await fetchPredictTransactions(
          consultation.expert.id,
          expertRequest?.id,
          group?.id,
          engagementType,
          initialExpectedDuration,
          billRate,
          creditRate
        );
        setInitialExpertEarnings(money(predictTransactions.expert_earnings).cents || 0);
        setInitialClientExpertFee(money(predictTransactions.client_expert_fee).cents || 0);
      })();
    }
  }, [consultation]);

  const deadline = proposedTimes && proposedTimes.length > 0 && proposedTimes[0];

  const isNegotiating = ['negotiating_client_time', 'negotiating_expert_time'].includes(state);
  const isConfirmed = isWrittenConsultationConfirmed(state);
  const isCompleted = state === 'completed';
  const isFinalizing = state === 'finalizing';
  const isCanceled = state === 'canceled' || state === 'client_rejected';
  const isDenied = state === 'denied';
  const isExpertNegotiating = state === 'negotiating_expert_time';
  const isClientNegotiating = state === 'negotiating_client_time';
  const isConsultationActive = isNegotiating || isConfirmed;
  const canViewerComplete = isViewerRequester || isViewerAdmin;
  const canViewerConfirm =
    (isViewerExpert && isExpertNegotiating) ||
    ((isViewerRequester || isViewerAdmin) && isClientNegotiating);

  const userTimezone = isViewerExpert ? requesterTimezone : expertTimezone;

  const canCompleteWithAttachment =
    canViewerComplete &&
    isConfirmed &&
    isAttachmentDeliverableType(expertRequest?.er_type) &&
    consultation.attachments?.length > 0;

  const handleFinalizeWithAttachment = () => {
    setOpenFinalizeWithAttachment(false);
    hideMessage();

    updateConsultation({
      id: consultation.id,
      state: 'finalizing',
      trigger: Trigger.consultationPage,
    }).then(
      () => {
        notify(`You have acknowledged receipt of the written response`, 'success');
      },
      (err: any) => {
        notify(`An error occurred when accepting the consultation:${err}`, 'error');
      }
    );
  };

  return (
    <Grid container style={{ paddingLeft: 10 }}>
      {(!isNegotiating || isViewerAdmin) && (
        <Participants
          expert={expert}
          requester={requester}
          expertName={expertName}
          requesterName={requesterName}
          isViewerAdmin={isViewerAdmin}
        />
      )}

      <Grid item md={isCompleted ? 6 : 12} sm={12}>
        <div className={s.headerText}>Response Deadline</div>
        <div className={s.valueText}>{formatDateTime(deadline, userTimezone)}</div>
      </Grid>

      {(isCompleted || isFinalizing) && (
        <Grid item md={6} sm={12}>
          <div className={s.headerText}>Acknowledged At</div>
          <div className={s.valueText}>{formatDateTime(endsAt, userTimezone)}</div>
        </Grid>
      )}

      <CostsAndPayout
        classes={{ headerText: s.headerText, valueText: s.valueText }}
        expectedDurationHours={expectedDurationHours}
        costs={costs}
        initialExpectedDuration={initialExpectedDuration}
        initialExpertEarnings={initialExpertEarnings}
        initialClientExpertFee={initialClientExpertFee}
        expertEarnings={isCompleted ? expertPayout : newExpertEarnings}
        clientExpertFee={newClientExpertFee}
        isViewerAdmin={isViewerAdmin}
        isViewerExpert={isViewerExpert}
        isViewerRequester={isViewerRequester}
        isConfirmed={isConfirmed}
        isCompleted={isCompleted}
        isClientNegotiating={isClientNegotiating}
        isExpertNegotiating={isExpertNegotiating}
        isDenied={isDenied}
        isCanceled={isCanceled}
      />

      {isViewerExpert && isExpertNegotiating && (
        <Grid item md={isCompleted ? 6 : 12} sm={12}>
          <div className={s.headerText}>Suggest Alternative Duration</div>
          <DurationPicker
            durationHours={newDurationHours}
            cost={`$${(newExpertEarnings / 100).toFixed(2)}`}
            minHours={1}
            maxHours={isViewerAdmin ? undefined : 10}
            handleDecrement={handleDecrement}
            handleIncrement={handleIncrement}
          />
        </Grid>
      )}

      {!isViewerExpert && isViewerAdmin && (
        <Grid item md={6} sm={12}>
          <div className={s.headerText}>Tracking Code</div>
          <div className={s.valueText}>
            {trackingCode}
            <EditIcon onClick={() => setOpenTrackingCodeDialog(true)} />
          </div>
        </Grid>
      )}

      <Grid item md={12}>
        <SubmitAttachments
          // @ts-expect-error TS(2322) FIXME: Type '{ consultation: any; isViewerExpert: any; is... Remove this comment to see the full error message
          consultation={consultation}
          isViewerExpert={isViewerExpert}
          isMobileVersion={isMobileVersion}
        />
      </Grid>

      <Grid item md={12}>
        <Actions
          user={user}
          userTimezone={userTimezone}
          canConfirm={canViewerConfirm}
          canCancel={isConfirmed || (!isViewerExpert && isNegotiating)}
          consultation={consultation}
          canExtendDeadline={!isViewerExpert && isConsultationActive}
          isViewerAdmin={isViewerAdmin}
          isViewerExpert={isViewerExpert}
          showCompleteTraining={showCompleteTraining}
          canCompleteWithAttachment={canCompleteWithAttachment}
          onFinalizeWithAttachment={() => setOpenFinalizeWithAttachment(true)}
          newDuration={newDuration}
        />

        {notes && <Notes notes={notes} />}

        {isMobileVersion && <Divider spacing={30} />}
      </Grid>

      <EditTrackingCodeDialog
        open={openTrackingCodeDialog}
        onClose={() => setOpenTrackingCodeDialog(false)}
        consultation={consultation}
      />

      <Dialog
        open={openFinalizeWithAttachment}
        title="Acknowledge Receipt"
        subTitle={
          'This engagement requires the expert to deliver a written review ' +
          'of your provided documents. Do you acknowledge receipt of this ' +
          'review and that this transaction is completed?'
        }
        confirmLabel="Acknowledge"
        onCancel={() => setOpenFinalizeWithAttachment(false)}
        onClose={() => setOpenFinalizeWithAttachment(false)}
        onConfirm={handleFinalizeWithAttachment}
      />
    </Grid>
  );
}

// @ts-expect-error TS(2630) FIXME: Cannot assign to 'WrittenResponse' because it is a... Remove this comment to see the full error message
WrittenResponse = connect(undefined, {
  hideMessage,
  notify,
  updateConsultation,
  fetchPredictTransactions,
})(WrittenResponse);

export default WrittenResponse;
