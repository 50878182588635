import moment from 'moment-timezone';

import MaterialIcon from '@/components/Icon/MaterialIcon';
import { TimeRange } from '@/core/time';
import { white } from '@/theme/colors';

import s from './TimelineCall.module.scss';

const callTypeIcon = {
  dialout: 'phone_forwarded',
  dialin: 'local_phone',
  phone: 'local_phone',
  web: 'laptop',
};

function TimelineCall({ participant, range, conferenceDuration, onClick }: any) {
  const percentPerSecond = 100 / conferenceDuration;

  const callStart = participant.callStart() ? moment(participant.callStart()) : range.start;
  const callEnd = participant.callEnd() ? moment(participant.callEnd()) : range.end;

  const secondsUntilCall = new TimeRange(range.start, callStart).duration().seconds();

  const secondsUntilEnd = new TimeRange(callStart, callEnd).duration().seconds();

  const style = {
    left: `${secondsUntilCall * percentPerSecond}%`,
    width: `${secondsUntilEnd * percentPerSecond}%`,
    cursor: participant.url() ? 'pointer' : '',
  };

  return [
    <MaterialIcon
      key={`icon-${participant.callId()}`}
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      icon={callTypeIcon[participant.inferCallType()]}
      color={white}
      style={{ left: style.left }}
      className={s.callIcon}
      size={18}
    />,
    <div
      key={participant.callId()}
      className={s.call}
      style={style}
      onKeyDown={onClick}
      onClick={onClick}
    />,
  ];
}

export default TimelineCall;
