import { GroupChannel } from '@sendbird/chat/groupChannel';
import { promisify } from 'es6-promisify';

import { AppThunk } from '@/store';

export const MESSAGING__EVENT_UNREAD_COUNT = 'MESSAGING__EVENT_UNREAD_COUNT';

export const messagingUnreadCount = Object.freeze({
  service: 'Messaging',
  queue: 'unread_messages',
});

export function saveMessagingAttachment(channelURL: any, fileURL: any): AppThunk<Promise<any>> {
  return async (_dispatch, _getState, { graphql }) => {
    const result = await graphql.mutate(
      `(
        $channel_url: String!
        $file_url: String!
      ) {
        saveMessagingAttachment(
          channel_url: $channel_url
          file_url: $file_url
        )
      }`,
      { channel_url: channelURL, file_url: fileURL }
    );

    return result.saveMessagingAttachment;
  };
}

export function createChannel(userIds: any, anonymous?: any): AppThunk<Promise<GroupChannel>> {
  return async (_dispatch, _getState, { graphql, sendbird }) => {
    const result = await graphql.mutate(
      `(
        $userIds: [String!]
        $anonymous: Boolean
      ) {
        createMessagingChannel(
          user_ids: $userIds
          anonymous: $anonymous
        ) {
          url
        }
      }`,
      { userIds, anonymous }
    );
    const url = result.createMessagingChannel.url;
    const sb = await sendbird.getSession();
    if (!sb) {
      throw new Error('Sendbird not initialized');
    }
    return await sb.groupChannel.getChannel(url);
  };
}

export function sendFileMessage(
  channelUrl: any,
  url: any,
  name: any,
  type: any,
  size: any
): AppThunk {
  return async () => {
    const channel: any = null;
    if (!channel) throw new Error(`channel not found ${channelUrl}`);

    const message = await promisify(channel.raw.sendFileMessage.bind(channel.raw))(
      // @ts-ignore
      url,
      name,
      type,
      size,
      null,
      null
    );
    return message;
  };
}

export function updateUnreadCount(count: any): AppThunk<Promise<any>> {
  return async (dispatch) => {
    dispatch({
      type: MESSAGING__EVENT_UNREAD_COUNT,
      unreadCount: count,
    });
  };
}
