import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { deleteProject } from '@/actions/project';
import { notify } from '@/actions/ui';

import DeleteDialog from './DeleteDialog';

const CONSULTATIONS_ERROR =
  'GraphQL Error: cannot delete project with pending/upcoming/completed consultations';

const DeleteProjectDialog = (props: any) => {
  const navigate = useNavigate();

  const handleDelete = () => {
    const { projectId, deleteProject, notify, onCancel, returnTo } = props;
    deleteProject(projectId)
      .then(() => {
        if (returnTo) navigate(returnTo);
      })
      .catch((err: any) => {
        if (err.message === CONSULTATIONS_ERROR) {
          notify(
            'Cannot delete project with pending, upcoming, or completed consultations.',
            'error'
          );
        } else {
          notify('An unexpected error occurred when trying ' + 'to delete the project.', 'error');
        }
        onCancel();
      });
  };

  return (
    <DeleteDialog
      {...props}
      title="Are you sure you want to delete this project?"
      onConfirm={handleDelete}
    >
      This action is permanent and will result in the loss of all expert requests and matches.
    </DeleteDialog>
  );
};

export default connect(undefined, {
  deleteProject,
  notify,
})(DeleteProjectDialog);
