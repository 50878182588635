import { gql } from '@apollo/client';
import { UnknownAction } from 'redux';

import { AppThunk } from '@/store';
import { shouldResetCollection } from '@/utils';

export type CollectionType =
  | 'unreviewed'
  | 'starting'
  | 'awaiting'
  | 'canceled'
  | 'confirmed'
  | 'default'
  | 'dashboardAwaiting'
  | 'dashboardConfirmed';

export const CONSULTATION__ADD_REGISTRANT = 'CONSULTATION__ADD_REGISTRANT';
export const CONSULTATION__BATCH_ADD = 'CONSULTATION__BATCH_ADD';
export const CONSULTATION__UPDATE = 'CONSULTATION__UPDATE';
export const CONSULTATION__DELETE = 'CONSULTATION__DELETE';
export const CONSULTATION__LIST_LOADING = 'CONSULTATION__LIST_LOADING';
export const CONSULTATION__LIST_LOADED = 'CONSULTATION__LIST_LOADED';
export const CONSULTATION__CLEAR = 'CONSULTATION__CLEAR';
export const CONSULTATION__DISMISS_REVIEW = 'CONSULTATION__DISMISS_REVIEW';
export const CONSULTATION__RESET_COLLECTION = 'CONSULTATION__RESET_COLLECTION';

export const OUTDATED_ERROR = 'GraphQL Error: cannot choose a past date to start the consultation';
export const ALREADY_CONFIRMED =
  'GraphQL Error: cannot change start at date of confirmed consultation';
export const ALREADY_CANCELED = 'GraphQL Error: cannot reopen a canceled or denied consultation';
export const ALREADY_STARTED =
  'GraphQL Error: cannot cancel or reschedule consultation that has already started';
export const NOT_ENOUGH_CREDITS = 'GraphQL Error: not enough credits for consultation';
export const DEADLINE_BEFORE_CURRENT =
  'GraphQL Error: cannot set new deadline before current deadline';
export const DEADLINE_WRONG_STATE =
  'GraphQL Error: can only set deadline when state is awaiting or confirmed';

export const Trigger = Object.freeze({
  consultationPage: Symbol('consultationPage'),
  emailLink: Symbol('emailLink'),
});

export const engagementTypes = Object.freeze({
  consultation: 'consultation',
  opportunity: 'opportunity',
  writtenResponse: 'written_response',
});

export const isCallType = (type: any) =>
  [engagementTypes.consultation, engagementTypes.opportunity].includes(type);

const state = Object.freeze({
  negotiating_expert_time: 'negotiating_expert_time',
  negotiating_client_time: 'negotiating_client_time',
  awaiting_expert_review: 'awaiting_expert_review',
  awaiting_client_accept: 'awaiting_client_accept',
  denied: 'denied',
  expired: 'expired',
  canceled: 'canceled',
  incomplete: 'incomplete',
  confirmed: 'confirmed',
  finalizing: 'finalizing',
  completed: 'completed',
  client_rejected: 'client_rejected',
});

export function getEngagementTimestamp(consultation: any): any {
  if (consultation.engagementType === engagementTypes.writtenResponse) {
    return (
      consultation.proposedTimes &&
      consultation.proposedTimes.length > 0 &&
      consultation.proposedTimes[0]
    );
  }
  return consultation.starts_at;
}

export const isFixedRate = (type: any) =>
  [engagementTypes.opportunity, engagementTypes.writtenResponse].includes(type);

export const isWrittenConsultationConfirmed = (type: any) =>
  [state.confirmed, state.awaiting_expert_review, state.awaiting_client_accept].includes(type);

function triggerToConsultationUpdateTrigger(trigger: any) {
  // Unfortunatelly, Symbol.prototype.description is not supported on
  // all browsers or node env
  switch (trigger) {
    case Trigger.consultationPage:
      return 'consultationPage';
    case Trigger.emailLink:
      return 'emailLink';
    default:
      throw new Error(`Unsupported trigger ${trigger}`);
  }
}

const requestConsultationMutation = gql`
  mutation actionRequestConsultation(
    $requester_id: String
    $expert_id: String!
    $profile_type: ProfileType
    $dates: [Datetime!]
    $duration: Duration
    $credits: MoneyInput
    $description: String
    $expert_request_id: String
    $group_id: String
    $disclosure: DisclosureOption
    $engagement_type: EngagementType!
    $tracking_code: String
  ) {
    requestConsultation(
      requester_id: $requester_id
      expert_id: $expert_id
      profile_type: $profile_type
      proposed_times: $dates
      expected_duration: $duration
      expected_credits: $credits
      description: $description
      expert_request_id: $expert_request_id
      group_id: $group_id
      disclosure: $disclosure
      engagement_type: $engagement_type
      tracking_code: $tracking_code
    ) {
      id
      html_url
    }
  }
`;

export function requestConsultation(values: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const result = await graphql.send(requestConsultationMutation, values);

    dispatch({ type: CONSULTATION__CLEAR });

    return result.requestConsultation;
  };
}

export function validateExpertConsultationPreferences(values: any): AppThunk<Promise<any>> {
  return async (_dispatch, _getState, { graphql }) => {
    const result = await graphql.query(
      `query (
        $expertId: String!
        $expertRequestId: String
        $groupId: String
        $engagementType: EngagementType!
      ) {
        validateExpertConsultationPreferences(
          expert_id: $expertId
          expert_request_id: $expertRequestId
          group_id: $groupId
          engagement_type: $engagementType
        )
      }
    `,
      values
    );

    return result.validateExpertConsultationPreferences;
  };
}

export function addConsultation(values: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const result = await graphql.mutate(
      `
      (
        $expert_request_id: String
        $group_id: String
        $requester_id: String
        $expert_id: String
        $requester_name: String
        $expert_name: String
        $started_at: Datetime!
        $recording_url: String!
        $recording_duration: Duration
        $engagement_type: EngagementType!
        $recording_file_size: Int
        $recording_filename: String!
      ) {
        addConsultation(
          expert_request_id: $expert_request_id
          group_id: $group_id
          requester_id: $requester_id
          expert_id: $expert_id
          requester_name: $requester_name
          expert_name: $expert_name
          started_at: $started_at
          recording_url: $recording_url
          recording_duration: $recording_duration
          engagement_type: $engagement_type
          recording_file_size: $recording_file_size
          recording_filename: $recording_filename
        ) {
          id
          html_url
        }
      }
    `,
      values
    );

    dispatch({ type: CONSULTATION__CLEAR });

    return result.addConsultation;
  };
}

export function deleteConsultation(id: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    await graphql.mutate(
      `
      ($consultationId: String!) {
        deleteConsultation(consultation_id: $consultationId)
      }
    `,
      { consultationId: id }
    );

    dispatch({ type: CONSULTATION__DELETE, id });
  };
}

export function orderTranscript(id: any, maxCredits: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const result = await graphql.mutate(
      `
      (
        $id: String!
        $maxCredits: MoneyInput
      ) {
        orderTranscription(
          consultation_id: $id
          max_credits: $maxCredits
        ) {
          state
        }
      }
    `,
      { id, maxCredits }
    );

    const order = result.orderTranscription;

    dispatch({
      type: CONSULTATION__UPDATE,
      consultation: { id, transcription_order: order },
    });
  };
}

export function updateConsultation(values: any): AppThunk<Promise<any>> {
  return (dispatch, _getState, { graphql }) =>
    graphql
      .mutate(
        `
      (
        $id: String!
        $starts_at: Datetime
        $state: ConsultationState
        $expert_request_id: String
        $proposed_times: [Datetime!]
        $cancel_reason: String
        $duration: Duration
        $recording_url: String
        $expert_id: String
        $expert_name: String
        $requester_id: String
        $requester_name: String
        $started_at: Datetime
        $recording_duration: Duration
        $tracking_code: String
        $initial_expected_duration: Duration
        $bill_rate: Int
        $credit_rate: Int

        $trigger: ConsultationUpdateTrigger
      ) {
        updateConsultation(
          id: $id
          starts_at: $starts_at
          state: $state
          expert_request_id: $expert_request_id
          proposed_times: $proposed_times
          cancel_reason: $cancel_reason
          expected_duration: $duration
          recording_url: $recording_url
          expert_id: $expert_id
          expert_name: $expert_name
          requester_id: $requester_id
          requester_name: $requester_name
          started_at: $started_at
          recording_duration: $recording_duration
          tracking_code: $tracking_code
          initial_expected_duration: $initial_expected_duration
          bill_rate: $bill_rate
          credit_rate: $credit_rate

          trigger: $trigger
        ) {
          id
          starts_at
          started_at
          state
          archived
          expert_request {
            id
            er_type
            html_url
            name
            description
            focus_areas
            qualifications { id, query }
            questions { id, query }
            attachments {
              id
              created_at
              author {
                name
                html_url
              }
              expert_request_id
              consultation_id
              name
              description
              file_url
            }
            companies_pursue
            companies_avoid
            disclosure
            regions { id, name }
            sectors { id, name }
            project {
              name
              html_url
              tracking_code
              group { id name }
            }
            unpaid
          }
          proposed_times
          rejected_times
          cancel_reason
          expected_duration
          initial_expected_duration
          estimated_expert_payout
          requester {
            id
            first_name
            last_name
            name
            html_url
            picture_url
            timezone
            phone
          }
          requester_timezone
          requester_name
          expert {
            id
            first_name
            last_name
            name
            html_url
            picture_url
            timezone
            phone
            expert_state
            compliance_completed_at
            bill_rate
          }
          expert_timezone
          expert_name
          event {
            description
            summary
            location
            start
            end
          }
          original_recording_url
          recording_url
          recording_duration
          tracking_code
          permissions
          transcription_price {
            cents
            currency
          }
          attachments {
            id
            created_at
            author {
              name
              html_url
            }
            expert_request_id
            consultation_id
            name
            description
            file_url
          }
          bill_rate
          credit_rate
          expected_duration
          initial_expected_duration
        }
      }
    `,
        {
          ...values,
          trigger: triggerToConsultationUpdateTrigger(values.trigger),
        }
      )
      .then((result: any) => {
        const consultation = result.updateConsultation;
        dispatch({ type: CONSULTATION__UPDATE, consultation });
      });
}

export function reviewConsultation(values: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const result = await graphql.mutate(
      `
      (
        $consultation_id: String!
        $match_experience_rating: Int
        $uselfulness_rating: Int
        $communication_rating: Int
        $connection_quality_rating: Int
        $review: String
        $target_feedback: String
        $onfrontiers_feedback: String
        $expert_usefulness_rating: Int
        $time_savings_feedback: Boolean
        $improved_bid_confidence_feedback: Boolean
        $partner_identification_feedback: Boolean
        $cost_savings_feedback: Boolean
        $new_opportunity_identification_feedback: Boolean
        $other_feedback: Boolean
        $other_text_feedback: String
      ) {
        reviewConsultation(
          consultation_id: $consultation_id
          match_experience_rating: $match_experience_rating
          uselfulness_rating: $uselfulness_rating
          communication_rating: $communication_rating
          connection_quality_rating: $connection_quality_rating
          review: $review
          target_feedback: $target_feedback
          onfrontiers_feedback: $onfrontiers_feedback
          expert_usefulness_rating: $expert_usefulness_rating
          time_savings_feedback: $time_savings_feedback
          improved_bid_confidence_feedback: $improved_bid_confidence_feedback
          partner_identification_feedback: $partner_identification_feedback
          cost_savings_feedback: $cost_savings_feedback
          new_opportunity_identification_feedback: $new_opportunity_identification_feedback
          other_feedback: $other_feedback
          other_text_feedback: $other_text_feedback
        ) {
          id
          review_target
          match_experience_rating
          uselfulness_rating
          communication_rating
          connection_quality_rating
          review
          target_feedback
          onfrontiers_feedback
          expert_usefulness_rating
          time_savings_feedback
          improved_bid_confidence_feedback
          partner_identification_feedback
          cost_savings_feedback
          new_opportunity_identification_feedback
          other_feedback
          other_text_feedback
        }
      }
    `,
      values
    );

    const review = result.reviewConsultation;

    if (review) {
      const reviewPropName = review.review_target === 'client' ? 'client_review' : 'expert_review';

      dispatch({
        type: CONSULTATION__DELETE,
        collection: 'unreviewed',
        id: values.consultation_id,
      });

      dispatch({
        type: CONSULTATION__UPDATE,
        consultation: {
          id: values.consultation_id,
          [reviewPropName]: review,
        },
      });

      return review;
    }
  };
}

export function updateConsultationReview(values: any): AppThunk<Promise<any>> {
  return async (dispatch, _, { graphql }) => {
    const result = await graphql.mutate(
      `
      (
        $id: String!
        $review: String!
      ) {
        updateConsultationReview(
          id: $id
          review: $review
        ) {
          id
          consultation_id
          review_target
          match_experience_rating
          uselfulness_rating
          communication_rating
          connection_quality_rating
          review
          target_feedback
          onfrontiers_feedback
          expert_usefulness_rating
          time_savings_feedback
          improved_bid_confidence_feedback
          partner_identification_feedback
          cost_savings_feedback
          new_opportunity_identification_feedback
          other_feedback
          other_text_feedback
        }
      }
    `,
      values
    );

    const review = result.updateConsultationReview;

    if (review) {
      const reviewPropName = review.review_target === 'client' ? 'client_review' : 'expert_review';

      dispatch({
        type: CONSULTATION__UPDATE,
        consultation: {
          id: review.consultation_id,
          [reviewPropName]: review,
        },
      });
    }
  };
}

export function clearCollection(collection: any): AppThunk<Promise<any>> {
  return async (dispatch) => {
    // Reset the specified collection to the initial state
    dispatch({
      type: CONSULTATION__RESET_COLLECTION,
      collection,
    });
  };
}

const fetchConsultationQuery = gql`
  query getConsultation($id: String!) {
    consultation(id: $id) {
      id
      html_url
      type
      description
      starts_at
      proposed_times
      rejected_times
      expected_duration
      initial_expected_duration
      billing_duration
      started_at
      ended_at
      created_at
      credit_rate
      credit_amount
      disclosure
      summary
      costs {
        id
        state
        charge_type
        credits {
          cents
          currency
        }
      }
      bill_rate
      expert_payout
      estimated_expert_payout
      completion_adds_expert_to_network
      engagement_type
      state
      archived
      expert_identifier
      requester_identifier
      cancel_reason
      external
      recording_url
      recording_duration
      original_recording_url
      tracking_code
      conference {
        id
        carrier
        phone_numbers {
          display
          number
          country_code
        }
        duration
        registrants {
          email
          name
          identifier
          invited_by
        }
        attachments {
          file_type
          location
          recording_type
        }
        recording_state
      }
      requester {
        id
        username
        first_name
        last_name
        name
        html_url
        picture_url
        timezone
        phone
      }
      requester_timezone
      requester_name
      expert {
        id
        username
        first_name
        last_name
        name
        html_url
        picture_url
        timezone
        phone
        expert_state
        compliance_completed_at
        bill_rate
      }
      expert_timezone
      expert_name
      expert_request {
        id
        er_type
        html_url
        name
        description
        group_about
        phone
        focus_areas
        qualifications {
          id
          query
          response_type
          required
        }
        questions {
          id
          query
          response_type
          required
        }
        attachments {
          id
          created_at
          author {
            name
            html_url
          }
          expert_request_id
          consultation_id
          name
          description
          file_url
        }
        companies_pursue
        companies_avoid
        disclosure
        regions {
          id
          name
        }
        sectors {
          id
          name
        }
        project {
          name
          html_url
          tracking_code
          group {
            id
            name
            billing_account {
              id
            }
          }
        }
        unpaid
        group_about
        instructions_research
        job_scope
        opportunity_location
        phone
        tags
        expected_duration
      }
      group {
        id
        name
        account_type
      }
      client_review {
        id
        match_experience_rating
        communication_rating
        connection_quality_rating
        review
        target_feedback
        onfrontiers_feedback
      }
      expert_review {
        id
        uselfulness_rating
        communication_rating
        connection_quality_rating
        review
        target_feedback
        onfrontiers_feedback
        expert_usefulness_rating
        time_savings_feedback
        improved_bid_confidence_feedback
        partner_identification_feedback
        cost_savings_feedback
        new_opportunity_identification_feedback
        other_feedback
        other_text_feedback
      }
      event {
        description
        summary
        location
        start
        end
      }
      transcription_order {
        state
      }
      transcription_price {
        cents
        currency
      }
      transcription {
        speakers {
          id
          name
          user {
            id
            first_name
            last_name
            picture_url
            html_url
          }
        }
        monologues {
          speaker {
            id
            name
            user {
              id
              first_name
              last_name
              picture_url
              html_url
            }
          }
          elements {
            type
            value
            position
          }
        }
        summary
      }
      permissions
      attachments {
        id
        created_at
        author {
          name
          html_url
        }
        expert_request_id
        consultation_id
        name
        description
        file_url
      }
    }
  }
`;

export function fetchConsultation(id: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const result = await graphql.send(fetchConsultationQuery, { id });

    const { consultation } = result;

    if (consultation) {
      dispatch({
        type: CONSULTATION__UPDATE,
        collection: 'default',
        consultation,
      });
      return consultation;
    }
  };
}

export function fetchMetrics(id: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const result = await graphql.query(
      `query getConsultation ($id: String!) {
      consultation(id: $id) {
        id
        conference {
          id
          carrier
          participants {
            id
            identifier
            call
            metrics
          }
          attachments {
            file_type
            location
            recording_type
          }
          registrants {
            email
            name
            identifier
            invited_by
          }
        }
      }
    }`,
      { id }
    );

    const { consultation } = result;

    if (consultation) {
      dispatch({
        type: CONSULTATION__UPDATE,
        collection: 'default',
        consultation,
      });
      return consultation;
    }
  };
}

const consultationsFields = `
  pageInfo {
    hasNextPage
  }
  edges {
    cursor
    node {
      id
      html_url
      external
      state
      starts_at
      billing_duration
      recording_duration
      archived
      engagement_type
      permissions
      requester {
        id
        username
        name
        first_name
        last_name
        html_url
        picture_url
      }
      requester_name
      expert {
        id
        username
        name
        first_name
        last_name
        html_url
        picture_url
      }
      expert_name
      expert_request {
        id
        name
      }
    }
  }
`;

export function setListLoading(collection: any): UnknownAction {
  return { type: CONSULTATION__LIST_LOADING, collection };
}

function fetchConsultations(
  states: any,
  collection: CollectionType,
  cursor: any,
  pageSize = 10,
  all = true,
  sort = 'created_at',
  sortOrder = 'desc'
): AppThunk<Promise<any>> {
  return async (dispatch, getState, { graphql }) => {
    const reset = !cursor;
    const consultations = getState().consultations[collection];

    if (reset && !shouldResetCollection(consultations, pageSize)) return consultations;

    dispatch(setListLoading(collection));

    try {
      const result = await graphql.query(
        `query fetchConsultations(
        $cursor: String
        $all: Boolean
        $states: [ConsultationState]
        $pageSize: Int!
        $userContext: String!
        $sort: String
        $sortOrder: String
      ) {
        consultations(
          after: $cursor
          all: $all
          states: $states
          first: $pageSize
          user_context: $userContext
          sort: $sort
          sort_order: $sortOrder
        ) {
          ${consultationsFields}
        }
      }`,
        {
          states,
          cursor,
          pageSize,
          all,
          sort,
          sortOrder,
          userContext: getState().ui.userContext,
        }
      );

      const page = result.consultations;

      dispatch({
        type: CONSULTATION__BATCH_ADD,
        collection,
        reset,
        ...page,
      });

      return page;
    } finally {
      dispatch({ type: CONSULTATION__LIST_LOADED, collection });
    }
  };
}

const startingConsultationsQuery = gql`
  query fetchConsultations($userContext: String!) {
    startingConsultations(user_context: $userContext) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          html_url
          starts_at
          requester {
            id
            name
            first_name
            last_name
            html_url
            picture_url
          }
          requester_name
          expert {
            id
            name
            first_name
            last_name
            html_url
            picture_url
          }
          expert_name
        }
      }
    }
  }
`;

export function fetchStarting(): AppThunk<Promise<any>> {
  return async (dispatch, getState, { graphql }) => {
    const collection = 'starting';

    const consultations = getState().consultations[collection];
    const userContext = getState().ui.userContext;

    // @ts-ignore
    if (!shouldResetCollection(consultations, 1000, 1)) return consultations;

    dispatch({ type: CONSULTATION__LIST_LOADING, collection });

    try {
      const result = await graphql.send(startingConsultationsQuery, {
        userContext,
      });

      const page = result.startingConsultations;
      dispatch({
        type: CONSULTATION__BATCH_ADD,
        collection,
        reset: true,
        ...page,
      });

      return page;
    } finally {
      dispatch({ type: CONSULTATION__LIST_LOADED, collection });
    }
  };
}

export function fetchUnreviewed(): AppThunk<Promise<any>> {
  return async (dispatch, getState, { graphql }) => {
    const collection = 'unreviewed';

    const consultations = getState().consultations[collection];

    if (!shouldResetCollection(consultations, 1000)) return consultations;

    dispatch({ type: CONSULTATION__LIST_LOADING, collection });

    try {
      const result = await graphql.query(
        `query fetchConsultations {
        unreviewedConsultations {
          pageInfo {
            hasNextPage
          }
          edges {
            cursor
            node {
              id
              html_url
              external
              state
              starts_at
              proposed_times
              requester {
                id
                name
                first_name
                last_name
              }
              expert {
                id
                name
                first_name
                last_name
              }
            }
          }
        }
      }`,
        { userContext: getState().ui.userContext }
      );

      const page = result.unreviewedConsultations;

      dispatch({
        type: CONSULTATION__BATCH_ADD,
        collection,
        reset: true,
        ...page,
      });

      return page;
    } finally {
      dispatch({ type: CONSULTATION__LIST_LOADED, collection });
    }
  };
}

export function fetchAwaiting(
  cursor: any,
  pageSize = 10,
  all = true,
  collection: CollectionType = 'awaiting'
): AppThunk<Promise<any>> {
  const states = ['negotiating_expert_time', 'negotiating_client_time'];
  return fetchConsultations(states, collection, cursor, pageSize, all, 'created_at');
}

export function fetchCanceled(
  cursor: any,
  pageSize = 10,
  all = true,
  collection: CollectionType = 'canceled'
): AppThunk<Promise<any>> {
  const states = ['canceled', 'denied', 'client_rejected', 'expired', 'incomplete'];
  return fetchConsultations(states, collection, cursor, pageSize, all, 'canceled_at');
}

export function fetchConfirmed(
  cursor: any,
  pageSize = 10,
  all = true,
  collection: CollectionType = 'confirmed'
): AppThunk<Promise<any>> {
  const states = [
    'confirmed',
    'awaiting_join',
    'awaiting_client_join',
    'awaiting_expert_join',
    'awaiting_expert_review',
    'awaiting_client_accept',
    'in_progress',
    'finalizing',
  ];
  return fetchConsultations(states, collection, cursor, pageSize, all, 'starts_at', 'asc');
}

export function fetchCompleted(
  cursor: any,
  pageSize = 10,
  all = true,
  collection: any
): AppThunk<Promise<any>> {
  const states = ['completed'];
  return fetchConsultations(states, collection || 'completed', cursor, pageSize, all, 'ended_at');
}

export function fetchPredictTransactions(
  expertId: any,
  expertRequestId: any,
  groupId: any,
  engagementType: any,
  expectedDuration: any,
  billRate: any,
  creditRate: any
): AppThunk<Promise<any>> {
  return async (_dispatch, _getState, { graphql }) => {
    const result = await graphql.query(
      `query (
      $expertId: String!
      $expertRequestId: String
      $groupId: String
      $engagementType: EngagementType!
      $expectedDuration: Duration
      $billRate: Int
      $creditRate: Int
    ) {
      predictTransactions(
        expert_id: $expertId
        expert_request_id: $expertRequestId
        group_id: $groupId
        engagement_type: $engagementType
        expected_duration: $expectedDuration
        bill_rate: $billRate
        credit_rate: $creditRate
      ) {
          booking_fee { cents currency }
          client_expert_fee { cents currency }
          expert_earnings { cents currency }
      }
    }`,
      {
        expertId,
        expertRequestId,
        groupId,
        engagementType,
        expectedDuration,
        billRate,
        creditRate,
      }
    );

    return result.predictTransactions;
  };
}

export function dismissConsultationReview(id: any, permanent: any): UnknownAction {
  return {
    type: CONSULTATION__DISMISS_REVIEW,
    id,
    permanent,
  };
}

export function dialOutExpert(consultationId: any): AppThunk<Promise<any>> {
  return (_dispatch, _getState, { graphql }) =>
    graphql.mutate(
      `
    ($consultationId: String!) {
      dialOutExpert(consultation_id: $consultationId)
    }
  `,
      { consultationId }
    );
}

export function dialOutMe({ consultationId, phoneNumber }: any): AppThunk<Promise<any>> {
  return (_dispatch, _getState, { graphql }) =>
    graphql.mutate(
      `
    (
      $consultationId: String!
      $phoneNumber: String!
    ) {
      dialOutMe(
        consultation_id: $consultationId
        phone_number: $phoneNumber
      )
    }
  `,
      { consultationId, phoneNumber }
    );
}

export function changeCarrier({ consultation, carrier }: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const consultationId = consultation.id;
    const result = await graphql.mutate(
      `
      (
        $consultationId: String!
        $carrier: CarrierType!
      ) {
        updateConferenceCarrier(
          consultation_id: $consultationId
          carrier: $carrier
        ) {
          id
          carrier
        }
      }
      `,
      { consultationId, carrier }
    );

    const { updateConferenceCarrier: conference } = result;

    dispatch({
      type: CONSULTATION__UPDATE,
      consultation: { ...consultation, conference },
    });
  };
}

export function fetchAttachmentContent(attachment: any): AppThunk<Promise<any>> {
  return async (_dispatch, _getState) => {
    const resp = await fetch(attachment.location);
    const result = await resp.text();
    return result;
  };
}

export function addAttachments({ consultation, attachments }: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const consultationId = consultation.id;
    const result = await graphql.mutate(
      `
      (
        $consultationId: String!
        $attachments: [EngagementAttachmentInput]!
      ) {
        consultationAddAttachments(
          consultation_id: $consultationId
          attachments: $attachments
        ) {
          id
          created_at
          author {
            name
            html_url
          }
          expert_request_id
          consultation_id
          name
          description
          file_url
        }
      }
      `,
      { consultationId, attachments }
    );

    const addedAttachments = result?.consultationAddAttachments;

    if (addedAttachments && addedAttachments.length > 0) {
      const existingAttachments = consultation.attachments || [];
      dispatch({
        type: CONSULTATION__UPDATE,
        consultation: {
          ...consultation,
          attachments: existingAttachments.concat(addedAttachments),
        },
      });
    }
  };
}
