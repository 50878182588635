import moment from 'moment-timezone';

import { AppThunk } from '@/store';

import ActionTypes from './ActionTypes';

const { DOMAIN__UPDATE, GROUP__UPDATE } = ActionTypes;

export function fetchDomain(subdomain: any, { forceReload }: any = {}): AppThunk<Promise<any>> {
  return async (dispatch, getState, { graphql }) => {
    // @ts-ignore
    const cachedDomain = getState().domains[subdomain];
    if (!forceReload && cachedDomain && moment().isBefore(cachedDomain.expiresAt)) {
      return cachedDomain.node; // may be null
    }

    const result = await graphql.query(
      `query getDomain($subdomain: String!) {
      domain(subdomain: $subdomain) {
        subdomain
        root_subdomain_redirect
        logo_url
        signup_enabled
        email_domain
        expert_signup_about
        expert_signup_about_mobile
        network_join_title
        member_signup_about
        member_signup_about_mobile
        group {
          id
          name
          about
          enforce_2fa
          about_page {
            html_url
          }
        }
        agreements(active_only: true) {
          policy_code
          policy_label
          policy_url
        }
        signup_prompt_hourly_rate
        signup_prompt_marketplace
        signup_prompt_profile_publicity
      }
    }`,
      { subdomain }
    );
    const { domain } = result;
    dispatch({ type: DOMAIN__UPDATE, subdomain, domain });
    return domain;
  };
}

export function fetchSignupDomain(subdomain: any, opts?: any): AppThunk<Promise<any>> {
  return async (dispatch) => {
    if (!subdomain) return null;
    const domain = await dispatch(fetchDomain(subdomain, opts));
    return domain && domain.signup_enabled ? domain : null;
  };
}

export function updateGroupDomain(domain: any, groupId: any): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const { updateGroupDomain } = await graphql.mutate(
      `(
      $group_id: String!
      $logo_url: String
      $signup_enabled: Boolean
      $subdomain: String
      $root_subdomain_redirect: String
      $member_signup_about: String
      $member_signup_about_mobile: String
      $expert_signup_about: String
      $expert_signup_about_mobile: String
      $network_join_title: String
      $signup_prompt_hourly_rate: Boolean
      $signup_prompt_marketplace: Boolean
      $signup_prompt_profile_publicity: Boolean
      $agreements: [GroupDomainSignupAgreementInput]
    ) {
      updateGroupDomain(
        group_id: $group_id
        logo_url: $logo_url
        signup_enabled: $signup_enabled
        subdomain: $subdomain
        root_subdomain_redirect: $root_subdomain_redirect
        member_signup_about: $member_signup_about
        member_signup_about_mobile: $member_signup_about_mobile
        expert_signup_about: $expert_signup_about
        expert_signup_about_mobile: $expert_signup_about_mobile
        network_join_title: $network_join_title
        signup_prompt_hourly_rate: $signup_prompt_hourly_rate
        signup_prompt_marketplace: $signup_prompt_marketplace
        signup_prompt_profile_publicity: $signup_prompt_profile_publicity
        agreements: $agreements
      ) {
        subdomain
        root_subdomain_redirect
        logo_url
        signup_enabled
        expert_signup_about
        expert_signup_about_mobile
        network_join_title
        member_signup_about
        member_signup_about_mobile
        signup_prompt_hourly_rate
        signup_prompt_marketplace
        signup_prompt_profile_publicity
        agreements(active_only: false) {
          id
          active
          policy_code
          policy_label
          policy_url
        }
      }
    }`,
      domain
    );

    if (updateGroupDomain && groupId) {
      dispatch({
        type: GROUP__UPDATE,
        group: { id: groupId, domain: updateGroupDomain },
      });
    }

    return updateGroupDomain;
  };
}
