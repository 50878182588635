import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { fetchAllGroups } from '@/actions/group';
import { Group, Viewer } from '@/core/viewer';
import { RootState } from '@/store';

import CustomSelect from '../Select';

interface SelectGroupProps {
  fetchAllGroups: (opts?: any) => Promise<any>;
  groups?: {
    edges: { node: Group }[];
  };
  autoComplete?: boolean;
  includeEmpty?: boolean;
  autoFocus?: boolean;
  input?: object;
  limit?: number;
  [key: string]: any;
}

class SelectGroup extends PureComponent<SelectGroupProps> {
  fetchGroups = () => {
    const { fetchAllGroups, groups } = this.props;

    if (groups?.edges.length === 0) {
      fetchAllGroups();
    }
  };

  render() {
    const {
      autoComplete,
      groups,
      fetchAllGroups,
      includeEmpty = true,
      autoFocus,
      input = {},
      limit = 5,
      ...other
    } = this.props;

    return (
      <CustomSelect
        placeholder="Search team..."
        {...input}
        {...other}
        // @ts-expect-error
        limit={limit}
        TextFieldProps={{ autoFocus }}
        rawValueOnChange
        id="autoCompleteTeam"
        clearOnBlur
        disableClearable
        autocomplete
        options={groups?.edges.map(({ node: g }) => ({
          value: g.id,
          label: g.name,
        }))}
        onFocus={this.fetchGroups}
      />
    );
  }
}

// @ts-ignore
SelectGroup.fetch = fetchAllGroups;

export default connect(
  (state: RootState, ownProps: { groups?: any }) => ({
    viewer: state.viewer,
    groups: ownProps.groups || state.groups.all,
  }),
  {
    fetchAllGroups,
  }
)(SelectGroup);
