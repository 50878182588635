import { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { teal500 } from '@/theme/colors';

// @ts-expect-error TS(2339): Property 'progress' does not exist on type '{}'.
const PageLoadingBar = memo(({ progress }) => {
  // TODO: better way to set this
  const initial = progress === 10;

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    let timer: any;
    if (progress === 100) {
      // Set a timeout to hide the component after 1 second
      timer = setTimeout(() => setIsVisible(false), 1000);
    } else {
      setIsVisible(true);
    }

    // Clear the timeout if the component unmounts or if the value changes
    return () => clearTimeout(timer);
  }, [progress]); // This effect runs when the value prop changes

  if (!isVisible) {
    return null; // Render nothing if not visible
  }

  const barStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: `${progress}%`,
    height: '4px',
    backgroundColor: teal500,
  };

  if (!initial) {
    // @ts-expect-error TS(2339): Property 'transition' does not exist on type '{ po... Remove this comment to see the full error message
    barStyle.transition = 'width 0.5s ease-in-out';
  }

  // @ts-expect-error TS(2322): Type '{ position: string; top: number; left: numbe... Remove this comment to see the full error message
  return <div style={barStyle} />;
});

// @ts-expect-error TS(2571): Object is of type 'unknown'.
export default connect((state: RootState) => ({ progress: state.loading }))(PageLoadingBar);
