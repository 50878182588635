import { LinearProgress, Slider } from '@mui/material';
import React from 'react';
import ReactPlayer from 'react-player';

import AudioPlayer from '../AudioPlayer';
import CircularProgress from '../CircularProgress';
import FAIcon from '../Icon/FAIcon';
import IconButton from '../IconButton';
import s from './MediaPlayer.module.scss';

class MediaPlayer extends React.PureComponent {
  player: any;
  state = {
    ready: false,
    playing: false,
    muted: false,
    seeking: false,
    currentTime: 0,
    totalTime: 0,
    loaded: 0,
  };

  handlePlayPause = () => {
    this.setState((prevState) => ({
      // @ts-expect-error TS(2339): Property 'playing' does not exist on type 'Readonl... Remove this comment to see the full error message
      playing: !prevState.playing,
    }));
  };

  handlePause = () => {
    this.setState({ playing: false });
  };

  handleProgress = (state: any) => {
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState({
        currentTime: state.playedSeconds,
        loaded: state.loaded,
      });
    }
  };

  handleDuration = (totalTime: any) => {
    this.setState({ totalTime });
  };

  handleSeekMouseDown = () => {
    this.setState({ seeking: true });
  };

  handleSeekChange = (_: any, value: any) => {
    this.player.seekTo(value);
    this.setState({ currentTime: value });
  };

  handleSeekMouseUp = () => {
    this.setState({ seeking: false });
  };

  handleToggleMuted = () => {
    this.setState((prevState) => ({
      // @ts-expect-error TS(2339): Property 'muted' does not exist on type 'Readonly<... Remove this comment to see the full error message
      muted: !prevState.muted,
    }));
  };

  handleOnReady = () => {
    this.setState({ ready: true });
  };

  registerRef = (player: any) => {
    this.player = player;
  };

  render() {
    // @ts-expect-error TS(2339): Property 'src' does not exist on type 'Readonly<{}... Remove this comment to see the full error message
    const { src, enabled, isAudioOnly } = this.props;
    const { playing, muted, currentTime, totalTime, ready, loaded } = this.state;
    const validTime = isTimeValid(totalTime);
    if (!enabled) this.handlePause();

    if (isAudioOnly) {
      // @ts-expect-error TS(2769): No overload matches this call.
      return <AudioPlayer recordingUrl={src} />;
    }

    return (
      <div className={s.root}>
        <ReactPlayer
          ref={this.registerRef}
          width="100%"
          height="100%"
          playing={playing}
          muted={muted}
          onPause={this.handlePause}
          onProgress={this.handleProgress}
          onDuration={this.handleDuration}
          onReady={this.handleOnReady}
          url={src}
          config={{
            file: {
              // @ts-expect-error TS(2769): No overload matches this call.
              tracks: [{ kind: 'captions', default: true }],
              attributes: {
                onContextMenu: (e: any) => e.preventDefault(),
                preload: 'metadata',
              },
            },
          }}
        />
        <div style={{ position: 'relative', width: '100%', height: 0 }}>
          <Slider
            style={{
              position: 'relative',
              top: isAudioOnly ? -15 : -20,
              cursor: 'pointer',
            }}
            min={0}
            max={validTime ? totalTime : 100}
            value={validTime ? currentTime : 0}
            disabled={!validTime}
            onChange={this.handleSeekChange}
            onMouseDown={this.handleSeekMouseDown}
            onChangeCommitted={this.handleSeekMouseUp}
            classes={{
              thumb: s.thumb,
              track: s.track,
            }}
          />
          <LinearProgress
            style={{
              position: 'absolute',
              width: '100%',
              height: 2,
              zIndex: 1,
              top: isAudioOnly ? -2 : -7,
            }}
            color="secondary"
            variant="determinate"
            value={loaded * 100}
          />
        </div>
        <div className={s.controlContainer}>
          <div className={s.leftControls}>
            <div style={{ width: 50, display: 'inline-block' }}> {formatTime(currentTime)} </div>/
            <div style={{ marginLeft: 5, display: 'inline-block' }}> {formatTime(totalTime)} </div>
          </div>
          <div className={s.centerControls}>
            {ready ? (
              <IconButton onClick={this.handlePlayPause} disabled={!enabled}>
                <FAIcon icon={playing ? 'pause' : 'play'} size={28} className={s.controlButton} />
              </IconButton>
            ) : (
              <div>
                <CircularProgress />
              </div>
            )}
          </div>
          <div className={s.rightControls}>
            <IconButton
              onClick={this.handleToggleMuted}
              style={{ width: 21, textAlign: 'left' }}
              disabled={!enabled}
            >
              <FAIcon
                icon={muted ? 'volume-off' : 'volume-up'}
                size={28}
                className={s.controlButton}
              />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }
}

function formatTime(timeInSeconds: any) {
  if (!isTimeValid(timeInSeconds)) return '--:--';

  let mins = Math.floor(timeInSeconds / 60);
  // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'number'.
  if (mins < 10) mins = `0${mins}`;
  let secs = Math.floor(timeInSeconds % 60);
  // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'number'.
  if (secs < 10) secs = `0${secs}`;
  return `${mins}:${secs}`;
}

function isTimeValid(time: any) {
  if (time === 0) return true;
  if (!time) return false;
  if (Number.isNaN(time)) return false;
  if (time === Infinity) return false;
  return true;
}

export default MediaPlayer;
