import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import { InjectedFormProps } from 'redux-form';

import { inviteUser } from '@/actions/invitation';
import { addProjectMember, removeProjectMember, updateProjectMember } from '@/actions/project';
import { notify } from '@/actions/ui';
import { ErrMemberAlreadyAdded } from '@/core/project';
import { RootState } from '@/store';
import { removeAttribute } from '@/utils/reducer';

import Button from '../Button';
import Form from '../Form';
import SelectUser from '../SelectUser';
import s from './Form.module.scss';
import ProjectMember from './ProjectMember';

interface Member {
  id: string;
  user?: {
    name: string;
    id: string;
    emails?: string[];
  };
  email?: string;
  role?: string;
  state?: string;
  new?: boolean;
}

interface EditMembersFormProps {
  resetLabel?: string;
  submitLabel?: string;
  searchLabel?: string;
  searchHint?: string;
  buttonSize?: string;
  onSubmit: (values: any) => void;
  onReset: (c?: boolean | undefined) => void;
  project: any;
  initialValues: { members: Member[] };
  usersLocked?: string[];
  members?: Member[];
  // Add other props here as needed
}

interface FormData {
  members: Member[];
}

class EditMembersForm extends PureComponent<
  EditMembersFormProps & InjectedFormProps<FormData, EditMembersFormProps>
> {
  suggestions: any;
  viewerRole = () => {
    // @ts-expect-error TS(2339): Property 'viewer' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { viewer, expertRequest } = this.props;
    if (viewer.admin) return 'super-user';

    const members = expertRequest ? expertRequest.members : [];
    const member = members.find((x: any) => x.user && x.user.id === viewer.id);
    return member && member.role;
  };

  handleSubmit = async (values: FormData) => {
    const {
      initialValues,
      onSubmit,
      project,
      // @ts-expect-error TS(2339): Property 'inviteUser' does not exist on type 'Read... Remove this comment to see the full error message
      inviteUser,
      // @ts-expect-error TS(2339): Property 'addProjectMember' does not exist on type... Remove this comment to see the full error message
      addProjectMember,
      // @ts-expect-error TS(2339): Property 'removeProjectMember' does not exist on t... Remove this comment to see the full error message
      removeProjectMember,
      // @ts-expect-error TS(2339): Property 'updateProjectMember' does not exist on t... Remove this comment to see the full error message
      updateProjectMember,
      // @ts-expect-error TS(2339): Property 'notify' does not exist on type 'Readonly... Remove this comment to see the full error message
      notify,
    } = this.props;
    const { members } = values;
    const initialMembers = initialValues.members;

    this.suggestions = null;

    const toAdd = members
      .filter((m: any) => m.new && m.user)
      .map((m: any) => removeAttribute(m, 'new'));
    const toInvite = members
      .filter((m: any) => m.new && m.email)
      .map((m: any) => removeAttribute(m, 'new'));
    const toDelete = initialMembers.filter((m: any) => !members.some((m2: any) => m.id === m2.id));
    const toUpdate = members
      .filter((m: any) => !m.new)
      .filter((m: any) => {
        const original = initialMembers.find((o: any) => o.id === m.id);
        return original && (m.role !== original.role || m.state !== original.state);
      });

    try {
      await Promise.all([
        ...toInvite.map((m: any) =>
          inviteUser({
            collectionType: 'project',
            collectionId: project.id,
            email: m.email,
            role: m.role,
          })
        ),
        ...toAdd.map((m: any) =>
          addProjectMember(project.id, {
            user: m.user,
            role: m.role,
          })
        ),
        ...toDelete.map((m: any) => removeProjectMember(project.id, m)),
        ...toUpdate.map((m: any) => updateProjectMember(project.id, m)),
      ]);

      if (onSubmit) {
        const projectAccessible = !!this.viewerRole();
        onSubmit({ toUpdate, toAdd, toDelete, projectAccessible });
      }
    } catch (err) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      switch (err.message) {
        case ErrMemberAlreadyAdded.message:
          {
            const member = members.find(
              (o: any) =>
                // @ts-expect-error TS(2571): Object is of type 'unknown'.
                o.user && o.user.id === err.rawData.addProjectMember.UserID
            );
            notify(`Error adding ${member?.user?.name}, member already added.`, 'error');
          }
          break;
        default:
          notify('Error when saving project members.', 'error');
          throw err;
      }
    }
  };

  handleReset = () => {
    const { reset, onReset } = this.props;
    reset();

    this.suggestions = null;

    if (onReset) {
      onReset();
    }
  };

  handleAddMember = ({ type, value }: any) => {
    const { array } = this.props;

    const member = {
      role: 'member',
      new: true,
    };

    if (type === 'email') {
      // @ts-expect-error TS(2339): Property 'id' does not exist on type '{ role: stri... Remove this comment to see the full error message
      member.id = `temp-${value.email}`;
      // @ts-expect-error TS(2339): Property 'email' does not exist on type '{ role: s... Remove this comment to see the full error message
      member.email = value.email;
    } else {
      // @ts-expect-error TS(2339): Property 'id' does not exist on type '{ role: stri... Remove this comment to see the full error message
      member.id = `temp-${value.id}`;
      // @ts-expect-error TS(2339): Property 'user' does not exist on type '{ role: st... Remove this comment to see the full error message
      member.user = value;
    }

    array.push('members', member);
  };

  handleRemoveMember = (groupId: any, memberId: any) => {
    const { array, members = [] } = this.props;
    array.remove(
      'members',
      members.findIndex((m: any) => m.id === memberId)
    );
  };

  handleUpdateMember = (groupId: any, memberId: any, { role, state }: any) => {
    const { array, members = [] } = this.props;

    const member = members.find((m: any) => m.id === memberId);
    const update = { ...member, role, state };

    const index = members.findIndex((m: any) => m.id === memberId);
    array.splice('members', index, 1, update);
  };

  userFilter = (u: any) => {
    const { members = [] } = this.props;
    return !members.some((m: any) => {
      if (m.user && u.type === 'user') {
        return m.user.id === u.value.id;
      }

      if (u.type === 'email') {
        if (m.email) {
          return u.value.email === m.email;
        }
        if (m.user) {
          const emails = m.user.emails || [];
          if (emails.includes(u.value.email)) {
            return true;
          }
        }
      }
      return false;
    });
  };

  render() {
    const {
      handleSubmit,
      members: membersProp = [],
      initialValues = { members: [] },
      project,
      usersLocked = [],
    } = this.props;

    const members = membersProp || initialValues.members;
    const viewerRole = this.viewerRole();
    const permissions = (m: any) =>
      m.user && Array.isArray(usersLocked) && usersLocked.includes(m.user.id)
        ? []
        : project.permissions;

    const buttonSize = (this.props.buttonSize || 'huge') as
      | 'tiny'
      | 'normal'
      | 'large'
      | 'huge'
      | 'small'
      | 'medium'
      | 'large';
    return (
      <div className={s.root}>
        <Form
          onSubmit={handleSubmit(this.handleSubmit)}
          onReset={this.props.onReset}
          className={s.form}
        >
          <div style={{ marginTop: -14 }}>
            <SelectUser
              // @ts-expect-error TS(2769): No overload matches this call.
              allowEmail
              listStyle={{ width: 408 }}
              style={{ marginBottom: 20 }}
              label={this.props.searchLabel || ''}
              placeholder={this.props.searchHint || 'Enter email addresses to invite members'}
              noOptionsText="No members match current entry"
              userFilter={this.userFilter}
              onChange={this.handleAddMember}
              value={null}
            />
          </div>
          <div className={s.expertRequestMembers}>
            {members.map((m: any) => (
              <ProjectMember
                key={m.id || `new-member-${m.email || m.user.id}`}
                // @ts-expect-error TS(2769): No overload matches this call.
                member={m}
                projectId={project.id}
                permissions={permissions(m)}
                viewerRole={viewerRole}
                removeMember={this.handleRemoveMember}
                updateMember={this.handleUpdateMember}
              />
            ))}
          </div>
          <div className={s.actions}>
            <Button type="reset" variant="text" size={buttonSize}>
              {this.props.resetLabel || 'Skip for Now'}
            </Button>
            <Button color="teal" type="submit" size={buttonSize}>
              {this.props.submitLabel || 'Invite Team Members'}
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

const WrappedForm = reduxForm<FormData, EditMembersFormProps>({
  form: 'editProjectMembers',
  validate: () => ({}),
  enableReinitialize: true,
})(EditMembersForm);

const selector = formValueSelector('editProjectMembers');

export default connect(
  (state: RootState, ownProps: any) => {
    const members = selector(state, 'members');

    return {
      viewer: state.viewer,
      initialValues: ownProps.initialValues,
      project: ownProps.project,
      onSubmit: ownProps.onSubmit,
      onReset: ownProps.onReset,
      members: members?.length ? members : ownProps.initialValues.members,
    };
  },
  {
    inviteUser,
    addProjectMember,
    removeProjectMember,
    updateProjectMember,
    notify,
  }
)(WrappedForm);
