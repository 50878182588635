import { Chip } from '@mui/material';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { highlight } from '@/utils';

import ChipList from '../ChipList';
import Link from '../Link';

interface RegionListProps {
  regions: string[];
  allRegions?: { id: string; name: string }[];
  lookup?: boolean;
  icon?: string;
  [key: string]: any;
}

class RegionList extends PureComponent<RegionListProps> {
  render() {
    const { regions, children, allRegions, lookup, icon = 'globe', ...other } = this.props;

    return (
      <ChipList {...other} icon={icon}>
        {regions.map((key: any) => {
          let name = key;
          if (lookup) {
            const region = allRegions?.find((r: any) => r.id === key);
            if (region) name = region.name;
          }

          return (
            <Link key={key} href={`/search?q=${encodeURIComponent(name)}`}>
              <Chip
                style={{
                  cursor: 'pointer',
                  width: 'initial',
                  // @ts-expect-error TS(2769): No overload matches this call.
                  label: { overflow: 'hidden', textOverflow: 'ellipsis' },
                }}
                label={
                  // @ts-expect-error TS(2322): Type 'string | HTMLElement | DocumentFragment' is ... Remove this comment to see the full error message
                  <span dangerouslySetInnerHTML={{ __html: highlight(name) }} />
                }
              />
            </Link>
          );
        })}
      </ChipList>
    );
  }
}

// @ts-expect-error TS(2629): Cannot assign to 'RegionList' because it is a clas... Remove this comment to see the full error message
RegionList = connect((state: RootState) => ({
  allRegions: state.countries,
}))(RegionList);

export default RegionList;
