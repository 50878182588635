import MaterialButton from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import arrayMutators from 'final-form-arrays';
import pick from 'lodash.pick';
import React, { FC, PureComponent, useCallback, useEffect, useRef, useState } from 'react';
import { Form } from 'react-final-form';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';

import { setAddress } from '@/actions/address';
import { invalidateFetchedProjectsCache, updateProjectMember } from '@/actions/project';
import { notify } from '@/actions/ui';
import ActivityLog from '@/components/ActivityLog';
import Button from '@/components/Button';
import CircularProgress from '@/components/CircularProgress';
import CloseExpertRequestDialog from '@/components/CloseExpertRequestDialog';
import ConsultationList from '@/components/ConsultationList';
import ConsultationsStarting from '@/components/ConsultationsStarting';
import CountBox from '@/components/CountBox';
import DeleteExpertRequestDialog from '@/components/DeleteDialog/DeleteExpertRequestDialog';
import Divider from '@/components/Divider';
import EditIcon from '@/components/EditIcon';
import EmptyMessage from '@/components/EmptyMessage';
import FAIcon from '@/components/Icon/FAIcon';
import MaterialIcon from '@/components/Icon/MaterialIcon';
import IconMenu from '@/components/IconMenu/IconMenu';
import LayoutPage from '@/components/Layout/LayoutPage';
import Link from '@/components/Link';
import MediaQuery from '@/components/MediaQuery';
import MemberRequests from '@/components/MemberRequests/Project';
import { SendMessageDialog } from '@/components/MessageTemplate';
import ProjectBar from '@/components/ProjectBar';
import ShareableLink from '@/components/ShareableLink';
import { sortMembers } from '@/core/project';
import { Viewer } from '@/core/viewer';
import { Candidate, ExpertRequest } from '@/expertrequest';
import { buildRefUrl } from '@/expertrequest';
import Details from '@/expertrequest/components/ExpertRequestForm/DetailsNew';
import Name from '@/expertrequest/components/ExpertRequestForm/Name';
import Project from '@/expertrequest/components/ExpertRequestForm/Project';
import SubmitContainer from '@/expertrequest/components/ExpertRequestForm/SubmitContainer';
import ReferFriend from '@/expertrequest/components/ReferFriend';
import {
  removeExpertRequestCandidate,
  saveExpertRequest,
  updateExpertRequestCandidate,
  updateExpertRequestState,
} from '@/expertrequest/store';
import { useApp } from '@/hooks/useAppContext';
import SelectedProfilesBar from '@/search/components/SelectedProfilesBar';
import { black, darkBrown, darkGray, sand } from '@/theme/colors';
import { SCREEN_SM } from '@/theme/screens';
import { add, removeAt } from '@/utils/reducer';

import CandidateWidgetHorizontal from './CandidateWidgetHorizontal/CandidateWidgetHorizontal';
import EditExpertRequest from './EditExpertRequest';
import s from './ExpertRequest.module.scss';

const dialogs = {
  name: {
    component: Name,
    title: 'Edit Name',
  },
  project: {
    component: Project,
    title: 'Edit Associated Project',
    onSubmit: ({ store }: any) => {
      store.dispatch(invalidateFetchedProjectsCache());
    },
  },
};

function sortDates(a: any, b: any) {
  return new Date(a).getTime() - new Date(b).getTime();
}

class Promo extends PureComponent {
  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'title' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { title, children } = this.props;
    return (
      <div className={s.promo}>
        <div>
          <h3 className={s.promoTitle}>{title}</h3>
        </div>
        <div>{children}</div>
      </div>
    );
  }
}

const DETAILS_SHOW_FIELDS = [
  'er_type',
  'description',
  'qualifications',
  'questions',
  'companies',
  'disclosure',
  'sectors',
  'regions',
  'focusAreas',
  'group_about',
  'instructions_research',
  'job_scope',
  'opportunity_location',
  'attachments',
  'tags',
  'expected_duration',
  'time_done_scoping_call',
];

const titles = [
  'May the force be with you on this match.',
  'Good luck and godspeed, soldier.',
  'I feel the need... the need for speed.',
  'If you match them, they will come.',
  "Toto, I've a feeling we're not in Kansas anymore.",
  'Keep your friends close, but your experts closer.',
  'Go ahead, make my match.',
  'We love the smell of expert matches in the morning.',
  'Happy matching! And may the odds be ever in your favor.',
  "Life is like a box of matches. You never know who you're gonna get.",
  'To match, or not to match, that is the question.',
  'Match or match not. There is no try.',
  'Keep calm and match on.',
  "I'll be back... with a match.",
  "I'll make the expert an offer he can't refuse.",
  'Keep your friends close, but your experts closer.',
  'Not all those who wander are lost... some are just seeking the right expert.',
  'Matchmaker, matchmaker, make me a match, find me an expert, catch me a catch.',
];

function RMStartPromo({ viewer, expertRequest }: any) {
  const url = buildRefUrl(expertRequest, 'linkedin', viewer.id);
  const isAdmin = viewer.admin;

  const title = titles[parseInt(expertRequest.id) % titles.length];
  return (
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    <Promo title={title}>
      <div style={{ marginBottom: 10 }}>
        <a href="https://www.notion.so/Fulfillment-Team-Wiki-d09340631e5c42f98e086dba51a213c9">
          Fulfillment Team Wiki
        </a>
      </div>
      <div style={{ marginBottom: 24 }}>
        <ShareableLink title="LinkedIn referral URL" url={url} enableShorten={isAdmin} />
      </div>
      <Button
        href="https://www.linkedin.com/search/results/people/"
        startIcon="binoculars"
        color="lightTan"
        size="medium"
        style={{ color: black }}
      >
        Start Searching
      </Button>
    </Promo>
  );
}

// @ts-expect-error TS(2630) FIXME: Cannot assign to 'RMStartPromo' because it is a fu... Remove this comment to see the full error message
RMStartPromo = connect((state) => ({
  // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
  viewer: state.viewer,
}))(RMStartPromo);

class ClientPromo extends PureComponent {
  render() {
    return (
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      <Promo title="We're working on finding your expert matches...">
        <p>Need to make a change to this expert request?</p>
        <Button href="mailto:hello@onfrontiers.com">Contact Support</Button>
      </Promo>
    );
  }
}

interface CountBoxesState {
  selected: {
    suggestions: { [key: string]: boolean };
    experts: { [key: string]: boolean };
  };
}

interface CountBoxesProps {
  section: string;
  expertCounts: any;
  isClient: boolean;
  selectedBoxes: any;
  onClick: (states: any, boxes: any) => void;
}

class CountBoxes extends PureComponent<CountBoxesProps, CountBoxesState> {
  static boxes = {
    suggestions: [
      {
        id: 'suggestions-suggested',
        label: 'Suggested',
        states: ['suggested_by_platform', 'suggested_by_research'],
        hiddenFromClients: true,
      },
      {
        id: 'suggestions-contacted',
        label: 'Contacted',
        states: ['contacted'],
        hiddenFromClients: true,
      },
      {
        id: 'suggestions-interested',
        label: 'Interested',
        states: ['interested'],
        hiddenFromClients: true,
      },
      {
        id: 'suggestions-rejected',
        label: 'Rejected',
        states: ['rejected_suggestion'],
        hiddenFromClients: true,
        warn: true,
      },
    ],
    experts: [
      {
        id: 'experts-polishing',
        label: 'Polishing',
        states: ['polishing'],
        hiddenFromClients: true,
      },
      {
        id: 'experts-vetting',
        label: 'Vetting',
        states: ['vetting'],
      },
      {
        id: 'experts-verified',
        label: 'Verified',
        states: ['verified'],
      },
      {
        id: 'experts-matched',
        label: 'Matched',
        states: ['matched'],
      },
      {
        id: 'experts-rejected',
        label: 'Rejected',
        states: ['rejected_by_client', 'rejected_by_research'],
        warn: true,
      },
    ],
  };

  constructor(props: any) {
    super(props);
    this.state = {
      selected: props.selectedBoxes || { suggestions: {}, experts: {} },
    };
  }

  postClick = () => {
    const { onClick } = this.props;
    const { selected } = this.state;

    const selectedStatesFor = (section: keyof CountBoxesState['selected']) =>
      CountBoxes.boxes[section as keyof typeof CountBoxes.boxes].reduce((acc: any, box: any) => {
        if (!selected[section as keyof CountBoxesState['selected']][box.id]) return acc;
        return [...acc, ...box.states];
      }, []);

    onClick(
      {
        suggestions: selectedStatesFor('suggestions'),
        experts: selectedStatesFor('experts'),
      },
      selected
    );
  };

  handleClick = (event: any, box: any) => {
    event.preventDefault();
    const { section } = this.props as { section: 'suggestions' | 'experts' };

    this.setState(
      (prevState) => ({
        selected: {
          ...prevState.selected,
          [section]: {
            ...prevState.selected[section],
            [box.id]: !prevState.selected[section][box.id],
          },
        },
      }),
      () => this.postClick()
    );
  };

  sumCounts = (expertCounts: any, states: any) => {
    const matchingCounts = Object.values(pick(expertCounts, states));
    return matchingCounts.length > 0 ? (matchingCounts as number[]).reduce((a, b) => a + b, 0) : 0;
  };

  render() {
    const { section, expertCounts, isClient } = this.props;
    const { selected } = this.state;
    const boxes = CountBoxes.boxes[section as keyof typeof CountBoxes.boxes].filter(
      (x: any) => !(isClient && x.hiddenFromClients)
    );

    if (!expertCounts) {
      return null;
    }

    return (
      <div className={s.countBoxes}>
        {boxes.map((b: any) => (
          <CountBox
            className={s.countBox}
            grayOutZero
            key={b.id}
            selected={selected[section as 'suggestions' | 'experts'][b.id]}
            label={b.label}
            count={this.sumCounts(expertCounts, b.states)}
            warn={b.warn}
            labelClassName={s.countBoxLabel}
            onClick={(event: any) => this.handleClick(event, b)}
          />
        ))}
      </div>
    );
  }
}

const ADDRESS_TAKEN_ERROR = 'GraphQL Error: address already taken';

// TODO: replace with useQuery
const connector = connect(
  (state: { viewer: Viewer; expertRequests: any }, ownProps: { expertRequestId: string }) => {
    const er = (
      state.expertRequests.default.edges.find((p: any) => p.node.id === ownProps.expertRequestId) ||
      {}
    ).node as Record<string, any> | undefined;
    return {
      viewer: state.viewer,
      matchedCandidates: (er?.matchedCandidates || []) as Candidate[],
      suggestedCandidates: (er?.suggestedCandidates || []) as Candidate[],
      loadingCandidates: (er?.loadingCandidates || []) as Candidate[],
      loadedCandidates: (er?.loadedCandidates || []) as Candidate[],
      suggestedCandidatesPageInfo: er?.suggestedCandidatesPageInfo as Candidate[],
      matchedCandidatesPageInfo: er?.matchedCandidatesPageInfo as Candidate[],
      consultations: (er?.consultations || []) as any[],
    };
  },
  {
    removeExpertRequestCandidate,
    saveExpertRequest,
    updateExpertRequestCandidate,
    notify,
    updateExpertRequestState,
    updateProjectMember,
    setAddress,
  }
);

export type SectionType = 'suggestions' | 'experts' | 'details' | 'consultations';

interface ExpertRequestProps {
  expertRequestId: string;
  candidateId: string | number;
  isGoodMatch: boolean;
  fetchMoreCandidates: (
    type: 'matched' | 'suggested',
    pageInfo: Record<string, any>
  ) => Promise<void>;
  section: SectionType;
  viewer: Viewer;
  expertRequest: ExpertRequest;
  suggestedCandidates: Candidate[];
  matchedCandidates: Candidate[];
  loadingCandidates: Candidate[];
  loadedCandidates: Candidate[];
  suggestedCandidatesPageInfo: any;
  matchedCandidatesPageInfo: any;
  consultations: any[];
}

const ExpertRequestComponent: FC<ExpertRequestProps & ConnectedProps<typeof connector>> = ({
  expertRequest,
  viewer,
  candidateId,
  notify,
  updateExpertRequestState,
  saveExpertRequest,
  setAddress,
  removeExpertRequestCandidate,
  updateExpertRequestCandidate,
  isGoodMatch,
  fetchMoreCandidates,
  suggestedCandidates,
  matchedCandidates,
  suggestedCandidatesPageInfo,
  matchedCandidatesPageInfo,
  loadingCandidates,
  loadedCandidates,
  consultations,
  section,
}) => {
  const { store } = useApp();
  const navigate = useNavigate();

  const [editDialog, setEditDialog] = useState(null);
  const [stateSection, setSection] = useState<SectionType>(section || 'experts');
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [selectedStates, setSelectedStates] = useState({
    suggestions: [],
    experts: [],
  });
  const [selectedBoxes, setSelectedBoxes] = useState(null);
  const [selectedProfiles, setSelectedProfiles] = useState<any[]>([]);
  const [sendMessageDialogOpen, setSendMessageDialogOpen] = useState(false);
  const [closeExpertRequestDialogOpen, setCloseExpertRequestDialogOpen] = useState(false);
  const candidateRefs = useRef(() => {
    const cands = (matchedCandidates || []).concat(suggestedCandidates || []);
    const refs: { [key: string]: React.RefObject<HTMLDivElement> } = {};
    cands.forEach((c: any) => (refs[c.id] = React.createRef()));
    return refs;
  });

  useEffect(() => {
    const selectedCandRef =
      // @ts-expect-error
      candidateRefs.current[candidateId as string]?.current;

    if (selectedCandRef) {
      const rect = selectedCandRef.getBoundingClientRect();
      const headerOffset = -45;
      const elementPosition = rect.top;
      const offsetPosition = elementPosition + headerOffset;
      window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
    }

    async function fetchData() {
      // @ts-ignore
      await Promise.all(Details.fetch.map((f: any) => store.dispatch(f({ viewer }))));
    }

    fetchData();
  }, [candidateId, store, viewer]);

  const openDialog = useCallback(
    async (editDialog: any) => {
      // @ts-expect-error
      const { component } = dialogs[editDialog];

      if (component.fetch && Array.isArray(component.fetch)) {
        await Promise.all(component.fetch.map((f: any) => store.dispatch(f({ viewer }))));
      } else if (component.fetch) {
        await store.dispatch(component.fetch({ viewer }));
      }

      setEditDialog(editDialog);
    },
    [store, viewer]
  );

  const closeDialog = useCallback(
    (message: any, error: any) => {
      setEditDialog(null);

      if (message) {
        notify(message, error ? 'error' : 'success');
      }
    },
    [notify]
  );

  const handleUpdateState = useCallback(
    (closeReason: any) => {
      const state = expertRequest.state === 'open' ? 'closed' : 'open';
      updateExpertRequestState(expertRequest.id, state, closeReason);
      if (state === 'closed') navigate('/expert_requests');
    },
    [expertRequest?.id, expertRequest?.state, navigate, updateExpertRequestState]
  );

  const openExpertsRequestDialog = () => {
    setCloseExpertRequestDialogOpen(true);
  };

  const closeExpertRequestDialog = () => {
    setCloseExpertRequestDialogOpen(false);
  };

  const confirmDelete = () => setDeleteConfirmationOpen(true);
  const closeConfirmDelete = () => setDeleteConfirmationOpen(false);

  const selectProfile = (profile: any, selected: any) => {
    if (selected) {
      setSelectedProfiles((state) => add(state, profile, (p: any) => p.id === profile.id));
    } else {
      setSelectedProfiles((state) =>
        removeAt(
          state,
          state.findIndex((p) => p.id === profile.id)
        )
      );
    }
  };

  const clearProfileSelection = () => {
    setSelectedProfiles([]);
  };

  const handleSubmit = useCallback(
    async (values: any, _: any, callback: any) => {
      if (!viewer.phone && values.phone) {
        try {
          setAddress(viewer.profile?.id, 'phone', values.phone, true);
        } catch (err) {
          if (err instanceof Error && err.message.startsWith(ADDRESS_TAKEN_ERROR)) {
            callback({
              phone: 'Already in use, please use another',
            });
            return;
          }
          notify('Error saving phone number.', 'error');
        }
      }

      const permissions = expertRequest.permissions || [];
      const canEditAdminFields = permissions.includes('update_admin_fields');
      const canEditQueries = permissions.includes('update_queries');

      try {
        await saveExpertRequest(values, {
          includeAdminFields: canEditAdminFields,
          includeQueries: canEditQueries,
        });
      } catch {
        notify('An error occurred when updating the expert request.', 'error');
      }
      notify('Saved successfully.', 'success');
    },
    [viewer, saveExpertRequest, setAddress, notify, expertRequest]
  );

  const { name, project, stats } = expertRequest;
  const selectedSection = stateSection;
  const permissions = expertRequest.permissions || [];
  const canEdit = permissions.includes('update');
  const canEditQueries = permissions.includes('update_queries');
  const canAddAttachment = permissions.includes('add_attachment');

  const projectMembers = project.members || [];

  const isResearchTeam = projectMembers.some(
    (m: any) =>
      m.user && m.user.id === viewer.id && (m.role === 'manager' || m.role === 'associate')
  );
  const isClient = projectMembers.some(
    (m: any) => m.user && m.user.id === viewer.id && (m.role === 'owner' || m.role === 'member')
  );
  const hasAllPermissions = viewer.admin || isResearchTeam;

  const membersToShow = project.members
    .filter((m: any) => m.state === 'active')
    .sort((a: any, b: any) => {
      const roles = ['manager', 'associate', 'owner', 'member', 'viewer'];
      return roles.indexOf(a.role) - roles.indexOf(b.role);
    });

  const memberRequests = sortMembers(
    projectMembers.filter((m: any) => m.state === 'awaiting_approval')
  );

  const suggestionsTabAvailable =
    hasAllPermissions || (suggestedCandidates && suggestedCandidates.length > 0);

  const section2 =
    selectedSection === 'suggestions' && !suggestionsTabAvailable ? 'experts' : selectedSection;

  const selectedStatesList =
    section2 === 'suggestions' ? selectedStates.suggestions : selectedStates.experts;
  let candidates = section2 === 'suggestions' ? suggestedCandidates : matchedCandidates;
  if (selectedStatesList.length > 0) {
    candidates = candidates?.filter((c) =>
      // @ts-expect-error
      selectedStatesList.includes(c.state)
    );
  }
  candidates = candidates || [];

  const candidatePageInfo =
    section2 === 'suggestions' ? suggestedCandidatesPageInfo : matchedCandidatesPageInfo;

  const fetchMoreCandidatesInternal = () => {
    return fetchMoreCandidates(
      section2 === 'suggestions' ? 'suggested' : 'matched',
      candidatePageInfo
    );
  };

  const showClientPromo =
    loadedCandidates &&
    candidates.filter(
      (e: any) =>
        ['suggested_by_platform', 'suggested_by_research', 'rejected_suggestion'].indexOf(e.state) <
        0
    ).length === 0;

  return (
    <MediaQuery maxWidth={SCREEN_SM}>
      {(isMobileVersion: any) => {
        const contextActions = canEdit && (
          <div className={s.contextActions}>
            <IconMenu iconElement={<FAIcon icon="cog" color={darkBrown} />}>
              <MenuItem
                onClick={() => openDialog('project')}
                disabled={!permissions.includes('update')}
              >
                Edit Associated Project
              </MenuItem>
              <MenuItem
                onClick={() => {
                  if (expertRequest.state === 'open') {
                    openExpertsRequestDialog();
                  } else {
                    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
                    handleUpdateState();
                  }
                }}
                disabled={!permissions.includes('update')}
              >
                {expertRequest.state === 'open' ? 'Close' : 'Reopen'}
              </MenuItem>
              <MenuItem
                onClick={() => navigate(`/request_expert/details?copy_from=${expertRequest.id}`)}
              >
                Make a Copy
              </MenuItem>
              <MenuItem
                onClick={confirmDelete}
                disabled={!permissions.includes('delete') || (stats && stats.calls > 0)}
              >
                Delete Request
              </MenuItem>
            </IconMenu>
          </div>
        );

        return (
          <LayoutPage showNav selected="expert_requests">
            <ConsultationsStarting />

            <ProjectBar showSettings viewer={viewer} project={project} members={membersToShow} />

            {expertRequest.state === 'closed' && (
              <div className={s.status}>
                <MaterialIcon color={darkGray} className={s.statusIcon} icon="history" />
                <span className={s.statusText}>Closed</span>
              </div>
            )}

            <div>
              <div className={s.headerLabel}>Expert Request Title:</div>
              <div className={s.header}>
                <h3 className={s.name}>{name}</h3>
                {canEdit && (
                  <EditIcon onClick={() => openDialog('name')} style={{ marginLeft: 10 }} />
                )}
                {contextActions}
              </div>
            </div>

            <MemberRequests projectId={project.id} memberRequests={memberRequests} />

            <div className={s.content}>
              <div className={s.section}>
                <Tabs
                  value={section2}
                  onChange={(_e, section) => setSection(section)}
                  variant="scrollable"
                >
                  <Tab
                    value="experts"
                    label="Matched Experts"
                    id="expertRequestsMatchedExpertsTab"
                  />
                  {suggestionsTabAvailable && <Tab value="suggestions" label="Suggested Experts" />}
                  <Tab value="consultations" label="Consultations" />
                  <Tab
                    id="expertRequestsAdminRequestDetails"
                    value="details"
                    label="Request Details"
                  />
                </Tabs>

                <div className={s.sectionContent}>
                  <div className={s.sectionItems}>
                    {['suggestions', 'experts'].includes(section2) && (
                      <div>
                        {!selectedStates[section2 as 'suggestions' | 'experts'].length &&
                          (viewer.admin || isResearchTeam
                            ? section2 === 'suggestions' && (
                                <RMStartPromo expertRequest={expertRequest} />
                              )
                            : showClientPromo && <ClientPromo />)}
                        {candidates.map((c: any) => (
                          <CandidateWidgetHorizontal
                            key={c.id}
                            // @ts-expect-error TS(2322) FIXME: Type '{ key: any; showKeywords: true; candidate: a... Remove this comment to see the full error message
                            showKeywords
                            candidate={c}
                            expertRequest={expertRequest}
                            viewer={viewer}
                            canConfirmMatch={isClient}
                            style={{ padding: 15, marginBottom: 20 }}
                            onUpdate={(m: Candidate) =>
                              updateExpertRequestCandidate(expertRequest.id, m)
                            }
                            onRemove={
                              hasAllPermissions &&
                              (() => removeExpertRequestCandidate(expertRequest.id, c))
                            }
                            onSelect={selectProfile}
                            selected={selectedProfiles.includes(c.profile)}
                            isGoodMatch={
                              isGoodMatch && candidateId === c.id ? isGoodMatch : undefined
                            }
                            // @ts-expect-error
                            ref={candidateRefs[c.id]}
                          />
                        ))}
                        {loadingCandidates.length > 0 && (
                          <div className={s.loading}>
                            <CircularProgress />
                          </div>
                        )}
                        {loadingCandidates.length === 0 && candidatePageInfo?.hasNextPage && (
                          <Waypoint onEnter={fetchMoreCandidatesInternal} />
                        )}
                        {loadedCandidates && !candidates.length && (
                          <EmptyMessage
                            style={{ padding: 20, backgroundColor: sand }}
                            titleStyle={{ fontSize: 24 }}
                            bodyStyle={{ fontSize: 16 }}
                            border={false}
                            title="Request another expert for this project"
                            body="Looking for another type of expertise for the same project?"
                            action={
                              <div>
                                <Link to={`/request_expert?project_id=${project.id}`}>
                                  <MaterialButton color="secondary">Add New Request</MaterialButton>
                                </Link>
                                <Link to={`/request_expert/details?copy_from=${expertRequest.id}`}>
                                  <MaterialButton color="secondary">Make a Copy</MaterialButton>
                                </Link>
                              </div>
                            }
                          />
                        )}
                        <SelectedProfilesBar
                          onRemove={(candidate: any) => selectProfile(candidate, false)}
                          onClear={() => clearProfileSelection()}
                          profiles={selectedProfiles}
                        >
                          {viewer.admin && (
                            <div>
                              <Button
                                variant="contained"
                                color="teal"
                                size="medium"
                                startIcon={
                                  <FAIcon iconSet="fal" icon="comment-alt-lines" size={20} />
                                }
                                onClick={() => setSendMessageDialogOpen(true)}
                                style={{
                                  marginRight: 10,
                                  textTransform: 'initial',
                                }}
                              >
                                Send Message
                              </Button>
                              <SendMessageDialog
                                open={sendMessageDialogOpen}
                                onClose={() => setSendMessageDialogOpen(false)}
                                onSend={clearProfileSelection}
                                profiles={selectedProfiles}
                                expertRequestId={expertRequest.id}
                              />
                            </div>
                          )}
                        </SelectedProfilesBar>
                      </div>
                    )}

                    {section2 === 'consultations' && (
                      <div>
                        <ConsultationList
                          title="Upcoming"
                          consultations={consultations
                            .filter((c: any) => c.state === 'confirmed')
                            .sort((a: any, b: any) => sortDates(a.starts_at, b.starts_at))}
                        />

                        <ConsultationList
                          title="Awaiting"
                          consultations={consultations
                            .filter((c: any) =>
                              [
                                'negotiating_client_time',
                                'negotiating_expert_time',
                                'awaiting_expert_review',
                              ].includes(c.state)
                            )
                            .sort((a: any, b: any) => sortDates(b.created_at, a.created_at))}
                        />

                        <ConsultationList
                          grid
                          title="Completed"
                          consultations={consultations
                            .filter((c: any) => c.state === 'completed')
                            .sort((a: any, b: any) => sortDates(b.ended_at, a.ended_at))}
                        />

                        <ConsultationList
                          grid
                          title="Canceled"
                          consultations={consultations
                            .filter((c: any) =>
                              ['canceled', 'denied', 'expired', 'incomplete'].includes(c.state)
                            )
                            .sort((a: any, b: any) => sortDates(b.canceled_at, a.canceled_at))}
                        />
                      </div>
                    )}
                    {section2 === 'details' && (
                      <Form
                        onSubmit={handleSubmit}
                        initialValues={expertRequest}
                        mutators={{
                          ...arrayMutators,
                        }}
                      >
                        {({ handleSubmit, form, values }) => (
                          <Grid container spacing={4} justifyContent="center">
                            <Grid item md={8} sm={12}>
                              <Details
                                isViewerExpert={false}
                                showFields={DETAILS_SHOW_FIELDS}
                                canEdit={canEdit}
                                canEditQueries={canEditQueries}
                                canAddAttachment={canAddAttachment}
                                change={form.change}
                                values={values}
                                isMobileVersion={isMobileVersion}
                              />
                            </Grid>
                            <Grid
                              item
                              md={4}
                              sm={12}
                              style={{
                                position: 'sticky',
                                top: 0,
                                height: '100%',
                              }}
                            >
                              <SubmitContainer disabled={!canEdit} handleSubmit={handleSubmit} />
                            </Grid>
                          </Grid>
                        )}
                      </Form>
                    )}
                  </div>
                  {!['details', 'consultations'].includes(section2) && (
                    <div className={s.expertRequestFilters}>
                      <CountBoxes
                        isClient={!viewer.admin && !isResearchTeam}
                        section={section2}
                        expertCounts={candidatePageInfo?.metaData}
                        selectedBoxes={selectedBoxes}
                        onClick={(states: any, boxes: any) => {
                          setSelectedStates(states);
                          setSelectedBoxes(boxes);
                        }}
                      />
                      {isClient && <ReferFriend expertRequest={expertRequest} />}
                    </div>
                  )}
                </div>
              </div>
              <CloseExpertRequestDialog
                expertRequestId={expertRequest.id}
                onConfirm={handleUpdateState}
                onReset={closeExpertRequestDialog}
                onCancel={closeExpertRequestDialog}
                onClose={closeExpertRequestDialog}
                open={closeExpertRequestDialogOpen}
              />
              <DeleteExpertRequestDialog
                expertRequestId={expertRequest.id}
                onConfirm={closeConfirmDelete}
                onCancel={closeConfirmDelete}
                open={deleteConfirmationOpen}
                returnTo="/expert_requests"
              />
            </div>

            <Divider style={{ marginTop: 30, marginBottom: 20 }} />

            <div className={s.activity}>
              <ActivityLog
                objectType="expert_request"
                objectId={expertRequest.id}
                actions={['created', 'closed', 'open']}
                store={store}
              />
            </div>

            {editDialog && (
              <EditExpertRequest
                open
                onClose={closeDialog}
                initialValues={{
                  ...expertRequest,
                  project_id: expertRequest.project.id,
                }}
                // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
                {...dialogs[editDialog]}
              />
            )}
          </LayoutPage>
        );
      }}
    </MediaQuery>
  );
};

export default connector(ExpertRequestComponent);
