import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { defaultNext, loginAs } from '@/auth';
import { useApp } from '@/hooks/useAppContext';

interface LoginAsProps {
  query: { user: string; next: Location };
}

const LoginAs: React.FC<LoginAsProps> = ({ query }) => {
  const navigate = useNavigate();
  const { graphql, store } = useApp();

  useEffect(() => {
    const { next, user } = query;
    const performLogin = async () => {
      try {
        await loginAs(graphql, store, user);
        window.location = next || defaultNext;
      } catch {
        navigate(`/profile/${user}`, { replace: true });
      }
    };

    performLogin();
  }, [navigate, query, graphql, store]);

  return null;
};
export default LoginAs;
