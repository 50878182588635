import arrayMutators from 'final-form-arrays';
import setFieldData from 'final-form-set-field-data';
import moment from 'moment-timezone';
import { useCallback, useMemo } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { notify, popup } from '@/actions/ui';
import { engagementTypes, requestConsultation } from '@/consultation/store';
import {
  calculateExpertCredits,
  getSuggestedTimes,
  isDurationEmpty,
  minimumTimeNotice,
  validate,
} from '@/core/consultation';
import { money } from '@/core/money';
import { erTypes } from '@/expertrequest/store';

import RequestConsultationDialog from './Dialog';
import {
  ERR_NOT_AVAILABLE_SELF_SERVICE,
  ERR_NOT_ENOUGH_CREDITS,
  ERR_NO_BILLING_ACCOUNT,
  ERR_OUT_OF_MARKETPLACE,
  ERR_REQUESTER_EQUALS_EXPERT,
  MSG_OUT_OF_MARKETPLACE,
} from './constants';
import minimumTimeNoticePopup from './minimumTimeNoticePopup';
import purchasePromoPopup from './purchasePromoPopup';

const RequestConsultation = ({
  // Props
  profile,

  returnTo,
  expertId,
  onClose,
  open,
  expertRequest,
  groupId,

  // Redux State
  viewer,

  group,

  // Redux Actions
  requestConsultation,

  notify,
  popup,
}: any) => {
  const navigate = useNavigate();
  const handleClose = useCallback(() => {
    if (returnTo) navigate(returnTo);
    if (onClose) onClose();
  }, [navigate, onClose, returnTo]);

  const handleSubmit = useCallback(
    (values: any) =>
      new Promise((resolve, reject) => {
        const dates = values.dates.filter(Boolean);

        (async () => {
          try {
            const {
              duration,
              engagement_type: engagementType,
              booking_fee: bookingFee,
              ...other
            } = values;

            const expertCredits = calculateExpertCredits(
              profile.credit_rate,
              duration,
              engagementType
            );
            const cents = expertCredits + bookingFee;

            const consultation = await requestConsultation({
              ...other,
              duration,
              engagement_type: engagementType,
              expert_id: expertId,
              credits: money({ cents, currency: 'OFC' }),
              dates,
            });

            notify('Your request has been submitted.', 'success');

            navigate(`/consultation/${consultation.id}`);

            // @ts-expect-error TS(2794): Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
            resolve();
          } catch (err) {
            if (
              // @ts-expect-error TS(2571): Object is of type 'unknown'.
              err.message === ERR_NOT_ENOUGH_CREDITS ||
              // @ts-expect-error TS(2571): Object is of type 'unknown'.
              err.message === ERR_NO_BILLING_ACCOUNT
            ) {
              // if (viewer.admin) {
              //   return resolve({
              //     group_id: 'Team does not have enough credits',
              //   });
              // }
              let purchaseLink = group ? `/team/${group.slug}` : '';
              purchaseLink += `/settings/credits/purchase`;
              purchasePromoPopup(popup, purchaseLink, !!group);
              // @ts-expect-error TS(2794): Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
              return resolve();
            }

            // @ts-expect-error TS(2571): Object is of type 'unknown'.
            if (err.message === ERR_OUT_OF_MARKETPLACE) {
              notify(`${MSG_OUT_OF_MARKETPLACE}.`, 'error');
              // @ts-expect-error TS(2794): Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
              return resolve();
            }

            // @ts-expect-error TS(2571): Object is of type 'unknown'.
            if (err.message === ERR_NOT_AVAILABLE_SELF_SERVICE) {
              notify(
                'Expert is not available for self service (must be added to the expert request).',
                'error'
              );
              // @ts-expect-error TS(2794): Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
              return resolve();
            }

            // @ts-expect-error TS(2571): Object is of type 'unknown'.
            if (err.message === ERR_REQUESTER_EQUALS_EXPERT) {
              notify('Requester and expert cannot be the same.', 'error');
              // @ts-expect-error TS(2794): Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
              return resolve();
            }

            // @ts-expect-error TS(2571): Object is of type 'unknown'.
            if (err.message === 'User not logged in') {
              notify('User not logged in.', 'error');
            } else {
              notify('An error occurred when trying to submit your request.', 'error');
            }
            reject(err);
          }
        })();
      }),
    [expertId, group, navigate, notify, popup, profile.credit_rate, requestConsultation]
  );

  const handleSubmitWithCheck = useCallback(
    (values: any) => {
      const dates = values.dates.filter(Boolean);
      // @ts-expect-error TS(2554): Expected 1 arguments, but got 2.
      if (minimumTimeNotice(dates, viewer.timezone)) {
        handleSubmit(values);
      } else {
        minimumTimeNoticePopup(popup, false, () => handleSubmit(values));
      }
      onClose();
    },
    [handleSubmit, popup, viewer.timezone, onClose]
  );

  // if (!viewer.groups || viewer.groups.length === 0) {
  //   return (
  //     <TeamAccountPromoDialog
  //       open={open}
  //       onClose={handleClose}
  //       showEventTrack="promo.show.team_account.consultation_request"
  //       clickEventTrack="promo.click.team_account.consultation_request"
  //     />
  //   );
  // }
  const initialValues = useMemo(
    () =>
      expertRequest && expertRequest.er_type === erTypes.writtenReview
        ? {
            dates: [moment().add(7, 'd').startOf('day')],
            expert_request_id: expertRequest.id || '',
            group_id: groupId || '',
            requester_id: '',
            disclosure: 'full',
            engagement_type: engagementTypes.writtenResponse,
            booking_fee: 0,
            duration:
              (!isDurationEmpty(expertRequest.expected_duration) &&
                expertRequest.expected_duration) ||
              '1h',
          }
        : {
            dates: getSuggestedTimes(viewer.timezone, profile.timezone, 12, 1),
            expert_request_id: expertRequest?.id || '',
            group_id: groupId || '',
            requester_id: '',
            duration: '1h',
            disclosure: 'full',
            engagement_type: engagementTypes.consultation,
            booking_fee: 0,
          },
    [expertRequest, groupId, profile.timezone, viewer.timezone]
  );

  const getEngagementType = useCallback(
    (er = expertRequest) => {
      if (er?.er_type === erTypes.writtenReview) {
        return engagementTypes.writtenResponse;
      }
      if (er?.er_type === erTypes.newHire || er?.er_type === erTypes.consultingProject) {
        return engagementTypes.opportunity;
      }
      return engagementTypes.consultation;
    },
    [expertRequest]
  );

  return (
    <Form
      // Final Form
      initialValues={initialValues}
      onSubmit={handleSubmitWithCheck}
      // @ts-expect-error TS(2322): Type '(values: any, props: any) => {} | undefined'... Remove this comment to see the full error message
      validate={validate}
      mutators={{ setFieldData, ...arrayMutators }}
      // @ts-ignore
      component={RequestConsultationDialog}
      // Only re-render if these parts of the form state change
      subscription={{
        submitting: true,
        values: true,
        initialValues: true,
      }}
      // RequestConsultationDialog props
      expertId={expertId}
      profile={profile}
      expertRequest={expertRequest}
      getEngagementType={getEngagementType}
      open={open}
      onClose={handleClose}
    />
  );
};

export default connect(
  (state, props) => {
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    const group = state.groups.all.edges.find(
      // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
      (g: any) => g.node.id === props.groupId
    )?.node;
    return {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      viewer: state.viewer,
      group,
    };
  },
  {
    requestConsultation,
    notify,
    popup,
  }
)(RequestConsultation);
