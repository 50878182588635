import cx from 'classnames';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { track } from '@/actions/tracking';
import { popup } from '@/actions/ui';
import { getStateDescription, getUser, getUserName } from '@/core/consultation';
import Duration from '@/core/duration';
import { getTimezoneDescription } from '@/core/time';
import { RootState } from '@/store';
import { dateFormat, formatDuration } from '@/utils';

import FAIcon from '../Icon/FAIcon';
import Link from '../Link';
import Picture from '../Picture';
import s from './ConsultationPreview.module.scss';

function formatDate(consultation: any, timezone: any, compact: any) {
  const {
    starts_at: date,
    billing_duration: billingDuration,
    recording_duration: recordingDuration,
  } = consultation;

  if (!date) return;

  const momentTimezone = moment.tz(date, moment.tz.zone(timezone) ? timezone : moment.tz.guess());

  if (compact) {
    let formatted = momentTimezone.format('D MMM YYYY');
    let duration = billingDuration && Duration.parse(billingDuration);
    if (!duration || duration.seconds() === 0) {
      duration = recordingDuration && Duration.parse(recordingDuration);
    }
    if (duration) {
      formatted += ` • ${formatDuration(duration)}`;
    }
    return formatted;
  }

  return `${momentTimezone.format(dateFormat)} • ${momentTimezone.format('h:mm a')}`;
}

interface ConsultationPreviewProps {
  consultation: any;
  viewer: any;
  compact?: any;
  userContext: any;
  popup: any;
  track: any;
}

class ConsultationPreview extends PureComponent<ConsultationPreviewProps> {
  showPremiumFeaturePromo = () => {
    const { popup, track } = this.props;
    track('promo.show.consultation.archived');
    popup({
      layout: 'promo',
      title: 'Call archival is a premium feature',
      contents:
        'Trying to access a consultation over 90 days old? ' +
        'Upgrade to our Advanced or Enterprise account ' +
        'to view your full call history.',
      buttons: [
        {
          label: 'Start a chat',
          primary: true,
          callback: () => {
            track('promo.chat.consultation.archived');
            // @ts-expect-error TS(2339): Property 'HubSpotConversations' does not exist on ... Remove this comment to see the full error message
            window.HubSpotConversations?.widget?.open();
          },
        },
      ],
      links: [{ label: 'No thanks.', callback: () => {} }],
    });
  };

  render() {
    const { consultation, viewer, compact, userContext } = this.props;
    const { expert } = consultation;

    const user = getUser(viewer, userContext, consultation, true);
    const userName = getUserName(viewer, userContext, consultation, true);
    const stateDescription = consultation && getStateDescription(consultation);

    const full = !compact;
    const photoSize = compact ? 60 : 80;
    const date = formatDate(consultation, viewer.timezone, compact);

    const blocked =
      consultation.archived &&
      !(consultation.permissions || []).includes('view_archived') &&
      (!expert || expert.id !== viewer.id);

    return (
      <div className={cx(s.root, { [s.compact]: compact })}>
        <div className={s.card}>
          {blocked && (
            <div
              className={s.blockedArchived}
              onClick={this.showPremiumFeaturePromo}
              onKeyPress={this.showPremiumFeaturePromo}
            />
          )}
          <div className={s.user}>
            <div className={s.photo}>
              <Picture user={user} size={photoSize} />
            </div>

            <Link to={consultation.html_url} className={s.content}>
              {stateDescription && (
                <div className={s.contentTop}>
                  <div className={s.state}>
                    <FAIcon
                      className={s.stateIcon}
                      iconSet={stateDescription.iconSet}
                      icon={stateDescription.icon || 'access_time'}
                      color={stateDescription.color}
                    />
                    <span style={{ color: stateDescription.color }} className={s.stateText}>
                      {stateDescription.text}
                    </span>
                  </div>

                  {date && (
                    <div className={s.time}>
                      {date}&nbsp;
                      {full && (
                        <span className={s.timezone}>
                          {getTimezoneDescription(viewer.timezone)}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              )}

              <div className={s.name}>{userName}</div>

              {consultation.expert_request && (
                <div className={s.expertRequest}>{consultation.expert_request.name}</div>
              )}
            </Link>
          </div>

          {userContext === 'admin' && (
            <div className={s.expert}>
              <div className={s.photo}>
                <Picture user={expert} size={photoSize} />
              </div>
              <div className={s.name}>
                {expert ? (
                  <span>
                    {expert.name} <span className={s.expertLabel}>(Expert)</span>
                  </span>
                ) : (
                  consultation.expert_name || 'Expert not available'
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

// @ts-expect-error TS(2339): Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
ConsultationPreview.propTypes = {
  viewer: PropTypes.object,
  consultation: PropTypes.object,
  user: PropTypes.object,
  compact: PropTypes.bool,
};

export default connect(
  (state: RootState) => ({
    viewer: state.viewer,
    userContext: state.ui.userContext,
  }),
  {
    popup,
    track,
  }
)(ConsultationPreview);
