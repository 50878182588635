import { IconButton } from '@mui/material';
import { PureComponent } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { removeAddress, setAddress } from '@/actions/address';
import { notify } from '@/actions/ui';
import {
  ErrAddressAlreadyTaken,
  ErrAddressAtLeastOne,
  ErrPhoneAlreadyExists,
} from '@/core/address';
import { RootState } from '@/store';
import { red500 } from '@/theme/colors';

import Button from '../Button/Button';
import Form from '../Form';
import FAIcon from '../Icon/FAIcon';
import PhoneInput from '../PhoneInput';
import s from './EditPhones.module.scss';

function validate(values: FormData) {
  const errors: { phone?: string } = {};

  if (!values.phone) {
    errors.phone = 'Required';
  }

  return errors;
}

class Phone extends PureComponent {
  handleRemove = () => {
    // @ts-expect-error TS(2339): Property 'address' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { address, onRemove } = this.props;
    if (onRemove) {
      onRemove(address);
    }
  };

  render() {
    // @ts-expect-error TS(2339): Property 'address' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { address, showRemove } = this.props;

    return (
      <div className={s.phoneRoot}>
        <div className={s.phone}>
          <span className={s.address} title={address}>
            {address}
          </span>
        </div>
        <div className={s.actions}>
          {showRemove && (
            <IconButton onClick={this.handleRemove}>
              <FAIcon icon="trash-o" size={24} color={red500} />
            </IconButton>
          )}
        </div>
      </div>
    );
  }
}

interface EditPhonesProps {
  profileId: string;
}

const connector = connect(
  (state: RootState, ownProps: EditPhonesProps) => ({
    allCountries: state.countries,
    profile: state.profiles.fullProfiles[ownProps.profileId],
  }),
  {
    setAddress,
    removeAddress,
    notify,
  }
);

interface FormData {
  phone: string;
}

class EditPhones extends PureComponent<
  EditPhonesProps & ConnectedProps<typeof connector> & InjectedFormProps<FormData, EditPhonesProps>
> {
  handleSubmit = (values: FormData) => {
    const { profileId, setAddress, reset, notify, profile } = this.props;
    const { phones = [] } = profile;
    return setAddress(profileId, 'phone', values.phone, !phones.length)
      .then(() => {
        reset();
      })
      .catch((e: any) => {
        switch (e.message) {
          case ErrAddressAlreadyTaken.message:
          case ErrPhoneAlreadyExists.message:
            notify('Phone already in use, please add a different one.', 'error');
            break;
          default:
            notify('An error occurred when adding the phone number.', 'error');
            Promise.reject(e);
            break;
        }
      });
  };

  handleRemove = async (phone: any) => {
    const { profileId, removeAddress, notify } = this.props;
    try {
      await removeAddress(profileId, 'phone', phone);
    } catch (e: any) {
      switch (e.message) {
        case ErrAddressAtLeastOne.message:
          notify('User must have at least one primary address', 'error');
          break;
        default:
          notify('An error occurred when removing the phone number.', 'error');
          break;
      }
    }
  };

  render() {
    const { profile, allCountries, handleSubmit } = this.props;

    const { phones } = profile;

    return (
      <div>
        <div className={s.root}>
          {phones.map((e: any) => (
            <Phone
              key={e.address}
              profileId={profile.id}
              showRemove={phones.length > 1}
              {...e}
              onRemove={this.handleRemove}
            />
          ))}
        </div>

        <Form onSubmit={handleSubmit(this.handleSubmit)}>
          <div className={s.add}>
            <Field
              component={PhoneInput}
              type="tel"
              name="phone"
              label="Mobile Phone"
              className={s.phone}
              allCountries={allCountries}
              showExampleOnError
            />
            <Button type="submit" size="small" variant="inverted" className="mb-4">
              Add
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

export default reduxForm<FormData, EditPhonesProps>({
  form: 'phones',
  validate,
})(connector(EditPhones));
