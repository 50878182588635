import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Viewer } from '@/core/viewer';

type ViewerState = Viewer;

const initialState: ViewerState = {
  admin: false,
  groups: [],
  agreements: [],
};

const viewerSlice = createSlice({
  name: 'viewer',
  initialState,
  reducers: {
    setViewer(state, action: PayloadAction<Viewer>) {
      Object.assign(state, action.payload);
    },
  },
});

export const { setViewer } = viewerSlice.actions;
export default viewerSlice.reducer;
