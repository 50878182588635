import { gql, useQuery } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import { FC, useCallback, useState } from 'react';

import { EnableOtpAuth } from '@/auth/components/SecurityEnableOtpAuth';
import Button from '@/components/Button';
import Divider from '@/components/Divider';
import { borderColor } from '@/theme/colors';

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
  },
  button: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginTop: 25,
  },
  submitButton: {
    margin: '20px 15px 0 ',
  },
  title: {
    fontSize: 36,
  },
  divider: {
    backgroundColor: borderColor,
    margin: '20px 0 40px',
  },
  logo: {
    maxWidth: 300,
    maxHeight: 100,
    width: 'auto',
    height: 'auto',
  },
});

const GET_ABOUT_PAGE_LOGO_URL = gql(/* GraphQL */ `
  query getEnrollOtpAuthAboutPageLogoUrl($groupId: String!) {
    aboutPageLogoURL(group_id: $groupId)
  }
`);

interface EnrollOtpAuthProps {
  groupId: string;
  email: string;
  setOtp: (otp: string) => void;
}

const EnrollOtpAuth: FC<EnrollOtpAuthProps> = ({ groupId, email, setOtp }) => {
  const s = useStyles();

  const { data } = useQuery(GET_ABOUT_PAGE_LOGO_URL, { variables: { groupId } });
  const [enableOtpAuthOpen, setEnableOtpAuthOpen] = useState(false);

  const handleEnableOtpAuthOpen = useCallback(() => {
    setEnableOtpAuthOpen(true);
  }, []);

  // const handleEnableOtpAuthClose = useCallback(() => {
  //   onSubmit();
  // }, [onSubmit]);

  const groupLogoURL = data?.aboutPageLogoURL;

  const logo = groupLogoURL && <img alt="logo" src={groupLogoURL} className={s.logo} />;

  return (
    <div className={s.root}>
      {logo}

      {logo && <Divider className={s.divider} />}

      <h1 className={s.title}>Set up two-factor authentication</h1>

      <p>
        To protect your account and your team&apos;s data, each time you sign in to <br />
        your OnFrontiers account, you&apos;ll need your password and a verification <br />
        code we send to your mobile phone or authentication app or email.
      </p>

      <div className={s.button}>
        <Button
          classes={{ root: s.submitButton }}
          onClick={handleEnableOtpAuthOpen}
          fullWidth={false}
        >
          Get Started
        </Button>
      </div>

      <EnableOtpAuth
        open={enableOtpAuthOpen}
        onCancel={() => setEnableOtpAuthOpen(false)}
        email={email}
        setOtp={setOtp}
      />
    </div>
  );
};

export default EnrollOtpAuth;
