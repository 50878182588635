import { Chip } from '@mui/material';
import { PureComponent } from 'react';

import Message from '@/messaging/components/Message';
import { darkBrown, darkGreen, sand, white } from '@/theme/colors';

import s from './Transcript.module.scss';

function createSpeakerUser(speaker: any, speakerUnknownMap: any) {
  let name = speaker && (speaker.name || speakerUnknownMap[speaker.id]);
  if (!name) {
    name = String(Object.keys(speakerUnknownMap).length + 1);
    speakerUnknownMap[speaker.id] = name;
  }
  const nameParts = name.split(' ');
  return { first_name: nameParts[0], last_name: nameParts[1] };
}

class Transcript extends PureComponent {
  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'transcription' does not exist on type 'R... Remove this comment to see the full error message
    const { transcription, expertId } = this.props;

    const speakers = transcription.speakers || [];
    const monologues = transcription.monologues || [];

    const leftSpeaker =
      speakers.find((x: any) => x.user && x.user.id === expertId) ||
      speakers.find((x: any) => !x.user) ||
      speakers[0] ||
      {};

    const speakerUnknownMap = {};

    return (
      <div className={s.root}>
        {monologues.map((m: any) => {
          const elements = m.elements || [];
          const speaker = m.speaker || {};
          const { user } = speaker;

          const text = elements.map((e: any) => {
            if (e.type === 'tag')
              return (
                <Chip
                  key={`${e.position}${e.value}`}
                  style={{
                    display: 'inline',
                    verticalAlign: 'baseline',
                    marginLeft: 3,
                    marginRight: 3,
                  }}
                  label={e.value}
                />
              );
            return e.value;
          });

          const isLeft = leftSpeaker.id === speaker.id;

          return (
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            <Message
              key={text}
              className={s.message}
              color={isLeft ? darkBrown : white}
              borderColor={isLeft ? sand : darkGreen}
              backgroundColor={isLeft ? sand : darkGreen}
              position={isLeft ? 'left' : 'right'}
              align="top"
              user={user || createSpeakerUser(speaker, speakerUnknownMap)}
            >
              {text}
            </Message>
          );
        })}
      </div>
    );
  }
}

export default Transcript;
