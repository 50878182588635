import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { track } from '@/actions/tracking';
import Button from '@/components/Button/Button';
import EmptyMessage from '@/components/EmptyMessage';
import { darkBlue } from '@/theme/colors';

class SearchPromo extends PureComponent {
  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'track' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { track } = this.props;

    return (
      <div>
        <EmptyMessage
          style={{ maxWidth: 750, padding: 0 }}
          border={false}
          iconName="search"
          iconColor={darkBlue}
          title="Knowledge Network is an Enterprise feature"
          body="Upgrade to an Enterprise account to search your internal knowledge network. Reconnect with Experts, find key phrases within transcripts, and more."
          action={
            <Button
              size="normal"
              onClick={() => {
                track('promo.chat.messaging');
                // @ts-expect-error TS(2339) FIXME: Property 'HubSpotConversations' does not exist on ... Remove this comment to see the full error message
                window.HubSpotConversations?.widget?.open();
              }}
            >
              Start a Chat
            </Button>
          }
        />
      </div>
    );
  }
}

export default connect(undefined, { track })(SearchPromo);
