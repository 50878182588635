import isEqual from 'lodash.isequal';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Select } from '../FormAdapters/FormAdapters';

const SectorType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export default class SelectSector extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    sectors: PropTypes.arrayOf(SectorType),
    onRequestAdd: PropTypes.func,
    onRequestDelete: PropTypes.func,
    value: PropTypes.oneOfType([SectorType, PropTypes.arrayOf(SectorType)]),
    input: PropTypes.object,
    meta: PropTypes.object,
  };

  render() {
    // @ts-expect-error TS(2339): Property 'input' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { input, meta, sectors = [], ...other } = this.props;

    // @ts-expect-error TS(2339): Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const value = this.props.value || (input && input.value) || [];

    return (
      <Select
        // @ts-expect-error TS(2322): Type '{ autocomplete: true; input: any; meta: any;... Remove this comment to see the full error message
        autocomplete
        input={input}
        meta={meta}
        multiple
        options={sectors}
        getOptionLabel={(option: any) => option.name}
        getOptionValue={(option: any) => option}
        isOptionEqualToValue={(option: any, value: any) => isEqual(option, value)}
        rawValueOnChange
        value={value}
        label="Sectors or industries"
        limit={null}
        {...other}
      />
    );
  }
}
