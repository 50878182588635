import MuiTextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';
import isEqual from 'lodash.isequal';
import React, { memo, useEffect, useState } from 'react';
import { Field as FinalField, Form as FinalForm } from 'react-final-form';

import { orange500 } from '@/theme/colors';

import CustomCheckbox from '../Checkbox';
import CustomFileUpload from '../FileUpload';
import CustomPhoneInput from '../PhoneInput';
import CustomRadioGroup from '../RadioGroup';
import CustomSelect from '../Select';
import CustomSelectProfile from '../SelectProfile';
import CustomSelectUser from '../SelectUser';

const useStyles = makeStyles({
  warning: {
    '& .MuiInput-underline:before': {
      borderBottomColor: orange500,
    },
    '& .MuiInput-underline:after': {
      transform: 'scaleX(1)',
      borderBottomColor: orange500,
    },
  },
});

export function areEqual(prevProps: any, nextProps: any) {
  // https://github.com/final-form/react-final-form/issues/598
  // input and meta are never the same object, causing unnecessary renders,
  // so we compare them separately
  const { input: prevInput, meta: prevMeta, ...prevOther } = prevProps;
  const { input: nextInput, meta: nextMeta, ...nextOther } = nextProps;
  return (
    isEqual(prevInput, nextInput) && isEqual(prevMeta, nextMeta) && isEqual(prevOther, nextOther)
  );
}

export const HelperText = (meta: any, props: any) => {
  const classes = useStyles();

  const shouldDisplay = meta.touched || meta.submitFailed;
  const hasError = (shouldDisplay && !!meta.error) || !!meta.submitError;
  const warning = meta.warning || (meta.data && meta.data.warning);
  const hasWarning = shouldDisplay && !hasError && !!warning;
  const helperText = shouldDisplay ? meta.error || meta.submitError || warning : undefined;

  const rootClass = props && props.classes && props.classes.root;
  return {
    helperText,
    error: hasError,
    FormHelperTextProps: {
      style: { color: hasWarning ? orange500 : undefined },
    },
    classes: {
      root: cx(rootClass, { [classes.warning]: hasWarning }),
    },
  };
};
HelperText.displayName = 'HelperText';

export const TextField = memo(
  // @ts-expect-error TS(2339): Property 'changeOnBlur' does not exist on type '{}... Remove this comment to see the full error message
  ({ changeOnBlur = true, inputType, input, maxLength = 0, meta, ...rest }) =>
    changeOnBlur ? (
      <ChangeOnBlurTextField
        input={input}
        meta={meta}
        type={inputType}
        {...rest}
        {...CharacterCounter(maxLength)}
      />
    ) : (
      <MuiTextField type={inputType} {...input} {...rest} {...HelperText(meta, rest)} />
    ),
  areEqual
);
TextField.displayName = 'TextField';

export const CharacterCounter = (maxCharacters = 0) => {
  const [otherTextLength, setOtherTextLength] = useState(0);
  if (maxCharacters > 0) {
    const handleKeyUp = (event: any) => {
      const textLength = event.target.value.length;
      setOtherTextLength(textLength > maxCharacters ? maxCharacters : textLength);
    };
    return {
      onKeyUp: handleKeyUp,
      helperText: otherTextLength + '/' + maxCharacters + ' characters',
      inputProps: { ...{ maxLength: maxCharacters } },
    };
  } else {
    return {};
  }
};

const ChangeOnBlurTextField = ({ input, meta, ...rest }: any) => {
  const [value, setValue] = useState(input.value);

  return (
    <MuiTextField
      {...rest}
      {...HelperText(meta, rest)}
      name={input.name}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => {
        input.onChange(e);
        input.onBlur(e);
      }}
      helperText={rest.helperText}
    />
  );
};

export const Select = memo(
  // @ts-expect-error TS(2339): Property 'autocomplete' does not exist on type '{}... Remove this comment to see the full error message
  ({ autocomplete, input, meta, TextFieldProps = {}, ...rest }) => (
    <CustomSelect
      autocomplete={autocomplete}
      {...input}
      {...rest}
      {...(autocomplete
        ? { TextFieldProps: { ...HelperText(meta, rest), ...TextFieldProps } }
        : HelperText(meta, rest))}
    />
  ),
  areEqual
);
Select.displayName = 'Select';

export const Checkbox = memo(
  // @ts-expect-error TS(2339): Property 'input' does not exist on type '{}'.
  ({ input, meta, ...rest }) => <CustomCheckbox {...input} {...rest} {...HelperText(meta, rest)} />,
  areEqual
);
Checkbox.displayName = 'Checkbox';

export const RadioGroup = memo(
  // @ts-expect-error TS(2339): Property 'input' does not exist on type '{}'.
  ({ input, meta, ...rest }) => (
    <CustomRadioGroup {...input} {...rest} {...HelperText(meta, rest)} />
  ),
  areEqual
);
RadioGroup.displayName = 'RadioGroup';

export const PhoneInput = memo(
  // @ts-expect-error TS(2339): Property 'input' does not exist on type '{}'.
  ({ input, meta, ...rest }) => (
    <CustomPhoneInput {...input} {...rest} {...HelperText(meta, rest)} />
  ),
  areEqual
);
PhoneInput.displayName = 'PhoneInput';

export const FileUpload = memo(
  // @ts-expect-error TS(2339): Property 'input' does not exist on type '{}'.
  ({ input, meta, ...rest }) => (
    <CustomFileUpload {...input} {...rest} {...HelperText(meta, rest)} />
  ),
  areEqual
);
FileUpload.displayName = 'FileUpload';

// Note that this adapter only supports profile select and not free-form email
// input.
// @ts-expect-error TS(2339): Property 'input' does not exist on type '{}'.
export const SelectProfile = memo(({ input, meta, multiple, ...rest }) => {
  // @ts-expect-error TS(2339): Property 'value' does not exist on type '{}'.
  const defaultValue = rest.value;
  const [value, setValue] = useState(defaultValue);

  const onChange = (newValue: any) => {
    setValue(newValue);
    if (multiple) {
      input.onChange(newValue ? newValue.map((v: any) => v.value.id) : []);
    } else {
      input.onChange(newValue ? newValue.value.id : null);
    }
  };

  const shouldDisplay = (meta.touched && meta.modified) || meta.submitFailed;

  return (
    <CustomSelectProfile
      {...rest}
      // @ts-expect-error TS(2769): No overload matches this call.
      onChange={onChange}
      value={value}
      errorText={shouldDisplay ? meta.error : undefined}
      menuStyle={{ maxHeight: 300 }}
    />
  );
}, areEqual);
SelectProfile.displayName = 'SelectProfile';

// Note that this adapter only supports profile select and not free-form email
// input.
// @ts-expect-error TS(2339): Property 'input' does not exist on type '{}'.
export const SelectUser = memo(({ input, meta, multiple, ...rest }) => {
  // @ts-expect-error TS(2339): Property 'value' does not exist on type '{}'.
  const defaultValue = rest.value;
  const [value, setValue] = useState(defaultValue);

  const onChange = (newValue: any) => {
    setValue(newValue);
    if (multiple) {
      input.onChange(newValue || []);
    } else {
      input.onChange(newValue ? newValue.value.id : null);
    }
  };

  const shouldDisplay = (meta.touched && meta.modified) || meta.submitFailed;

  return (
    <CustomSelectUser
      {...rest}
      // @ts-expect-error TS(2769): No overload matches this call.
      onChange={onChange}
      value={value}
      errorText={shouldDisplay ? meta.error : undefined}
      menuStyle={{ maxHeight: 300 }}
      multiple={multiple}
    />
  );
}, areEqual);
SelectUser.displayName = 'SelectUser';

// React-Final-Form does not allow for fields with numeric names.
// We adopt a modified form of the suggestions in:
//
// https://final-form.org/docs/react-final-form/fa
// Why can't I have numeric keys in an object?
//
// Field names are prefixed with a "key" string. Form initial
// values are prefixed similarly using a 'stringifyKeys' method.
// The onSubmit and validate calls transform the values by
// removing the prefix using the 'destringifyKeys'.

export const Field = ({ name, ...other }: any) => {
  return <FinalField name={`key${name}`} {...other} />;
};

export const Form = ({ onSubmit, initialValues, validate, children, ...other }: any) => {
  const stringifyKeys = (values: any) =>
    Object.keys(values).reduce((result, key) => {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      result[`key${key}`] = values[key];
      return result;
    }, {});

  const destringifyKeys = (values: any) =>
    Object.keys(values).reduce((result, key) => {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      result[key.substring(3)] = values[key];
      return result;
    }, {});

  const finalOnSubmit = onSubmit ? (values: any) => onSubmit(destringifyKeys(values)) : undefined;
  const finalInitialValues = initialValues ? stringifyKeys(initialValues) : undefined;
  const finalValidate = validate
    ? (values: any) => stringifyKeys(validate(destringifyKeys(values)))
    : undefined;

  return (
    <FinalForm
      onSubmit={finalOnSubmit}
      initialValues={finalInitialValues}
      validate={finalValidate}
      {...other}
    >
      {children}
    </FinalForm>
  );
};
