import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Collapse from '@mui/material/Collapse';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { X } from 'react-feather';
import { CSSTransition } from 'react-transition-group';

import CollapseToggle from '@/componentsv2/CollapseToggle';
import Tag from '@/componentsv2/Tag';
import { Skeletons } from '@/componentsv2/TagGroup';
import Tooltip from '@/componentsv2/Tooltip';
import { useSelf } from '@/hooks/store';
import { CompleteKnowledge, SimpleKnowledgeRaw } from '@/openapi';
import GeneratingKnowledgeAnimation from '@/profilev2/components/ManageExperiencesDrawer/steps/BulkEditStep/GeneratingKnowledgeAnimation';
import theme from '@/theme';
import { knowledgeCapitalized } from '@/utils/capitalization';

import KnowledgeLookup, { IKnowledgeLookupProps } from '../KnowledgeLookup';

export type KnowledgeTypes = (typeof KNOWLEDGES_ORDER)[number];
type KnowledgeTagData = SimpleKnowledgeRaw & { isNew?: boolean };

export const KNOWLEDGES_TYPE_DICT = (
  isSelf: boolean
): Record<KnowledgeTypes | 'country', string> => ({
  'Area of Expertise': isSelf ? 'Topics you learned about' : 'Topics',
  'Specialized Activity': isSelf ? 'Core Skills you performed' : 'Core Skills',
  Tool: isSelf ? 'Tools you used' : 'Tools',
  'Complementary Skills': 'Complementary Skills',
  country: isSelf ? 'Experience by Countries' : 'Countries',
});

export const KNOWLEDGES_ORDER = [
  'Area of Expertise',
  'Specialized Activity',
  'Tool',
  'Complementary Skills',
];

const KNOWLEDGES_COLLAPSED_BY_DEFAULT: KnowledgeTypes[] = ['Complementary Skills'];

const ITEM_LIMIT = 10;

export const sortNewThenAlpha = (a: KnowledgeTagData, b: KnowledgeTagData) => {
  if (a.isNew && !b.isNew) {
    return -1;
  }

  if (!a.isNew && b.isNew) {
    return 1;
  }

  if (a.name < b.name) {
    return -1;
  }

  if (a.name > b.name) {
    return 1;
  }

  return 0;
};

export const KnowledgeTag = ({
  id,
  name,
  className,
  isNew,
  description,
  index,
  deleteKnowledge,
  style = {},
}: {
  id: string;
  name: string;
  className?: string;
  isNew?: boolean;
  description?: string;
  index?: number;
  deleteKnowledge?: (id: string) => void;
  style?: object;
}) => {
  return (
    <Tag
      key={id}
      size="sm"
      isNew={isNew}
      textClassName="truncate"
      className={clsx(className, 'flex max-w-fit', {
        'animate-in': isNew,
      })}
      style={isNew ? { '--animate-index': index } : style}
      endIcon={
        deleteKnowledge ? (
          <button
            aria-label="close"
            onClick={() => {
              deleteKnowledge(id);
            }}
          >
            <X className="h-16 w-16" />
          </button>
        ) : null
      }
    >
      <Tooltip title={description || ''} enterDelay={500} enterNextDelay={500}>
        <>{name}</>
      </Tooltip>
    </Tag>
  );
};

const KnowledgeGroup = ({
  knowledges,
  knowledgeType,
  deleteKnowledge,
  isLoading,
}: {
  knowledges: KnowledgeTagData[];
  knowledgeType: KnowledgeTypes;
  deleteKnowledge?: (id: string) => void;
  isLoading?: boolean;
}) => {
  const isSelf = useSelf();
  const [isExpanded, setIsExpanded] = useState(
    () =>
      !KNOWLEDGES_COLLAPSED_BY_DEFAULT.includes(knowledgeType) &&
      (isLoading || knowledges.length > 0)
  );
  const prevKnowledgeCount = useRef(knowledges.length);
  const [seeMoreIsExpanded, setSeeMoreIsExpanded] = useState(false);

  useEffect(() => {
    if (KNOWLEDGES_COLLAPSED_BY_DEFAULT.includes(knowledgeType)) {
      return;
    }

    if (isLoading) {
      setIsExpanded(true);
      return;
    }

    if (knowledges.length === 0) {
      setIsExpanded(false);
    }

    if (knowledges.length > prevKnowledgeCount.current) {
      setIsExpanded(true);
    }

    prevKnowledgeCount.current = knowledges.length;
  }, [isLoading, knowledgeType, knowledges.length]);

  return (
    <div className="m-10 rounded-sm border border-light-primary shadow-5">
      <Accordion
        expanded={isExpanded}
        onChange={(_, isExpanded) => setIsExpanded(isExpanded)}
        elevation={0}
      >
        <AccordionSummary
          aria-controls={knowledgeType}
          expandIcon={<ArrowForwardIos className="rotate-90" />}
          sx={{
            minHeight: 40,
            maxHeight: 40,
            '&.Mui-expanded': {
              minHeight: 40,
              maxHeight: 40,
            },
          }}
        >
          <h3 className="subtitle-1">
            {KNOWLEDGES_TYPE_DICT(isSelf)[knowledgeType]} ({knowledges.length})
          </h3>
        </AccordionSummary>
        <AccordionDetails
          id={knowledgeType}
          sx={{
            borderTop: 1,
            borderColor: theme.palette.grey[400],
          }}
        >
          {isLoading ? (
            <div className="grid grid-cols-2 gap-8 sm:grid-cols-3 lg:grid-cols-2">
              <Skeletons tagSize="sm" number={4} />
            </div>
          ) : (
            <>
              <div className="grid grid-cols-2 gap-8 sm:grid-cols-3 lg:grid-cols-2">
                {knowledges
                  .sort(sortNewThenAlpha)
                  .slice(0, ITEM_LIMIT)
                  .map((knowledge, i) => (
                    <KnowledgeTag
                      key={knowledge.id}
                      id={knowledge.id || ''}
                      name={knowledgeCapitalized(knowledge.name)}
                      description={knowledge.description || ''}
                      isNew={knowledge.isNew}
                      index={i}
                      deleteKnowledge={deleteKnowledge}
                    />
                  ))}
              </div>
              <Collapse in={seeMoreIsExpanded}>
                <div className="mt-8 grid grid-cols-2 gap-8">
                  {knowledges
                    .sort(sortNewThenAlpha)
                    .slice(ITEM_LIMIT, knowledges.length)
                    .map((knowledge, i) => (
                      <KnowledgeTag
                        key={knowledge.id}
                        id={knowledge.id || ''}
                        name={knowledgeCapitalized(knowledge.name)}
                        description={knowledge.description || ''}
                        isNew={knowledge.isNew}
                        index={i}
                        deleteKnowledge={deleteKnowledge}
                      />
                    ))}
                </div>
              </Collapse>
              {knowledges.length > ITEM_LIMIT && (
                <CollapseToggle
                  className="mt-16"
                  handleToggle={() => setSeeMoreIsExpanded((prev) => !prev)}
                  expanded={seeMoreIsExpanded}
                />
              )}
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const KnowledgeColumn = ({
  knowledges,
  isUpdating,
  isLoading,
  knowledgeLookupProps,
  addKnowledge,
  deleteKnowledge,
  description = 'This knowledge was extracted from your job details.',
}: {
  knowledges: SimpleKnowledgeRaw[];
  isUpdating?: boolean;
  isLoading?: boolean;
  knowledgeLookupProps?: Omit<IKnowledgeLookupProps, 'onChange'>;
  addKnowledge?: (newKnowledge: CompleteKnowledge | null) => void;
  deleteKnowledge?: (id: string) => void;
  description?: string;
}) => {
  const extractingOverlayRef = useRef(null);
  const [initialKnowledges] = useState(() => knowledges);

  const isNewKnowledge = (knowledge: SimpleKnowledgeRaw) =>
    !initialKnowledges.some((initialKnowledge) => initialKnowledge.id === knowledge.id);

  const groupByType = (knowledges: Array<KnowledgeTagData>) => {
    return knowledges.reduce(
      (acc: { [key: string]: typeof knowledges }, knowledge) => {
        if (knowledge.type) {
          const key = ['Transversal Skill', 'Intrinsic Ability'].includes(knowledge.type)
            ? 'Complementary Skills'
            : knowledge.type;

          acc[key] = acc[key] || [];
          acc[key].push({ ...knowledge, isNew: isNewKnowledge(knowledge) });
        }
        return acc;
      },
      {
        'Area of Expertise': [],
        'Specialized Activity': [],
        Tool: [],
        'Complementary Skills': [],
      }
    );
  };

  const knowledgeTypeGroups = groupByType(knowledges);

  return (
    <section className="flex h-full flex-col">
      <div className="border-b border-grey-400 p-12">
        <h3 className="hd-5">Earned Knowledge & Skills</h3>
        {description ? <p className="mt-4 body-2">{description}</p> : null}
        {addKnowledge ? (
          <KnowledgeLookup
            className="mt-4"
            currentKnowledges={knowledges}
            onChange={(newKnowledge) => addKnowledge(newKnowledge)}
            {...knowledgeLookupProps}
          />
        ) : null}
      </div>
      <div className="relative grow">
        <div className="inset-0 overflow-auto lg:absolute">
          {KNOWLEDGES_ORDER.map((knowledgeType) => (
            <KnowledgeGroup
              key={knowledgeType}
              knowledgeType={knowledgeType}
              knowledges={knowledgeTypeGroups[knowledgeType]}
              deleteKnowledge={deleteKnowledge}
              isLoading={isLoading}
            />
          ))}
        </div>
        <CSSTransition
          nodeRef={extractingOverlayRef}
          in={isUpdating}
          timeout={500}
          mountOnEnter
          unmountOnExit
          classNames={{
            enter: 'opacity-0',
            enterActive: 'opacity-100 transition-opacity duration-500',
            exitActive: 'opacity-0 transition-opacity duration-500',
          }}
        >
          <div
            ref={extractingOverlayRef}
            className="absolute inset-0 z-10 flex flex-col items-center bg-white/80 pt-96 backdrop-blur-sm"
          >
            <span className="hd-6">Automagically extracting knowledge…</span>
            <div className="mt-64 rotate-90">
              <GeneratingKnowledgeAnimation />
            </div>
          </div>
        </CSSTransition>
      </div>
    </section>
  );
};

export default KnowledgeColumn;
