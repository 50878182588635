import cx from 'classnames';
import { FC, useCallback, useState } from 'react';

import { openImageDialog } from '@/core/attachment';
import { useApp } from '@/hooks/useAppContext';
import { white } from '@/theme/colors';

import FAIcon from '../Icon/FAIcon';
import Link from '../Link';
import s from './Image.module.scss';
import ImageOutline from './ImageOutline';

interface ImageComponentProps {
  onChange?: any;
  editable?: boolean;
  src?: string;
  imageStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  input?: React.InputHTMLAttributes<HTMLInputElement>;
  dimensions?: { width: number; height: number };
  alt?: string;
  href?: string;
  accept?: string;
  noStretch?: boolean;
  overlayStyle?: React.CSSProperties;
  emptyText?: string;
  className?: string;
  label?: string;
  keepOutline?: boolean;
}

const Image: FC<ImageComponentProps> = ({
  input,
  editable,
  style,
  imageStyle,
  overlayStyle,
  href,
  alt,
  className,
  dimensions,
  emptyText = 'Upload a photo',
  accept,
  src,
  label,
  keepOutline,
  noStretch,
  onChange,
  ...other
}) => {
  const { graphql } = useApp();

  const [error, setError] = useState(false);

  const handleUpload = useCallback(() => {
    openImageDialog(graphql.client, { dimensions, accept }).then(
      (files) => onChange && onChange(files[0])
    );
  }, [accept, dimensions, graphql.client, onChange]);

  const realSrc = input ? (input.value as string | undefined) : src;

  if (!realSrc && editable) {
    return keepOutline ? (
      <ImageOutline onClick={handleUpload} label={label}>
        <FAIcon iconSet="fal" icon="camera" size={24} style={{ marginBottom: 10 }} />
        {emptyText}
      </ImageOutline>
    ) : (
      <div style={style} className={cx(s.root, s.noimage, className)} onClick={handleUpload}>
        <span>
          <FAIcon iconSet="fal" icon="camera" /> {emptyText}
        </span>
      </div>
    );
  }

  const editNav = editable ? (
    <div className={s.overlay} style={overlayStyle} onClick={handleUpload}>
      <FAIcon iconSet="fal" icon="camera" style={{ marginRight: 0 }} color={white} />
    </div>
  ) : undefined;

  let img =
    !error && realSrc ? (
      <img
        alt={alt}
        src={realSrc}
        style={imageStyle}
        className={cx(s.img, {
          [s.nostretch]: noStretch,
        })}
        onError={() => setError(true)}
        {...other}
      />
    ) : undefined;

  if (img && href && !editable) {
    img = <Link href={href}>{img}</Link>;
  }

  const component = (
    <div style={style} className={cx(s.root, className)}>
      {img}
      {editNav}
    </div>
  );

  return keepOutline ? <ImageOutline label={label}>{component}</ImageOutline> : component;
};

export default Image;
