import { MenuItem } from '@mui/material';
import cx from 'classnames';
import React, { Component } from 'react';

import { darkBlue, darkBrown, teal500, white } from '@/theme/colors';
import { SCREEN_MD } from '@/theme/screens';

import Divider from '../Divider';
import MaterialIcon from '../Icon/MaterialIcon';
import IconButton from '../IconButton';
import Link from '../Link';
import MediaQuery from '../MediaQuery';
import MobileNavigation from '../MobileNavigation';
import s from './MarketingNav.module.scss';
import { marketingLinks } from './marketingLinks';

function DesktopNav({ theme, links }: any) {
  return (
    <div>
      {links
        .filter((l: any) => !l.mobileOnly)
        .map((l: any) => {
          const classes = cx(s.desktopLink, {
            [s.dark]: theme === 'dark',
            [s.light]: theme === 'light',
            [s.border]: l.border,
          });

          return (
            <Link className={classes} key={l.to} to={l.to} force={l.force} id={l.id}>
              {l.text}
            </Link>
          );
        })}
    </div>
  );
}

function MobileLink({ selectedPath, to, onClick, text, divider, force }: any) {
  const linkClassName = cx(s.mobileLink, {
    [s.mobileLinkSelected]: to && selectedPath === to,
  });
  return (
    <MenuItem>
      <Link className={linkClassName} to={to} onClick={onClick} force={force}>
        {text}
      </Link>
      {divider && <Divider height={2} color={teal500} />}
    </MenuItem>
  );
}

class MobileNav extends React.Component {
  state = {
    open: false,
  };

  handleToggle = () => {
    // @ts-expect-error TS(2339): Property 'open' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    this.setState((prevState) => ({ open: !prevState.open }));
  };

  render() {
    // @ts-expect-error TS(2339): Property 'theme' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { theme, selectedPath, links } = this.props;
    const { open } = this.state;
    const iconColor = theme === 'dark' ? white : darkBrown;
    return (
      // @ts-ignore
      <div className={cx(s.mobileNav, s[`${theme}MobileNav`])}>
        <IconButton
          style={{ marginRight: 10, opacity: 0.7 }}
          // @ts-expect-error TS(2339): Property 'HubSpotConversations' does not exist on ... Remove this comment to see the full error message
          onClick={() => window.HubSpotConversations?.widget?.open()}
        >
          <MaterialIcon icon="help_outline" color={iconColor} size={30} />
        </IconButton>
        <IconButton style={{ opacity: 0.7 }} onClick={this.handleToggle}>
          <MaterialIcon icon="menu" color={iconColor} size={30} />
        </IconButton>
        <MobileNavigation
          open={open}
          drawerColor={darkBlue}
          closeButtonColor={teal500}
          onRequestClose={this.handleToggle}
        >
          {links.map((l: any) => (
            <MobileLink
              {...l}
              key={l.text}
              selectedPath={selectedPath}
              onClick={
                l.onClick &&
                (() => {
                  this.handleToggle();
                  l.onClick();
                })
              }
              force={l.force}
            />
          ))}
        </MobileNavigation>
      </div>
    );
  }
}

class Nav extends Component {
  render() {
    const {
      // @ts-expect-error TS(2339): Property 'theme' does not exist on type 'Readonly<... Remove this comment to see the full error message
      theme = 'dark',
      // @ts-expect-error TS(2339): Property 'enableMobile' does not exist on type 'Re... Remove this comment to see the full error message
      enableMobile = true,
      // @ts-expect-error TS(2339): Property 'links' does not exist on type 'Readonly<... Remove this comment to see the full error message
      links = marketingLinks(),
      ...other
    } = this.props;

    return (
      <MediaQuery maxWidth={SCREEN_MD}>
        {(matches: any) =>
          matches && enableMobile ? (
            // @ts-expect-error TS(2769): No overload matches this call.
            <MobileNav theme={theme} links={links} {...other} />
          ) : (
            <DesktopNav theme={theme} links={links} {...other} />
          )
        }
      </MediaQuery>
    );
  }
}

export default Nav;
