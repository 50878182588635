import IconButton from '@mui/material/IconButton';
import React, { PureComponent, useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';

import { removeAddress, requestEmailValidation, setAddress } from '@/actions/address';
import { notify } from '@/actions/ui';
import { ErrAddressAlreadyTaken } from '@/core/address';
import { Profile } from '@/openapi';
import { RootState } from '@/store';
import { darkGreen, red500 } from '@/theme/colors';
import { isEmailValid } from '@/utils';

import Button from '../Button';
import { TextField } from '../FormAdapters';
import FAIcon from '../Icon/FAIcon';
import s from './EditEmails.module.scss';

class Email extends PureComponent {
  handleSetPrimary = () => {
    // @ts-expect-error TS(2339): Property 'address' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { address, onSetPrimary } = this.props;
    if (onSetPrimary) {
      onSetPrimary(address);
    }
  };

  handleVerification = () => {
    this.resendValidation('verify').then(() => {
      // @ts-expect-error TS(2339): Property 'notify' does not exist on type 'Readonly... Remove this comment to see the full error message
      this.props.notify('Verification email sent.');
    });
  };

  handleConfirmation = () => {
    this.resendValidation('confirm').then(() => {
      // @ts-expect-error TS(2339): Property 'notify' does not exist on type 'Readonly... Remove this comment to see the full error message
      this.props.notify('Confirmation email sent.');
    });
  };

  resendValidation = (action: any) => {
    // @ts-expect-error TS(2339): Property 'profileId' does not exist on type 'Reado... Remove this comment to see the full error message
    const { profileId, address, requestEmailValidation } = this.props;
    return requestEmailValidation(profileId, address, action);
  };

  handleRemove = () => {
    // @ts-expect-error TS(2339): Property 'address' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { address, onRemove } = this.props;
    if (onRemove) {
      onRemove(address);
    }
  };

  render() {
    // @ts-expect-error TS(2339): Property 'address' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { address, verified, confirmed, primary, canRemove, userId } = this.props;

    return (
      <div className={s.emailRoot}>
        <div className={s.email}>
          <span className={s.address} title={address}>
            {verified && confirmed && userId && (
              <FAIcon
                icon="check-circle"
                color={darkGreen}
                style={{ marginRight: 8 }}
                title="Verified"
              />
            )}
            {address}
          </span>
          <div className={s.badges}>
            {primary && userId && <div className={s.greenMessage}>Default</div>}
            {!verified && userId && <div className={s.grayMessage}>Unverified</div>}
            {verified && !confirmed && userId && <div className={s.grayMessage}>Unconfirmed</div>}
          </div>
        </div>
        <div className={s.actions}>
          {!verified && userId && (
            <div className={s.verificationAction}>
              <div>Verification sent</div>
              <Button
                color="secondary"
                variant="text"
                onClick={this.handleVerification}
                size="small"
              >
                Resend
              </Button>
            </div>
          )}
          {verified && !confirmed && userId && (
            <div className={s.verificationAction}>
              <div>Confirmation sent</div>
              <Button
                color="secondary"
                variant="text"
                onClick={this.handleConfirmation}
                size="small"
              >
                Resend
              </Button>
            </div>
          )}
          {!primary && verified && confirmed && userId && (
            <Button color="secondary" variant="text" onClick={this.handleSetPrimary} size="small">
              Make Default
            </Button>
          )}
          {canRemove && (
            <IconButton onClick={this.handleRemove} size="large">
              <FAIcon color={red500} icon="trash-o" size={14} />
            </IconButton>
          )}
        </div>
      </div>
    );
  }
}

const WrappedEmail = connect(undefined, {
  requestEmailValidation,
  notify,
})(Email);

interface EditEmailProps {
  userId: string;
  profileId: string;
  profile: Profile;
  setAddress: (
    profileId: string,
    transport: string,
    address: string,
    primary?: boolean
  ) => Promise<any>;
  removeAddress: (profileId: string, transport: string, address: string) => any;
  notify: (message: string, type?: string) => void;
  showSuccessMessage?: boolean;
}

interface FormProps {
  email: string;
}

const EditEmails: React.FC<EditEmailProps> = ({
  profileId,
  setAddress,
  userId,
  profile,
  removeAddress,
  notify,
  showSuccessMessage,
}) => {
  const validate = useCallback(
    (values: FormProps) => {
      const errors: { email?: string } = {};

      if (!values.email) {
        errors.email = 'Required';
      } else if (!isEmailValid(values.email)) {
        errors.email = 'Invalid email';
      } else if (
        profile.emails.some((e: any) => e.address.toLowerCase() === values.email.toLowerCase())
      ) {
        errors.email = 'Email already added';
      }

      return errors;
    },
    [profile.emails]
  );

  const onSubmit = useCallback(
    async (values: FormProps) => {
      const errors: { email?: string } = {};
      try {
        await setAddress(profileId, 'email', values.email);
        if (showSuccessMessage) notify('Email address added.');
      } catch (err: any) {
        if (err.message === ErrAddressAlreadyTaken.message) {
          errors.email = 'Email address is already in use.';
        } else {
          errors.email = 'An error occurred when adding the email address.';
        }
      }
      return errors;
    },
    [notify, profileId, setAddress, showSuccessMessage]
  );

  const handleSetPrimary = useCallback(
    (email: string) => {
      setAddress(profileId, 'email', email, true);
    },
    [profileId, setAddress]
  );

  const handleRemove = useCallback(
    (email: string) => {
      removeAddress(profileId, 'email', email);
    },
    [profileId, removeAddress]
  );

  const { emails } = profile;

  return (
    <div>
      <div className={s.root}>
        {emails.map((e: any) => {
          const canRemove = e.primary
            ? emails.filter((e: any) => e.confirmed && e.accepted).length > 1
            : emails.length > 1;
          return (
            <WrappedEmail
              key={e.address}
              userId={userId}
              profileId={profileId}
              {...e}
              onSetPrimary={handleSetPrimary}
              onRemove={handleRemove}
              canRemove={canRemove}
            />
          );
        })}
      </div>

      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({
          form,
          handleSubmit,
          submitErrors,
          submitting,
          dirtySinceLastSubmit,
          errors,
        }) => (
          <form
            onSubmit={async (values) => {
              const result = await handleSubmit(values);
              if (!result?.email) {
                form.restart();
              }
              return result;
            }}
            className="flex items-start"
          >
            <Field
              id="settingsEmailAddress"
              component={TextField}
              changeOnBlur={false}
              helperText={!dirtySinceLastSubmit && (errors?.email || submitErrors?.email)}
              type="email"
              name="email"
              label="Add email address"
              placeholder="Email address"
              style={{ width: 300, marginRight: 15 }}
            />
            <Button type="submit" size="medium" submitting={submitting} className="mt-18">
              Add
            </Button>
          </form>
        )}
      />
    </div>
  );
};

export default connect(
  (state: RootState, ownProps: { profileId: string }) => ({
    profile: state.profiles.fullProfiles[ownProps.profileId],
  }),
  {
    setAddress,
    removeAddress,
    notify,
  }
)(EditEmails);
