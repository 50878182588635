import Button from '@/components/Button/Button';
import Link from '@/components/Link';

import s from './SelectSignupType.module.scss';

const SelectSignupType = ({ nextUrl, subdomain }: any) => {
  const queryPart = nextUrl ? `?next=${nextUrl}` : '';
  const subdomainPart = subdomain ? `/${subdomain}` : '';

  return (
    <div className={s.root}>
      <h2 className={s.title}>Are you interested in finding an Expert or becoming an Expert?</h2>

      <h4 className={s.subTitle}>You can always change this later</h4>

      <div className={s.actions}>
        <Link className={s.clientAction} href={`${subdomainPart}/signup/member${queryPart}`}>
          <Button size="large" style={{ width: 225 }}>
            Find an Expert
          </Button>
        </Link>
        <Link className={s.expertAction} href={`${subdomainPart}/signup/expert${queryPart}`}>
          <Button size="large" style={{ width: 225 }}>
            Become an Expert
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default SelectSignupType;
