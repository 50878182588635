import { AppThunk } from '@/store';

import { updateUserInfo } from '../store/user';
import ActionTypes from './ActionTypes';

const { ADDRESS__SET_ADDRESS, ADDRESS__REMOVE_ADDRESS } = ActionTypes;

export function setAddress(
  profileId: any,
  transport: any,
  address: any,
  primary = false
): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const data = await graphql.mutate(
      `(
      $profileId: String!
      $address: String!
      $transport: String!
      $primary: Boolean!
    ) {
      setAddress(
        profile_id: $profileId
        address: $address,
        transport: $transport
        primary: $primary
      ) {
        address
        display
        primary
        verified
        primary
      }
    }`,
      { profileId, address, transport, primary }
    );

    dispatch({
      type: ADDRESS__SET_ADDRESS,
      profileId,
      transport,
      address: data.setAddress,
    });
  };
}

export function requestEmailValidation(
  profileId: any,
  email: any,
  action = 'verify'
): AppThunk<Promise<any>> {
  return (_dispatch, _getState, { graphql }) =>
    graphql.mutate(
      `(
    $profileId: String!
    $email: String!
    $action: EmailValidationAction!
  ) {
    requestEmailValidation(
      profile_id: $profileId
      email: $email
      action: $action
    ) {
      address
      display
      primary
      verified
      primary
    }
  }`,
      { profileId, email, action }
    );
}

export function validateEmail(token: string): AppThunk<Promise<any>> {
  return async (dispatch, getState, { graphql }) => {
    const data = await graphql.mutate(
      `($token: String!) {
      validateEmail(token: $token) {
        address
        display
        primary
        verified
        primary
      }
    }`,
      { token }
    );

    const { viewer } = getState();

    if (viewer.id) {
      dispatch({
        type: ADDRESS__SET_ADDRESS,
        profileId: viewer.profile?.id,
        transport: 'email',
        address: data.validateEmail,
      });

      if (data.validateEmail.primary) {
        dispatch(
          updateUserInfo({
            id: viewer.id,
            email: {
              ...viewer.email,
              ...data.validateEmail,
              accepted: data.validateEmail.verified,
            },
          })
        );
      }
    }
  };
}

export function removeAddress(
  profileId: any,
  transport: any,
  address: any
): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const { removeAddress: addresses } = await graphql.mutate(
      `(
      $profileId: String!
      $transport: String!
      $address: String!
    ) {
      removeAddress(
        profile_id: $profileId,
        transport: $transport
        address: $address
      ) {
        address
        display
        primary
        verified
        primary
        confirmed
      }
    }`,
      { profileId, transport, address }
    );

    dispatch({
      type: ADDRESS__REMOVE_ADDRESS,
      profileId,
      transport,
      addresses,
    });
  };
}
