import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { fetchGroupMembers, removeGroupMember, updateGroupMember } from '@/actions/group';
import { notify } from '@/actions/ui';
import Breadcrumbs from '@/components/Breadcrumbs';
import Button from '@/components/Button';
import CircularProgress from '@/components/CircularProgress/CircularProgress';
import CountBox from '@/components/CountBox';
import Divider from '@/components/Divider';
import GroupMember from '@/components/GroupMember';
import FAIcon from '@/components/Icon/FAIcon';
import Link from '@/components/Link';
import MemberRequest from '@/components/MemberRequests/Group';
import Pagination from '@/components/Pagination/Pagination';
import { APIError } from '@/core/api';
import { money } from '@/core/money';
import { Viewer } from '@/core/viewer';
import { borderColor, darkGray, primary } from '@/theme/colors';
import { debounce, formatCredits, normalizeSpace } from '@/utils';

import AddTeamMemberDialog from './AddTeamMemberDialog';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerLink: {
    whiteSpace: 'nowrap',
    marginLeft: 15,
    fontSize: 14,
  },
  members: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  membersActions: {
    width: 200,
  },
  membersActionsTitle: {
    color: darkGray,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  membersList: {
    flex: 1,
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 20,
    borderTop: `1px solid ${borderColor}`,
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 25,
    '& span': {
      paddingTop: 15,
    },
  },
  counts: {
    display: 'flex',
    flexWrap: 'wrap',
    // @ts-expect-error TS(2339) FIXME: Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('md')]: {
      '& > *': {
        width: 170,
        paddingBottom: 10,
      },
    },
  },
  search: {
    marginLeft: 'auto',
    // @ts-expect-error TS(2339) FIXME: Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('md')]: {
      marginTop: 10,
      width: 190,
    },
  },
}));

function Team(
  this: any,
  { viewer, groupId, updateGroupMember, removeGroupMember, team, fetchGroupMembers, notify }: any
) {
  const s = useStyles();

  const { members_awaiting: awaitingPage } = team;
  const { name, stats = {}, html_url: htmlUrl, billing_account: billingAccount } = team;

  const [addOpen, setAddOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [members, setMembers] = useState({ edges: [] });
  const [totalMembers, setTotalMembers] = useState(0);
  const [nameSearch, setNameSearch] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchPageMembers = useCallback(
    async ({ name = nameSearch } = {}) => {
      const members = await fetchGroupMembers(groupId, {
        page,
        name,
      });

      // if empty, decrease page
      if (page > 1 && !members.edges.length) {
        setPage(page - 1);
      }

      setMembers(members);
      setLoading(false);
    },
    [groupId, page, fetchGroupMembers]
  );

  const fetchTotalMembers = useCallback(async () => {
    const members = await fetchGroupMembers(groupId, {
      limit: 1,
    });
    setTotalMembers(members.pageInfo?.total);
  }, [groupId, fetchGroupMembers]);

  const debounceSearch = useCallback(
    debounce((name: any) => {
      fetchPageMembers({ name });
    }, 500),
    [fetchPageMembers]
  );

  useEffect(() => {
    fetchPageMembers({ name: nameSearch });
  }, [fetchPageMembers]);

  useEffect(() => {
    fetchTotalMembers();
  }, [fetchTotalMembers]);

  useEffect(() => {
    if (nameSearch === null) return;
    debounceSearch(nameSearch);
  }, [nameSearch]);

  const handleAddClose = () => {
    setAddOpen(false);
  };

  const handleAdd = () => {
    setAddOpen(true);
  };

  const fetchMembers = () => {
    fetchPageMembers({ name: nameSearch });
    fetchTotalMembers();
  };

  const removeGroupMemberAndFetch = (...params: any[]) => {
    setLoading(true);
    removeGroupMember
      .apply(this, params)
      .then(() => {
        fetchMembers();
      })
      .catch((e: any) => {
        if (e instanceof APIError) {
          e.rawError.map((error: any) => notify(error.message));
        }
        fetchMembers();
      });
  };

  const updateGroupMemberAndFetch = (...params: any[]) => {
    setLoading(true);
    updateGroupMember
      .apply(this, params)
      .then(() => {
        fetchMembers();
      })
      .catch((e: any) => {
        if (e instanceof APIError) {
          e.rawError.map((error: any) => notify(error.message));
        }
        fetchMembers();
      });
  };

  const awaitingMembers = useMemo(() => {
    if (!awaitingPage) return null;
    const awaiting = awaitingPage.edges.map((x: any) => x.node).filter((x: any) => x.user);
    if (!awaiting.length) return null;

    return <MemberRequest onApprove={fetchMembers} memberRequests={awaiting} />;
  }, [awaitingPage]);

  const creditBalance = billingAccount
    ? money(billingAccount.credit_balance)
    : money({ cents: 0, currency: 'OFC' });

  const permissions = team.permissions || [];
  const viewerMember = members.edges.find(
    // @ts-expect-error TS(2339) FIXME: Property 'user' does not exist on type 'never'.
    ({ node: m }) => m.user && m.user.id === viewer.id
  );
  // @ts-expect-error TS(2339) FIXME: Property 'role' does not exist on type 'never'.
  const viewerRole = (viewerMember && viewerMember.role) || 'owner';

  const memberIDs = members.edges
    // @ts-expect-error TS(2339) FIXME: Property 'user' does not exist on type 'never'.
    .map(({ node: m }) => m.user && m.user.id)
    .filter((x) => x);
  const addUserFilter = (suggestionCandidate: any) => !memberIDs.includes(suggestionCandidate.id);

  const perms = {
    add: permissions.some((x: any) => x === 'add_member'),
    update: {
      owner: permissions.some((x: any) => x === 'update_owner'),
      admin: permissions.some((x: any) => x === 'update_admin'),
      member: permissions.some((x: any) => x === 'update_member'),
    },
    remove: {
      owner: permissions.some((x: any) => x === 'remove_owner'),
      admin: permissions.some((x: any) => x === 'remove_admin'),
      member: permissions.some((x: any) => x === 'remove_member'),
    },
    allowedRoles: permissions
      .filter((x: any) => x.startsWith('update_'))
      .map((x: any) => x.replace(/^update_/, '')),
  };

  // @ts-expect-error TS(2339) FIXME: Property 'pageInfo' does not exist on type '{ edge... Remove this comment to see the full error message
  const totalPages = members.pageInfo?.lastPage ?? 0;

  return (
    // @ts-expect-error TS(2339) FIXME: Property 'root' does not exist on type 'ClassNameM... Remove this comment to see the full error message
    <div className={s.root}>
      <div className={s.header}>
        <Breadcrumbs
          crumbs={[
            {
              title: name,
              href: htmlUrl,
            },
          ]}
        />
        {team.internal_network && (
          <Link className={s.headerLink} href={`/search?networks[]=${team.internal_network.id}`}>
            <FAIcon icon="users-class" /> View Network
          </Link>
        )}
        <Link className={s.headerLink} href={`${htmlUrl}/settings`}>
          <FAIcon icon="cog" /> Settings &amp; Billing
        </Link>
      </div>
      {awaitingMembers}
      <div className={s.counts}>
        <CountBox count={totalMembers} label="Team Members" />
        <CountBox count={stats.expert_request_count} label="Expert Requests" />
        <CountBox count={stats.consultation_count} label="Consultations" />
        <CountBox
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          count={formatCredits(creditBalance.cents)}
          warn={creditBalance.cents <= 0}
          linkTo={billingAccount && `${htmlUrl}/settings/credits`}
          label="Credits"
        />
        <div className={s.search}>
          <TextField
            value={nameSearch ?? ''}
            margin="none"
            fullWidth={false}
            onChange={({ target: { value } }) => {
              setPage(1);
              // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
              setNameSearch(normalizeSpace(value));
            }}
            placeholder="Filter by name"
          />
        </div>
      </div>
      <Divider />
      <div className={s.members}>
        <div className={s.membersActions}>
          <div className={s.membersActionsTitle}>Team Members</div>
          <Button
            onClick={handleAdd}
            color="white"
            variant="text"
            fontColor={primary}
            disabled={!perms.add}
            size="small"
          >
            Add
          </Button>
          <AddTeamMemberDialog
            groupId={groupId}
            userFilter={addUserFilter}
            open={addOpen}
            onSubmit={() => {
              handleAddClose();
              setLoading(true);
            }}
            onClose={handleAddClose}
            onRequestDone={fetchMembers}
          />
        </div>
        <div className={s.membersList}>
          {loading ? (
            <div className={s.loading}>
              <CircularProgress size={52} />
              <span>Loading...</span>
            </div>
          ) : (
            members.edges.map(({ node: m }) => (
              <GroupMember
                // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
                key={m.id}
                // @ts-expect-error TS(2322) FIXME: Type '{ key: any; member: never; groupId: any; vie... Remove this comment to see the full error message
                member={m}
                groupId={groupId}
                viewerRole={viewerRole}
                removeGroupMember={
                  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  perms.remove[m.role] ? removeGroupMemberAndFetch : null
                }
                updateGroupMember={
                  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  perms.update[m.role] ? updateGroupMemberAndFetch : null
                }
                allowedRoles={perms.allowedRoles}
              />
            ))
          )}
          {totalPages > 1 && (
            <div className={s.pagination}>
              <Pagination
                page={page}
                count={totalPages}
                onChange={(_: any, value: any) => setPage(value)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps: (
  state: { viewer: Viewer; groups: any },
  ownProps: { groupId: string }
) => {} = (state, ownProps) => ({
  viewer: state.viewer,
  groupId: ownProps.groupId,
  team: (state.groups.all.edges.find((g: any) => g.node.id === ownProps.groupId) || {}).node,
});

export default connect(mapStateToProps, {
  removeGroupMember,
  updateGroupMember,
  fetchGroupMembers,
  notify,
})(Team);
