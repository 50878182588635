import LoginAs from '@/auth/components/LoginAs';
import WrapperComponent from '@/components/WrapperComponent';

export default {
  subdomain: '*',
  path: '/loginas',
  element: <WrapperComponent />,
  async action({ query }: any) {
    return <LoginAs query={query} />;
  },
};
