import cx from 'classnames';
import React, { PureComponent } from 'react';

import Link from '../Link';
import s from './CountBox.module.scss';

interface CountBoxProps {
  count?: number;
  label?: string;
  className?: string;
  grayOutZero?: boolean;
  linkTo?: string;
  labelClassName?: string;
  selected?: boolean;
  warn?: boolean;
  onClick?: (e: any) => void;
}

class CountBox extends PureComponent<CountBoxProps> {
  render() {
    const {
      count = 0,
      grayOutZero,
      label,
      warn,
      linkTo,
      onClick,
      className,
      labelClassName,
      selected,
    } = this.props;

    const grayOut = grayOutZero && count === 0;

    const contents = (
      <div
        className={cx(s.root, className, {
          [s.grayOut]: grayOut,
          [s.selected]: selected,
          [s.warn]: warn,
        })}
      >
        <div className={s.count}>{count}</div>
        {label && (
          <span
            className={cx(s.label, {
              [labelClassName || '']: !!labelClassName,
            })}
          >
            {label}
          </span>
        )}
      </div>
    );

    return linkTo || onClick ? (
      <Link href={linkTo || '#'} onClick={onClick}>
        {' '}
        {contents}{' '}
      </Link>
    ) : (
      contents
    );
  }
}

export default CountBox;
