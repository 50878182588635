import { TaskStatus } from '@/componentsv2/Task/types';
import { PRODUCT_NAME } from '@/config';

import { TASKS } from '../store/onboardingSlice';

type ValueOf<T> = T[keyof T];

export const FE_ONLY_TASKS = {
  INTRO_VIDEO: 'intro-video' as const,
};
export type FE_ONLY_TASK_SLUG = ValueOf<typeof FE_ONLY_TASKS>;

export const convertProgressToStatus = (progress: string | null): TaskStatus => {
  const asNum = Number(progress);

  if (asNum === 0) {
    return 'not-started';
  }

  if (asNum < 100) {
    return 'in-progress';
  }

  return 'completed';
};

export const INITIAL_COMPLETED_TASKS = [
  {
    slug: FE_ONLY_TASKS.INTRO_VIDEO,
    progress: '100',
  },
];

/**
 * @TODO
 * - add USER_MISSION_STATEMENT
 * - add SEARCH_AND_TRAVEL_PREFERENCES
 */

const ALL_TASKS = {
  [TASKS.CONFIRM_PERSONAL_INFORMATION]: {
    title: 'Confirm Personal Information',
    description:
      'Add your preferred name, years of experience, and your current location to your profile.',
    estimatedTimeInMinutes: 4,
  },
  [TASKS.CONFIRM_CURRENT_CONTRACTS]: {
    title: 'Add Your Project Experiences or New Business Activities',
    description: 'Search the database for projects and proposals to link and add to your profile.',
    estimatedTimeInMinutes: 4,
  },
  [TASKS.REVIEW_ALL_CONTRACTS]: {
    title: 'Review Project Experiences or New Business Activities',
    description:
      'These experiences are at the core of how your earned knowledge and skills are mapped. You can come back to this at any time.',
    estimatedTimeInMinutes: 4,
  },
  [TASKS.ADD_PROFESSIONAL_HISTORY]: {
    title: 'Add Your Professional History',
    description:
      'Upload your CV/Resume. If you don’t have one on file it’s okay - you will be able to add manually.',
    estimatedTimeInMinutes: 5,
  },
  [TASKS.REVIEW_ALL_PROFESSIONAL_HISTORY]: {
    title: 'Review Your Professional History',
    description:
      'These experiences are the core of how your earned knowledge and skills are mapped. You can come back to this at any time.',
    estimatedTimeInMinutes: 5,
  },
  [TASKS.ADD_AVAILABILITY]: {
    title: 'Set Your Work and Travel Availability',
    description:
      'This will help people understand if you have capacity to help with other projects or are willing to travel.',
    estimatedTimeInMinutes: 1,
  },
  [TASKS.ADD_LANGUAGE]: {
    title: 'Add Your Languages to Your Profile',
    description:
      'Languages play a big role in where and what assignments and opportunities are open to you.',
    estimatedTimeInMinutes: 2,
  },
  // [TASKS.USER_MISSION_STATEMENT]: {
  //   title: 'TODO',
  //   description: 'TODO',
  //   estimatedTimeInMinutes: 5,
  // },
  // [TASKS.SEARCH_AND_TRAVEL_PREFERENCES]: {
  //   title: 'TODO',
  //   description: 'TODO',
  //   estimatedTimeInMinutes: 5,
  // },
  [FE_ONLY_TASKS.INTRO_VIDEO]: {
    title: `Watch the Intro to ${PRODUCT_NAME} Video`,
    description: `An introductory welcome video to the ${PRODUCT_NAME} product.`,
    estimatedTimeInMinutes: 5,
  },
};

export default ALL_TASKS;
