import { AnyAction } from 'redux';

import ActionTypes from '@/actions/ActionTypes';
import { Page } from '@/core/paging';
import { EXPERT_REQUEST__DELETE, EXPERT_REQUEST__UPDATE } from '@/expertrequest/store';
import { add, mergeAt, removeAt } from '@/utils/reducer';

const {
  PROJECT__LIST,
  PROJECT__LIST_LOADED,
  PROJECT__LIST_LOADING,
  PROJECT__LIST_INVALIDATE,

  PROJECT__UPDATE,
  PROJECT__DELETE,
  PROJECT__ADD_MEMBER,
  PROJECT__UPDATE_MEMBER,
  PROJECT__REMOVE_MEMBER,

  UI__SET_USER_CONTEXT,
} = ActionTypes;

export type CollectionType = 'default' | 'dashboard' | 'names';

interface ProjectMember {
  id: string;
}

interface Project {
  id: string;
  name: string;
  summary: string;
  members: ProjectMember[];
  group: any;
  expert_requests: any[];
  permissions: any;
  tracking_code: any;
}

export interface CollectionState extends Page<Project> {
  loading: boolean;
  resetAt?: Date;
}

type ProjectsState = {
  [key in CollectionType]: CollectionState;
};

const initialCollectionState: CollectionState = {
  edges: [],
  pageInfo: { hasNextPage: true },
  loading: false,
  resetAt: undefined,
};

const initialState: ProjectsState = {
  default: initialCollectionState,
  dashboard: initialCollectionState,
  names: initialCollectionState,
};

export default function projectsReducer(state = initialState, action: AnyAction): ProjectsState {
  switch (action.type) {
    case PROJECT__LIST_INVALIDATE:
      return initialState;

    case PROJECT__LIST:
    case PROJECT__LIST_LOADED:
    case PROJECT__LIST_LOADING: {
      const col = action.collection;
      return {
        ...state,
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        [col]: collectionReducer(state[col], action),
      };
    }
    case UI__SET_USER_CONTEXT:
      return initialState;

    case PROJECT__DELETE:
    case PROJECT__UPDATE:
    case PROJECT__ADD_MEMBER:
    case PROJECT__REMOVE_MEMBER:
    case PROJECT__UPDATE_MEMBER:
    case EXPERT_REQUEST__UPDATE:
    case EXPERT_REQUEST__DELETE: {
      const newState = { ...state };
      Object.keys(state).forEach((collection) => {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        newState[collection] = collectionReducer(state[collection], action);
      });
      return newState;
    }
    default:
      return state;
  }
}

function collectionReducer(state = initialCollectionState, action: AnyAction): CollectionState {
  switch (action.type) {
    case PROJECT__LIST: {
      const { reset, edges, pageInfo } = action;
      if (reset) {
        return { loading: false, edges, pageInfo, resetAt: new Date() };
      }
      return { loading: false, edges: [...state.edges, ...edges], pageInfo };
    }
    case PROJECT__LIST_LOADING:
      return { ...state, loading: true };
    case PROJECT__LIST_LOADED:
      return { ...state, loading: false };

    case PROJECT__UPDATE:
      return (() => {
        const index = state.edges.findIndex((p) => p.node.id === action.project.id);
        const currentEdge = index > -1 ? state.edges[index] : {};
        const updatedEdge = {
          ...currentEdge,
          // @ts-expect-error TS(2339): Property 'node' does not exist on type '{}'.
          node: projectReducer(currentEdge.node || {}, action),
        };
        return {
          ...state,
          edges:
            index > -1
              ? mergeAt(state.edges, index, updatedEdge)
              : // @ts-expect-error TS(2554): Expected 3 arguments, but got 2.
                add(state.edges, updatedEdge),
        };
      })();
    case PROJECT__DELETE: {
      const { edges: edgesToDelete } = state;
      return {
        ...state,
        edges: removeAt(
          edgesToDelete,
          edgesToDelete.findIndex((p) => p.node.id === action.id)
        ),
      };
    }
    case PROJECT__ADD_MEMBER:
    case PROJECT__UPDATE_MEMBER:
    case PROJECT__REMOVE_MEMBER:
    case EXPERT_REQUEST__UPDATE:
    case EXPERT_REQUEST__DELETE:
      return (() => {
        const { projectId } = action;
        const projectIndex = state.edges.findIndex((p) => p.node.id === projectId);
        if (projectIndex < 0) return state;
        const original = state.edges[projectIndex];
        const updated = {
          ...original,
          node: projectReducer(original.node, action),
        };
        return {
          ...state,
          edges: mergeAt(state.edges, projectIndex, updated),
        };
      })();

    default:
      return state;
  }
}

export function projectReducer(state = {}, action: any) {
  switch (action.type) {
    case PROJECT__UPDATE: {
      const newPrj = action.project;
      const newMembers =
        newPrj.members &&
        newPrj.members.map((m: any) => {
          const currentMember =
            // @ts-expect-error TS(2339): Property 'members' does not exist on type '{}'.
            state.members && state.members.find((e: any) => e.id === m.id);
          if (!currentMember) return m;
          return {
            ...currentMember,
            ...m,
            user:
              currentMember.user || m.user
                ? {
                    ...currentMember.user,
                    ...m.user,
                  }
                : null,
          };
        });
      return {
        ...state,
        ...newPrj,
        members: newMembers,
      };
    }
    case PROJECT__ADD_MEMBER:
    case PROJECT__UPDATE_MEMBER:
    case PROJECT__REMOVE_MEMBER:
      return {
        ...state,
        // @ts-expect-error TS(2339): Property 'members' does not exist on type '{}'.
        members: membersReducer(state.members, action),
      };

    case EXPERT_REQUEST__UPDATE:
      return (() => {
        // @ts-expect-error TS(2339): Property 'expert_requests' does not exist on type ... Remove this comment to see the full error message
        const erIndex = (state.expert_requests || []).findIndex(
          (er: any) => er.id === action.expertRequest.id
        );
        if (erIndex < 0) return state;
        // @ts-expect-error TS(2339): Property 'expert_requests' does not exist on type ... Remove this comment to see the full error message
        const er = state.expert_requests[erIndex];
        return {
          ...state,
          // @ts-expect-error TS(2339): Property 'expert_requests' does not exist on type ... Remove this comment to see the full error message
          expert_requests: mergeAt(state.expert_requests, erIndex, {
            ...er,
            ...action.expertRequest,
          }),
        };
      })();
    case EXPERT_REQUEST__DELETE:
      return (() => {
        // @ts-expect-error TS(2339): Property 'expert_requests' does not exist on type ... Remove this comment to see the full error message
        const erIndex = (state.expert_requests || []).findIndex((er: any) => er.id === action.id);
        if (erIndex < 0) return state;
        return {
          ...state,
          // @ts-expect-error TS(2339): Property 'expert_requests' does not exist on type ... Remove this comment to see the full error message
          expert_requests: removeAt(state.expert_requests, erIndex),
        };
      })();

    default:
      return state;
  }
}

function membersReducer(state = [], action: any) {
  switch (action.type) {
    case PROJECT__ADD_MEMBER:
      return (() => {
        if (
          state.findIndex((m) => {
            // @ts-expect-error TS(2339): Property 'user' does not exist on type 'never'.
            if (m.user)
              // @ts-expect-error TS(2339): Property 'user' does not exist on type 'never'.
              return action.member.user && action.member.user.id === m.user.id;
            // @ts-expect-error TS(2339): Property 'email' does not exist on type 'never'.
            if (m.email) return m.email === action.member.email;
            // don't add member that is already in
            return false;
          }) >= 0
        )
          return state;
        return [...state, action.member];
      })();
    case PROJECT__UPDATE_MEMBER:
      return (() => {
        // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
        const index = state.findIndex((m) => m.id === action.member.id);
        return mergeAt(state, index, action.member);
      })();
    case PROJECT__REMOVE_MEMBER:
      return (() => {
        // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
        const index = state.findIndex((m) => m.id === action.member.id);
        return removeAt(state, index);
      })();

    default:
      return state;
  }
}
