import React from 'react';

import { validateToken } from '@/actions/token';
import RequestPasswordReset from '@/auth/components/RequestPasswordReset';
import SetPassword from '@/auth/components/SetPassword';
import LayoutPage from '@/components/Layout/LayoutPage';
import WrapperComponent from '@/components/WrapperComponent';

export default {
  path: '/password_reset',
  // @ts-ignore
  element: <WrapperComponent />,
  async action({ store, query }: any) {
    const { password_token: token } = query;

    let isInvalid = false;
    try {
      if (token) {
        await store.dispatch(validateToken(token));
      }
    } catch {
      isInvalid = true;
    }

    document.title = 'Reset Password | OnFrontiers';
    return (
      <LayoutPage>
        {token ? <SetPassword token={token} isInvalid={isInvalid} /> : <RequestPasswordReset />}
      </LayoutPage>
    );
  },
};
