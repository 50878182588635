import React from 'react';
import { redirect } from 'react-router-dom';

import ChangePassword from '@/auth/components/ChangePassword';
import LayoutPage from '@/components/Layout/LayoutPage';
import WrapperComponent from '@/components/WrapperComponent';

export default {
  path: '/change_password',
  // @ts-ignore
  element: <WrapperComponent />,
  async action({ store, query }: any) {
    const { viewer } = store.getState();

    if (!viewer.id) {
      return redirect('/login');
    }

    document.title = 'Change Password';
    return (
      <LayoutPage hideSearch showNewRequest={false} showReviewConsultation={false}>
        <ChangePassword next={query.next} />
      </LayoutPage>
    );
  },
};
