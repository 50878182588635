import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Select } from '../FormAdapters/FormAdapters';

type NameableType = { id: string; name: string };

interface SelectNameableProps extends NameableType {
  multiple?: boolean;
  options?: { id: string; name: string }[];
  value?: { id: string; name: string } | { id: string; name: string }[];
  input?: any;
  meta?: any;
}

export default class SelectNameable extends Component<SelectNameableProps> {
  label?: string;
  placeholder?: string;

  render() {
    const { id, input, meta, multiple = false, options = [], ...other } = this.props;
    const value = this.props.value || (input && input.value) || [];

    return (
      <Select
        // @ts-expect-error
        autocomplete
        input={input}
        meta={meta}
        multiple={multiple}
        fullWidth
        openOnFocus
        options={options}
        getOptionLabel={(option: { name: any }) => option?.name}
        getOptionValue={(option: { id: any }) => option?.id}
        isOptionEqualToValue={(option: any, value: any) => option === value}
        // Show all requests at all times
        limit={null}
        rawValueOnChange
        value={value}
        {...other}
      />
    );
  }
}
