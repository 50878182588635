import { Viewer } from './viewer';

export function isAdvancedUser(user: Viewer) {
  return (
    user.admin ||
    (user.groups || []).some((g) => ['advanced', 'enterprise'].includes(g.account_type))
  );
}

export function isEnterpriseUser(user: Viewer) {
  return user.admin || (user.groups || []).some((g) => g.account_type === 'enterprise');
}
