/// <reference types="@types/intercom-web" />
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
//import { detect } from 'detect-browser';
import moment from 'moment';

import { CLIENT_NAME } from '@/config';
import { CurrentUser } from '@/core/viewer';
import { updateTasks } from '@/dashboardv2/store/onboardingSlice';
import { Me, MeHistory, MeService, PatchedProfileUpdate } from '@/openapi';
import initialState from '@/store/initialState';
import IState from '@/store/state';
import { calculateDateDiff } from '@/utils/date';

export const SIGN_IN_KEY = 'of_sign_in_trigger';

export const fetchData = createAsyncThunk(
  'user/fetchData',
  async (_: void, { rejectWithValue, dispatch }) => {
    return MeService.meRetrieve()
      .then((data) => {
        dispatch(updateTasks(data.tasks));
        return data;
      })
      .catch((e) => rejectWithValue(e.status));
  }
);

export const updateUser = createAsyncThunk('user/updateUser', async (data: Me) => {
  // Every change on userData will propagate again to Intercom, e.g.: onboarding completed at
  initializeIntercom(data);
  return data;
});

export const updateMe = createAsyncThunk(
  'user/updateMe',
  async (profile: PatchedProfileUpdate, { rejectWithValue }) =>
    await MeService.meUpdatePartialUpdate(profile).catch(() => {
      return rejectWithValue('Unable to save. Please contact support');
    })
);

export const updateMeHistory = createAsyncThunk(
  'user/updateMeHistory',
  async (requestBody: Array<MeHistory>, { rejectWithValue }) =>
    await MeService.meHistoryCreate(requestBody).catch(() => {
      return rejectWithValue('Unable to save. Please contact support');
    })
);

//const browser = detect();

const initializeIntercom = (user: Me) => {
  const onboardingAt = moment(user.onboarding_completed_at);
  const cvAt = moment(user.fields_updated_at.find((v) => v.field === 'cv_url')?.updated_at);

  const [firstName, lastName] = (user.full_name || '').split(/\s(.*)/s).filter(Boolean);

  const [clientExperienceTime, clientExperienceType] = calculateDateDiff(
    user.client_experience_relative_date
  );

  const years_at_client =
    (clientExperienceType === 'years' && clientExperienceTime
      ? clientExperienceTime
      : clientExperienceType === 'months'
        ? 0
        : user.client_total_experience) || 0;

  window.intercomSettings = {
    name: user.full_name || '',
    first_name: firstName,
    last_name: lastName,
    user_hash: user.user_intercom_hash || '',
    user_id: user.id,
    location: user.country,
    email: user.email || '',
    work_email: user.email,
    personal_email: user.secondary_email,
    job_title: user.job_title || '',
    department: user.department || '',
    years_at_client,
    onboarding_completed_at: onboardingAt.isValid() ? onboardingAt.unix() : null,
    resume_uploaded_at: user.cv_url && cvAt.isValid() ? cvAt.unix() : null,
    organization_name: CLIENT_NAME,
    domain_name: window.location.host.split('.').shift(),
  };
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialState.user,
  reducers: {
    resetData: () => initialState.user,
  },
  extraReducers: (builder) => {
    builder.addCase(updateUser.fulfilled, (state, action) => ({
      ...state,
      data: action.payload,
    }));
    builder.addCase(updateMe.fulfilled, (state, action) => ({
      ...state,
      data: action.payload,
    }));
    builder.addCase(fetchData.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(fetchData.fulfilled, (state, action) => {
      initializeIntercom(action.payload);

      Sentry.setUser({
        email: action.payload.email || '',
        id: action.payload.id,
      });

      CurrentUser.EMAIL = action.payload.email;
      CurrentUser.OF_ID = action.payload.id;
      CurrentUser.NAME = action.payload.full_name;
      // CurrentUser.BROWSER = `${browser?.name} ${browser?.version}`;
      CurrentUser.LOCATION = action.payload.country;

      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    });
    builder.addCase(fetchData.rejected, (state, action) => ({
      ...state,
      isLoading: false,
      error: {
        message: '',
        status: action.payload as number,
      },
    }));
  },
});

export const userSelector = (state: IState) => state.user;
export const { resetData: resetUser } = userSlice.actions;
export default userSlice.reducer;
