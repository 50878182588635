import MaterialButton from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setLoadingProgress } from '@/actions/loading';
import { toQueryString } from '@/core/search';
import { RootState } from '@/store';
import { darkBlue, grey50, grey100 } from '@/theme/colors';

import Form from '../Form';
import MaterialIcon from '../Icon/MaterialIcon';

const useSearchStyles = makeStyles((theme) => ({
  search: {
    border: `1px solid ${grey100}`,
    borderRadius: 4,
    paddingLeft: 12,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    // @ts-expect-error TS(2339): Property 'breakpoints' does not exist on type 'Def... Remove this comment to see the full error message
    [theme.breakpoints.down('xl')]: {
      backgroundColor: grey50,
    },
  },
  searchIcon: {
    fontSize: 26,
    marginRight: '6px !important',
  },
}));

function Search({ viewer, query }: any) {
  const navigate = useNavigate();
  const s = useSearchStyles();

  const [text, setText] = useState(query?.text ?? '');
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xl'));

  useEffect(() => {
    const newText = query?.text ?? '';
    if (newText !== text) {
      setText(newText);
    }
  }, [query]);

  const handleChange = ({ target: { value } }: any) => {
    setText(value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoadingProgress(10);
    navigate(
      `/search?${toQueryString({
        ...query,
        text,
        sort: undefined,
        ascending: undefined,
      })}`
    );
  };

  return (
    <Form onSubmit={handleSubmit} className={s.search} id="searchForm">
      <MaterialIcon icon="search" className={s.searchIcon} />

      <TextField
        id="search"
        name="q"
        placeholder="Search Your Knowledge Network"
        className="m-0 text-10"
        InputProps={{
          disableUnderline: true,
        }}
        margin="none"
        value={text}
        onChange={handleChange}
        fullWidth
        autoFocus={mobile}
      />

      {mobile && (
        <MaterialButton type="submit" size="small" style={{ color: darkBlue, fontSize: 16 }}>
          Go
        </MaterialButton>
      )}
    </Form>
  );
}

export default connect((state: RootState) => ({
  viewer: state.viewer,
  query: state.search.query,
}))(Search);
