import MuiAutocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Popper from '@mui/material/Popper';
import MuiSelect from '@mui/material/Select';
import MuiTextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import isEqual from 'lodash.isequal';
import React, { memo, useCallback, useEffect, useState } from 'react';

const defaultGetOptionValue = (o: any) => (o && o.value) || '';
const defaultGetOptionLabel = (o: any) => (o && o.label) || '';
const defaultIsOptionEqualToValue = (o: any, v: any) => o.value === v.value;

const defaultFilterOptions = (limit: any, getOptionLabel: any) =>
  createFilterOptions({
    limit,
    trim: true,
    stringify: getOptionLabel,
  });

export const Autocomplete = memo(
  ({
    // Autocomplete props
    // @ts-expect-error TS(2339): Property 'value' does not exist on type '{}'.
    value,
    // @ts-expect-error TS(2339): Property 'multiple' does not exist on type '{}'.
    multiple,
    // @ts-expect-error TS(2339): Property 'options' does not exist on type '{}'.
    options = [],
    // @ts-expect-error TS(2339): Property 'onChange' does not exist on type '{}'.
    onChange,
    // @ts-expect-error TS(2339): Property 'rawValueOnChange' does not exist on type... Remove this comment to see the full error message
    rawValueOnChange,
    // @ts-expect-error TS(2339): Property 'getOptionValue' does not exist on type '... Remove this comment to see the full error message
    getOptionValue = defaultGetOptionValue,
    // @ts-expect-error TS(2339): Property 'getOptionLabel' does not exist on type '... Remove this comment to see the full error message
    getOptionLabel = defaultGetOptionLabel,
    // @ts-expect-error TS(2339): Property 'isOptionEqualToValue' does not exist on ... Remove this comment to see the full error message
    isOptionEqualToValue = defaultIsOptionEqualToValue,
    // @ts-expect-error TS(2339): Property 'limit' does not exist on type '{}'.
    limit = 10,
    // @ts-expect-error TS(2339): Property 'sort' does not exist on type '{}'.
    sort = true,
    // @ts-expect-error TS(2339): Property 'freeSolo' does not exist on type '{}'.
    freeSolo,
    // @ts-expect-error TS(2339): Property 'selectOnExactMatchInMultiMode' does not ... Remove this comment to see the full error message
    selectOnExactMatchInMultiMode = false,

    // TextInput props
    // @ts-expect-error TS(2339): Property 'TextFieldProps' does not exist on type '... Remove this comment to see the full error message
    TextFieldProps = {},
    // @ts-expect-error TS(2339): Property 'label' does not exist on type '{}'.
    label,
    // @ts-expect-error TS(2339): Property 'placeholder' does not exist on type '{}'... Remove this comment to see the full error message
    placeholder,
    // @ts-expect-error TS(2339): Property 'margin' does not exist on type '{}'.
    margin,

    ...other
  }) => {
    const autocompleteRef = React.createRef();
    const [autocompleteWidth, setAutocompleteWidth] = useState(0);

    const resizeAutocomplete = useCallback(() => {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      setAutocompleteWidth(autocompleteRef.current?.clientWidth);
    }, [autocompleteRef, setAutocompleteWidth]);

    useEffect(() => {
      resizeAutocomplete();
      window.addEventListener('resize', resizeAutocomplete);
      return () => window.removeEventListener('resize', resizeAutocomplete);
    }, [resizeAutocomplete]);

    const onChangeFixed = useCallback(
      (event: any, selected: any) => {
        const newValue = multiple ? selected.map(getOptionValue) : getOptionValue(selected) || '';

        // Same format used for Redux
        if (rawValueOnChange) {
          onChange(newValue);
        } else {
          // Use same format as the Select component
          onChange({ target: { value: newValue } });
        }
      },
      [getOptionValue, multiple, onChange, rawValueOnChange]
    );

    // Clear the selection if input is empty and control is in single mode
    // Append(if in multiple mode) or override the selection if exact match
    const onInputChange = useCallback(
      (event: any, inputValue: any) => {
        if (!multiple && inputValue === '') {
          return onChangeFixed(event, '');
        }

        if (selectOnExactMatchInMultiMode || !multiple) {
          const val = options.find(
            (c: any) =>
              (c.name && c.name.toLowerCase() === inputValue.toLowerCase()) ||
              (c.label && c.label.toLowerCase() === inputValue.toLowerCase())
          );

          if (val) {
            if (!multiple || !value.includes(val)) {
              onChangeFixed(event, multiple ? [...value, val] : val);
            }
          }
        }
      },
      [multiple, selectOnExactMatchInMultiMode, onChangeFixed, options, value]
    );

    if (sort) {
      options = [...options].sort((a: any, b: any) => {
        const aValue = getOptionLabel(a).trim().toUpperCase();
        const bValue = getOptionLabel(b).trim().toUpperCase();
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      });
    }

    // Internally the Autocomplete value must be the selected options in order
    // to work properly, we use isEqual to avoid the need for reference
    // based equality when value is an object
    const filteredValue = multiple
      ? options.filter((o: any) => value && value.find((v: any) => isEqual(v, getOptionValue(o))))
      : options.find((o: any) => isEqual(value, getOptionValue(o))) || null;

    return (
      <MuiAutocomplete
        multiple={multiple}
        options={options}
        onChange={onChangeFixed}
        onInputChange={onInputChange}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        filterOptions={defaultFilterOptions(limit, getOptionLabel)}
        PopperComponent={(props) => (
          <Popper {...props} style={{ width: autocompleteWidth }} placement="bottom-start" />
        )}
        ref={autocompleteRef}
        freeSolo={freeSolo}
        value={freeSolo ? value : filteredValue}
        renderInput={(params) => (
          <MuiTextField
            {...params}
            {...TextFieldProps}
            InputProps={{
              ...params.InputProps,
              ...(TextFieldProps.InputProps || {}),
            }}
            inputProps={{
              ...params.inputProps,
              ...(TextFieldProps.inputProps || {}),
            }}
            // When using redux form the following props are not passed directly
            // and instead are placed in to TextFieldProps
            onClick={(e) => e.stopPropagation()}
            label={label || TextFieldProps.label}
            placeholder={placeholder || TextFieldProps.placeholder}
            margin={margin || TextFieldProps.margin}
          />
        )}
        {...other}
        // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'FocusEventH... Remove this comment to see the full error message
        onBlur={null}
      />
    );
  }
);
Autocomplete.displayName = 'Autocomplete';

const useStyles = makeStyles({
  checkbox: {
    padding: 0,
    marginRight: 5,
  },
});

export const Select = memo(
  ({
    // FormControl props
    // @ts-expect-error TS(2339): Property 'error' does not exist on type '{}'.
    error,
    // @ts-expect-error TS(2339): Property 'disabled' does not exist on type '{}'.
    disabled,
    // @ts-expect-error TS(2339): Property 'fullWidth' does not exist on type '{}'.
    fullWidth = true,
    // @ts-expect-error TS(2339): Property 'margin' does not exist on type '{}'.
    margin,

    // FormControl children props
    // @ts-expect-error TS(2339): Property 'FormHelperTextProps' does not exist on t... Remove this comment to see the full error message
    FormHelperTextProps,
    // @ts-expect-error TS(2339): Property 'label' does not exist on type '{}'.
    label,
    // @ts-expect-error TS(2339): Property 'helperText' does not exist on type '{}'.
    helperText,

    // Custom Label props
    // @ts-expect-error TS(2339): Property 'LabelProps' does not exist on type '{}'.
    LabelProps,

    // Custom Select props
    // @ts-expect-error TS(2339): Property 'menuItemClasses' does not exist on type ... Remove this comment to see the full error message
    menuItemClasses,
    // @ts-expect-error TS(2339): Property 'displayCheckbox' does not exist on type ... Remove this comment to see the full error message
    displayCheckbox,
    // @ts-expect-error TS(2339): Property 'options' does not exist on type '{}'.
    options,
    // @ts-expect-error TS(2339): Property 'includeEmpty' does not exist on type '{}... Remove this comment to see the full error message
    includeEmpty,
    // @ts-expect-error TS(2339): Property 'getOptionValue' does not exist on type '... Remove this comment to see the full error message
    getOptionValue = defaultGetOptionValue,
    // @ts-expect-error TS(2339): Property 'getOptionLabel' does not exist on type '... Remove this comment to see the full error message
    getOptionLabel = defaultGetOptionLabel,
    // @ts-expect-error TS(2339): Property 'sort' does not exist on type '{}'.
    sort = true,
    // @ts-expect-error TS(2339): Property 'native' does not exist on type '{}'.
    native,

    // Material UI Select props
    // @ts-expect-error TS(2339): Property 'value' does not exist on type '{}'.
    value,
    // @ts-expect-error TS(2339): Property 'multiple' does not exist on type '{}'.
    multiple,
    // @ts-expect-error TS(2339): Property 'onChange' does not exist on type '{}'.
    onChange,
    ...other
  }) => {
    const classes = useStyles();

    if (sort) {
      options = options.sort((a: any, b: any) => {
        const aValue = getOptionLabel(a).trim().toUpperCase();
        const bValue = getOptionLabel(b).trim().toUpperCase();
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      });
    }

    if (native) {
      const nativeOnChange = ({ target: { value } }: any) =>
        value ? onChange([value]) : onChange([]);

      return (
        <MuiSelect
          native
          fullWidth={fullWidth}
          value={value.length ? value[0] : ''}
          onChange={nativeOnChange}
        >
          <option value="" disabled={!includeEmpty} />
          {options.map((op: any, i: number) => (
            <option key={i} value={getOptionValue(op)}>
              {getOptionLabel(op)}
            </option>
          ))}
        </MuiSelect>
      );
    }

    const menuItems = options.map((o: any) => {
      const itemValue = getOptionValue(o);
      const itemLabel = getOptionLabel(o);

      const isSelected = multiple ? value.some((v: any) => v === itemValue) : value === itemValue;

      return (
        <MenuItem key={itemValue} value={itemValue} classes={menuItemClasses}>
          {displayCheckbox && (
            <Checkbox classes={{ root: classes.checkbox }} checked={isSelected} />
          )}
          {itemLabel}
        </MenuItem>
      );
    });

    if (includeEmpty) {
      menuItems.unshift(<MenuItem key="" value="" />);
    }

    return (
      <FormControl margin={margin} error={error} disabled={disabled} fullWidth={fullWidth}>
        {label && <InputLabel {...LabelProps}>{label}</InputLabel>}
        <MuiSelect value={value} multiple={multiple} onChange={onChange} {...other}>
          {menuItems}
        </MuiSelect>
        {helperText && <FormHelperText {...FormHelperTextProps}>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
);
Select.displayName = 'Select';

const SelectWithNativeSwitch = memo(
  ({
    // @ts-expect-error TS(2339): Property 'autocomplete' does not exist on type '{}... Remove this comment to see the full error message
    autocomplete, // Autocomplete props
    // @ts-expect-error TS(2339): Property 'isOptionEqualToValue' does not exist on ... Remove this comment to see the full error message
    isOptionEqualToValue, // Select props
    // @ts-expect-error TS(2339): Property 'includeEmpty' does not exist on type '{}... Remove this comment to see the full error message
    includeEmpty,
    // @ts-expect-error TS(2339): Property 'useNativeSelectForMobile' does not exist... Remove this comment to see the full error message
    useNativeSelectForMobile,
    ...other
  }) => {
    const theme = useTheme();
    const mq = useMediaQuery(theme.breakpoints.down('md'));
    const native = useNativeSelectForMobile ? mq : false;

    return autocomplete && !native ? (
      // @ts-expect-error TS(2322): Type '{ isOptionEqualToValue: any; }' is not assig... Remove this comment to see the full error message
      <Autocomplete isOptionEqualToValue={isOptionEqualToValue} {...other} />
    ) : (
      // @ts-expect-error TS(2322): Type '{ includeEmpty: any; native: boolean; }' is ... Remove this comment to see the full error message
      <Select includeEmpty={includeEmpty} native={native} {...other} />
    );
  }
);
SelectWithNativeSwitch.displayName = 'SelectWithNativeSwitch';

export default SelectWithNativeSwitch;
