import { ApolloClient, gql } from '@apollo/client';

import { forceValue } from '@/types';

const FETCH_PERMISSIONS = gql(/* GraphQL */ `
  query fetchPermissions($userId: String, $requests: [PermissionRequestInput]!) {
    permissions(user_id: $userId, requests: $requests) {
      service
      action
      resource
      allowed
    }
  }
`);

export interface PermissionRequest {
  service: string;
  action: string;
  resource?: string | null;
}

export interface Permission extends PermissionRequest {
  allowed: boolean;
}

export class PermissionService {
  private graphql: ApolloClient<any>;
  private userId?: string;

  constructor(graphql: ApolloClient<any>, userId?: string) {
    this.graphql = graphql;
    this.userId = userId;
  }

  async allowed(service: string, action: string, resource: string): Promise<boolean> {
    const req = { service, action, resource };
    const perms = await this.allowedBatch([req]);
    return perms[0].allowed;
  }

  async allowedBatch(requests: PermissionRequest[]): Promise<Permission[]> {
    requests = requests.filter((req) => req.resource !== undefined && req.resource !== null);
    if (requests.length === 0) return [];

    const { data } = await this.graphql.query({
      query: FETCH_PERMISSIONS,
      variables: {
        userId: this.userId,
        requests,
      },
    });

    return (data.permissions || []).map(forceValue);
  }

  forUser(userId: string) {
    return new PermissionService(this.graphql, userId);
  }
}
