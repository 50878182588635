import IconButton from '@mui/material/IconButton';
import MuiTextField from '@mui/material/TextField';
import React, { memo, useState } from 'react';
import { Field as FinalField } from 'react-final-form';

import { PasswordHelpInputAdornment } from '@/auth/components/PasswordHelp';
import { TextField, areEqual } from '@/components/FormAdapters';
import FAIcon from '@/components/Icon/FAIcon';
import { darkGray } from '@/theme/colors';

const ShowPasswordComponent = ({ showPassword, toggleShowPassword, id }: any) => (
  <IconButton id={id} onClick={toggleShowPassword}>
    <FAIcon iconSet="fal" icon={showPassword ? 'eye-slash' : 'eye'} color={darkGray} size={20} />
  </IconButton>
);

const PasswordHelpingAndShowing = ({ showPassword, toggleShowPassword, id }: any) => (
  <PasswordHelpInputAdornment id={id}>
    <ShowPasswordComponent
      showPassword={showPassword}
      toggleShowPassword={toggleShowPassword}
      id={id}
    />
  </PasswordHelpInputAdornment>
);

export const PasswordTextField = memo(function PasswordTextField({
  // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
  id,
  // @ts-expect-error TS(2339): Property 'endAdornmentId' does not exist on type '... Remove this comment to see the full error message
  endAdornmentId,
  // @ts-expect-error TS(2339): Property 'variant' does not exist on type '{}'.
  variant,
  // @ts-expect-error TS(2339): Property 'fullWidth' does not exist on type '{}'.
  fullWidth = false,
  // @ts-expect-error TS(2339): Property 'label' does not exist on type '{}'.
  label,
  // @ts-expect-error TS(2339): Property 'value' does not exist on type '{}'.
  value,
  // @ts-expect-error TS(2339): Property 'name' does not exist on type '{}'.
  name,
  // @ts-expect-error TS(2339): Property 'onPasswordChange' does not exist on type... Remove this comment to see the full error message
  onPasswordChange,
  // @ts-expect-error TS(2339): Property 'current' does not exist on type '{}'.
  current = true,
  ...input
}) {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  return (
    <MuiTextField
      id={id}
      variant={variant}
      fullWidth={fullWidth}
      type={showPassword ? 'text' : 'password'}
      label={label}
      value={value}
      name={name}
      onChange={onPasswordChange}
      inputProps={{
        autocomplete: current ? 'current-password' : 'new-password',
      }}
      {...input}
      InputProps={{
        style: {
          paddingRight: 0,
        },

        endAdornment: (
          <>
            <ShowPasswordComponent
              id={endAdornmentId}
              showPassword={showPassword}
              toggleShowPassword={toggleShowPassword}
            />
          </>
        ),
      }}
    />
  );
}, areEqual);

export const PasswordText = memo(function PasswordText({
  // @ts-expect-error TS(2339): Property 'input' does not exist on type '{}'.
  input,
  // @ts-expect-error TS(2339): Property 'rest' does not exist on type '{}'.
  rest,
  // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
  id,
  // @ts-expect-error TS(2339): Property 'variant' does not exist on type '{}'.
  variant,
  // @ts-expect-error TS(2339): Property 'changeOnBlur' does not exist on type '{}... Remove this comment to see the full error message
  changeOnBlur,
  // @ts-expect-error TS(2339): Property 'label' does not exist on type '{}'.
  label,
  // @ts-expect-error TS(2339): Property 'showHelp' does not exist on type '{}'.
  showHelp,
  // @ts-expect-error TS(2339): Property 'current' does not exist on type '{}'.
  current = true,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  return (
    <FinalField
      id={id}
      variant={variant}
      component={TextField}
      changeOnBlur={changeOnBlur}
      name={input.name}
      label={label}
      inputType={showPassword ? 'text' : 'password'}
      inputProps={{
        autocomplete: current ? 'current-password' : 'new-password',
      }}
      {...rest}
      InputProps={{
        endAdornment: (
          <>
            {showHelp ? (
              <PasswordHelpingAndShowing
                showPassword={showPassword}
                toggleShowPassword={toggleShowPassword}
                id={id}
              />
            ) : (
              <ShowPasswordComponent
                showPassword={showPassword}
                toggleShowPassword={toggleShowPassword}
                id={id}
              />
            )}
          </>
        ),
      }}
    />
  );
}, areEqual);
