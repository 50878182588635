import moment from 'moment-timezone';

import { engagementTypes } from '@/consultation/store';
import { darkBlue, darkGreen, red500 } from '@/theme/colors';

import Duration from './duration';
import { TimeRange } from './time';

export const chargeLabels = {
  booking_fee: 'Booking Fee',
  expert_time: 'Expert Time',
  transcription_fee: 'Transcription Fee',
  service_fee: 'Service Fee',
  concierge_fee: 'Concierge Fee',
  active_user_fee: 'Active User Fee',
  retainer_fee: 'Retainer Fee',
};

export function getStateDescription(consultation: any) {
  if (consultation.external) {
    return {
      icon: 'external-link-square',
      text: 'External Consultation',
      color: darkGreen,
    };
  }

  const target =
    consultation.engagement_type === engagementTypes.writtenResponse ? 'Consultation' : 'Call';

  const states = {
    negotiating_expert_time: { text: 'Awaiting Confirmation', color: darkBlue },
    negotiating_client_time: { text: 'Awaiting Confirmation', color: darkBlue },
    awaiting_expert_review: {
      text: 'Awaiting Written Review From Expert',
      color: darkBlue,
    },
    awaiting_client_accept: {
      text: 'Awaiting Client Approval',
      color: darkBlue,
    },
    denied: { text: `${target} Denied`, color: red500 },
    expired: { text: `${target} Expired`, color: red500 },
    canceled: { text: `${target} Canceled`, color: red500 },
    client_rejected: { text: `${target} Rejected`, color: red500 },
    incomplete: { text: `${target} Incomplete`, color: red500 },
    confirmed: { text: `${target} Confirmed`, color: darkGreen },
    finalizing: { text: `${target} Finalizing`, color: darkGreen },
    completed: { text: `${target} Completed`, color: darkGreen },
  };

  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return { icon: 'clock', iconSet: 'far', ...states[consultation.state] };
}

export function shouldShowJoinCall(viewer: any, consultation: any) {
  return consultation.state === 'confirmed';
}

export function shouldAllowJoinCall(consultation: any) {
  // confirmation must be in confirmed state
  if (consultation.state !== 'confirmed') return false;

  // must be in call window (t-1 until t+12 hours)
  const now = moment();
  const callTime = moment(consultation.starts_at);
  const callWindowStart = moment(callTime).add(-1, 'hours');
  const callWindowEnd = moment(callTime).add(12, 'hours');
  if (now.isBefore(callWindowStart)) return false;
  if (now.isAfter(callWindowEnd)) return false;

  // allow joining call
  return true;
}

export function isTooEarlyToJoinCall(consultation: any) {
  // confirmation must be in confirmed state
  if (consultation.state !== 'confirmed') return false;

  // must be before call window (t-1 hour)
  const now = moment();
  const callWindowStart = moment(consultation.starts_at).add(-1, 'hours');
  if (now.isAfter(callWindowStart)) return false;

  // it is too early to join call
  return true;
}

export function getSuggestedTime(start: any, timezone1: any, timezone2: any) {
  const range1 = new TimeRange(
    moment.tz(start, timezone1).startOf('day').hour(8),
    moment.tz(start, timezone1).startOf('day').hour(22)
  );
  const range2 = new TimeRange(
    moment.tz(start, timezone2).startOf('day').hour(8),
    moment.tz(start, timezone2).startOf('day').hour(22)
  );

  const overlap1 = range1.overlap(range2);
  if (overlap1 && start.isSameOrAfter(overlap1.start) && start.isBefore(overlap1.end)) return start;

  // Depending on the timezones we have to check on the next day for overlap
  if (range1.isBefore(range2)) range1.add(1, 'd');
  else range2.add(1, 'd');

  const overlap2 = range1.overlap(range2);
  if (overlap2 && start.isSameOrAfter(overlap2.start) && start.isBefore(overlap2.end)) return start;

  if (overlap1 && start.isBefore(overlap1.start)) return overlap1.start;

  if (overlap2 && start.isBefore(overlap2.start)) return overlap2.start;

  if (overlap1) return overlap1.start.add(1, 'd');

  if (overlap2) return overlap2.start.add(1, 'd');
}

function sortDates(a: any, b: any) {
  return !a
    ? Number.MAX_VALUE
    : !b
      ? Number.MIN_VALUE
      : a.toDate().getTime() - b.toDate().getTime();
}

export function getSuggestedTimes(t1: any, t2: any, hourOffset: any, hourInterval: any) {
  const minutes = moment().add(10, 'm').minutes();
  const start = moment()
    .add(hourOffset, 'h')
    .minutes(Math.ceil(minutes / 5) * 5)
    .seconds(0)
    .milliseconds(0);
  const time1 = getSuggestedTime(start, t1, t2);
  const time2 = time1 && getSuggestedTime(moment(time1).add(hourInterval, 'h'), t1, t2);
  const time3 = time2 && getSuggestedTime(moment(time2).add(hourInterval, 'h'), t1, t2);
  return [time1, time2, time3].sort(sortDates).map((t) => moment.tz(t, t1));
}

export function validate(values: any, props: any) {
  const errors = {};

  const isAdmin = props && props.viewer && props.viewer.admin;
  const dates = values.dates.filter(Boolean);

  const dateErrors =
    values.dates.filter(Boolean).length === 0
      ? ['Required']
      : values.dates.map((date: any) => {
          if (!date) return;

          const now = moment().seconds(0).milliseconds(0);
          const suggested = moment(date).seconds(0).milliseconds(0);

          if (suggested.isBefore(now)) {
            return 'Must be a future time';
          }

          const timeUntilStart = new TimeRange(now, suggested);
          if (!isAdmin && timeUntilStart.duration().minutes() < 10) {
            return 'Consultation must be scheduled at least 10 minutes in advance';
          }

          const alreadySuggested = dates
            .filter((d: any) => d !== date)
            .some((d: any) => {
              const start = moment(d).seconds(0).milliseconds(0);
              return suggested.isSame(start);
            });

          if (alreadySuggested) {
            return 'Time has already been suggested';
          }
        });

  if (dateErrors.length > 0) {
    // @ts-expect-error TS(2339): Property 'dates' does not exist on type '{}'.
    errors.dates = dateErrors;
  }

  if (!values.duration || values.duration === '0') {
    // @ts-expect-error TS(2339): Property 'duration' does not exist on type '{}'.
    errors.duration = 'Required';
  }

  return Object.keys(errors).length > 0 ? errors : undefined;
}

export function minimumTimeNotice(dates: any) {
  const now = moment().seconds(0).milliseconds(0);
  return dates.some((date: any) => {
    const suggested = moment(date).seconds(0).milliseconds(0);
    const duration = new Duration(suggested.diff(now));
    return duration.hours() >= 12;
  });
}

export function isDurationEmpty(duration: any) {
  if (!duration) return true;
  return new Duration(duration).isEqualTo('0s');
}

export function getUser(viewer: any, userContext: any, consultation: any, adminViewRequester: any) {
  const { expert, requester } = consultation;
  const isExpert = expert && viewer.id === expert.id;
  const isAdminContext = userContext === 'admin';

  return isExpert || (isAdminContext && adminViewRequester) ? requester : expert;
}

export function getUserName(
  viewer: any,
  userContext: any,
  consultation: any,
  adminViewRequester: any
) {
  const { expert, requester } = consultation;
  const isExpert = expert && viewer.id === expert.id;
  const isAdminContext = userContext === 'admin';

  const requesterName = requester
    ? requester.name
    : consultation.requester_name || 'Confidential client';
  const expertName = expert ? expert.name : consultation.expert_name || 'Expert not available';
  return isExpert || (isAdminContext && adminViewRequester) ? requesterName : expertName;
}

export function parseDuration(duration: any) {
  return typeof duration === 'string' ? Duration.parse(duration) : duration;
}

export function formatDuration(duration: any) {
  if (!duration) return '';

  const d = parseDuration(duration);
  const hours = d.hours();
  const minutes = hours > 0 ? d.minutes() % 60 : d.minutes();
  if (hours === 1 && minutes === 0) return '60min';
  // @ts-expect-error TS(2447): The '&' operator is not allowed for boolean types.... Remove this comment to see the full error message
  if ((hours > 0) & (minutes > 0)) return `${hours}hr ${minutes}min`;
  if (hours > 0) return `${hours}hr`;
  if (minutes > 0) return `${minutes}min`;
  const seconds = d.seconds();
  if (seconds > 0) return `${seconds}sec`;
  return '';
}

export function calculateExpertCredits(creditRate: any, duration: any, engagementType: any) {
  if (engagementType === engagementTypes.opportunity) return 0;
  if (engagementType === engagementTypes.writtenResponse) return 300;
  const rate = creditRate || 0;
  const minutes = Duration.parse(duration).minutes();
  return (rate * minutes) / 60;
}

export function canRequestConsultation(user: any, profile?: { can_request_consultation: boolean }) {
  return (
    user &&
    user.expert_state === 'active' &&
    user.available_self_service &&
    (!profile || profile.can_request_consultation) // check profile when passed
  );
}

export const recordingTypes = Object.freeze({
  audioOnly: 'audio_only',
  chatFile: 'chat_file',
  sharedScreenWithSpeakerView: 'shared_screen_with_speaker_view',
  sharedScreenWithGalleryView: 'shared_screen_with_gallery_view',
  sharedScreen: 'shared_screen',
  activeSpeaker: 'active_speaker',
  galleryView: 'gallery_view',
});

export const fileTypes = Object.freeze({
  mp3: 'MP3',
  mp4: 'MP4',
  m4a: 'M4A',
  chat: 'CHAT',
  transcript: 'TRANSCRIPT',
});

export const isVideoType = (type: any) => [fileTypes.mp4].includes(type);

export const isAudioType = (type: any) => [fileTypes.mp3, fileTypes.m4a].includes(type);

export const isAudioOrVideoType = (type: any) => isAudioType(type) || isVideoType(type);
