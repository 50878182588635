import qs from 'query-string';
import { FC, ReactNode, useEffect } from 'react';
import { RouteObject, json, redirect, useLocation } from 'react-router-dom';

import { setLoadingProgress } from '@/actions/loading';
import changePassword from '@/auth/pages/ChangePasswordPage';
import loginAs from '@/auth/pages/LoginAsPage';
import LoginPage, { loginPageMiddleware } from '@/auth/pages/LoginPage';
import LogoutPage, { logoutPageLoader } from '@/auth/pages/LogoutPage';
import register from '@/auth/pages/RegisterPage';
import resetPassword from '@/auth/pages/ResetPasswordPage';
import selectDomain from '@/auth/pages/SelectDomainPage';
import validateEmail from '@/auth/pages/ValidateEmailPage';
import consultation from '@/consultation/pages/ConsultationPage';
import expertRequest from '@/expertrequest/pages/expertRequest';
import expertRequestNew, {
  addMembers as expertRequestAddMembers,
} from '@/expertrequest/pages/expertRequestNew';
import expertRequests from '@/expertrequest/pages/expertRequests';
import { useApp } from '@/hooks/useAppContext';
import MessagingPage, { messagingMiddleware } from '@/messaging/pages/MessagingPage';
import NotFoundPage from '@/pages/NotFoundPage';
import profile from '@/profile/pages/profile';
import profileConflicts from '@/profile/pages/profileConflicts';
import profileMerge from '@/profile/pages/profileMerge';
import profileUploader from '@/profile/pages/profileUploader';
import unregisteredProfile from '@/profile/pages/unregisteredProfile';
import search from '@/search/pages/SearchPage';
import { setRuntimeVariable } from '@/store/runtime';
import { parseHostname } from '@/utils';

import admin from './admin';
import application from './application';
import browse from './browse';
import complianceTraining from './complianceTraining';
import consultations from './consultations';
import dashboard from './dashboard';
import expertNetwork from './expertNetwork';
import graphiql from './graphiql';
import harnessRoutes from './harnessRoutes';
import legalAck from './legalAck';
import payoutDashboard from './payoutDashboard';
import projectDetails from './projectDetails';
import {
  LegacyRoute,
  MiddlewareContext,
  agreementsRequiredLegacy,
  allRequiredLegacy,
  allRequiredMiddleware,
  applyMiddlewares as applyMiddleware,
  loginRequiredLegacy,
  superAdminRequiredLegacy,
  verifiedEmailRequiredLegacy,
} from './routesMiddleware';
import settings from './settings';
import team from './team';
import teamSettings from './teamSettings';
import teams from './teams';

interface TrackingComponentProps {
  children: ReactNode;
}

const TrackingComponent: FC<TrackingComponentProps> = ({ children }) => {
  const { store } = useApp();
  const location = useLocation();
  const { userContext } = store.getState().ui;

  useEffect(() => {
    store.dispatch(setLoadingProgress(100));
    window.scrollTo(0, 0);
    // Update referrer on push navigation
    store.dispatch(setRuntimeVariable('referrer', `${window.location.origin}${location.pathname}`));
  }, [location.key, location.pathname, userContext, store]);

  return children;
};

function buildRoute(
  context: MiddlewareContext,
  route: LegacyRoute,
  protectionFn?: (route: LegacyRoute) => LegacyRoute
): RouteObject {
  if (protectionFn) {
    route = protectionFn(route);
  }

  let loader;
  const action = route.action;
  if (action) {
    loader = ({ params, request }: any) => {
      const url = new URL(request.url);
      const { subdomain } = parseHostname(url.hostname);
      return action({
        ...context,
        request,
        params,
        location: url,
        path: url.pathname,
        subdomain: subdomain === 'www' ? null : subdomain,
        query: mapQueryParams(request.url),
      });
    };
  }

  const element = route.element && <TrackingComponent>{route.element}</TrackingComponent>;

  return {
    path: route.path,
    element: element,
    loader: loader,
    children:
      route.children && route.children.map((child) => buildRoute(context, child, protectionFn)),
  };
}

function agreementsWithEmailRequiredLegacy(route: LegacyRoute): LegacyRoute {
  return agreementsRequiredLegacy(verifiedEmailRequiredLegacy(route));
}

/**
 * Maps query parameters to an object with key value pairs
 * @param {string} url
 * @returns {Object<string, string>}
 */
export function mapQueryParams(url: string) {
  return qs.parse(new URL(url).search, { arrayFormat: 'bracket' });
}

function createAccessRoutes(context: MiddlewareContext): RouteObject[] {
  return [
    {
      path: '/become-an-expert',
      loader: () => redirect('/signup/expert'),
    },
    buildRoute(context, browse),
    buildRoute(context, graphiql),
    {
      path: '/health-check',
      element: <h1>Ok</h1>,
      loader: () => json({ status: 200 }),
    },
    {
      path: '/login/:type?',
      element: <LoginPage />,
      loader: applyMiddleware(context, [loginPageMiddleware]),
    },
    {
      path: '/:subdomain/login/:type?',
      element: <LoginPage />,
      loader: applyMiddleware(context, [loginPageMiddleware]),
    },
    buildRoute(context, profile),
    buildRoute(context, unregisteredProfile, allRequiredLegacy),
    buildRoute(context, resetPassword),
    buildRoute(context, selectDomain),
    {
      path: '/logout',
      element: <LogoutPage />,
      loader: logoutPageLoader,
    },
    buildRoute(context, changePassword),
    buildRoute(context, loginAs, loginRequiredLegacy),
    buildRoute(context, legalAck, loginRequiredLegacy),
    buildRoute(context, application, allRequiredLegacy),
    buildRoute(context, consultation, allRequiredLegacy),
    buildRoute(context, consultations, allRequiredLegacy),
    buildRoute(context, dashboard, allRequiredLegacy),
    buildRoute(context, expertRequest),
    buildRoute(context, expertRequestNew, allRequiredLegacy),
    buildRoute(context, expertRequestAddMembers, allRequiredLegacy),
    {
      path: '/messaging/:channelId?',
      element: <MessagingPage />,
      loader: applyMiddleware(context, [allRequiredMiddleware, messagingMiddleware]),
    },
    buildRoute(context, payoutDashboard, allRequiredLegacy),
    buildRoute(context, profileConflicts, allRequiredLegacy),
    buildRoute(context, profileUploader, allRequiredLegacy),
    buildRoute(context, profileMerge, allRequiredLegacy),
    buildRoute(context, search, allRequiredLegacy),
    buildRoute(context, settings, allRequiredLegacy),
    buildRoute(context, teams, allRequiredLegacy),
    buildRoute(context, teamSettings, allRequiredLegacy),
    buildRoute(context, expertRequests, agreementsWithEmailRequiredLegacy),
    buildRoute(context, register, agreementsRequiredLegacy),
    buildRoute(context, admin, superAdminRequiredLegacy),
    ...validateEmail.map((route) => buildRoute(context, route, loginRequiredLegacy)),
    ...expertNetwork.map((route) => buildRoute(context, route, loginRequiredLegacy)),
    ...projectDetails.map((route) => buildRoute(context, route, agreementsWithEmailRequiredLegacy)),
    ...team.map((route) => buildRoute(context, route, allRequiredLegacy)),
    ...complianceTraining.path.map((path) =>
      buildRoute(
        context,
        {
          path,
          element: complianceTraining.element,
          action: complianceTraining.action,
        },
        allRequiredLegacy
      )
    ),
    {
      path: '*',
      element: (
        <TrackingComponent>
          <NotFoundPage />
        </TrackingComponent>
      ),
      loader: () => {
        document.title = '404 - Not found';
        return null;
      },
    },
  ];
}

export default (context: MiddlewareContext): RouteObject[] => {
  const accessRoutes = createAccessRoutes(context);
  return [...accessRoutes, ...harnessRoutes];
};
