import { AppThunk } from '@/store';

import ActionTypes from './ActionTypes';

const { GROUP__ADD_MEMBER, PROJECT__ADD_MEMBER } = ActionTypes;

export function inviteUser(invitation: any, opts = { reinvite: false }): AppThunk<Promise<any>> {
  return async (dispatch, _getState, { graphql }) => {
    const { inviteUser: member } = await graphql.mutate(
      `(
      $email: String
      $profileId: String
      $firstName: String
      $lastName: String
      $collectionType: InvitationCollectionType!
      $collectionId: String!
      $role: String!
      $teamNote: String
      $invitationMessage: String
    ) {
      inviteUser(
        email: $email
        profile_id: $profileId
        first_name: $firstName
        last_name: $lastName
        collection_type: $collectionType
        collection_id: $collectionId
        role: $role
        team_note: $teamNote
        invitation_message: $invitationMessage
      ) {
        id
        role
        email
      }
    }`,
      invitation
    );

    if (opts.reinvite) return;

    switch (invitation.collectionType) {
      case 'group':
        dispatch({
          type: GROUP__ADD_MEMBER,
          groupId: invitation.collectionId,
          member,
        });
        break;
      case 'project':
        member.state = 'active';
        dispatch({
          type: PROJECT__ADD_MEMBER,
          projectId: invitation.collectionId,
          member,
        });
        break;
      case 'internal_network_experts':
        // ignore
        break;
      default:
        throw new Error(`unknown collection type: '${invitation.collectionType}'`);
    }
  };
}

export function acceptInvitation(token: any): AppThunk<Promise<any>> {
  return async (_dispatch, _getState, { graphql }) => {
    const { acceptInvitation: nextURL } = await graphql.mutate(
      `($token: String!) {
        acceptInvitation(token: $token)
      }`,
      { token }
    );
    return nextURL;
  };
}
