import React, { FC, useCallback, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { saveProject } from '@/actions/project';
import { notify } from '@/actions/ui';
import Button from '@/components/Button/Button';
import ConsultationsStarting from '@/components/ConsultationsStarting';
import EditDialog, { EditDialogProps } from '@/components/EditDialog/EditDialog';
import EditIcon from '@/components/EditIcon';
import ProjectSettingsMenu from '@/components/EditProjectMembers/SettingsMenu';
import { TextField } from '@/components/FormAdapters/FormAdapters';
import LayoutPage from '@/components/Layout/LayoutPage';
import Link from '@/components/Link';
import Markdown from '@/components/Markdown';
import MediaQuery from '@/components/MediaQuery';
import MemberList from '@/components/MemberList';
import MemberRequests from '@/components/MemberRequests/Project';
import SelectGroup from '@/components/SelectGroup';
import { sortMembers } from '@/core/project';
import ExpertRequestPreview from '@/expertrequest/components/ExpertRequestPreview';
import { useApp, usePermissions } from '@/hooks/useAppContext';
import { RootState } from '@/store';
import { SCREEN_XS } from '@/theme/screens';
import { normalizeSpace } from '@/utils';

import s from './ProjectDetails.module.scss';

interface Dialog {
  title: string;
  component: () => JSX.Element;
  fetch?: (args: any) => any;
}

const dialogs: Record<string, Dialog> = {
  name: {
    title: 'Edit Name',
    component() {
      return (
        <Field
          id="projectName"
          component={TextField}
          name="name"
          fullWidth
          label="Name this project"
        />
      );
    },
  },
  tracking_code: {
    title: 'Edit Tracking Code',
    component() {
      return (
        <Field
          id="projectTrackingCode"
          component={TextField}
          inputProps={{ maxLength: 60 }}
          name="tracking_code"
          fullWidth
          label="Tracking Code"
          multiline
          onKeyDown={(e: any) => e.keyCode === 13 && e.preventDefault()}
          parse={(v: string) => normalizeSpace(v)}
        />
      );
    },
  },
  group: {
    title: 'Edit Team',
    // @ts-ignore
    fetch: SelectGroup.fetch,
    component() {
      return <Field name="group.id" limit={10} component={SelectGroup} />;
    },
  },
};

interface ProjectDetailsProps {
  projectId: string;
}

const connector = connect(
  (state: RootState, ownProps: ProjectDetailsProps) => {
    return {
      viewer: state.viewer,
      project: (state.projects.default.edges.find((e) => e.node.id === ownProps.projectId) || {})
        .node,
    };
  },
  {
    notify,
  }
);

const ProjectDetails: FC<ProjectDetailsProps & ConnectedProps<typeof connector>> = ({
  viewer,
  project,
  projectId,
  notify,
}) => {
  const { store } = useApp();
  const [canDeleteProject] = usePermissions([
    { service: 'project', action: 'delete', resource: projectId },
  ]);
  const [editSections, setEditSections] = useState<Record<string, boolean>>({});

  const openDialog = async (name: string) => {
    const dialog = dialogs[name];

    if (dialog.fetch) await store.dispatch(dialog.fetch({ viewer }));
    setEditSections((sections) => ({
      ...sections,
      [name]: true,
    }));
  };

  const closeDialog = (key: any, message: any, error: any) => {
    setEditSections((sections) => ({
      ...sections,
      [key]: false,
    }));

    if (message) {
      notify(message, error ? 'error' : 'success');
    }
  };

  if (!project) return <div />;

  const members = sortMembers(project.members);
  const membersToShow = members.filter((m: any) => m.state === 'active');
  const membersToEdit = members.filter((m: any) => ['active', 'denied'].includes(m.state));
  const memberRequests = members.filter((m: any) => m.state === 'awaiting_approval');

  const none = <span className={s.none}>None</span>;

  return (
    <LayoutPage showNav selected="expert_requests">
      <ConsultationsStarting />
      <div className={s.header}>
        <div className={s.title}>
          <div className={s.titleText}>
            {project.name}
            <EditIcon
              onClick={() => openDialog('name')}
              style={{ marginLeft: 10, verticalAlign: 'middle' }}
            />
          </div>
          <div className={s.members}>
            <MemberList members={membersToShow} />
            <ProjectSettingsMenu
              showDelete={canDeleteProject}
              project={project}
              members={membersToEdit}
            />
          </div>
        </div>
        {viewer.admin && (
          <div className={s.field}>
            <div className={s.fieldLabel}>Team:</div>
            <div className={s.fieldValue}>
              {project.group ? <Link to={project.group.html_url}>{project.group.name}</Link> : none}
            </div>
            <EditIcon onClick={() => openDialog('group')} style={{ marginLeft: 10 }} />
          </div>
        )}
        <div className={s.field}>
          <div className={s.fieldLabel}>Tracking Code:</div>
          <div className={s.fieldValue}>{project.tracking_code || none}</div>
          <EditIcon onClick={() => openDialog('tracking_code')} style={{ marginLeft: 10 }} />
        </div>
      </div>

      <div>
        <MemberRequests projectId={project.id} memberRequests={memberRequests} />
      </div>

      <div className={s.requests}>
        {project.expert_requests.map((er: any) => (
          <ExpertRequestPreview
            // @ts-ignore
            className={s.request}
            key={er.id}
            request={er}
            showState
            showContextActions
            showDeleteAction
            showCloseAction
          />
        ))}
      </div>

      <Link
        to={`/request_expert?project_id=${project.id}`}
        style={{ display: 'inline-block', marginTop: 20 }}
      >
        <Button color="lightTan" size="large">
          Add Expert Request
        </Button>
      </Link>

      <Markdown body={project.summary} />

      {Object.keys(dialogs).map((dialog) => (
        <EditProject
          key={dialog}
          initialValues={project}
          open={editSections[dialog]}
          onClose={(message: any, error: any) => {
            closeDialog(dialog, message, error);
          }}
          {...dialogs[dialog]}
        />
      ))}
    </LayoutPage>
  );
};

interface FormData {
  id: string;
  group: {
    id: string;
  };
}

interface EditProjectProps extends Omit<EditDialogProps, 'onClose'> {
  component: React.ComponentType<any>;
  children?: React.ReactNode;
  onClose: (message?: string, error?: boolean) => void;
  submitOnClick?: boolean;
}

const editProjectConnector = connect(
  (_state: RootState, ownProps: EditProjectProps) => {
    return {
      // @ts-ignore
      validate: ownProps.component.validate,
    };
  },
  {
    saveProject,
  }
);

const EditProjectBare: FC<
  EditProjectProps &
    InjectedFormProps<FormData, EditProjectProps> &
    ConnectedProps<typeof editProjectConnector>
> = ({
  onClose,
  reset,
  submitOnClick = false,
  handleSubmit,
  component,
  array,
  change,
  children,
  saveProject,
  ...props
}) => {
  const myHandleSubmit = useCallback(
    async (data: FormData) => {
      console.log(data);
      try {
        const result = await saveProject({
          ...data,
          group_id: data.group.id,
        });
        if (result) {
          reset();
          onClose('Project updated.', false);
        }
      } catch (e) {
        console.error(e);
        onClose('An error occurred when updating the project', true);
      }
    },
    [onClose, reset, saveProject]
  );

  const handleCloseRequest = useCallback(() => onClose(), [onClose]);

  return (
    <EditDialog onSubmit={handleSubmit(myHandleSubmit)} onClose={handleCloseRequest} {...props}>
      <MediaQuery maxWidth={SCREEN_XS}>
        {(isMobileVersion: boolean) =>
          component
            ? React.createElement(component, {
                array,
                change,
                isMobileVersion,
                onClick: submitOnClick ? handleSubmit(myHandleSubmit) : undefined,
              })
            : children
        }
      </MediaQuery>
    </EditDialog>
  );
};

const EditProject = reduxForm<FormData, EditProjectProps>({
  form: 'editProject',
  enableReinitialize: true,
})(editProjectConnector(EditProjectBare));

export default connector(ProjectDetails);
