import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import { Component } from 'react';
import { connect } from 'react-redux';

import { RootState } from '@/store';
import { darkBrown, red500, white } from '@/theme/colors';
import { SCREEN_MD } from '@/theme/screens';

import Divider from '../Divider';
import MaterialIcon from '../Icon/MaterialIcon';
import Link from '../Link';
import MediaQuery from '../MediaQuery';
import Menu from '../Menu';
import MobileNavigation from '../MobileNavigation';
import { MobileUserContextMenu } from './UserContextMenu';
import s from './UserMenu.module.scss';

function UserMenuItem({ text, to, onClick, promo, force, badgeCount, id }: any) {
  const style = {};

  if (promo) {
    // @ts-expect-error TS(2339): Property 'color' does not exist on type '{}'.
    style.color = red500;
  }

  return (
    <Link href={to} onClick={onClick} force={force} id={id}>
      <MenuItem style={style}>
        {text}
        <BadgeCount count={badgeCount} />
      </MenuItem>
    </Link>
  );
}

const useBadgeStyles = makeStyles(() => ({
  badge: {
    backgroundColor: red500,
    color: white,
  },
}));

export function BadgeCount({ count }: any) {
  const classes = useBadgeStyles();
  if (!count) return null;
  if (count.loading)
    return (
      <div className={s.spinnerContainer}>
        <MaterialIcon className={s.spinner} icon="cached" size={16} />
      </div>
    );
  if (!count.value) return null;

  return (
    <Badge
      badgeContent={count.value}
      classes={classes}
      style={{
        paddingBottom: 0,
        position: 'absolute',
        right: 20,
        verticalAlign: 'middle',
      }}
    />
  );
}

function DesktopMenu({ anchorEl, conflictsCount, onRequestClose, viewer }: any) {
  return (
    <Menu anchorEl={anchorEl} onClose={onRequestClose} paperStyles={{ minWidth: 185 }}>
      <MenuItems
        viewer={viewer}
        onRequestClose={onRequestClose}
        divider={<Divider spacing={0} />}
        conflictsCount={conflictsCount}
      />
    </Menu>
  );
}

function MobileMenu({
  viewer,
  open,
  theme,
  onRequestClose,
  userContextOptions,
  conflictsCount,
}: any) {
  return (
    <MobileNavigation
      open={open}
      drawerColor={white}
      closeButtonColor={darkBrown}
      theme={theme}
      onRequestClose={onRequestClose}
    >
      <MenuItems
        viewer={viewer}
        divider={<Divider spacing={20} margin={16} />}
        fontSize={20}
        handleToggle={onRequestClose}
        showIntercom
        onRequestClose={onRequestClose}
        conflictsCount={conflictsCount}
      />

      {userContextOptions.length > 1 && (
        <div>
          <Divider spacing={20} />
          <div className={s.switchContext}>Switch Context</div>
          <MobileUserContextMenu onChange={onRequestClose} />
        </div>
      )}
    </MobileNavigation>
  );
}

function MenuItems({
  viewer,
  fontSize,
  divider,
  onRequestClose,
  showIntercom,
  conflictsCount,
}: any) {
  return (
    <div>
      <UserMenuItem onClick={onRequestClose} fontSize={fontSize} to="/dashboard" text="Dashboard" />
      <UserMenuItem
        onClick={onRequestClose}
        fontSize={fontSize}
        to={viewer.html_url}
        text="Edit Profile"
      />

      {!viewer.expert_state && (
        <UserMenuItem
          onClick={onRequestClose}
          fontSize={fontSize}
          to="/signup/expert"
          text="Become an Expert"
          promo
        />
      )}

      {divider}

      {viewer.admin && (
        <div>
          <UserMenuItem
            onClick={onRequestClose}
            fontSize={fontSize}
            to="/profile-uploader"
            text="Profile Uploader"
          />
          <UserMenuItem
            onClick={onRequestClose}
            fontSize={fontSize}
            to="/profile-conflicts"
            text="Profile Conflicts"
            badgeCount={conflictsCount}
          />
          {divider}
        </div>
      )}

      <UserMenuItem
        onClick={onRequestClose}
        fontSize={fontSize}
        to="/settings"
        text="Settings"
        id="userHeadSettings"
      />

      {showIntercom && (
        <UserMenuItem
          fontSize={fontSize}
          text="Live Chat"
          onClick={() => {
            onRequestClose();
            // @ts-expect-error TS(2339): Property 'HubSpotConversations' does not exist on ... Remove this comment to see the full error message
            window.HubSpotConversations?.widget?.open();
          }}
        />
      )}

      <UserMenuItem
        onClick={() => {
          // @ts-expect-error TS(2322): Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
          window.location = '/logout';
          onRequestClose();
        }}
        fontSize={fontSize}
        to="/logout"
        text="Log Out"
        id="userHeadLogOut"
      />
    </div>
  );
}

class UserMenu extends Component {
  render() {
    return (
      <MediaQuery maxWidth={SCREEN_MD}>
        {(matches: any) =>
          matches ? <MobileMenu {...this.props} theme="dark" /> : <DesktopMenu {...this.props} />
        }
      </MediaQuery>
    );
  }
}

export default connect((state: RootState) => ({
  viewer: state.viewer,
  userContextOptions: state.ui.userContextOptions,
}))(UserMenu);
